import modules from "./modules";
import { createStore } from "vuex";

const store = createStore({
  modules,
});

/**
 * Exporting the primed Store here
 */
export default store;
