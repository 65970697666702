<template>
  <div class="onboarding-step-page-main">
    <div class="onboarding-step-page-content">
      <div v-if="!showDetailedEdit">
        <h2>
          {{
            $tf(
              "onboarding.setupRoles.title|Beállítottunk neked pár szerepkört."
            )
          }}
        </h2>
        <p>
          {{
            $tf(
              "onboarding.setupRoles.subtitle|Ha szeretnéd, ezeket módosíthatod, vagy hozzáadhatsz újakat is. Jelöld ki az alapértelmezett szerepkört, melyet a felhasználókhoz rendelhetünk."
            )
          }}
        </p>
      </div>

      <div v-else>
        <div class="is-flex">
          <h2 v-if="roleEditIndex === null">
            {{ $tf("onboarding.setupRoles.add|Új szerepkör hozzáadása") }}
          </h2>
          <h2 v-else>
            {{ $tf("onboarding.setupRoles.edit|Szerepkör szerkesztése") }}
          </h2>
          <div class="ml-auto">
            <b-icon icon="xmark" @click="cancelEdit" class="cursor-pointer" />
          </div>
        </div>
        <p>
          {{
            $tf(
              "onboarding.setupRoles.edit.message|Add meg a szerepkör nevét, és válaszd ki mely funkciókhoz van jogosultsága."
            )
          }}
        </p>
      </div>

      <div v-if="!showDetailedEdit" class="roles-container">
        <template v-for="(role, index) in roles" :key="'role' + index">
          <div
            v-if="index === roles.findIndex((r) => r.recommendation)"
            :key="'roleRecommendationTitle' + index"
          >
            <h3 class="my-2">
              {{
                $tf(
                  "onboarding.setupRoles.additionalRecommendedRoles|További ajánlott szerepkörök"
                )
              }}
            </h3>
          </div>
          <fieldset
            class="role-card"
            :class="{ 'default-role-container': role.default }"
          >
            <legend v-if="role.default">
              <b-icon icon="star" size="is-small"></b-icon>
              {{ $tf("onboarding.setupRoles.default|Alapértelmezett") }}
            </legend>
            <div
              v-if="role.recommendation"
              @click="useRecommendedRole(index)"
              class="add-circle"
            >
              <b-icon icon="plus-circle" />
            </div>
            <div style="width: 100%">
              <div
                class="is-flex is-justify-content-space-between"
                style="width: 100%"
              >
                <div
                  class="has-text-dark-blue has-font-weight-700 mr-auto"
                  style="font-size: 16px"
                >
                  {{ role.name }}
                </div>
                <p
                  class="cursor-pointer has-text-light-blue is-underlined ml-auto mr-0"
                  @click="selectDefaultRoleIndex(index)"
                  v-if="!role.default && !role.recommendation"
                >
                  {{
                    $tf(
                      "onboarding.setupRoles.makeDefault|Alapértelmezetté teszem"
                    )
                  }}
                </p>
              </div>
              <p>
                {{ role.description }}
              </p>
            </div>
            <div v-if="!role.recommendation" class="actionButtons ml-auto">
              <!--              <b-icon-->
              <!--                icon="pencil"-->
              <!--                size="is-small"-->
              <!--                @click="edit(index)"-->
              <!--                v-if="!role.essential"-->
              <!--              />-->
              <b-icon
                v-if="
                  index !== defaultRoleIndex &&
                  !role.mandatory &&
                  !role.essential
                "
                icon="trash"
                size="is-small"
                @click="remove(index)"
              />
            </div>
          </fieldset>
        </template>
      </div>

      <div v-if="showDetailedEdit" style="width: 100%">
        <div class="permission-edit-input-container">
          <b-input
            v-model="roleEditName"
            size="is-small"
            expanded
            :placeholder="
              $tf('onboarding.setupRoles.edit.name.placeholder|Szerepkör neve')
            "
          />
          <b-input
            v-model="roleEditDescription"
            size="is-small"
            expanded
            :placeholder="
              $tf(
                'onboarding.setupRoles.edit.description.placeholder|Szerepkör leírása'
              )
            "
          />
        </div>
        <div class="permission-group-container">
          <div
            v-for="(permissionGroup, index) in Object.keys(privileges)"
            :key="index"
            class="permission-group is-flex is-flex-direction-column has-gap-2"
          >
            <div>
              <b-checkbox
                :indeterminate="isGroupIndeterminate(permissionGroup)"
                :modelValue="isGroupComplete(permissionGroup)"
                @update:modelValue="
                  (value) => handleGroupSelection(permissionGroup, value)
                "
                class="onboarding-checkbox-group-leader"
              >
                {{ permissionGroup }}
              </b-checkbox>
            </div>
            <div
              v-for="(permission, index) in privileges[permissionGroup]"
              :key="index"
            >
              <b-checkbox
                v-model="roleEditPermissions"
                :native-value="permission.code"
                class="onboarding-checkbox-thin"
              >
                {{ permission.name }}
              </b-checkbox>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="onboarding-step-page-footer">
      <template v-if="!showDetailedEdit">
        <!--        <div>-->
        <!--          <b-button-->
        <!--            class="onboarding-button onboarding-button-transparent onboarding-button-small"-->
        <!--            icon-left="plus"-->
        <!--            @click="edit(null)"-->
        <!--          >-->
        <!--            Újabb hozzáadása-->
        <!--          </b-button>-->
        <!--        </div>-->
        <div class="is-flex ml-auto has-gap-2">
          <b-button
            class="onboarding-button onboarding-button-transparent onboarding-button-small"
            @click="stepBack"
          >
            {{ $tf("onboarding.setupRoles.back|Vissza") }}
          </b-button>
          <b-button
            class="onboarding-button onboarding-button-small"
            type="is-info"
            @click="stepNext"
          >
            {{ $tf("onboarding.setupRoles.continue|Tovább") }}
          </b-button>
        </div>
      </template>
      <template v-else>
        <div class="ml-auto">
          <b-button
            class="onboarding-button onboarding-button-small"
            type="is-info"
            @click="saveRole"
          >
            {{ $tf("onboarding.setupRoles.save|Mentés") }}
          </b-button>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { ONBOARDING_PAGES } from "@/utils/const";
import { deepCopy } from "@/utils/util";
import { mapGetters } from "vuex";

export default {
  name: "OnboardingSetupUserRoles",
  data: function () {
    return {
      showDetailedEdit: false,
      roleEditIndex: null,
      roleEditName: "",
      roleEditDescription: "",
      roleEditPermissions: [],
      ONBOARDING_PAGES,
      roles: [],
    };
  },
  mounted() {
    this.roles = deepCopy(this.$store.getters["onboarding/getRoles"]);
    this.sortByRecommendation();
  },
  computed: {
    ...mapGetters({
      privilegesGetter: "onboarding/getPrivileges",
    }),
    privileges() {
      return this.privilegesGetter ?? [];
    },
    defaultRoleIndex() {
      const index = this.roles.findIndex((role) => role.default);
      return index === -1 ? 0 : index;
    },
  },
  methods: {
    sortByRecommendation() {
      this.roles.sort((a, b) => {
        if (a.recommendation !== b.recommendation) {
          return a.recommendation > b.recommendation ? 1 : -1;
        }
        return a.name?.localeCompare(b.name);
      });
    },
    edit(index) {
      if (index !== null) {
        this.roleEditName = this.roles[index].name;
        this.roleEditDescription = this.roles[index].description;
        this.roleEditPermissions = this.roles[index].privileges;
        this.roleEditIndex = index;
      } else {
        this.roleEditName = "";
        this.roleEditDescription = "";
        this.roleEditPermissions = [];
        this.roleEditIndex = null;
      }
      this.showDetailedEdit = true;
    },
    remove(index) {
      this.roles[index].recommendation = true;
      this.sortByRecommendation();
    },
    saveRole() {
      if (this.roleEditIndex !== null) {
        this.roles[this.roleEditIndex].name = this.roleEditName;
        this.roles[this.roleEditIndex].description = this.roleEditDescription;
        this.roles[this.roleEditIndex].privileges = this.roleEditPermissions;
      } else {
        this.roles.push({
          name: this.roleEditName,
          description: this.roleEditDescription,
          privileges: this.roleEditPermissions,
          default: false,
          mandatory: false,
          recommendation: false,
        });
      }

      this.cancelEdit();
    },
    cancelEdit() {
      this.roleEditIndex = null;
      this.roleEditName = "";
      this.roleEditDescription = "";
      this.roleEditPermissions = [];

      this.sortByRecommendation();

      this.showDetailedEdit = false;
    },
    async save() {
      await this.$store.dispatch("onboarding/updateRoles", this.roles);
      await this.$store.dispatch("onboarding/fetchInfo");
    },
    async stepBack() {
      await this.save();
      this.$emit("nextPage", ONBOARDING_PAGES.COMPANY_PROFILE);
    },
    async stepNext() {
      await this.save();
      this.$emit("nextPage", ONBOARDING_PAGES.SETUP_USER_PARAMETERS);
    },
    isGroupIndeterminate(permissionGroup) {
      let allOfGroup = this.privileges[permissionGroup].length;

      let selected = this.privileges[permissionGroup].filter((prv) =>
        this.roleEditPermissions.includes(prv.code)
      ).length;

      return selected > 0 && selected < allOfGroup;
    },
    isGroupComplete(permissionGroup) {
      let allOfGroup = this.privileges[permissionGroup].length;

      let selected = this.privileges[permissionGroup].filter((prv) =>
        this.roleEditPermissions.includes(prv.code)
      ).length;

      return selected === allOfGroup;
    },
    handleGroupSelection(permissionGroup, value) {
      this.roleEditPermissions
        .filter((prv) =>
          this.privileges[permissionGroup].find((allPrv) => allPrv.code === prv)
        )
        .forEach((prv) =>
          this.roleEditPermissions.splice(
            this.roleEditPermissions.indexOf(prv),
            1
          )
        );
      if (value) {
        this.privileges[permissionGroup].forEach((prv) =>
          this.roleEditPermissions.push(prv.code)
        );
      }
    },
    selectDefaultRoleIndex(newIndex) {
      const oldIndex = this.defaultRoleIndex;
      this.roles[oldIndex].default = false;
      this.roles[newIndex].default = true;
    },
    useRecommendedRole(index) {
      this.roles[index].recommendation = false;
      this.sortByRecommendation();
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/assets/scss/colors.scss";
.roles-container {
  margin-top: 12px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

p.roles-title {
  font-weight: 700;
  font-size: 14px;
}

.actionButtons {
  color: #4e75d9;
  display: flex;
  gap: 16px;
  cursor: pointer;
}

.permission-group-container {
  display: grid;
  margin-top: 16px;
  margin-bottom: 16px;
  width: 100%;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
}

.permission-group > div:not(:first-child) {
  margin-left: 8px;
}

.permission-edit-input-container {
  margin-top: 8px;
  max-width: 512px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.role-card {
  display: flex;
  gap: 8px;
  border-radius: 8px;
  border: 2px solid #cad3de;
  padding: 16px;
  max-width: 812px;
  transition: border-color 0.3s;
}

.select-circle {
  width: 24px;
  height: 24px;
  border-radius: 50px;
  border: 1px solid #cccccc;
  transition: all 0.3s;
}

.select-circle:hover {
  cursor: pointer;
}

.select-circle-active {
  border: 1px solid #aaaaaa;
  background-color: $blue;
}

.add-circle {
  color: $blue;
  cursor: pointer;
}

.default-role-text {
  margin: 0;
  text-decoration: underline;
  cursor: pointer;
  color: $custom-light-blue;
}

.default-role-container {
  border-color: $blue;
  legend {
    color: $blue;
  }
}
</style>
