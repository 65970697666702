<template>
  <WorkScheduleList :user-id="userId" />
</template>

<script>
import { mapGetters } from "vuex";
import WorkScheduleList from "../census/workschedule/WorkScheduleList";

export default {
  name: "MyWorkSchedule",
  components: { WorkScheduleList },
  computed: {
    ...mapGetters({
      userId: "session/userId",
    }),
  },
};
</script>

<style scoped></style>
