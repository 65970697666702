<template>
  <section class="section">
    <div v-if="!userId" class="container">
      <div class="columns">
        <div class="column is-half">
          <h1 class="title">
            {{ $tf("workSchedule.global.title|Dolgozói munkarendek") }}
          </h1>
          <h2 class="subtitle">
            {{
              $tf(
                "workSchedule.global.subtitle|Dolgozók heti munkarendjének megtekintése"
              )
            }}
          </h2>

          <b-field
            :label="$tf('workSchedule.global.search.label|Munkatárs')"
            label-position="on-border"
          >
            <b-autocomplete
              rounded
              v-model="name"
              :data="filteredEmployees"
              :placeholder="
                $tf('workSchedule.global.search.placeholder|Munkatárs')
              "
              icon="search"
              clearable
              field="name"
              @select="(option) => (selectedUser = option)"
            >
              <template #empty>{{
                $tf("workSchedule.global.search.empty|Nincs találat")
              }}</template>
            </b-autocomplete>
          </b-field>
        </div>
        <div class="column is-half">
          <b-field class="is-pulled-right">
            <b-button
              type="is-info"
              icon-left="gear"
              class="work-schedule-edit-button"
              @click="editWorkSchedule"
              v-if="canEditWorkSchedule"
            >
              {{ $tf("workSchedule.global.button.set|Munkarendek beállítása") }}
            </b-button>
          </b-field>
        </div>
      </div>
    </div>

    <div class="container">
      <h1 v-if="userId" class="title">
        {{ $tf("workSchedule.self.title|Munkarendem") }}
      </h1>
      <h2 class="subtitle">
        {{
          $tf(
            "workSchedule.self.subtitle|Heti munkarend és beosztás megtekintése"
          )
        }}
      </h2>
      <div v-if="selectedUser">
        <loading-component>
          <template #loader>
            <div class="weekComponent">
              <div class="columns">
                <div class="column" v-for="index in 7" :key="index + 'wl'">
                  <div-with-loading> </div-with-loading>
                </div>
              </div>
            </div>
          </template>
          <div class="content" v-if="ownWorkSchedules">
            <div class="weekComponent">
              <b-field grouped class="week-select">
                <b-button
                  icon-left="chevron-right"
                  class="left-button"
                  @click="prev()"
                  title="Hátra"
                />
                <b-input
                  expanded
                  :value="
                    formatDate(mondayOfActDate) +
                    ' - ' +
                    formatDate(sundayOfActDate)
                  "
                  disabled
                />
                <b-button
                  icon-left="chevron-right"
                  class="right-button"
                  @click="next()"
                  title="Előre"
                />
              </b-field>
              <div class="columns">
                <div
                  class="column"
                  v-for="dayNumber in 7"
                  :key="'dN' + dayNumber"
                >
                  <div-with-loading>
                    <b-field
                      :label="
                        $tf(DAY_NUMBER_LABEL[dayNumber > 6 ? 0 : dayNumber])
                      "
                    />

                    <template
                      v-if="
                        ownWorkSchedules[indexNames[dayNumber - 1].hours] > 0
                      "
                    >
                      <div class="work-schedule-day-column">
                        <b-field>
                          <portal-icon icon="stopwatch" class="has-text-blue" />
                          <p class="work-schedule-icon-label">
                            {{
                              $tf("workSchedule.display.hours|{hours} óra", {
                                hours:
                                  ownWorkSchedules[
                                    indexNames[dayNumber - 1].hours
                                  ],
                              })
                            }}
                          </p>
                        </b-field>

                        <div v-if="allocatedHoursForDay(dayNumber).length > 0">
                          <b-field
                            v-for="allocation in allocatedHoursForDay(
                              dayNumber
                            )"
                            :key="allocation.id"
                          >
                            <i class="fa fa-briefcase has-text-fc-tertiary" />
                            <p class="work-schedule-icon-label">
                              {{
                                $tf(
                                  "workSchedule.display.allocations|{projectName}: {hours} óra",
                                  {
                                    projectName: allocation.projectData.name,
                                    hours: allocation.hours,
                                  }
                                )
                              }}
                            </p>
                          </b-field>
                        </div>

                        <b-field
                          v-if="
                            isSeatingFeatureEnabled &&
                            ownWorkSchedules[indexNames[dayNumber - 1].site] &&
                            !editMode
                          "
                        >
                          <portal-icon
                            icon="office"
                            class="work-schedule-building-icon"
                          />
                          <p class="work-schedule-icon-label">
                            {{
                              ownWorkSchedules[indexNames[dayNumber - 1].site]
                                .identifier
                            }}
                          </p>
                        </b-field>

                        <b-field
                          v-else-if="isSeatingFeatureEnabled && editMode"
                        >
                          <b-select
                            expanded
                            v-model="
                              siteEditData[indexNames[dayNumber - 1].site]
                            "
                          >
                            <option
                              v-for="value in sites"
                              :key="`sid` + value.id"
                              :value="value.id"
                            >
                              {{ value.identifier }}
                            </option>
                          </b-select>
                        </b-field>
                      </div>
                    </template>
                    <template v-else>
                      <div class="work-schedule-day-column">
                        <img
                          class="work-schedule-vacation"
                          :src="require('@/assets/images/timesheet/guru.svg')"
                          alt="guru"
                        />
                      </div>
                    </template>
                  </div-with-loading>
                </div>
              </div>
            </div>
          </div>
          <div class="content" v-else>
            <div class="column has-text-centered">
              <p class="title">
                {{
                  $tf(
                    "workSchedule.global.noScheduleSet|Nincs munkarend megadva!"
                  )
                }}
              </p>
              <img
                :src="require('@/assets/images/schedule/no-workschedule.svg')"
                alt="Empty"
              />
            </div>
          </div>
          <div v-if="userId">
            <b-button
              v-if="editMode"
              type="is-primary"
              @click="editMyWorkScheduleSites"
              icon-left="save"
              >Mentés</b-button
            >
            <b-button
              v-else
              type="is-primary"
              @click="editMode = true"
              icon-left="edit"
              >Helyszínek módosítása</b-button
            >
          </div>
        </loading-component>
      </div>
      <div v-else>
        {{
          $tf(
            "workSchedule.global.pickUser|Válassz felhasználót a folytatáshoz..."
          )
        }}
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import { formatDate, getMonthName } from "@/utils/util";
import { DAY_NAME_LABEL, DAY_NUMBER_LABEL } from "@/utils/const";
import PortalIcon from "@/components/module/icon/PortalIcon";
import DivWithLoading from "@/components/loading/DivWithLoading.vue";
import LoadingComponent from "@/components/loading/LoadingComponent.vue";
import LoadingMixin from "@/mixins/LoadingMixin";

export default {
  name: "WorkScheduleList",
  components: {
    PortalIcon,
    DivWithLoading,
    LoadingComponent,
  },
  props: {
    userId: {
      type: String,
      required: false,
    },
  },
  mixins: [LoadingMixin],
  async mounted() {
    await this.doStartLoading();
    if (!this.userId) {
      await this.$store.dispatch("employee/fetch");
    } else {
      this.selectedUser = { id: this.userId };
      await this.fetch();
    }
  },
  watch: {
    async selectedUser() {
      if (this.selectedUser) {
        await this.fetch();
      }
    },
  },
  data() {
    return {
      getMonthName,
      DAY_NAME_LABEL,
      name: "",
      selectedUser: null,
      activeElement: null,
      actDate: new Date(),
      userData: null,
      indexNames: [
        { hours: "mondayHours", site: "mondaySite" },
        { hours: "tuesdayHours", site: "tuesdaySite" },
        { hours: "wednesdayHours", site: "wednesdaySite" },
        { hours: "thursdayHours", site: "thursdaySite" },
        { hours: "fridayHours", site: "fridaySite" },
        { hours: "saturdayHours", site: "saturdaySite" },
        { hours: "sundayHours", site: "sundaySite" },
      ],
      siteEditData: {},
      editMode: false,
    };
  },
  computed: {
    DAY_NUMBER_LABEL() {
      return DAY_NUMBER_LABEL;
    },
    isSeatingFeatureEnabled() {
      return this.$store.getters["uiConfigStore/getFlags"]
        .seatingFeatureEnabled;
    },
    ...mapGetters({
      workScheduleMode: "session/workScheduleMode",
      ownWorkSchedules: "work_schedule/ownWorkSchedules",
      sites: "work_schedule/workScheduleSites",
      employees: "employee/employees",
      associations: "association/associations",
    }),
    filteredEmployees() {
      let employees = this.employees || [];
      return employees.filter((element) => {
        if (!this.name || !element.name) return false;
        return (
          element.name
            .toString()
            .toLowerCase()
            .indexOf(
              this.name
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .toLowerCase()
            ) >= 0 ||
          element.username
            .toString()
            .toLowerCase()
            .indexOf(
              this.name
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .toLowerCase()
            ) >= 0
        );
      });
    },
    mondayOfActDate() {
      switch (this.actDate.getDay) {
        case 0:
          return new Date(
            new Date(this.actDate).setDate(this.actDate.getDate() - 6)
          );
        case 1:
          return this.actDate;
        default:
          return new Date(
            new Date(this.actDate).setDate(
              this.actDate.getDate() - this.actDate.getDay() + 1
            )
          );
      }
    },
    sundayOfActDate() {
      return this.actDate.getDay() === 0
        ? new Date(new Date(this.actDate).setDate(this.actDate.getDate() + 7))
        : new Date(
            new Date(this.actDate).setDate(
              this.actDate.getDate() - this.actDate.getDay() + 7
            )
          );
    },
  },
  methods: {
    formatDate,
    async fetch() {
      await this.doStartLoading();
      await this.getUserData();
      await this.getWorkSchedules();
      await this.getResourceAllocation();
      if (this.userId) {
        await this.$store.dispatch("work_schedule/getWorkScheduleSites");
      }
      await this.doFinishLoading();
    },
    async getUserData() {
      if (this.selectedUser) {
        await this.$store.dispatch("census_user/fetchProfile", {
          userId: this.userId ? undefined : this.selectedUser.id,
        });
        this.userData = this.$store.getters["census_user/profile"];
      }
    },
    async getWorkSchedules() {
      this.$store.commit("work_schedule/setOwnWorkSchedules", null);
      if (this.userId) {
        await this.$store.dispatch("work_schedule/getWorkSchedulesForMe", true);
        this.indexNames
          .map((indexName) => indexName.site)
          .forEach((siteProp) => {
            this.siteEditData[siteProp] = this.ownWorkSchedules[siteProp]?.id;
          });
      } else {
        await this.$store.dispatch(
          "work_schedule/getWorkSchedulesForUser",
          this.selectedUser.id
        );
      }
    },
    async getResourceAllocation() {
      if (this.userId) {
        await this.$store.dispatch("association/getMine", {
          from: formatDate(this.mondayOfActDate),
          to: formatDate(this.sundayOfActDate),
        });
      } else {
        await this.$store.dispatch("association/getForUser", {
          employeeId: this.selectedUser.id,
          from: formatDate(this.mondayOfActDate),
          to: formatDate(this.sundayOfActDate),
        });
      }
    },
    async editMyWorkScheduleSites() {
      this.doStartLoading();
      await this.$store.dispatch(
        "work_schedule/updateWorkScheduleSitesByUser",
        this.siteEditData
      );
      await this.getWorkSchedules();
      this.editMode = false;
      this.doFinishLoading();
    },
    editWorkSchedule() {
      if (this.selectedUser) {
        this.$router.push({
          path: "/census/admin/work-schedule/" + this.selectedUser.id,
        });
      } else {
        this.$router.push({
          path: "/census/admin/work-schedule/",
        });
      }
    },
    prev() {
      this.actDate = new Date(this.actDate.setDate(this.actDate.getDate() - 7));
      this.getResourceAllocation();
    },
    next() {
      this.actDate = new Date(this.actDate.setDate(this.actDate.getDate() + 7));
      this.getResourceAllocation();
    },
    allocatedHoursForDay(dayNumber) {
      let dayDate = new Date(
        new Date().setDate(this.mondayOfActDate.getDate() + dayNumber - 1)
      );
      return (
        this.associations?.filter((a) => a.day === formatDate(dayDate)) || []
      );
    },
  },
};
</script>

<style lang="scss">
@import "~@/assets/scss/colors.scss";
.week-select {
  .button,
  .input:disabled {
    border-color: $grey-lighter;
    color: $custom-dark-blue !important;
    background-color: $white !important;
  }

  .left-button {
    transform: rotate(180deg);
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: none;
    padding-left: 0.5rem;
    padding-right: 1rem;
  }

  .right-button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: none;
    padding-left: 0.5rem;
    padding-right: 1rem;
  }

  .left-button,
  .right-button {
    &:hover,
    &:active,
    &:focus {
      border: 1px solid $grey-lighter;
      border-left: none;
    }
  }

  .input:disabled {
    cursor: default !important;
    border-radius: 0 !important;
    border-left: none !important;
    border-right: none !important;
    text-align: center;
  }

  .button {
    width: 0.5rem;
  }

  .control {
    margin-left: 0 !important;
    margin-right: 0 !important;

    .input {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .icon {
    font-size: 0.9rem;
  }

  select {
    border-left: none !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
    border-right: none !important;
    border-radius: 0 !important;
    text-align: center !important;
  }
}
</style>

<style scoped lang="scss">
@import "~@/assets/scss/colors.scss";
.userListTile {
  padding: 10px;
}

.userListContent {
  padding: 10px;
  height: 768px;
  overflow-x: hidden;
  overflow-y: auto;
}
.workScheduleContent {
  padding: 10px;
}
.userSpan {
  font-size: large;
}
.activeElement {
  transform: scale(1.1) translateX(10px);

  background-color: $primary;
  color: $white;
  border-radius: 5px;
}
.userSpan:not(.activeElement):hover {
  transform: scale(1.1) translateX(10px);

  background-color: $grey-lightest;
  border-radius: 5px;
}

.weekStatus {
  padding-top: 50px;
}

.work-schedule-selected-week-checkbox {
  padding-right: 20px;
  border-radius: 4px;
}

.work-schedule-day-column {
  border: 1px solid $grey-lighter;
  border-radius: 10px;
  padding: 24px 24px 40px;
  height: 80%;
}

.work-schedule-icon-label {
  margin-left: 10px;
}
.work-schedule-vacation {
  max-width: 100%;
  max-height: 100%;
}
.work-schedule-building-icon {
  color: $purple;
}
.title {
  color: $custom-dark-blue;
}
.subtitle {
  color: $custom-grayer-blue;
}
.input {
  border-color: $grey-lighter;
  color: $custom-dark-blue !important;
  background-color: $white !important;
}
</style>
