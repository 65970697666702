<template>
  <div>
    <ul>
      <li>
        {{
          $tf(
            "riskManagement.sharedIssues.totalTime|Teljes többszörösen célzott idő:"
          )
        }}
        &nbsp;
        <b>{{
          loading
            ? $tf("riskManagement.sharedIssues.loading|Töltés...")
            : secondToShortWorkDayFormat(issues.sumLoggedSeconds)
        }}</b>
      </li>
      <li>
        {{
          $tf(
            "riskManagement.sharedIssues.totalCost|Teljes többszörös, hibás költség:"
          )
        }}
        <b>{{
          loading
            ? $tf("riskManagement.sharedIssues.loading|Töltés...")
            : moneyify(issues.sumEstimatedCost)
        }}</b>
      </li>
      <li>
        {{ $tf("riskManagement.sharedIssues.issueCount|Jegyek száma:") }}
        <b>{{
          loading
            ? $tf("riskManagement.sharedIssues.loading|Töltés...")
            : issues.issueCount + " db"
        }}</b>
      </li>
    </ul>
    <hr />
    <div class="columns">
      <div class="column is-third">
        <b-field label="Jegykereső" label-position="on-border">
          <b-input type="text" v-model="issueSearch"></b-input
          ><b-tooltip
            label="Ezzel a keresővel közvetlenül Jegy Kulcsra tudsz keresni, és összeszedve látni, hogy mely Mérföldkövek célozzák"
          >
            <portal-icon icon="info-circle" />
          </b-tooltip>
        </b-field>
      </div>
      <div class="column is-two-thirds">
        <ul>
          <li v-for="hit in issueHits" v-bind:key="hit.identifier">
            {{ hit.identifier }} / {{ hit.name }}
          </li>
        </ul>
      </div>
    </div>
    <hr />
    <b-table
      :data="issues?.projects || []"
      paginated
      :per-page="50"
      hoverable
      :loading="loading"
      detailed
      detail-key="identifier"
      :show-detail-icon="true"
    >
      <b-table-column
        field="leadName"
        :label="$tf('riskManagement.sharedIssues.table.user|Projektvezető')"
        searchable
        v-slot="props"
      >
        <user-info
          :user="{
            userId: props.row.leadUserId,
            name: props.row.leadName,
            avatar: props.row.leadAvatar,
            email: props.row.leadEmail,
            phone: props.row.leadPhone,
          }"
          displayMode="long"
          tooltipPosition="bottom"
        />
      </b-table-column>
      <b-table-column
        field="prefix"
        :label="$tf('riskManagement.sharedIssues.table.prefix|Prefix')"
        searchable
        v-slot="props"
      >
        {{ props.row.prefix }}
      </b-table-column>
      <b-table-column
        field="name"
        :label="$tf('riskManagement.sharedIssues.table.name|Projekt')"
        searchable
        v-slot="props"
      >
        <div>{{ props.row.identifier }}</div>
        <div>{{ props.row.name }}</div>
      </b-table-column>
      <b-table-column
        field="issueCount"
        :label="$tf('riskManagement.sharedIssues.table.issues|Jegyek')"
        searchable
        v-slot="props"
      >
        <div>{{ props.row.issueCount }} db</div>
      </b-table-column>
      <b-table-column
        field="sumLoggedSeconds"
        :label="$tf('riskManagement.sharedIssues.table.spent|Költség')"
        searchable
        v-slot="props"
      >
        <div>{{ secondToShortWorkDayFormat(props.row.sumLoggedSeconds) }}</div>
        <div>{{ moneyify(props.row.sumEstimatedCost) }}</div>
      </b-table-column>
      <template #detail="props">
        <b-table
          :data="props.row.issues"
          paginated
          :per-page="50"
          hoverable
          :loading="loading"
        >
          <b-table-column
            field="tigName"
            :label="$tf('riskManagement.sharedIssues.innerTable.tig|Mérföldkő')"
            centered
            v-slot="innerProps"
          >
            <div>{{ innerProps.row.tigIdentifier }}</div>
            <div>{{ innerProps.row.tigName }}</div>
          </b-table-column>

          <b-table-column
            field="issueKey"
            :label="$tf('riskManagement.sharedIssues.innerTable.issue|Jegy')"
            searchable
            v-slot="innerProps"
          >
            <div>{{ innerProps.row.issueKey }}</div>
            <div>{{ innerProps.row.summary }}</div>
          </b-table-column>

          <b-table-column
            field="spentSeconds"
            :label="$tf('riskManagement.sharedIssues.innerTable.spent|Költség')"
            sortable
            centered
            v-slot="innerProps"
          >
            <div>{{ innerProps.row.spentTime }}</div>
            <div>{{ moneyify(innerProps.row.spentCost) }}</div>
          </b-table-column>

          <b-table-column
            field="spentSeconds"
            :label="
              $tf(
                'riskManagement.sharedIssues.innerTable.issueData|Jegy adatok'
              )
            "
            centered
            v-slot="innerProps"
          >
            <div>{{ innerProps.row.status }}</div>
            <div>{{ innerProps.row.type }}</div>
          </b-table-column>
        </b-table>
      </template>
    </b-table>
  </div>
</template>

<script>
import { secondToShortWorkDayFormat, formatDate, moneyify } from "@/utils/util";
import { mapGetters } from "vuex";
import UserInfo from "@/components/module/info/UserInfo.vue";
import PortalIcon from "@/components/module/icon/PortalIcon.vue";
export default {
  name: "RiskManagementSharedTable",
  components: { PortalIcon, UserInfo },
  computed: {
    ...mapGetters({
      issues: "risk_management/getSharedIssues",
    }),
    issueHits() {
      if (!this.issueSearch || this.loading) return [];
      let hits = [];
      for (let p in this.issues.projects) {
        let project = this.issues.projects[p];
        for (let i in project.issues) {
          let issue = project.issues[i];
          if (
            issue.issueKey &&
            issue.issueKey.includes(this.issueSearch.toUpperCase())
          ) {
            if (hits.filter((h) => h.identifier === issue.tigIdentifier).length)
              continue;
            hits.push({ identifier: issue.tigIdentifier, name: issue.tigName });
          }
        }
      }
      return hits;
    },
  },
  methods: {
    async fetchData() {
      this.loading = true;
      await this.$store.dispatch("risk_management/clearCache");
      await this.$store.dispatch("risk_management/fetchSharedIssues");
      this.loading = false;
    },
  },
  watch: {},
  data() {
    return {
      moneyify: moneyify,
      secondToShortWorkDayFormat: secondToShortWorkDayFormat,
      formatDate: formatDate,
      onlyMyProjects: true,
      showUnknown: false,
      loading: true,
      issueSearch: "",
    };
  },
  async mounted() {
    await this.fetchData();
  },
};
</script>

<style></style>
