<template>
  <div class="mb-4">
    <b-input
      type="password"
      password-reveal
      placeholder="******"
      :modelValue="value"
      @update:modelValue="emitChanges"
    ></b-input>

    <div class="content set-password">
      <ul class="has-text-grey-icon is-size-7 has-text-weight-medium">
        <li v-if="policy.length" :class="{ 'password--correct': isLengthy }">
          {{
            $tf(
              "changePassword.policy.length|Legalább {length} karakter hosszú",
              { length: policy.length }
            )
          }}
          <b-icon
            v-if="isLengthy"
            icon-pack="fa"
            icon="check"
            type="is-success"
            size="is-small"
          ></b-icon>
        </li>
        <li
          v-if="policy.upperLower"
          :class="{ 'password--correct': hasUpperLower }"
        >
          {{
            $tf(
              "changePassword.policy.upperLower|Tartalmaz kis- és nagybetűt is"
            )
          }}
          <b-icon
            v-if="hasUpperLower"
            icon-pack="fa"
            icon="check"
            type="is-success"
            size="is-small"
          ></b-icon>
        </li>
        <li v-if="policy.number" :class="{ 'password--correct': hasDigit }">
          {{ $tf("changePassword.policy.number|Tartalmaz számot") }}
          <b-icon
            v-if="hasDigit"
            icon-pack="fa"
            icon="check"
            type="is-success"
            size="is-small"
          ></b-icon>
        </li>
        <li
          v-if="policy.specialCharacter"
          :class="{ 'password--correct': hasSpecial }"
        >
          {{
            $tf(
              "changePassword.policy.specialCharacter|Tartalmaz különleges karaktert"
            )
          }}
          <b-icon
            v-if="hasSpecial"
            icon-pack="fa"
            icon="check"
            type="is-success"
            size="is-small"
          ></b-icon>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { passwordStrength } from "check-password-strength";

export default {
  name: "PasswordCheckerInput",
  props: {
    modelValue: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      policy: {
        upperLower: true,
        number: true,
        specialCharacter: true,
        length: 8,
      },
      passwordCandidate: "",
    };
  },
  computed: {
    result() {
      return passwordStrength(this.passwordCandidate);
    },
    hasUpperLower() {
      return (
        this.result.contains.includes("lowercase") &&
        this.result.contains.includes("uppercase")
      );
    },
    hasDigit() {
      return this.result.contains.includes("number");
    },
    hasSpecial() {
      return this.passwordCandidate.match(
        /[!"#$%&'()*+,\-./:;<=>?@\[\]^_`{|}~]/
      );
    },
    isLengthy() {
      return this.result.length >= this.policy.length;
    },
    isValid() {
      if (this.policy.upperLower && !this.hasUpperLower) return false;
      if (this.policy.number && !this.hasDigit) return false;
      if (this.policy.specialCharacter && !this.hasSpecial) return false;
      if (!this.isLengthy) return false;

      return true;
    },
  },
  async created() {
    await this.$store.dispatch("passwordPolicy/fetch");
    this.policy = this.$store.getters["passwordPolicy/get"];
  },
  methods: {
    emitChanges(value) {
      this.passwordCandidate = value;

      this.$emit("update:modelValue", value);
      this.$emit("validated", this.isValid);
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/assets/scss/colors.scss";

.password {
  &--correct {
    color: $green;

    span {
      color: inherit !important;
    }
  }
}
</style>
