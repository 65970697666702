<template>
  <div>
    <template v-if="response == null">
      <p>
        {{
          $tf(
            "logging.import.p1|Az Excel import segítségével gyorsan tudod rögzíteni az elvégzett munkát."
          )
        }}
        <br />
        {{
          $tf(
            "logging.import.p2|A feltöltéshez a Forecastify által biztosított sablont kell importálnod. A sablont"
          )
        }}
        <a @click="getTemplate">{{
          $tf("logging.import.downloadLink|itt tudod letölteni")
        }}</a
        >.
      </p>
      <p>
        {{
          $tf(
            "logging.import.p3|A feltöltött fájlnak tartalmaznia kell a fejléceket és az oszlopok nem törölhetőek. Amennyiben kezdési időpont nem kerül megadásra, az idő a nap legelejére (8:00) lesz rögzítve."
          )
        }}
      </p>
      <b-upload
        :loading="isImportInProgress"
        drag-drop
        expanded
        @input="($event) => onUpload($event)"
      >
        <section class="section">
          <div class="content has-text-centered">
            <span v-if="!isImportInProgress">
              <p>
                <b-icon icon="upload" size="is-large"> </b-icon>
              </p>
              <p>
                {{
                  $tf(
                    "logging.import.uploadText|Húzd ide a fájlt vagy kattints a feltöltéshez."
                  )
                }}
              </p>
            </span>
          </div>
        </section>
      </b-upload>
    </template>
    <template v-else>
      <h4 class="subtitle mb-4">
        {{ $tf("logging.import.result.subtitle|Import eredménye") }}
      </h4>
      <b-button icon-left="chevron-left" class="mb-4" @click="response = null"
        >Vissza</b-button
      >
      <p>
        {{
          $tf(
            "logging.import.result.lines|Az import során {lineCount} sort találtunk, ezek eredményét a táblázatban láthatod.",
            { lineCount: response.length }
          )
        }}
      </p>
      <b-table
        :data="response"
        detailed
        detail-key="date"
        custom-detail-row
        :show-detail-icon="true"
      >
        <b-table-column field="date" label="Dátum" v-slot="props">
          <span class="has-font-weight-700">
            {{ props.row.date }}
          </span>
        </b-table-column>
        <b-table-column field="issueKey" label="Projekt jegy" v-slot="props">
          <span class="has-font-weight-700">{{
            props.row.worklogs[0].issueKey
          }}</span>
          {{
            $tf(
              "logging.import.result.remainingKeys|és {remainingKeys} további",
              { remainingKeys: props.row.worklogs.length - 1 }
            )
          }}
        </b-table-column>
        <b-table-column field="started" label="Kezdési időpont" v-slot="props">
          {{ props.row.started }}
        </b-table-column>
        <b-table-column field="seconds" label="Időtartam" v-slot="props">
          {{ secondsToJiraTime(props.row.seconds) }}
        </b-table-column>
        <b-table-column field="comment" label="Megjegyzés" v-slot="props">
          {{ props.row.comment }}
        </b-table-column>

        <template #detail="props">
          <tr v-for="(item, i) in props.row.worklogs" :key="i">
            <td></td>
            <td>
              <b-tooltip
                :label="
                  $tf(
                    'logging.import.result.invalidIssueKey|A megadott jegy nem található a rendszerben! Az adott sor nem lesz rögzítve!'
                  )
                "
                multilined
                type="is-danger"
              >
                <b-icon
                  v-if="!item.issueId"
                  icon="triangle-exclamation"
                  type="is-danger"
                ></b-icon>
              </b-tooltip>
            </td>
            <td class="has-font-weight-700">
              {{ item.issueKey }}
            </td>
            <td>{{ item.started }}</td>
            <td>{{ secondsToJiraTime(item.seconds) }}</td>
            <td>{{ item.comment }}</td>
          </tr>
        </template>
      </b-table>
      <b-button
        type="is-primary"
        class="is-pulled-right"
        @click="postWorklogs"
        :loading="isUploadInProgress"
        >Munka rögzítése</b-button
      >
    </template>
  </div>
</template>

<script>
import { secondsToJiraTime } from "@/utils/util";
import FileSaver from "file-saver";

export default {
  name: "LoggingImport",
  methods: {
    secondsToJiraTime,
    async getTemplate() {
      let data = await this.$store.dispatch("worklog/exportTemplate");
      FileSaver.saveAs(data, "worklog_template.xlsx");
    },
    async onUpload(event) {
      if (event.target.files?.length > 0) {
        this.isImportInProgress = true;
        let formData = new FormData();
        formData.append("file", event.target.files[0]);
        this.response = await this.$store.dispatch(
          "worklog/importLogs",
          formData
        );
        event.target.value = "";
        this.isImportInProgress = false;
      }
    },
    async postWorklogs() {
      this.isUploadInProgress = true;
      let worklogs = [];
      this.response.forEach((day) => {
        worklogs.push(
          day.worklogs
            .filter((worklog) => worklog.issueId != null)
            .map((worklog) => {
              return {
                issueKey: worklog.issueKey,
                issueId: worklog.issueId,
                timeSpentSeconds: worklog.seconds,
                comment: worklog.comment,
                started: new Date(`${day.date}T${worklog.started}`),
              };
            })
        );
      });
      try {
        await this.$store.dispatch("worklog/postWorklogs", worklogs.flat());
      } finally {
        this.isUploadInProgress = false;
      }
    },
  },
  data() {
    return {
      file: null,
      isImportInProgress: false,
      isUploadInProgress: false,
      response: null,
    };
  },
};
</script>

<style scoped lang="scss"></style>
