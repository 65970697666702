<template>
  <div>
    <table aria-label="since-start" class="whole-table">
      <thead>
        <tr>
          <th></th>
          <th class="icon-cell"></th>
          <th>{{ $tf("projectPage.doneStatus.table.mwd|Munkaidő") }}</th>
          <th class="icon-cell"></th>
          <th>
            {{ $tf("projectPage.doneStatus.table.budgetSpent|Büdzsé/Költés") }}
          </th>
          <th class="icon-cell"></th>
          <th>{{ $tf("projectPage.doneStatus.table.time|Átfutás") }}</th>
          <th>{{ $tf("projectPage.doneStatus.table.dueDate|Határidő") }}</th>
          <th class="icon-cell"></th>
          <th>{{ $tf("projectPage.doneStatus.table.income|Bevétel") }}</th>
          <th class="icon-cell"></th>
          <th>{{ $tf("projectPage.doneStatus.table.profit|Profit") }}</th>
          <th>
            {{ $tf("projectPage.doneStatus.table.profitPercentage|Profit%") }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr class="plan-row">
          <td class="has-font-weight-500">
            {{ $tf("projectPage.doneStatus.table.plan|Terv") }}
          </td>
          <td class="icon-cell"></td>
          <td>{{ workdayify(commitment) }}</td>
          <td class="icon-cell"></td>
          <td>{{ moneyify(budget) }}</td>
          <td class="icon-cell"></td>
          <td>{{ numberify(timeDue) }} nap</td>
          <td>
            {{ formatDate(dueDate) }}
          </td>
          <td class="icon-cell"></td>
          <td>{{ moneyify(income) }}</td>
          <td class="icon-cell"></td>
          <td>{{ moneyify(profit) }}</td>
          <td>
            {{ percentify(plannedProfitPercent) }}
          </td>
        </tr>
        <tr class="fact-row">
          <td class="has-font-weight-500">
            {{ $tf("projectPage.doneStatus.table.fact|Tény") }}
          </td>
          <td class="icon-cell"></td>
          <td>{{ workdayify(spentMd) }}</td>
          <td class="icon-cell"></td>
          <td>{{ moneyify(spentBudget) }}</td>
          <td class="icon-cell"></td>
          <td>{{ numberify(timeEnd) }} nap</td>
          <td>
            {{ formatDate(projectData.endDate) }}
          </td>
          <td class="icon-cell"></td>
          <td>{{ moneyify(income) }}</td>
          <td class="icon-cell"></td>
          <td>{{ moneyify(income - spentBudget) }}</td>
          <td>
            {{ percentify(finalProfitPercent) }}
          </td>
        </tr>
        <tr class="blank-row-small">
          <td colspan="8"></td>
        </tr>
        <tr>
          <td
            class="is-rounded bg-lightgray is-vertical-align-middle has-font-weight-500"
          >
            {{ $tf("projectPage.doneStatus.table.planVsFact|Terv vs tény") }}
          </td>
          <td class="icon-cell">
            <div
              class="diff-icon"
              :style="{
                background:
                  diffPercentMd > 0 ? currentColors.red : currentColors.green,
              }"
            >
              <b-icon
                size="is-small"
                :icon="diffPercentMd > 0 ? 'circle-exclamation' : 'thumbs-up'"
                :icon-pack="diffPercentMd > 0 ? 'fa' : 'far'"
              ></b-icon>
            </div>
          </td>
          <td>{{ workdayify(diffMd) }} ({{ percentify(diffPercentMd) }})</td>
          <td class="icon-cell">
            <div
              class="diff-icon"
              :style="{
                background:
                  diffPercentSpentBudget > 0
                    ? currentColors.red
                    : currentColors.green,
              }"
            >
              <b-icon
                size="is-small"
                :icon="
                  diffPercentSpentBudget > 0
                    ? 'circle-exclamation'
                    : 'thumbs-up'
                "
                :icon-pack="diffPercentSpentBudget > 0 ? 'fa' : 'far'"
              ></b-icon>
            </div>
          </td>
          <td>
            {{ moneyify(diffSpentBudget) }} ({{
              percentify(diffPercentSpentBudget)
            }})
          </td>
          <td class="icon-cell">
            <div
              class="diff-icon"
              :style="{
                background:
                  diffPercentTime > 0 ? currentColors.red : currentColors.green,
              }"
            >
              <b-icon
                size="is-small"
                :icon="diffPercentTime > 0 ? 'circle-exclamation' : 'thumbs-up'"
                :icon-pack="diffPercentTime > 0 ? 'fa' : 'far'"
              ></b-icon>
            </div>
          </td>
          <td>
            {{ numberify(diffTime) }} nap ({{ percentify(diffPercentTime) }})
          </td>
          <td></td>
          <td class="icon-cell">
            <div
              class="diff-icon"
              :style="{
                background:
                  diffPercentIncome < 0
                    ? currentColors.red
                    : currentColors.green,
              }"
            >
              <b-icon
                size="is-small"
                :icon="
                  diffPercentIncome < 0 ? 'circle-exclamation' : 'thumbs-up'
                "
                :icon-pack="diffPercentIncome < 0 ? 'fa' : 'far'"
              ></b-icon>
            </div>
          </td>
          <td>
            {{ moneyify(diffIncome) }} ({{ percentify(diffPercentIncome) }})
          </td>
          <td class="icon-cell">
            <div
              class="diff-icon"
              :style="{
                background:
                  diffPercentProfit < 0
                    ? currentColors.red
                    : currentColors.green,
              }"
            >
              <b-icon
                size="is-small"
                :icon="
                  diffPercentProfit < 0 ? 'circle-exclamation' : 'thumbs-up'
                "
                :icon-pack="diffPercentProfit < 0 ? 'fa' : 'far'"
              ></b-icon>
            </div>
          </td>
          <td>
            {{ moneyify(diffProfit) }} ({{ percentify(diffPercentProfit) }})
          </td>
          <td>{{ percentify(diffProfitPercent) }}</td>
        </tr>
        <tr class="blank-row-small">
          <td colspan="8"></td>
        </tr>
        <tr>
          <td class="has-font-weight-500">KPI</td>
          <td class="icon-cell"></td>
          <td>
            <b-tag
              :icon="riskMd > 1 ? 'circle-exclamation' : 'thumbs-up'"
              :icon-pack="riskMd > 1 ? 'fa' : 'far'"
              :type="getRiskColor(riskMd)"
              class="rpi-tag"
              size="is-medium"
            >
              {{ $filterRiskKpi(riskMd) }}
            </b-tag>
          </td>
          <td class="icon-cell"></td>
          <td>
            <b-tag
              :icon="riskBudget > 1 ? 'circle-exclamation' : 'thumbs-up'"
              :icon-pack="riskBudget > 1 ? 'fa' : 'far'"
              :type="getRiskColor(riskBudget)"
              class="rpi-tag"
              size="is-medium"
            >
              {{ $filterRiskKpi(riskBudget) }}
            </b-tag>
          </td>
          <td class="icon-cell"></td>
          <td>
            <b-tag
              :icon="riskTime > 1 ? 'circle-exclamation' : 'thumbs-up'"
              :icon-pack="riskTime > 1 ? 'fa' : 'far'"
              :type="getRiskColor(riskTime)"
              class="rpi-tag"
              size="is-medium"
            >
              {{ $filterRiskKpi(riskTime) }}
            </b-tag>
          </td>
        </tr>
        <tr class="blank-row-big">
          <td colspan="8"></td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import {
  formatDate,
  getCurrentColorSet,
  moneyify,
  numberify,
  percentify,
  roundToTwoDecimals,
  workdayify,
} from "@/utils/util";
import StatusMixin from "../../../../mixins/StatusMixin";
import { PROJECT_PROGRESS_TYPE } from "@/utils/const";

export default {
  name: "DoneWholeTable",
  mixins: [StatusMixin],
  props: {
    projectDetails: {
      type: Object,
      required: true,
    },
    projectData: {
      type: Object,
      required: true,
    },
  },
  computed: {
    currentColors() {
      return getCurrentColorSet();
    },
    notTracked() {
      return this.projectDetails.progressType === PROJECT_PROGRESS_TYPE.NONE;
    },
    commitment() {
      return this.projectDetails.commitment;
    },
    spentMd() {
      if (this.notTracked) return "-";
      return this.projectDetails.sumSpentMd;
    },
    diffMd() {
      if (this.notTracked) return "-";
      return this.spentMd - this.commitment;
    },
    diffPercentMd() {
      if (this.notTracked) return "-";
      return this.diffMd / this.commitment;
    },
    riskMd() {
      if (this.notTracked) return "-";
      return roundToTwoDecimals(this.spentMd / this.commitment);
    },
    planningCommitment() {
      if (this.notTracked) return "-";
      return this.projectDetails.forecastPlanningMd;
    },
    diffPlanningMd() {
      if (this.notTracked) return "-";
      return this.planningCommitment - this.commitment;
    },
    diffPercentPlanningMd() {
      if (this.notTracked) return "-";
      return this.diffPlanningMd / this.commitment;
    },
    riskPlanningMd() {
      if (this.notTracked) return "-";
      return roundToTwoDecimals(this.planningCommitment / this.commitment);
    },

    budget() {
      return this.projectDetails.budget;
    },
    spentBudget() {
      if (this.notTracked) return "-";
      return this.projectDetails.sumSpentBudget;
    },
    diffSpentBudget() {
      if (this.notTracked) return "-";
      return this.spentBudget - this.budget;
    },
    diffPercentSpentBudget() {
      if (this.notTracked) return "-";
      return this.diffSpentBudget / this.budget;
    },
    riskBudget() {
      if (this.notTracked) return "-";
      return roundToTwoDecimals(this.spentBudget / this.budget);
    },
    planningBudget() {
      if (this.notTracked) return "-";
      return this.projectDetails.forecastPlanningBudget;
    },
    diffPlanningBudget() {
      if (this.notTracked) return "-";
      return this.planningBudget - this.budget;
    },
    diffPercentPlanningBudget() {
      if (this.notTracked) return "-";
      return this.diffPlanningBudget / this.budget;
    },
    riskPlanningBudget() {
      if (this.notTracked) return "-";
      return roundToTwoDecimals(this.planningBudget / this.budget);
    },

    timeDue() {
      return this.projectDetails.daysBetweenDue;
    },
    timeEnd() {
      return this.projectDetails.daysBetweenEnd;
    },
    diffTime() {
      if (this.notTracked) return "-";
      return this.timeEnd - this.timeDue;
    },
    diffPercentTime() {
      if (this.notTracked) return "-";
      return this.diffTime / this.timeDue;
    },
    riskTime() {
      if (this.notTracked) return "-";
      return roundToTwoDecimals(this.timeEnd / this.timeDue);
    },

    dueDate() {
      return new Date(this.projectDetails.dueDate);
    },

    income() {
      return this.projectDetails.expectedIncome;
    },
    finalIncome() {
      if (this.notTracked) return "-";
      return this.income;
    },
    diffIncome() {
      if (this.notTracked) return "-";
      return this.finalIncome - this.income;
    },
    diffPercentIncome() {
      if (this.notTracked) return "-";
      return this.diffIncome / this.income;
    },

    profit() {
      return this.income - this.budget;
    },
    finalProfit() {
      if (this.notTracked) return "-";
      return this.income - this.spentBudget;
    },
    diffProfit() {
      if (this.notTracked) return "-";
      return this.finalProfit - this.profit;
    },
    diffPercentProfit() {
      if (this.notTracked) return "-";
      return this.diffProfit / this.profit;
    },

    plannedProfitPercent() {
      return this.profit / this.income;
    },
    finalProfitPercent() {
      return (this.income - this.spentBudget) / this.income;
    },
    diffProfitPercent() {
      return this.finalProfitPercent - this.plannedProfitPercent;
    },
  },
  data() {
    return {
      formatDate,
      percentify,
      moneyify,
      workdayify,
      numberify,
    };
  },
  methods: {
    getRiskColor(value) {
      if (value === 1) return "is-warning";
      if (value > 10) return "is-fatal";
      if (value > 1) return "is-danger";
      return "is-success";
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/assets/scss/colors.scss";

.is-vertical-align-middle {
  vertical-align: middle !important;
}

.is-rounded {
  border-radius: 8px;
}

.bg-lightgray {
  background: $grey-lighter;
}

.blank-row-big {
  height: 24px !important;
}

.blank-row-big > td {
  padding: 0;
}

.blank-row-small {
  height: 6px !important;
  line-height: 0;
}

.blank-row-small > td {
  padding: 0;
}

.whole-table th,
.whole-table td {
  border: none !important;
  color: $custom-dark-blue;
}

.plan-row > td {
  background: $forecast-primary;
  color: $white !important;
}

.forecast-trend-row > td {
  background: $forecast-secondary;
  color: $white !important;
}

.forecast-plan-row > td {
  background: $forecast-tertiary;
  color: $white;
}

.plan-row > td:first-child,
.forecast-trend-row > td:first-child,
.forecast-plan-row > td:first-child {
  border-top-left-radius: 8px;
}
.plan-row > td:last-child,
.forecast-trend-row > td:last-child,
.forecast-plan-row > td:last-child {
  border-top-right-radius: 8px;
}

.rpi-tag {
  width: 130px;
  font-weight: 500;
}

.icon-cell {
  width: 28px;
  padding: 0 !important;
  vertical-align: middle;
}

.diff-icon {
  background: $red;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  color: $white;
  margin: 2px;
  height: 2rem;
}
.fact-row > td {
  background: $black;
  color: $white !important;
}
.fact-row > td:first-child {
  border-bottom-left-radius: 8px;
}
.fact-row > td:last-child {
  border-bottom-right-radius: 8px;
}
</style>
