<template>
  <i
    class="ip"
    :class="`ip-${icon} ${type ? type : ''} ${size ? size : ''}`"
  ></i>
</template>

<script>
export default {
  name: "PortalIcon",
  props: {
    icon: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: false,
    },
    size: {
      type: String,
      required: false,
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../../assets/scss/colors";

.is-info {
  color: $custom-light-blue;
}

.is-title {
  color: $custom-dark-blue;
}

.is-danger {
  color: $red;
}

.is-purple {
  color: $purple;
}

.is-small {
  font-size: 0.8rem;
}

.is-big {
  font-size: 1.25rem;
}

.is-extra-big {
  font-size: 1.5rem;
}
</style>
