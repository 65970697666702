<template>
  <div>
    <div v-if="displayMode === 'minimal'">
      <b-tooltip
        :position="`is-${tooltipPosition}`"
        multilined
        :active="active"
        always
      >
        <figure class="image is-48x48 mx-1">
          <img
            :src="
              client?.avatar ??
              'https://cdn2.iconfinder.com/data/icons/social-flat-buttons-3/512/anonymous-512.png'
            "
            class="is-rounded"
            @mouseenter="showTooltip"
            @mouseleave="delayedHideTooltip"
          />
        </figure>
        <template v-slot:content>
          <div
            @mouseenter="showTooltip"
            @mouseleave="delayedHideTooltip"
            @click.stop
          >
            {{ client?.identifier }}<br />
            {{ client?.name }}<br />
          </div>
        </template>
      </b-tooltip>
    </div>
    <div v-else-if="displayMode === 'long'" class="media is-align-items-center">
      <figure class="image is-48x48 mx-2 mb-0 is-block">
        <img
          :src="
            client?.avatar ??
            'https://cdn2.iconfinder.com/data/icons/social-flat-buttons-3/512/anonymous-512.png'
          "
          class="is-rounded"
          @mouseenter="showTooltip"
          @mouseleave="delayedHideTooltip"
        />
      </figure>
      <div class="media-content">
        <p class="title is-5">{{ client?.identifier }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      active: false,
    };
  },
  props: {
    client: {
      type: Object,
      required: true,
      default: () => ({
        identifier: "",
        name: "",
        avatar: "",
      }),
    },
    displayMode: {
      type: String,
      required: true,
      validator: (value) => ["minimal", "long"].includes(value),
    },
    tooltipPosition: {
      type: String,
      validator: function (value) {
        return ["top", "right", "bottom", "left"].includes(value);
      },
    },
  },
  methods: {
    showTooltip() {
      if (this.hideTimeout) {
        clearTimeout(this.hideTimeout);
        this.hideTimeout = null;
      }
      this.active = true;
    },
    delayedHideTooltip() {
      this.hideTimeout = setTimeout(() => {
        this.active = false;
      }, 10);
    },
  },
};
</script>

<style scoped></style>
