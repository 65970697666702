import { newLogger } from "@/utils/util";
import StoreCore from "../../../StoreCore";

let logger = newLogger("ApiKeyStore");

const urls = {
  list: "/bulwark/api-key",
  withId: (id) => `/bulwark/api-key/${id}`,
};

/**
 * Api key store module
 */

const initState = () => {
  return {
    apiKeys: [],
  };
};

const storeCore = new StoreCore();
const actions = {
  list: async function ({ commit, dispatch }, force) {
    try {
      let data = await this.remoteRequest(
        "get",
        urls.list,
        null,
        true,
        dispatch,
        undefined,
        null,
        force
      );
      commit("saveList", data);
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  create: async function ({ dispatch }, apiKey) {
    await this.remoteRequest(
      "post",
      urls.list,
      apiKey,
      false,
      dispatch,
      undefined,
      null,
      false
    );
  }.bind(storeCore),
  update: async function ({ dispatch }, apiKey) {
    await this.remoteRequest(
      "put",
      urls.withId(apiKey.id),
      apiKey,
      false,
      dispatch,
      undefined,
      null,
      false
    );
  }.bind(storeCore),
  delete: async function ({ dispatch }, id) {
    await this.remoteRequest(
      "delete",
      urls.withId(id),
      null,
      false,
      dispatch,
      undefined,
      null,
      false
    );
  }.bind(storeCore),
  clearCache: async function ({}) {
    this.clearCoreCache();
  }.bind(storeCore),
};

const mutations = {
  saveList: (state, apiKeys) => {
    state.apiKeys = apiKeys;
  },
};

const getters = {
  list: (state) => {
    return state.apiKeys;
  },
};

export default {
  namespaced: true,
  state: initState(),
  mutations: mutations,
  actions: actions,
  getters: getters,
};
