<template>
  <section class="section">
    <div class="container">
      <h1 class="title">{{ $tf("teamList.title|Csapatok") }}</h1>
      <h2 class="subtitle">
        {{ $tf("teamList.subtitle|Csapatok létrehozása, kezelése") }}
      </h2>

      <b-button
        type="is-info"
        class="is-pulled-top-right"
        @click="toTeamEdit()"
        icon-left="plus-circle"
        >{{ $tf("teamList.new|Új csapat") }}
      </b-button>
    </div>

    <div class="container">
      <h3 class="heading">
        {{ $tf("teamList.employeeTeams.heading|Dolgozói csapatok") }}
      </h3>
      <b-table :data="teams">
        <b-table-column
          field="identifier"
          :label="$tf('teamList.table.identifier|Azonosító')"
          sortable
          searchable
          v-slot="props"
        >
          {{ props.row.identifier }}
        </b-table-column>
        <b-table-column
          field="name"
          :label="$tf('teamList.table.name|Csapat neve')"
          sortable
          searchable
          v-slot="props"
        >
          {{ props.row.name }}
        </b-table-column>
        <b-table-column
          field="employees.length"
          :label="$tf('teamList.table.memberCount|Dolgozók száma')"
          v-slot="props"
          sortable
          numeric
        >
          {{ props.row.employees.length }}
        </b-table-column>
        <b-table-column
          field="leads.length"
          :label="$tf('teamList.table.leadCount|Vezetők száma')"
          v-slot="props"
          sortable
          numeric
        >
          {{ props.row.leads.length }}
        </b-table-column>
        <b-table-column
          field="row.projects.length"
          :label="$tf('teamList.table.projectCount|Projektek száma')"
          v-slot="props"
          sortable
          numeric
        >
          {{ props.row.projects.length }}
        </b-table-column>

        <b-table-column v-slot="props" cell-class="do-not-wrap" width="32">
          <clickable-icon
            portal-icon
            icon="pencil"
            type="is-info"
            class="mr-2 is-align-self-center"
            @click="toTeamEdit(props.row.identifier)"
          ></clickable-icon>
          <clickable-icon
            portal-icon
            icon="trash"
            type="is-danger"
            @click="deleteTeam(props.row.identifier)"
          ></clickable-icon>
        </b-table-column>
      </b-table>
    </div>
    <b-collapse
      class="card"
      style="margin-right: 2rem; margin-left: 2rem"
      animation="slide"
      aria-id="colleaguesWithoutTeam"
      :open="false"
    >
      <template #trigger="props">
        <div
          class="card-header"
          style="padding: 24px 40px 16px"
          role="button"
          aria-controls="colleaguesWithoutTeam"
          :aria-expanded="props.open"
        >
          <h3 class="heading card-header-title my-auto">
            {{
              $tf(
                "teamList.teamlessColleagues|Csapat nélküli kollégák száma: {count} db",
                { count: teamlessColleagues.length }
              )
            }}
          </h3>
          <a class="card-header-icon">
            <b-icon :icon="props.open ? 'chevron-up' : 'chevron-down'">
            </b-icon>
          </a>
        </div>
      </template>

      <div
        class="card-content pb-1"
        style="padding-left: 40px; padding-right: 40px"
      >
        <div class="content">
          <b-table
            :data="teamlessColleagues"
            ref="table"
            striped
            hoverable
            :show-detail-icon="true"
            class="mb-6"
          >
            <b-table-column
              field="name"
              :label="$tf('teamList.teamlessTable.name|Név')"
              v-slot="props"
              sortable
              :custom-sort="
                (a, b, isAsc) =>
                  isAsc
                    ? Intl.Collator('hu').compare(a.name, b.name)
                    : Intl.Collator('hu').compare(b.name, a.name)
              "
            >
              <div
                class="usernameField is-flex is-align-items-center is-justify-content-start"
              >
                <figure
                  class="image is-32x32 ml-0 mt-0 mb-0 mr-3"
                  style="flex: 0 0 32px; max-width: 32px"
                >
                  <img
                    :src="
                      props.row.avatar
                        ? props.row.avatar
                        : 'https://cdn2.iconfinder.com/data/icons/social-flat-buttons-3/512/anonymous-512.png'
                    "
                    alt="Avatar"
                    class="is-rounded"
                  />
                </figure>
                <div class="is-flex-1">
                  <p>{{ props.row.name }}</p>
                </div>
              </div>
            </b-table-column>

            <b-table-column field="" label=" " v-slot="props">
              <b-tag v-if="props.row.external" type="is-danger">{{
                $tf("teamList.teamlessTable.external|Külsős")
              }}</b-tag>
              <b-tag v-else type="is-success">{{
                $tf("teamList.teamlessTable.internal|Belsős")
              }}</b-tag>
            </b-table-column>

            <b-table-column
              field="title"
              :label="$tf('teamList.teamlessTable.title|Titulus')"
              v-slot="props"
              sortable
            >
              {{ props.row.title }}
            </b-table-column>

            <b-table-column
              field="seniority"
              :label="$tf('teamList.teamlessTable.seniority|Szenioritás')"
              v-slot="props"
              sortable
            >
              {{ props.row.seniority }}
            </b-table-column>

            <b-table-column
              field="department"
              :label="$tf('teamList.teamlessTable.department|Terület')"
              v-slot="props"
              sortable
            >
              {{ props.row.department }}
            </b-table-column>

            <b-table-column
              field="competencies"
              :label="$tf('teamList.teamlessTable.competencies|Kompetenciák')"
              v-slot="props"
            >
              <b-tag
                v-for="(competency, index) in props.row.competencies"
                :key="index"
                icon="book"
                type="is-light"
              >
                {{ competency.name }}
              </b-tag>
            </b-table-column>
          </b-table>
        </div>
      </div>
    </b-collapse>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import { openDeleteConfirm } from "@/utils/util";
import { TEAM_PROJECT_STATUS } from "@/utils/const";
import ClickableIcon from "../../../components/module/icon/ClickableIcon";
import eventBus from "../../../utils/eventBus";
import LoadingMixin from "@/mixins/LoadingMixin";

export default {
  name: "TeamList",
  components: {
    ClickableIcon,
  },
  mixins: [LoadingMixin],
  async mounted() {
    await this.doStartLoading();
    await this.fetchData();
    await this.doFinishLoading();
  },
  computed: {
    ...mapGetters({
      teams: "census_team/teams",
      teamlessColleagues: "employee/teamlessEmployees",
    }),
  },
  data() {
    return {
      TEAM_PROJECT_STATUS,
    };
  },
  methods: {
    async fetchData(force) {
      await this.$store.dispatch("census_team/fetchTeams", force);
      await this.$store.dispatch("employee/fetchTeamlessColleagues", force);
    },
    async toTeamEdit(id) {
      await this.$router.push({
        path: `/census/team/${id ? id : ""}`,
      });
    },
    async deleteTeam(id) {
      openDeleteConfirm(this.$buefy, async () => {
        await this.$store.dispatch("census_team/deleteTeam", id);
        await this.fetchData(true);
      });
    },
  },
};
</script>

<style></style>
