<template>
  <div class="container">
    <p class="title">
      {{ $tf("specialDays.editModal.title|Különleges nap módosítása") }}
    </p>
    <hr />
    <form @submit.prevent="submit">
      <b-field :expanded="true">
        <vuelidated-form :validations="v$.formData.type">
          <template v-slot="{ errors, invalid }">
            <b-field
              :expanded="true"
              :type="{ 'is-danger': invalid }"
              :message="v$.formData.type.$error ? errors[0] : null"
              label-for="type"
            >
              <template #label>
                {{ $tf("specialDays.editModal.type|Type") }}
                <span class="has-text-danger">*</span>
              </template>
              <b-autocomplete
                :expanded="true"
                v-model="typeDisplaySelected"
                :data="filteredTypeArray"
                :placeholder="
                  $tf('specialDays.editModal.type.placeholder|Nap típusa')
                "
                clearable
                open-on-focus
                field="display"
                @select="(option) => (formData.type = option)"
              >
                <template #empty>{{
                  $tf("specialDays.editModal.type.empty|Nincs találat")
                }}</template>
              </b-autocomplete>
            </b-field>
          </template>
        </vuelidated-form>
      </b-field>

      <b-field :expanded="true">
        <vuelidated-input
          :expanded="true"
          v-model="formData.name"
          :placeholder="$tf('specialDays.editModal.name.placeholder|Név')"
          :label="$tf('specialDays.editModal.name|Név')"
          :ref-value="'name'"
          :validation-rule="v$.formData.name"
        />
      </b-field>

      <b-field :expanded="true">
        <vuelidated-input
          :expanded="true"
          v-model="formData.description"
          :placeholder="
            $tf('specialDays.editModal.description.placeholder|Leírás')
          "
          :label="$tf('specialDays.editModal.description|Leírás')"
          :ref-value="'description'"
          :validation-rule="v$.formData.description"
        />
      </b-field>

      <b-field expanded>
        <vuelidated-form :validations="v$.formData.dayDate">
          <template v-slot="{ errors, invalid }">
            <b-field
              :expanded="true"
              :type="{ 'is-danger': invalid }"
              :message="v$.formData.dayDate.$error ? errors[0] : null"
              label-for="dayDate"
            >
              <template #label>
                {{ $tf("specialDays.editModal.date|Dátum") }}
                <span class="has-text-danger">*</span>
              </template>
              <b-datepicker
                :expanded="true"
                :events="specDays"
                indicators="bars"
                ref="dayDate"
                name="dayDate"
                id="dayDate"
                position="is-top-right"
                :placeholder="
                  $tf(
                    'specialDays.editModal.date.placeholder|Dátum kiválasztása'
                  )
                "
                v-model="formData.dayDate"
                icon="calendar-alt"
                trap-focus
                :month-names="getMonthNames"
                :day-names="getDayNames"
                :first-day-of-week="getFirstDayOfWeek"
              />
            </b-field>
          </template>
        </vuelidated-form>
      </b-field>

      <b-field>
        <b-button native-type="submit" type="is-primary" icon-left="edit">
          {{ $tf("specialDays.editModal.edit|Módosítás") }}
        </b-button>
      </b-field>
    </form>
  </div>
</template>

<script>
import { formatDate } from "@/utils/util";
import { maxLength, required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import { mapGetters } from "vuex";
import VuelidatedForm from "@/components/module/input/VuelidatedForm";
import VuelidatedInput from "@/components/module/input/VuelidatedInput";
import { getCurrentInstance } from "vue";

export default {
  name: "SpecialDayModal",
  components: { VuelidatedInput, VuelidatedForm },
  setup: () => {
    const instance = getCurrentInstance();
    return {
      v$: useVuelidate(),
      instance: instance,
    };
  },
  props: {
    specialDayId: {
      type: [String, Number],
      required: true,
    },
    specDays: {
      type: Array,
      required: false,
    },
  },
  watch: {
    async specialDayId() {
      await this.$store.dispatch("specialday/getSpecialDay", this.specialDayId);
      this.formData = this.specialDay;
      this.formData.dayDate = new Date(this.specialDay.dayDate);
      this.typeDisplaySelected = this.formData.type.display;
    },
  },
  computed: {
    ...mapGetters({
      specialDay: "specialday/specialDay",
    }),
    filteredTypeArray() {
      return this.specDayTypes.filter((option) => {
        return (
          option.display
            .toString()
            .toLowerCase()
            .indexOf(this.typeNameSelected.toLowerCase()) >= 0
        );
      });
    },
  },
  data() {
    return {
      formData: {
        type: null,
        name: null,
        description: null,
        dayDate: null,
      },
      formatDate,
      specDayTypes: [
        {
          enum: "WEEKEND",
          display: this.$tf("specialDays.type.weekend|Hétvége"),
        },
        {
          enum: "HOLIDAY",
          display: this.$tf("specialDays.type.holiday|Ünnepnap"),
        },
        {
          enum: "EXTRA_WORKDAY",
          display: this.$tf("specialDays.type.extraWorkday|Munkanap"),
        },
      ],
      typeDisplaySelected: null,
      typeNameSelected: "",
    };
  },
  validations: {
    formData: {
      type: {
        required,
      },
      name: {
        required,
      },
      description: {
        maxLength: maxLength(255),
      },
      dayDate: {
        required,
      },
    },
  },
  methods: {
    submit() {
      this.v$.$touch();
      if (!this.v$.$invalid) {
        let request = {
          id: this.specialDayId,
          type: this.formData.type.enum,
          name: this.formData.name,
          description: this.formData.description,
          dayDate: formatDate(this.formData.dayDate),
        };
        this.$store
          .dispatch("specialday/updateSpecialDay", request)
          .then(() => {
            this.instance.emit("close");
          });
        this.$buefy.toast.open(
          this.$tf("specialDays.editModal.success|Nap módosítva!")
        );
      }
    },

    getMonthNames() {
      return [
        this.$tf("month.january|Január"),
        this.$tf("month.february|Február"),
        this.$tf("month.march|Március"),
        this.$tf("month.april|Április"),
        this.$tf("month.may|Május"),
        this.$tf("month.june|Június"),
        this.$tf("month.july|Július"),
        this.$tf("month.august|Augusztus"),
        this.$tf("month.september|Szeptember"),
        this.$tf("month.october|Október"),
        this.$tf("month.november|November"),
        this.$tf("month.december|December"),
      ];
    },
    getDayNames() {
      return [
        this.$tf("day.short.monday|Hé"),
        this.$tf("day.short.tuesday|Ke"),
        this.$tf("day.short.wednesday|Sze"),
        this.$tf("day.short.thursday|Cs"),
        this.$tf("day.short.friday|Pé"),
        this.$tf("day.short.saturday|Szo"),
        this.$tf("day.short.sunday|Va"),
      ];
    },
    getFirstDayOfWeek() {
      return 1;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/colors.scss";

.container {
  background-color: $white;
  padding: 50px;
  border-radius: 8px;
}
</style>
