<template>
  <component
    v-bind="$attrs"
    :is="isPageLoading ? null : 'div'"
    :animation="isPageLoading ? 'wave-new' : $attrs.animation"
    :height="isPageLoading ? loadingHeight : $attrs.height"
    :width="isPageLoading ? loadingWidth : $attrs.width"
    :rounded="isPageLoading ? true : $attrs.rounded"
    :type="circular ? 'circle' : 'rect'"
  >
    <slot v-if="!hideComponent"></slot>
  </component>
</template>

<script>
import LoadingMixin from "@/mixins/LoadingMixin";

export default {
  name: "DivWithLoading",
  props: {
    loadingWidth: {
      type: [String, Number],
    },
    loadingHeight: {
      type: [String, Number],
    },
    hideComponent: {
      type: Boolean,
      default: false,
    },
    circular: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [LoadingMixin],
  inheritAttrs: false,
};
</script>
