<template>
  <div class="modal-card" style="width: auto">
    <div class="container" style="width: 42rem">
      <section class="modal-card-body">
        <h1 class="title" v-if="createMode">
          {{
            $tf("tiers.fixedPrice.modal.newTitle|Új egyedi árszint felvétele")
          }}
        </h1>
        <h1 class="title" v-else>
          {{
            $tf("tiers.fixedPrice.modal.editTitle|Egyedi árszint szerkesztése")
          }}
        </h1>

        <form v-if="loaded">
          <vuelidated-input
            :label="$tf('tiers.fixedPrice.modal.cost|Óradíj')"
            :placeholder="$tf('tiers.fixedPrice.modal.cost|Óradíj')"
            v-model="editFixedPriceTier.cost"
            :validation-rule="v$.editFixedPriceTier.cost"
            label-position="on-border"
            class="mt-5"
          ></vuelidated-input>

          <vuelidated-form
            :validations="v$.editFixedPriceTier.userId"
            class="mt-5"
          >
            <template v-slot="{ errors, invalid }">
              <b-field
                label-position="on-border"
                :type="{ 'is-danger': invalid }"
                :message="
                  v$.editFixedPriceTier.userId.$error ? errors[0] : null
                "
              >
                <template #label>
                  {{ $tf("tiers.fixedPrice.modal.user|Dolgozó") }}
                  <span class="has-text-danger">*</span>
                </template>

                <b-autocomplete
                  rounded
                  v-model="editFixedPriceTier.displayName"
                  :data="selectableNewUsers"
                  :placeholder="
                    $tf(
                      'tiers.fixedPrice.modal.user.placeholder|pl. Elektrom Ágnes'
                    )
                  "
                  icon="search"
                  clearable
                  field="name"
                  @select="
                    (option) =>
                      (editFixedPriceTier.userId = option ? option.id : null)
                  "
                  @blur="v$.editFixedPriceTier.userId.$touch()"
                  @update:modelValue="
                    selectableNewUsers = filterEmployees(
                      editFixedPriceTier.displayName
                    )
                  "
                >
                  <template #empty>{{
                    $tf("tiers.fixedPrice.modal.user.empty|Nincs találat")
                  }}</template>
                </b-autocomplete>
              </b-field>
            </template>
          </vuelidated-form>

          <b-field
            :label="$tf('tiers.fixedPrice.modal.validFrom|Érvényesség kezdete')"
            label-position="on-border"
            class="mt-5"
          >
            <b-datepicker
              :placeholder="
                $tf(
                  'tiers.fixedPrice.modal.validFrom.placeholder|Kezdeti dátum'
                )
              "
              icon="calendar-alt"
              v-model="editFixedPriceTier.date"
              trap-focus
              append-to-body
            >
            </b-datepicker>
          </b-field>

          <b-field class="mt-5 is-pulled-right">
            <b-button @click="close">
              {{ $tf("tiers.fixedPrice.modal.cancel|Mégse") }}
            </b-button>
            <b-button @click="save" type="is-info" class="ml-3">
              {{ $tf("tiers.fixedPrice.modal.save|Mentés") }}
            </b-button>
          </b-field>
        </form>

        <LoadingSkeleton v-else />
      </section>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import VuelidatedForm from "@/components/module/input/VuelidatedForm";
import VuelidatedInput from "@/components/module/input/VuelidatedInput";
import { deepCopy, employeeFilter, formatDate } from "@/utils/util";
import LoadingSkeleton from "../../../../components/module/loading/LoadingSkeleton";
import { getCurrentInstance } from "vue";

export default {
  name: "FixedPriceTierEditModal",
  components: { LoadingSkeleton, VuelidatedForm, VuelidatedInput },
  setup: () => {
    const instance = getCurrentInstance();
    return {
      v$: useVuelidate(),
      instance: instance,
    };
  },
  props: {
    fixedPriceTier: {
      type: Object,
      required: false,
    },
  },
  async mounted() {
    this.createMode = !this.fixedPriceTier;
    await this.$store.dispatch("employee/fetch");

    if (this.fixedPriceTier) {
      this.editFixedPriceTier = deepCopy(this.fixedPriceTier);
      this.editFixedPriceTier.date = new Date(this.fixedPriceTier.since);
    }

    this.loaded = true;
  },
  data() {
    return {
      loaded: false,
      createMode: false,
      editFixedPriceTier: {
        userId: undefined,
        date: new Date(),
        displayName: undefined,
      },
      selectableNewUsers: [],
      selectableEditUsers: [],
    };
  },
  validations: {
    editFixedPriceTier: {
      userId: {
        required,
      },
      cost: {
        required,
      },
    },
  },
  computed: {
    ...mapGetters({
      employees: "employee/employees",
    }),
  },
  methods: {
    close() {
      this.instance.emit("close");
    },
    filterEmployees(name) {
      return employeeFilter(name, this.employees || []);
    },
    async save() {
      this.v$.editFixedPriceTier.$touch();
      if (!this.v$.editFixedPriceTier.$invalid) {
        let request = deepCopy(this.editFixedPriceTier);
        request.since = formatDate(this.editFixedPriceTier.date);

        if (this.createMode) {
          await this.$store.dispatch(
            "enterprise_tiers/createFixedPriceTier",
            request
          );
        } else {
          await this.$store.dispatch(
            "enterprise_tiers/modifyFixedPriceTier",
            request
          );
        }
        this.$emit("save");
        this.close();
      }
    },
  },
};
</script>
