<template>
  <section class="section">
    <div class="container">
      <h1 class="title">
        {{ $tf("adminAbsenceRequests.title|Összes szabadságékérelem") }}
        <b-tag v-if="waitingRequests" rounded>
          {{ waitingRequests.absenceRequestCount }}
        </b-tag>
      </h1>
      <h2 class="subtitle">
        {{
          $tf(
            "adminAbsenceRequests.subtitle|A rendszerben található összes szabadságkérelem listázása, módosítása."
          )
        }}
      </h2>
    </div>
    <div class="container">
      <AbsenceRequestList></AbsenceRequestList>
    </div>
  </section>
</template>

<script>
import AbsenceRequestList from "@/components/absence/admin/AbsenceRequestList";
import { mapGetters } from "vuex";

export default {
  name: "PortalAdmin",
  components: {
    AbsenceRequestList,
  },
  mounted() {
    this.$store.dispatch("admin/getWaitingRequests");
  },
  data() {
    return {
      isAddUserModalActive: false,
    };
  },
  computed: {
    ...mapGetters({
      waitingRequests: "admin/waitingRequests",
    }),
  },
  methods: {},
};
</script>
<style lang="scss">
@import "~@/assets/scss/colors.scss";

.adminTab .tabs li.is-active a {
  color: $blue;
}
</style>
