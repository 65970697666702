<template>
  <b-table v-if="membersData.length > 0" :data="membersData">
    <b-table-column field="name" label="Név" sortable v-slot="props">
      {{ props.row.name }}
    </b-table-column>
    <b-table-column field="email" label="Elérhetőség" sortable v-slot="props">
      {{ props.row.email }} <br />
      {{ $filterPhone(props.row.phone) }}
    </b-table-column>
    <b-table-column field="title" label="Titulus" sortable v-slot="props">
      <strong>{{ props.row.title }}</strong> <br />
      {{ props.row.department }}
    </b-table-column>
    <b-table-column
      field="roleLabel"
      label="Szerep a csapaton belül"
      sortable
      v-slot="props"
    >
      <b-icon
        :icon="TEAM_LEAD_ROLE.ICON[props.row.role]"
        size="is-small"
        class="mr-1"
      />
      {{ props.row.roleLabel }}
    </b-table-column>

    <b-table-column v-slot="props" cell-class="do-not-wrap">
      <clickable-icon
        portal-icon
        icon="trash"
        type="is-danger"
        @click="deleteMember(props.row)"
      ></clickable-icon>
    </b-table-column>
  </b-table>
</template>

<script>
import { TEAM_LEAD_ROLE } from "@/utils/const";
import { mapGetters } from "vuex";
import ClickableIcon from "../../module/icon/ClickableIcon";

export default {
  name: "TeamMemberViewer",
  components: { ClickableIcon },
  props: {
    members: {
      type: Array,
    },
  },
  data() {
    return {
      TEAM_LEAD_ROLE,
    };
  },
  computed: {
    membersData() {
      return this.members.map((member) => {
        let employee = this.employees.find((e) => e.id === member.employeeId);
        return {
          id: member.employeeId,
          name: employee.name,
          email: employee.email,
          phone: employee.phone,
          title: employee.title,
          department: employee.department,
          roleLabel: this.$tf(TEAM_LEAD_ROLE.LABEL[member.role]),
          role: member.role,
        };
      });
    },
    ...mapGetters({
      employees: "employee/employees",
    }),
  },
  methods: {
    deleteMember(member) {
      this.$emit("delete", member);
    },
  },
};
</script>
