import { newLogger } from "@/utils/util";
import StoreCore from "../../../StoreCore";

let logger = newLogger("Enterprise-OvertimePolicyStore");

const urls = {
  overtimePolicies: "/enterprise/overtime-policies",
  activeOvertimePolicies: "/enterprise/overtime-policies/active",
  overtimePolicyWithId: (id) => `/enterprise/overtime-policy/${id}`,
  activateOvertimePolicy: (id) => `/enterprise/overtime-policy/activate/${id}`,
  deactivateOvertimePolicy: (id) =>
    `/enterprise/overtime-policy/deactivate/${id}`,
  overtimePolicy: "/enterprise/overtime-policy",
};

const initState = () => {
  return {
    overtimePolicies: [],
    activeOvertimePolicies: [],
  };
};

const storeCore = new StoreCore();
const actions = {
  fetchOvertimePolicies: async function ({ commit, dispatch }, force) {
    try {
      let data = await this.remoteRequest(
        "get",
        urls.overtimePolicies,
        null,
        true,
        dispatch,
        "Túlóra policyk letöltése...",
        null,
        force
      );
      commit("saveOvertimePolicies", data);
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  fetchActiveOvertimePolicies: async function ({ commit, dispatch }) {
    try {
      let data = await this.remoteRequest(
        "get",
        urls.activeOvertimePolicies,
        null,
        true,
        dispatch,
        "Túlóra policyk letöltése..."
      );
      commit("saveActiveOvertimePolicies", data);
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  activateOvertimePolicy: async function ({ dispatch }, overtimePolicyId) {
    try {
      await this.remoteRequest(
        "patch",
        urls.activateOvertimePolicy(overtimePolicyId),
        null,
        false,
        dispatch,
        "Túlóra policy aktiválása..."
      );
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  deactivateOvertimePolicy: async function ({ dispatch }, overtimePolicyId) {
    try {
      await this.remoteRequest(
        "patch",
        urls.deactivateOvertimePolicy(overtimePolicyId),
        null,
        false,
        dispatch,
        "Túlóra policy inaktiválása..."
      );
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  delete: async function ({ dispatch }, overtimePolicyId) {
    try {
      await this.remoteRequest(
        "delete",
        urls.overtimePolicyWithId(overtimePolicyId),
        null,
        false,
        dispatch,
        "Túlóra policy törlése..."
      );
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  create: async function ({ dispatch }, overtimePolicy) {
    try {
      await this.remoteRequest(
        "post",
        urls.overtimePolicy,
        overtimePolicy,
        false,
        dispatch,
        "Túlóra policy létrehozása..."
      );
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  update: async function ({ dispatch }, overtimePolicy) {
    try {
      await this.remoteRequest(
        "put",
        urls.overtimePolicyWithId(overtimePolicy.id),
        overtimePolicy,
        false,
        dispatch,
        "Túlóra policy módosítása..."
      );
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  clearCache: async function ({}) {
    this.clearCoreCache();
  }.bind(storeCore),
};

const mutations = {
  saveOvertimePolicies: (state, data) => {
    state.overtimePolicies = data;
  },
  saveActiveOvertimePolicies: (state, data) => {
    state.activeOvertimePolicies = data;
  },
};

const getters = {
  getOvertimePolicies: (state) => {
    return state.overtimePolicies;
  },
  getActiveOvertimePolicies: (state) => {
    return state.activeOvertimePolicies;
  },
};

export default {
  namespaced: true,
  state: initState(),
  mutations: mutations,
  actions: actions,
  getters: getters,
};
