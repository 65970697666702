<template>
  <div class="is-flex is-justify-content-center has-font-weight-700">
    <b-tag :size="size" :type="getBudgetRiskKPIColor(value)">
      {{ $filterRiskKpi(value) }}
    </b-tag>
  </div>
</template>

<script>
import { getBudgetRiskKPIColor } from "@/utils/util";

export default {
  name: "RiskKpiTag",
  props: {
    value: {
      type: [String, Number],
    },
    size: {
      type: String,
      default: "is-medium",
    },
  },
  methods: {
    getBudgetRiskKPIColor,
  },
};
</script>
