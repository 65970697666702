<template>
  <b-checkbox
    :modelValue="checkbox"
    @update:modelValue="onCheckBoxClick"
    :indeterminate="indeterminate"
  >
    <slot />
  </b-checkbox>
</template>

<script>
export default {
  name: "TriStateCheckBox",
  props: {
    modelValue: {
      required: false,
      type: [Boolean, String],
    },
    string: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      checkbox: this.modelValue,
      indeterminate: false,
    };
  },
  methods: {
    async onCheckBoxClick() {
      if (!this.checkbox && !this.indeterminate) {
        this.checkbox = false;
        this.indeterminate = true;
      } else if (!this.checkbox && this.indeterminate) {
        this.indeterminate = false;
        this.checkbox = true;
      } else if (this.checkbox && !this.indeterminate) {
        this.checkbox = null;
        this.indeterminate = false;
      }
      let returnValue = this.checkbox;
      if (this.string && returnValue !== null) {
        returnValue = returnValue.toString();
      }
      this.$emit("update:modelValue", returnValue);
    },
  },
};
</script>

<style scoped></style>
