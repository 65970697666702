<template>
  <vuelidated-form :validations="v$.phone">
    <template v-slot="{ errors, invalid }">
      <b-field
        v-bind="$attrs"
        :expanded="expanded"
        :is="withLoading ? BFieldWithLoading : BField"
        :type="{ 'is-danger': invalid }"
        label-position="on-border"
        :message="v$.phone.$error ? errors[0] : null"
        class="validator-field"
        :class="fieldClass"
        :label="label"
      >
        <template #label v-if="required">
          {{ label }}
          <span class="has-text-danger">*</span>
        </template>
        <span class="phone-input-group">
          <div class="phone-input">
            <b-input
              v-bind="$attrs"
              v-maska:phone.unmasked="'## ### ####'"
              v-model="phone"
              type="text"
              :placeholder="placeholder"
            ></b-input>
          </div>
          <p class="control phone-prefix">
            <b-input value="+36" disabled></b-input>
          </p>
        </span>
        <p
          class="help is-danger"
          v-if="v$.phone.$dirty && !v$.phone.phoneFormat"
        >
          Nem megfelelő telefonszám formátum!
        </p>
      </b-field>
    </template>
  </vuelidated-form>
</template>

<script>
import { requiredIf } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import VuelidatedForm from "@/components/module/input/VuelidatedForm";
import BFieldWithLoading from "@/components/loading/BFieldWithLoading.vue";
import { BField } from "buefy/src/components/field";
import { vMaska } from "maska/vue";

export default {
  props: {
    label: {
      type: String,
      default: "Telefonszám",
    },
    modelValue: {
      type: [String, Number],
      default: "",
    },
    fieldClass: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "Telefonszám",
    },
    required: {
      type: Boolean,
      default: false,
    },
    expanded: {
      type: Boolean,
      default: false,
    },
    withLoading: {
      type: Boolean,
      default: false,
    },
  },
  components: { VuelidatedForm },
  setup: () => ({ v$: useVuelidate() }),
  data() {
    return {
      BFieldWithLoading,
      BField,
      phone: null,
    };
  },
  directives: { maska: vMaska },
  watch: {
    phone() {
      let phoneNumber = null;
      if (this.phone) {
        phoneNumber = "36".concat(this.phone);
      }
      this.$emit("update:modelValue", phoneNumber);
    },
    modelValue: {
      handler() {
        if (this.modelValue && this.modelValue.startsWith("36")) {
          this.phone = this.modelValue.substring(2);
        }
      },
      once: true,
    },
  },
  validations: {
    phone: {
      required: requiredIf(function () {
        return this.required;
      }),
      phoneFormat(value) {
        return !value || !!value.replace(/\s+/g, "").match(/^\d{9}$/);
      },
    },
  },
};
</script>

<style lang="scss">
@import "../../../assets/scss/colors";

.phone-input-group {
  display: flex;

  .phone-input {
    order: 2;
    width: 100%;

    input {
      width: 100%;
      margin-left: 0;
      border-top-left-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
    }
  }
  .phone-prefix {
    order: 1;
    width: 3.5rem;

    .control {
      .input {
        width: 100%;
        padding-right: 0;
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
        margin-right: 0;
        border-right: none !important;
        border-color: $grey-lighter;

        &.is-danger {
          border-color: $red;
        }

        &.is-success {
          border-color: $green;
        }
      }
    }

    .icon.is-right {
      display: none;
    }
  }
}
</style>
