<template>
  <section class="section">
    <div class="container">
      <h1 class="title">Telephelyek</h1>
      <h2 class="subtitle">A cég telephelyei, azoknak termei, ülései</h2>
      <b-button type="is-info" class="is-pulled-top-right" @click="toSiteEdit()"
        ><portal-icon icon="plus-circle" class="mr-2"></portal-icon>Új helyszín
      </b-button>
    </div>
    <hr />
    <div class="container">
      <b-table
        :data="sites"
        ref="table"
        paginated
        per-page="30"
        detailed
        detail-key="identifier"
        :show-detail-icon="true"
        :opened-detailed="defaultOpenedDetails"
        detail-transition="fade"
        aria-next-label="Következő lap"
        aria-previous-label="Előző lap"
        aria-page-label="Lap"
        aria-current-label="Aktuális lap"
        @details-open="(row) => getRoomsFromId(row)"
      >
        <b-table-column
          field="identifier"
          label="Azonosító"
          sortable
          searchable
          v-slot="props"
        >
          {{ props.row.identifier }}
        </b-table-column>
        <b-table-column
          field="name"
          label="Telephely neve"
          sortable
          searchable
          v-slot="props"
        >
          {{ props.row.name }}
        </b-table-column>
        <b-table-column
          field="employees.length"
          label="Telephely címe"
          v-slot="props"
          searchable
          sortable
        >
          {{ props.row.address }}
        </b-table-column>
        <b-table-column
          field="description"
          label="Telephely leírása"
          v-slot="props"
          sortable
        >
          {{ props.row.description }}
        </b-table-column>

        <b-table-column v-slot="props" cell-class="do-not-wrap" width="32">
          <clickable-icon
            portal-icon
            icon="pencil"
            type="is-info"
            class="mr-2"
            @click="toSiteEdit(props.row.id)"
          ></clickable-icon>
          <clickable-icon
            portal-icon
            icon="trash"
            type="is-danger"
            @click="deleteSite(props.row.id)"
          ></clickable-icon>
        </b-table-column>

        <template #detail="props">
          <div class="container button-container">
            <b-button
              type="is-info"
              class=""
              @click="toRoomEdit(null, props.row)"
              ><portal-icon icon="plus-circle" class="mr-2"></portal-icon>Új
              terem
            </b-button>
          </div>

          <b-table
            :data="rooms"
            ref="table"
            detailed
            detail-key="identifier"
            :opened-detailed="secondOpenedDetails"
            :show-detail-icon="true"
            detail-transition="fade"
            @details-open="(row) => getSeatsFromId(row)"
          >
            <b-table-column
              field="identifier"
              label="Azonosító"
              searchable
              sortable
              v-slot="roomProps"
            >
              {{ roomProps.row.identifier }}
            </b-table-column>
            <b-table-column
              field="name"
              label="Terem neve"
              searchable
              sortable
              v-slot="roomProps"
            >
              {{ roomProps.row.name }}
            </b-table-column>
            <b-table-column
              field="description"
              label="Terem leírása"
              v-slot="roomProps"
              sortable
            >
              {{ roomProps.row.description }}
            </b-table-column>

            <b-table-column
              v-slot="roomProps"
              cell-class="do-not-wrap"
              width="32"
            >
              <clickable-icon
                portal-icon
                icon="pencil"
                type="is-info"
                class="mr-2"
                @click="toRoomEdit(roomProps.row.id, props.row)"
              ></clickable-icon>
              <clickable-icon
                portal-icon
                icon="trash"
                type="is-danger"
                @click="deleteRoom(roomProps.row.id)"
              ></clickable-icon>
            </b-table-column>

            <template #detail="props">
              <div class="container button-container">
                <b-button
                  type="is-info"
                  class=""
                  @click="toSeatEdit(null, props.row)"
                  ><portal-icon icon="plus-circle" class="mr-2"></portal-icon>Új
                  ülés
                </b-button>
              </div>
              <b-table :data="seats" ref="table">
                <b-table-column
                  field="identifier"
                  label="Azonosító"
                  sortable
                  searchable
                  v-slot="seatProps"
                >
                  {{ seatProps.row.identifier }}
                </b-table-column>
                <b-table-column
                  field="description"
                  label="Ülés leírása"
                  v-slot="seatProps"
                  sortable
                >
                  {{ seatProps.row.description }}
                </b-table-column>

                <b-table-column
                  v-slot="seatProps"
                  cell-class="do-not-wrap"
                  width="32"
                >
                  <clickable-icon
                    portal-icon
                    icon="pencil"
                    type="is-info"
                    class="mr-2"
                    @click="toSeatEdit(seatProps.row.id, props.row)"
                  ></clickable-icon>
                  <clickable-icon
                    portal-icon
                    icon="trash"
                    type="is-danger"
                    @click="deleteSeat(seatProps.row.id)"
                  ></clickable-icon>
                </b-table-column>
              </b-table>
            </template>
          </b-table>
        </template>
      </b-table>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import eventBus from "../../../utils/eventBus";
import ClickableIcon from "../../../components/module/icon/ClickableIcon";
import { openDeleteConfirm } from "@/utils/util";
import LoadingMixin from "@/mixins/LoadingMixin";
import { defineAsyncComponent } from "vue";

export default {
  name: "SiteList",
  components: {
    ClickableIcon,
    PortalIcon: defineAsyncComponent(() =>
      import("@/components/module/icon/PortalIcon")
    ),
  },
  mixins: [LoadingMixin],
  async mounted() {
    await this.doStartLoading();
    await this.fetchData();
    await this.doFinishLoading();
  },
  computed: {
    ...mapGetters({
      sites: "census_seating/sites",
    }),
  },
  data() {
    return {
      rooms: [],
      seats: [],
      defaultOpenedDetails: [1],
      secondOpenedDetails: [],
    };
  },
  methods: {
    async fetchData(force) {
      await this.$store.dispatch("census_seating/fetchSites", force);
    },
    async toSiteEdit(id) {
      await this.$router.push({
        path: `/census/seating/site/${id ? id : ""}`,
      });
    },
    async toRoomEdit(id, site) {
      await this.$router.push({
        name: "RoomEdit",
        params: {
          id: id,
          site: site,
        },
      });
    },
    async toSeatEdit(id, room) {
      await this.$router.push({
        name: "SeatEdit",
        params: {
          id: id,
          room: room,
        },
      });
    },
    async deleteSeat(id) {
      openDeleteConfirm(this.$buefy, async () => {
        await this.$store.dispatch("census_seating/deleteSeat", id);
        await this.fetchData(true);
      });
    },
    async deleteRoom(id) {
      openDeleteConfirm(this.$buefy, async () => {
        await this.$store.dispatch("census_seating/deleteRoom", id);
        await this.fetchData(true);
      });
    },
    async deleteSite(id) {
      openDeleteConfirm(this.$buefy, async () => {
        await this.$store.dispatch("census_seating/deleteSite", id);
        await this.fetchData(true);
      });
    },
    async getRoomsFromId(row) {
      this.rooms = [];
      this.defaultOpenedDetails = [row.identifier];
      await this.$store.dispatch("census_seating/fetchRoomsById", row.id);
      this.rooms = this.$store.getters["census_seating/rooms"];
    },
    async getSeatsFromId(row) {
      this.seats = [];
      this.secondOpenedDetails = [row.identifier];
      await this.$store.dispatch("census_seating/fetchSeatsById", row.id);
      this.seats = this.$store.getters["census_seating/seats"];
    },
  },
};
</script>

<style scoped lang="scss">
.button-container {
  display: flex;
  margin-left: 0;
  margin-right: 0;

  button {
    margin-left: auto;
  }
}
</style>
