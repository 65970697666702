<template>
  <section class="section" @click="hideNewFactContainer">
    <div class="container">
      <div>
        <h1 class="title">{{ $tf("forecastFact.title|Tényadatok") }}</h1>
      </div>
      <b-field grouped grouped-multiline v-if="!isEmpty(closedYears)">
        <ForecastYearSelector
          v-model="year"
          @select="selectedYearObject = $event"
          :key="yearSelectorKey"
          only-closed
        />
        <b-field :label="$tf('forecastFact.filter.segment|Szegmens típusa')">
          <multiselect-dropdown
            v-model="filter.segments"
            :items="segmentsWithUnassigned"
            append-to-body
            has-select-all-option
            identifier-field="id"
            name-field="name"
          />
        </b-field>
        <b-field :label="$tf('forecastFact.filter.client|Ügyfélválasztás')">
          <multiselect-dropdown
            v-model="filter.clients"
            :items="clientsWithUnassigned"
            append-to-body
            has-select-all-option
            identifier-field="id"
            name-field="name"
          />
        </b-field>
        <b-field :label="$tf('forecastFact.filter.type|Tervsor típusa')">
          <multiselect-dropdown
            v-model="filter.type"
            :items="planTypes"
            append-to-body
            has-select-all-option
            identifier-field="id"
            name-field="name"
            use-tf
          />
        </b-field>
        <b-field
          :label="$tf('forecastFact.filter.quarter|Negyedévek kiválasztása')"
        >
          <multiselect-dropdown
            v-model="filter.quarter"
            :items="quarters"
            append-to-body
            has-select-all-option
            identifier-field="id"
            name-field="name"
          />
        </b-field>
      </b-field>
    </div>
    <div class="container">
      <template v-if="!isEmpty(closedYears)">
        <div class="is-flex is-justify-content-space-between">
          <div
            v-for="quarter in FORECAST_YEAR_QUARTER"
            :key="quarter"
            class="is-flex is-flex-1 has-gap-1 is-justify-content-center"
          >
            <strong>{{ quarter }}:</strong>
            <p>
              {{
                moneyify(
                  filteredMoneyData[quarter],
                  CURRENCY_TIERS.LEVEL_TWO,
                  true,
                  false,
                  true,
                  2
                )
              }}
            </p>
            <p>/</p>
            <p>
              {{
                moneyify(
                  allMoneyData[quarter],
                  CURRENCY_TIERS.LEVEL_TWO,
                  true,
                  false,
                  true,
                  2
                )
              }}
            </p>
            <p>{{ currency }}</p>
          </div>
        </div>

        <ForecastYearlyData
          :data="forecastData"
          :year="year"
          :quarterly-money-data="allMoneyData"
          :yearly-income="yearlyIncome"
          show-quarter-closing
          @select="showNewFactContainer"
        >
          <template #footer>
            <div
              class="is-flex is-justify-content-space-between is-align-items-center mb-4"
            >
              <div
                v-for="quarter in FORECAST_YEAR_QUARTER"
                :key="`close-${quarter}`"
                class="is-flex is-flex-1 is-justify-content-center"
              >
                <div>
                  <template
                    v-if="!selectedYearObject[`${quarter.toLowerCase()}Closed`]"
                  >
                    <b-button
                      type="is-warning"
                      size="is-small"
                      icon-left="lock"
                      @click="closeQuarter(quarter)"
                    >
                      {{ $tf("forecastFact.closeQuarter|Negyedév zárása") }}
                    </b-button>
                  </template>
                  <template v-else>
                    <b-button
                      type="is-warning"
                      size="is-small"
                      icon-left="lock-open"
                      @click="openQuarter(quarter)"
                    >
                      {{ $tf("forecastFact.openQuarter|Negyedév nyitása") }}
                    </b-button>
                  </template>
                </div>
              </div>
            </div>
          </template>
        </ForecastYearlyData>
      </template>
      <template v-else>
        <div class="is-flex-1 content has-text-grey has-text-centered">
          <p><b-icon icon="frown" size="is-large"></b-icon></p>
          <p>
            {{
              $tf("forecastFact.noClosedYears|Még nincs lezárt tervezésű év!")
            }}
          </p>
        </div>
      </template>
      <div :class="$style.newFactContainer" ref="newFactContainer">
        <div class="is-flex is-flex-direction-column has-gap-2 p-2">
          <div
            :class="$style.entry"
            class="is-flex is-align-items-center has-gap-2"
            @click="openEdit(false, false)"
            v-if="canCreatePlan && !hasProject"
          >
            <b-icon icon="plus" />
            <p>
              {{
                $tf(
                  "forecastFact.newFact.newPlan|Szűkített tervsor hozzárendelése"
                )
              }}
            </p>
          </div>
          <div
            :class="$style.entry"
            class="is-flex is-align-items-center has-gap-2"
            @click="openEdit(false, true, true)"
            v-if="canCreate && !hasProject"
          >
            <b-icon icon="dollar-sign" />
            <p>
              {{ $tf("forecastFact.newFact.newFact|Projekt hozzárendelése") }}
            </p>
          </div>
          <div
            :class="$style.entry"
            class="is-flex is-align-items-center has-gap-2"
            @click="openEdit(true, false)"
            v-if="canViewPlan && !hasProject"
          >
            <b-icon icon="eye" />
            <p>{{ $tf("forecastFact.newFact.see|Adatok megtekintése") }}</p>
          </div>
          <div
            :class="$style.entry"
            class="is-flex is-align-items-center has-gap-2"
            @click="openEdit(false, true)"
            v-if="canEdit && isModifiable && !hasChildren"
          >
            <b-icon icon="pencil" />
            <p>{{ $tf("forecastFact.newFact.edit|Szerkesztés") }}</p>
          </div>
          <div
            :class="$style.entry"
            class="is-flex is-align-items-center has-gap-2 is-danger"
            @click="deleteRow(selectedPlan)"
            v-if="canDelete && isModifiable && !hasChildren"
          >
            <b-icon icon="trash" type="is-danger" />
            <p>{{ $tf("forecastFact.newFact.delete|Törlés") }}</p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {
  FORECAST_BASE_DATA_TYPE,
  FORECAST_YEAR_QUARTER,
  CURRENCY_TIERS,
  PRIVILEGES,
} from "@/utils/const";
import { mapGetters } from "vuex";
import ForecastFactEditModal from "@/components/forecast/edit/ForecastFactEditModal.vue";
import ForecastPlanEditModal from "@/components/forecast/edit/ForecastPlanEditModal.vue";

import { openDeleteConfirm, moneyify } from "@/utils/util";
import ForecastPlanSelectModal from "@/components/forecast/ForecastPlanSelectModal.vue";
import ForecastYearSelector from "@/components/forecast/ForecastYearSelector.vue";
import MultiselectDropdown from "@/components/MultiselectDropdown.vue";
import ForecastYearlyData from "@/components/forecast/ForecastYearlyData.vue";
import ForecastDataMixin from "@/mixins/ForecastDataMixin";
import { isEmpty } from "lodash";

export default {
  name: "ForecastFact",
  mixins: [ForecastDataMixin],
  data() {
    return {
      FORECAST_BASE_DATA_TYPE,
      FORECAST_YEAR_QUARTER,
      CURRENCY_TIERS,
      loaded: false,
      year: new Date().getFullYear(),
      selectedYearObject: {},
      planTypes: [
        {
          id: "PLAN",
          name: "forecastFact.filter.planName|Terv",
        },
        {
          id: "PROJECT",
          name: "forecastFact.filter.projectName|Projekt",
        },
      ],
      filter: {
        segments: [],
        clients: [],
        type: [],
        quarter: [],
      },
      yearSelectorKey: 0,
      selectedPlan: undefined,
    };
  },
  components: {
    MultiselectDropdown,
    ForecastYearlyData,
    ForecastYearSelector,
  },
  watch: {
    year() {
      this.load();
    },
  },
  computed: {
    ...mapGetters({
      facts: "forecast_fact/facts",
      plans: "forecast_plan/plans",
      segments: "forecast_segment/allSegments",
      clients: "enterprise_clients/clients",
      currency: "uiConfigStore/getCurrencySymbol",
      closedYears: "forecast_year/closedYears",
    }),
    segmentsWithUnassigned() {
      return [
        {
          id: null,
          name: this.$tf("forecast.unassignedFilter|Hozzárendelés nélkül"),
        },
        ...this.segments,
      ];
    },
    clientsWithUnassigned() {
      return [
        {
          id: null,
          name: this.$tf("forecast.unassignedFilter|Hozzárendelés nélkül"),
        },
        ...this.clients,
      ];
    },
    hasProject() {
      return !!this.selectedPlan?.project;
    },
    isModifiable() {
      return this.hasProject || !!this.selectedPlan?.parent?.id;
    },
    hasChildren() {
      return !!this.forecastData.find(
        (it) => it?.parent?.id && it.parent.id === this.selectedPlan?.id
      );
    },
    allMoneyData() {
      const data = [...this.facts, ...this.plans].filter((it) => !it.parent);
      return Object.keys(FORECAST_YEAR_QUARTER)
        .map((quarter) => {
          return {
            [quarter]: data
              .map((it) => this.getIncome(it, quarter))
              .reduce((acc, val) => acc + val, 0),
          };
        })
        .reduce((acc, val) => Object.assign(acc, val));
    },
    yearlyIncome() {
      return Object.values(this.filteredMoneyData).reduce(
        (acc, val) => acc + val,
        0
      );
    },
    filteredMoneyData() {
      return Object.keys(FORECAST_YEAR_QUARTER)
        .map((quarter) => {
          return {
            [quarter]: this.forecastData
              .filter((it) => !it.parent)
              .map((it) => this.getIncome(it, quarter))
              .reduce((acc, val) => acc + val, 0),
          };
        })
        .reduce((acc, val) => Object.assign(acc, val));
    },
    forecastData() {
      return this.sortAndRank(
        [...this.facts, ...this.plans].filter(
          (it) =>
            !(
              (!isEmpty(this.filter.segments) &&
                !this.filter.segments.some(
                  (segment) =>
                    (segment === null && !it.segment?.id) ||
                    it.segment?.id === segment
                )) ||
              (!isEmpty(this.filter.clients) &&
                !this.filter.clients.some(
                  (client) =>
                    (client === null && !it.account?.id) ||
                    it.account?.id === client
                )) ||
              (!isEmpty(this.filter.type) &&
                ((!this.filter.type.includes("PLAN") && !this.isProject(it)) ||
                  (!this.filter.type.includes("PROJECT") &&
                    this.isProject(it)))) ||
              (!isEmpty(this.filter.quarter) &&
                !this.filter.quarter.some((quarter) =>
                  this.isCurrentYear(it)
                    ? this.hasIncome(it, quarter)
                    : this.hasIncomeNextYear(it, quarter)
                ))
            )
        )
      );
    },
    canCreate() {
      return this.$store.getters["session/hasAnyAuthority"](
        PRIVILEGES.ENTERPRISE.FORECAST.FACT.CREATE,
        PRIVILEGES.ENTERPRISE.FORECAST.MISC.ADMIN
      );
    },
    canCreatePlan() {
      return this.$store.getters["session/hasAnyAuthority"](
        PRIVILEGES.ENTERPRISE.FORECAST.PLAN.CREATE,
        PRIVILEGES.ENTERPRISE.FORECAST.MISC.ADMIN
      );
    },
    canViewPlan() {
      return this.$store.getters["session/hasAnyAuthority"](
        PRIVILEGES.ENTERPRISE.FORECAST.PLAN.GET_ANY,
        PRIVILEGES.ENTERPRISE.FORECAST.PLAN.GET_OWN,
        PRIVILEGES.ENTERPRISE.FORECAST.MISC.ADMIN
      );
    },
    canEdit() {
      return this.$store.getters["session/hasAnyAuthority"](
        PRIVILEGES.ENTERPRISE.FORECAST.FACT.EDIT_OWN,
        PRIVILEGES.ENTERPRISE.FORECAST.FACT.EDIT_ANY,
        PRIVILEGES.ENTERPRISE.FORECAST.MISC.ADMIN
      );
    },
    canDelete() {
      return this.$store.getters["session/hasAnyAuthority"](
        PRIVILEGES.ENTERPRISE.FORECAST.FACT.DELETE_OWN,
        PRIVILEGES.ENTERPRISE.FORECAST.FACT.DELETE_ANY,
        PRIVILEGES.ENTERPRISE.FORECAST.MISC.ADMIN
      );
    },
    quarters() {
      return Object.keys(FORECAST_YEAR_QUARTER).map((it) => {
        return {
          id: it,
          name: it,
        };
      });
    },
  },
  methods: {
    moneyify,
    isEmpty,
    // Function to recursively sort items and calculate rank
    sortAndRank(items, parentId = undefined, rank = 0) {
      const sortedItems = [];

      // Filter items with matching parentId and calculate rank
      const children = items
        .filter((item) => item.parent?.id === parentId)
        .map((item) => ({
          ...item,
          rank,
        }));

      // Recursively sort and rank children
      children.forEach((child) => {
        sortedItems.push(child);
        sortedItems.push(...this.sortAndRank(items, child.id, rank + 1));
      });
      return sortedItems;
    },
    openEdit(readOnly = false, fact = false, parent = false) {
      if (fact) {
        const props = { selectedYear: this.year };
        if (parent) {
          props.parent = this.selectedPlan;
        } else {
          props.edit = this.selectedPlan;
        }
        this.$buefy.modal.open({
          parent: this,
          component: ForecastFactEditModal,
          props,
          hasModalCard: true,
          trapFocus: true,
          events: {
            save: () => this.load(true),
          },
        });
      } else {
        this.$buefy.modal.open({
          parent: this,
          component: ForecastPlanEditModal,
          props: {
            parent: this.selectedPlan,
            edit: this.selectedPlan,
            readOnly,
            selectedYear: this.year,
          },
          hasModalCard: true,
          trapFocus: true,
          events: {
            save: () => this.load(true),
          },
        });
      }
    },
    showNewFactContainer(row, event) {
      event.stopPropagation();
      this.$refs.newFactContainer.style.display = "block";
      this.$refs.newFactContainer.style.top = `${event.clientY}px`;
      this.$refs.newFactContainer.style.left = `${event.clientX}px`;
      this.selectedPlan = row;
    },
    hideNewFactContainer() {
      this.$refs.newFactContainer.style.display = "none";
      this.selectedPlan = undefined;
    },
    openPlanSelect() {
      const openEdit = this.openEdit;
      this.$buefy.modal.open({
        parent: this,
        component: ForecastPlanSelectModal,
        hasModalCard: true,
        trapFocus: true,
        events: {
          save: (plan) => openEdit(plan, true),
        },
      });
    },
    async deleteRow(row) {
      if (row.project) {
        openDeleteConfirm(this.$buefy, async () => {
          await this.$store.dispatch("forecast_fact/deleteFact", row.id);
          await this.load(true);
        });
      } else {
        openDeleteConfirm(this.$buefy, async () => {
          await this.$store.dispatch("forecast_plan/deletePlan", row.id);
          await this.load(true);
        });
      }
    },
    async load(force) {
      this.loaded = false;

      let params = new URLSearchParams();
      params.append("year", this.year);
      let requestParams = { params: params };

      await this.$store.dispatch("forecast_plan/fetchPlans", {
        force,
        params: requestParams,
      });
      await this.$store.dispatch("forecast_fact/fetchFacts", {
        force,
        params: requestParams,
      });

      this.loaded = true;
    },

    async closeQuarter(quarter) {
      await this.$store.dispatch("forecast_year/closeQuarter", {
        year: this.year,
        quarter,
      });
      await this.$store.dispatch("forecast_year/fetchClosedYears", true);
      this.yearSelectorKey++;
    },
    async openQuarter(quarter) {
      await this.$store.dispatch("forecast_year/openQuarter", {
        year: this.year,
        quarter,
      });
      await this.$store.dispatch("forecast_year/fetchClosedYears", true);
      this.yearSelectorKey++;
    },

    async init() {
      this.loaded = false;
      await this.$store.dispatch("forecast_year/fetchClosedYears");
      await this.$store.dispatch("forecast_segment/fetchAllSegments");
      await this.$store.dispatch("enterprise_clients/fetchClients");
    },
  },
  async mounted() {
    await this.init();
    await this.load();
    this.loaded = true;
  },
};
</script>

<style scoped module lang="scss">
@import "~@/assets/scss/colors.scss";

.newFactContainer {
  position: fixed;
  display: none;
  background-color: $white;
  border-radius: 10px;
  min-width: 20em;

  .entry {
    cursor: pointer;
  }
}
</style>
