<template>
  <component
    :is="isPageLoading ? null : BButton"
    v-bind="$attrs"
    v-on="$listeners"
    :animation="isPageLoading ? 'wave-new' : $attrs.animation"
    :height="isPageLoading ? '40px' : $attrs.height"
    :width="isPageLoading ? loadingWidth : $attrs.width"
    :rounded="isPageLoading ? true : $attrs.rounded"
  >
    <slot v-if="!hideComponent"></slot>
  </component>
</template>

<script>
import { BCheckboxButton } from "buefy/src/components/checkbox";
import { BButton } from "buefy/src/components/button";
import LoadingMixin from "@/mixins/LoadingMixin";

export default {
  name: "BButtonWithLoading",
  props: {
    loadingWidth: {
      type: [String, Number],
    },
    hideComponent: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [LoadingMixin],
  data() {
    return {
      BButton,
    };
  },
  inheritAttrs: false,
};
</script>
