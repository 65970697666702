<template>
  <section class="section">
    <div class="container">
      <div class="is-flex">
        <div>
          <h1 class="title">
            {{ $tf("adminSettings.title|Admin beállítások") }}
          </h1>
          <h2 class="subtitle">
            {{
              $tf(
                "adminSettings.subtitle|Admin beállítások megtekintése és módosítása"
              )
            }}
          </h2>
        </div>
      </div>
    </div>

    <div class="container">
      <b-loading
        v-if="loading"
        :can-cancel="false"
        :is-full-page="false"
        v-model="loading"
      />
      <div class="overflow-x-auto" v-else>
        <template v-if="!enterpriseData || editMode">
          <div
            class="is-flex is-flex-direction-column has-gap-4 mt-5"
            style="max-width: 500px"
          >
            <div>
              <vuelidated-input
                :label="
                  $tf(
                    'adminSettings.projectRiskKPIlowerLimit|Projekt risk KPI alsó határ'
                  )
                "
                :placeholder="
                  $tf(
                    'adminSettings.projectRiskKPIlowerLimit|Projekt risk KPI alsó határ'
                  )
                "
                v-model="enterpriseDataEdit.lowerRiskKpiLimit"
                :validation-rule="v$.enterpriseDataEdit.lowerRiskKpiLimit"
                label-position="on-border"
              />
            </div>
            <div>
              <vuelidated-input
                :label="
                  $tf(
                    'adminSettings.projectRiskKPIupperLimit|Projekt risk KPI felső határ'
                  )
                "
                :placeholder="
                  $tf(
                    'adminSettings.projectRiskKPIupperLimit|Projekt risk KPI felső határ'
                  )
                "
                v-model="enterpriseDataEdit.upperRiskKpiLimit"
                :validation-rule="v$.enterpriseDataEdit.upperRiskKpiLimit"
                label-position="on-border"
              />
            </div>
            <div>
              <vuelidated-input
                type="dropdown"
                :label="$tf('adminSettings.currency|Deviza')"
                :placeholder="$tf('adminSettings.currency|Deviza')"
                :validation-rule="v$.enterpriseDataEdit.currency"
                label-position="on-border"
              >
                <b-dropdown
                  v-model="enterpriseDataEdit.currency"
                  scrollable
                  :max-height="200"
                  aria-role="list"
                  append-to-body
                >
                  <template #trigger>
                    <div class="currency-dropdown-input">
                      <img
                        :src="selectedCurrency.icon"
                        :alt="selectedCurrency.code"
                      />
                      <div class="is-flex-grow-1">
                        {{ selectedCurrency.code }}
                        &nbsp;-&nbsp;{{ selectedCurrency.name }}
                      </div>
                      <b-icon icon="chevron-down" size="is-small"></b-icon>
                    </div>
                  </template>

                  <b-dropdown-item
                    v-for="curr in currencies"
                    :key="curr.id"
                    :value="curr.id"
                    style="z-index: 999"
                  >
                    <div class="media">
                      <img
                        class="media-left is-align-self-center"
                        :alt="curr.code"
                        :src="curr.icon"
                      />
                      <div
                        class="media-content has-text-dark-blue has-font-weight-500"
                      >
                        {{ curr.code }}
                        - {{ curr.name }}
                      </div>
                    </div>
                  </b-dropdown-item>
                </b-dropdown>
              </vuelidated-input>
            </div>
            <div>
              <vuelidated-input
                type="dropdown"
                :label="
                  $tf(
                    'adminSettings.issueTrackingSystem|Külső taskkezelő rendszer'
                  )
                "
                :placeholder="
                  $tf(
                    'adminSettings.issueTrackingSystem|Külső taskkezelő rendszer'
                  )
                "
                :validation-rule="v$.issueTrackingDataEdit.system"
                label-position="on-border"
              >
                <b-dropdown
                  v-model="issueTrackingDataEdit.system"
                  scrollable
                  :max-height="200"
                  aria-role="list"
                  append-to-body
                >
                  <template #trigger>
                    <div class="currency-dropdown-input">
                      <div class="is-flex-grow-1">
                        {{
                          $tf(
                            ISSUE_TRACKING_SYSTEM[issueTrackingDataEdit.system]
                              ?.label ?? ""
                          )
                        }}
                      </div>
                      <b-icon icon="chevron-down" size="is-small"></b-icon>
                    </div>
                  </template>

                  <b-dropdown-item
                    v-for="its in Object.keys(ISSUE_TRACKING_SYSTEM)"
                    :key="ISSUE_TRACKING_SYSTEM[its].enum"
                    :value="ISSUE_TRACKING_SYSTEM[its].enum"
                    style="z-index: 999"
                  >
                    {{ $tf(ISSUE_TRACKING_SYSTEM[its].label) }}
                  </b-dropdown-item>
                </b-dropdown>
              </vuelidated-input>
            </div>
            <div>
              <span class="data-details-label">
                <vuelidated-input
                  :label="
                    $tf(
                      'adminSettings.estimationFieldName.label|Feladat becsült időtartam mező'
                    )
                  "
                  :placeholder="
                    $tf(
                      'adminSettings.estimationFieldName.placeholder|Estimation'
                    )
                  "
                  v-model="issueTrackingDataEdit.estimationFieldName"
                  :validation-rule="
                    v$.issueTrackingDataEdit.estimationFieldName
                  "
                  label-position="on-border"
                />
              </span>
            </div>
            <div>
              <span class="data-details-label"
                >{{
                  $tf(
                    "adminSettings.issueStatusCorrelations|Task státusz korrelációk"
                  )
                }}:
              </span>
              <div>
                <div v-for="status of Object.keys(ISSUE_STATUS)" :key="status">
                  <span class="data-details-value"
                    >{{ status }}:
                    <b-input
                      v-model="
                        issueTrackingDataEdit.issueStatusCorrelations[status]
                      "
                  /></span>
                </div>
              </div>
            </div>
          </div>
        </template>

        <template v-if="enterpriseData && !editMode">
          <div class="is-flex is-flex-direction-column has-gap-2">
            <div>
              <span class="data-details-label"
                >{{
                  $tf(
                    "adminSettings.projectRiskKPIlowerLimit|Projekt risk KPI alsó határ"
                  )
                }}:
              </span>
              <span class="data-details-value">{{
                enterpriseData.lowerRiskKpiLimit
              }}</span>
            </div>

            <div>
              <span class="data-details-label"
                >{{
                  $tf(
                    "adminSettings.projectRiskKPIupperLimit|Projekt risk KPI felső határ"
                  )
                }}:
              </span>
              <span class="data-details-value">{{
                enterpriseData.upperRiskKpiLimit
              }}</span>
            </div>

            <div>
              <span class="data-details-label"
                >{{ $tf("adminSettings.currency|Deviza") }}:
              </span>
              <span class="data-details-value">{{
                selectedCurrency.name
              }}</span>
            </div>

            <div>
              <span class="data-details-label"
                >{{
                  $tf(
                    "adminSettings.issueTrackingSystem|Külső taskkezelő rendszer"
                  )
                }}:
              </span>
              <span class="data-details-value">{{
                $tf(
                  ISSUE_TRACKING_SYSTEM[issueTrackingDataEdit.system]?.label ??
                    ""
                )
              }}</span>
            </div>
            <div>
              <span class="data-details-label"
                >{{
                  $tf(
                    "adminSettings.estimationFieldName.label|Feladat becsült időtartam mező"
                  )
                }}:
              </span>
              <span class="data-details-value">{{
                issueTrackingDataEdit.estimationFieldName
              }}</span>
            </div>

            <div>
              <span class="data-details-label"
                >{{
                  $tf(
                    "adminSettings.issueStatusCorrelations|Task státusz korrelációk"
                  )
                }}:
              </span>
              <div>
                <div v-for="status of Object.keys(ISSUE_STATUS)" :key="status">
                  <span class="data-details-value"
                    >{{ status }}:
                    {{
                      issueTrackingDataEdit.issueStatusCorrelations[status]
                    }}</span
                  >
                </div>
              </div>
            </div>
          </div>
        </template>

        <div
          class="is-flex is-justify-content-space-between is-align-items-end pb-2 mt-4"
        >
          <div class="ml-auto">
            <div>
              <b-button
                class="is-primary"
                v-if="!editMode && enterpriseData && issueTrackingData"
                @click="editMode = true"
                >{{ $tf("adminSettings.edit|Szerkesztés") }}
              </b-button>
              <div v-if="editMode" class="is-flex has-gap-2">
                <b-button
                  class="is-light"
                  v-if="enterpriseData && issueTrackingData"
                  @click="editMode = false"
                  >{{ $tf("adminSettings.cancel|Mégse") }}
                </b-button>
                <b-button class="is-primary ml-auto" @click="save"
                  >{{ $tf("adminSettings.save|Mentés") }}
                </b-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { decimal, minValue, required, requiredIf } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import VuelidatedInput from "../../components/module/input/VuelidatedInput";
import { mapGetters } from "vuex";
import { cloneDeep } from "lodash";
import { deepCopy } from "@/utils/util";
import { ISSUE_TRACKING_SYSTEM, ISSUE_STATUS } from "@/utils/const";

export default {
  name: "AdminConfigEdit",
  components: { VuelidatedInput },
  setup: () => ({ v$: useVuelidate() }),
  data() {
    return {
      loading: false,
      editMode: false,
      enterpriseData: null,
      issueTrackingData: null,
      enterpriseDataEdit: {
        lowerRiskKpiLimit: 0.8,
        upperRiskKpiLimit: 0.99,
        currency: undefined,
      },
      issueTrackingDataEdit: {
        system: ISSUE_TRACKING_SYSTEM.JIRA.enum,
        estimationFieldName: undefined,
        issueStatusCorrelations: {
          ...Object.keys(ISSUE_STATUS)
            .map((it) => {
              return {
                [it]: "",
              };
            })
            .reduce((acc, val) => Object.assign(acc, val), {}),
        },
      },
      ISSUE_TRACKING_SYSTEM,
      ISSUE_STATUS,
    };
  },
  validations: {
    enterpriseDataEdit: {
      lowerRiskKpiLimit: {
        required,
        decimal,
        minValue: minValue(0),
      },
      upperRiskKpiLimit: {
        required,
        decimal,
      },
      currency: {
        required,
      },
    },
    issueTrackingDataEdit: {
      system: {
        required,
      },
      estimationFieldName: {
        required: requiredIf(function (modelValue) {
          return modelValue.system === ISSUE_TRACKING_SYSTEM.YOUTRACK.enum;
        }),
      },
    },
  },
  watch: {
    async activeTab() {
      await this.fetch();
    },
  },
  computed: {
    ...mapGetters({
      clients: "enterprise_clients/clients",
      currencies: "adminConfig/getCurrencies",
    }),
    selectedCurrency() {
      return (
        this.currencies.find(
          (x) => x.id === this.enterpriseDataEdit?.currency
        ) || {
          name: "-",
        }
      );
    },
  },
  async mounted() {
    await this.fetch();
  },
  methods: {
    async fetch(force) {
      this.loading = true;
      await this.$store.dispatch("adminConfig/fetchEnterpriseData", force);
      await this.$store.dispatch("adminConfig/fetchCurrencies", force);
      this.enterpriseData =
        this.$store.getters["adminConfig/getEnterpriseData"];
      if (this.enterpriseData) {
        this.enterpriseDataEdit = cloneDeep(this.enterpriseData);
        this.enterpriseDataEdit.currency = this.enterpriseDataEdit.currency?.id;
      }
      await this.$store.dispatch("adminConfig/fetchIssueTrackingData", force);
      this.issueTrackingData =
        this.$store.getters["adminConfig/getIssueTrackingData"];
      if (this.issueTrackingData) {
        const source = cloneDeep(this.issueTrackingData);
        Object.keys(source.issueStatusCorrelations).forEach((key) => {
          source.issueStatusCorrelations[key] =
            source.issueStatusCorrelations[key].join(",");
        });
        this.issueTrackingDataEdit = cloneDeep(source);
      }
      this.loading = false;
    },
    async save() {
      let success = false;
      success =
        (await this.saveEnterpriseData()) &&
        (await this.saveIssueTrackingData());
      if (success) {
        await this.fetch(true);
        this.editMode = false;
      }
    },
    async saveEnterpriseData() {
      this.v$.enterpriseDataEdit.$touch();
      if (!this.v$.enterpriseDataEdit.$invalid) {
        const payload = deepCopy(this.enterpriseDataEdit);
        payload.currency = this.selectedCurrency;
        try {
          await this.$store.dispatch("adminConfig/saveEnterpriseData", payload);
          return true;
        } catch (_) {
          return false;
        }
      }
      return false;
    },
    async saveIssueTrackingData() {
      this.v$.issueTrackingDataEdit.$touch();
      if (!this.v$.issueTrackingDataEdit.$invalid) {
        const payload = deepCopy(this.issueTrackingDataEdit);
        Object.keys(payload.issueStatusCorrelations).forEach((key) => {
          payload.issueStatusCorrelations[key] =
            payload.issueStatusCorrelations[key].split(",");
        });
        try {
          await this.$store.dispatch(
            "adminConfig/saveIssueTrackingData",
            payload
          );
          return true;
        } catch (_) {
          return false;
        }
      }
      return false;
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/assets/scss/colors.scss";

.data-details-label {
  color: $custom-light-blue;
}

.data-details-value {
  font-weight: 500;
  color: $custom-dark-blue;
}

.currency-dropdown-input {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
  gap: 8px;
  width: 250px;
  cursor: pointer;
  background: $white;
  border: 1px solid lightgray;
  border-radius: 5px;
  color: $custom-dark-blue;
  font-weight: 500;
  font-size: 14px;
}
</style>
