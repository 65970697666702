<template>
  <div class="modal-card" style="width: auto">
    <div class="container" style="width: 42rem">
      <section class="modal-card-body">
        <h1 class="title" v-if="createMode">
          {{ $tf(FORECAST_BASE_DATA_TYPE.LABEL.NEW_TITLE[type]) }}
        </h1>
        <h1 class="title" v-else>
          {{ $tf(FORECAST_BASE_DATA_TYPE.LABEL.EDIT_TITLE[type]) }}
        </h1>

        <form v-if="loaded" @submit.prevent="save">
          <vuelidated-input
            ref="input"
            ref-value="input"
            :label="$tf('projectBaseDataEditModal.inputLabel|Név')"
            :placeholder="$tf('projectBaseDataEditModal.inputPlaceholder|Név')"
            v-model="editData.name"
            :validation-rule="v$.editData.name"
            label-position="on-border"
            class="mt-5"
          ></vuelidated-input>

          <b-field class="mt-5 is-pulled-right">
            <b-button @click="close">
              {{ $tf("projectBaseDataEditModal.cancel|Mégsem") }}
            </b-button>
            <b-button @click="save" type="is-info" class="ml-3">
              {{ $tf("projectBaseDataEditModal.save|Mentés") }}
            </b-button>
          </b-field>
        </form>

        <LoadingSkeleton v-else />
      </section>
    </div>
  </div>
</template>

<script>
import { deepCopy } from "@/utils/util";
import { decimal, required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import VuelidatedInput from "@/components/module/input/VuelidatedInput.vue";
import LoadingSkeleton from "../../module/loading/LoadingSkeleton.vue";
import { mapGetters } from "vuex";
import { FORECAST_BASE_DATA_TYPE } from "@/utils/const";
import { getCurrentInstance, nextTick } from "vue";

export default {
  name: "ProjectBaseDataEditModal",
  setup: () => {
    const instance = getCurrentInstance();
    return {
      v$: useVuelidate(),
      instance: instance,
    };
  },
  props: {
    baseData: {
      type: Object,
      required: false,
    },
    type: {
      type: String,
      required: true,
    },
  },
  async mounted() {
    this.createMode = !this.baseData;

    if (this.baseData) {
      this.editData = deepCopy(this.baseData);
    }

    this.loaded = true;
    nextTick(() => {
      this.$refs.input.$refs.input.focus();
    });
  },
  data() {
    return {
      loaded: false,
      createMode: false,
      editData: {
        name: undefined,
      },
      FORECAST_BASE_DATA_TYPE,
    };
  },
  components: { LoadingSkeleton, VuelidatedInput },
  validations: {
    editData: {
      name: {
        required,
      },
    },
  },
  methods: {
    close() {
      this.instance.emit("close");
    },
    async save() {
      this.v$.editData.$touch();
      if (!this.v$.editData.$invalid) {
        let request = deepCopy(this.editData);
        request.type = this.type;

        if (this.createMode) {
          await this.$store.dispatch("project_base_data/create", request);
        } else {
          await this.$store.dispatch("project_base_data/update", request);
        }
        this.$emit("save");
        this.close();
      }
    },
  },
};
</script>
