<template>
  <div
    class="newForecastCard is-flex is-flex-direction-column"
    style="gap: 24px"
  >
    <h3 class="title">
      {{ $tf("projectPage.forecastWholeStatus.title|Egész projekt") }}
    </h3>
    <div>
      <ForecastWholeTable
        class="is-flex-5"
        :project-details="projectDetails"
        :project-data="projectData"
      />
    </div>
    <!--    <div class="is-flex is-justify-content-space-evenly has-gap-4">-->
    <!--      <div class="newForecastCard is-flex-grow-1 is-flex-basis-0">-->
    <!--        <forecast-horizontal-overheaded-of-first-bar-->
    <!--          :title="mwdData.title"-->
    <!--          :tooltip="mwdData.tooltip"-->
    <!--          :data="[-->
    <!--            {-->
    <!--              label: this.$tf(-->
    <!--                'projectPage.forecastWholeStatus.mwdChart.plan.label|Terv'-->
    <!--              ),-->
    <!--              value: projectDetails.commitment,-->
    <!--              kind: 'mwd',-->
    <!--              color: currentColors['forecast-primary'],-->
    <!--            },-->
    <!--            {-->
    <!--              label: this.$tf(-->
    <!--                'projectPage.forecastWholeStatus.mwdChart.trendingForecast.label|Trendtartó FC'-->
    <!--              ),-->
    <!--              value: projectDetails.forecastTrendingMd,-->
    <!--              kind: 'mwd',-->
    <!--              color: currentColors['forecast-secondary'],-->
    <!--            },-->
    <!--            {-->
    <!--              label: this.$tf(-->
    <!--                'projectPage.forecastWholeStatus.mwdChart.planningForecast.label|Tervtartó FC'-->
    <!--              ),-->
    <!--              value: projectDetails.forecastPlanningMd,-->
    <!--              kind: 'mwd',-->
    <!--              color: currentColors['forecast-tertiary'],-->
    <!--            },-->
    <!--          ]"-->
    <!--        />-->
    <!--      </div>-->
    <!--      <div class="newForecastCard is-flex-grow-1 is-flex-basis-0">-->
    <!--        <forecast-horizontal-overheaded-of-first-bar-->
    <!--          :title="budgetData.title"-->
    <!--          :tooltip="budgetData.tooltip"-->
    <!--          :data="[-->
    <!--            {-->
    <!--              label: this.$tf(-->
    <!--                'projectPage.forecastWholeStatus.budgetChart.plan.label|Terv'-->
    <!--              ),-->
    <!--              value: Math.round(projectDetails.budget),-->
    <!--              kind: 'ft',-->
    <!--              color: currentColors['forecast-primary'],-->
    <!--            },-->
    <!--            {-->
    <!--              label: this.$tf(-->
    <!--                'projectPage.forecastWholeStatus.budgetChart.trendingForecast.label|Trendtartó FC'-->
    <!--              ),-->
    <!--              value: Math.round(projectDetails.forecastTrendingBudget),-->
    <!--              kind: 'ft',-->
    <!--              color: currentColors['forecast-secondary'],-->
    <!--            },-->
    <!--            {-->
    <!--              label: this.$tf(-->
    <!--                'projectPage.forecastWholeStatus.budgetChart.planningForecast.label|Tervtartó FC'-->
    <!--              ),-->
    <!--              value: Math.round(projectDetails.forecastPlanningBudget),-->
    <!--              kind: 'ft',-->
    <!--              color: currentColors['forecast-tertiary'],-->
    <!--            },-->
    <!--          ]"-->
    <!--        />-->
    <!--      </div>-->
    <!--      <div class="newForecastCard is-flex-grow-1 is-flex-basis-0">-->
    <!--        <forecast-horizontal-overheaded-of-first-bar-->
    <!--          :title="profitData.title"-->
    <!--          :tooltip="profitData.tooltip"-->
    <!--          is-overhead-good-->
    <!--          :data="[-->
    <!--            {-->
    <!--              label: this.$tf(-->
    <!--                'projectPage.forecastWholeStatus.profitChart.plan.label|Terv'-->
    <!--              ),-->
    <!--              value: Math.round(-->
    <!--                projectDetails.expectedIncome - projectDetails.budget-->
    <!--              ),-->
    <!--              kind: 'ft',-->
    <!--              color: currentColors['forecast-primary'],-->
    <!--            },-->
    <!--            {-->
    <!--              label: this.$tf(-->
    <!--                'projectPage.forecastWholeStatus.profitChart.trendingForecast.label|Trendtartó FC'-->
    <!--              ),-->
    <!--              value: Math.round(-->
    <!--                Math.max(-->
    <!--                  projectDetails.expectedIncome - -->
    <!--                    projectDetails.forecastTrendingBudget,-->
    <!--                  0-->
    <!--                )-->
    <!--              ),-->
    <!--              kind: 'ft',-->
    <!--              color: currentColors['forecast-secondary'],-->
    <!--            },-->
    <!--            {-->
    <!--              label: this.$tf(-->
    <!--                'projectPage.forecastWholeStatus.profitChart.planningForecast.label|Tervtartó FC'-->
    <!--              ),-->
    <!--              value: Math.round(-->
    <!--                Math.max(-->
    <!--                  projectDetails.expectedIncome - -->
    <!--                    projectDetails.forecastPlanningBudget,-->
    <!--                  0-->
    <!--                )-->
    <!--              ),-->
    <!--              kind: 'ft',-->
    <!--              color: currentColors['forecast-tertiary'],-->
    <!--            },-->
    <!--          ]"-->
    <!--        />-->
    <!--      </div>-->
    <!--    </div>-->
  </div>
</template>

<script>
import StatusMixin from "../../../../../mixins/StatusMixin";
import ForecastWholeTable from "@/components/enterprise/project/module/forecast/ForecastWholeTable.vue";
// import ForecastHorizontalOverheadedOfFirstBar from "@/components/enterprise/project/module/forecast/ForecastHorizontalOverheadedOfFirstBar.vue";
import { mapGetters } from "vuex";
import { getCurrentColorSet } from "@/utils/util";

export default {
  name: "ForecastWholeStatusOverview",
  components: {
    // ForecastHorizontalOverheadedOfFirstBar,
    ForecastWholeTable,
  },
  mixins: [StatusMixin],
  props: {
    projectDetails: {
      type: Object,
      required: true,
    },
    projectData: {
      type: Object,
      required: true,
    },
  },
  computed: {
    currentColors() {
      return getCurrentColorSet();
    },
    ...mapGetters({
      currencySymbol: "uiConfigStore/getCurrencySymbol",
    }),
    budgetData() {
      return {
        title: this.$tf(
          "projectPage.forecastWholeStatus.budgetChart.title|Büdzsé - ezer {currency}",
          { currency: this.currencySymbol }
        ),
        tooltip: this.$tf(
          "projectPage.forecastWholeStatus.budgetChart.tooltip|Az oszlopdiagram megmutatja, hogy a projekt jelenlegi állapota szerint a várható költés hogyan fog alakulni a tervvel szemben. Optimális esetben a két előrejelzési adat a terv alatt van, vagy megegyezik vele. Amennyiben a költés előrejelzés meghaladja a tervezett büdzsé értékét, a diagram jelzi az egyes oszlopokon a különbséget."
        ),
      };
    },
    mwdData() {
      return {
        title: this.$tf(
          "projectPage.forecastWholeStatus.mwdChart.title|Munkaidő - MWD"
        ),
        tooltip: this.$tf(
          "projectPage.forecastWholeStatus.mwdChart.tooltip|Az oszlopdiagram megmutatja, hogy a projekt jelenlegi állapota szerint a várható erőforrás ráfordítás hogyan fog alakulni a tervvel szemben. Optimális esetben a két előrejelzési adat a terv alatt van, vagy megegyezik vele. Amennyiben a ráfordítás előrejelzés meghaladja a tervezett ráfordítás értékét, a diagram jelzi az egyes oszlopokon a különbséget."
        ),
      };
    },
    profitData() {
      return {
        title: this.$tf(
          "projectPage.forecastWholeStatus.profitChart.title|Profit - ezer {currency}",
          { currency: this.currencySymbol }
        ),
        tooltip: this.$tf(
          "projectPage.forecastWholeStatus.profitChart.tooltip|Az oszlopdiagram megmutatja, hogy a projekt jelenlegi állapota szerint a várható profit hogyan fog alakulni a tervvel szemben. Optimális esetben a két előrejelzési adat a terv felett van, vagy megegyezik vele. Amennyiben a profit előrejelzés alulmúlja a tervezett profit értékét, a diagram jelzi az egyes oszlopokon a különbséget."
        ),
      };
    },
    dueDateChartHeight() {
      const ref = this.$refs.dueDateChart;
      if (!ref) return 120;
      return ref.style.height;
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/assets/scss/colors.scss";

.is-flex-basis-0 {
  flex-basis: 0;
}
</style>
