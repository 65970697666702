<template>
  <div>
    <b-table :data="tiers" :per-page="10" paginated v-if="loaded">
      <b-table-column
        field="tier"
        :label="$tf('tiers.tier.table.tierName|Szint neve')"
        sortable
        v-slot="props"
      >
        <label class="has-font-weight-500">{{ props.row.tier }}</label>
      </b-table-column>

      <b-table-column
        field="description"
        :label="$tf('tiers.tier.table.description|Leírás')"
        sortable
        v-slot="props"
      >
        {{ props.row.description }}
      </b-table-column>

      <b-table-column v-slot="props" cell-class="do-not-wrap" width="32">
        <clickable-icon
          portal-icon
          icon="pencil"
          type="is-info"
          class="mr-2"
          @click="openEdit(props.row)"
        ></clickable-icon>
        <clickable-icon
          portal-icon
          icon="trash"
          type="is-danger"
          @click="deleteRow(props.row.id)"
        ></clickable-icon>
      </b-table-column>

      <template #empty>
        <section class="section">
          <div class="content has-text-grey has-text-centered">
            <p><b-icon icon="frown" size="is-large"></b-icon></p>
            <p>{{ $tf("tiers.tier.table.empty|Még nincsenek szintek.") }}</p>
          </div>
        </section>
      </template>
    </b-table>
    <LoadingSkeleton v-else />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { openDeleteConfirm } from "@/utils/util";
import ClickableIcon from "../../../../components/module/icon/ClickableIcon";
import LoadingSkeleton from "../../../../components/module/loading/LoadingSkeleton";
import TiersEditModal from "../edit/TiersEditModal";

export default {
  name: "TiersTab",
  components: { ClickableIcon, LoadingSkeleton },
  computed: {
    ...mapGetters({
      tiers: "enterprise_tiers/tiers",
    }),
  },
  data() {
    return {
      loaded: false,
    };
  },
  methods: {
    openEdit(row) {
      this.$buefy.modal.open({
        parent: this,
        component: TiersEditModal,
        props: { tier: row },
        hasModalCard: true,
        trapFocus: true,
        events: {
          save: () => this.reloadPage(true),
        },
      });
    },
    async deleteRow(id) {
      openDeleteConfirm(this.$buefy, async () => {
        await this.$store.dispatch("enterprise_tiers/deleteTier", id);
        await this.reloadPage(true);
      });
    },
    async load(force) {
      await this.$store.dispatch("enterprise_tiers/fetchTiers", force);
    },
    async reloadPage(force) {
      await this.load(force);
      await this.$store.dispatch("enterprise_tiers/fetchUsersWithoutTier");
      await this.$store.dispatch("enterprise_tiers/fetchUserTiers");
      await this.$store.dispatch("enterprise_tiers/fetchStandardCosts");
      await this.$store.dispatch("enterprise_tiers/fetchTierCosts");
      await this.$store.dispatch("enterprise_tiers/fetchFixedPriceTiers");
    },
  },
  async mounted() {
    await this.load();
    this.loaded = true;
  },
};
</script>
