import { newLogger } from "@/utils/util";
import StoreCore from "../../../StoreCore";

let logger = newLogger("Enterprise-ProjectDimensions");

const urls = {
  projectDimensions: "/enterprise/project-dimensions",
  projectDimensionsWithId: (dimensionId) =>
    `/enterprise/project-dimensions/${dimensionId}`,
  projectDimensionsSelection: "/enterprise/project-dimensions-selection",
  projectDimensionsAggregated: "/enterprise/project-dimensions/aggregate",
  projectDimensionValuesByDimensionId: (dimensionId) =>
    `/enterprise/project-dimensions/${dimensionId}/values`,
  projectDimensionValuesByDimensionValueId: (dimensionValueId) =>
    `/enterprise/project-dimensions/value/${dimensionValueId}`,
  projectColors: "/enterprise/project-colors",
  projectColorsCompact: "/enterprise/project-colors-compact",
  projectColorsWithId: (id) => `/enterprise/project-colors/${id}`,
  dimensionValuesForProject: (dimensionId, projectTag) =>
    `/enterprise/project-dimensions/${dimensionId}/values/project-assign/${projectTag}`,
  colorForProject: (projectTag) =>
    `/enterprise/project-colors/project-assign/${projectTag}`,
};

const initState = () => {
  return {
    projectColors: [],
    projectDimensions: [], //all not just actives (for admin)
    projectDimensionsWithValues: [], //actives with values
    projectDimensionValues: [],
  };
};

const storeCore = new StoreCore();
const actions = {
  fetchProjectColors: async function ({ commit, dispatch, state }) {
    try {
      let data = await this.remoteRequest(
        "get",
        urls.projectColors,
        null,
        true,
        dispatch,
        "Projekt színek letöltése...",
        null,
        true
      );
      commit("saveProjectColors", data);
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  fetchProjectColorsCompact: async function ({ commit, dispatch, state }) {
    try {
      let data = await this.remoteRequest(
        "get",
        urls.projectColorsCompact,
        null,
        true,
        dispatch,
        "Projekt színek letöltése...",
        null,
        true
      );
      commit("saveProjectColors", data);
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  fetchAllProjectDimensions: async function ({ commit, dispatch, state }) {
    try {
      let data = await this.remoteRequest(
        "get",
        urls.projectDimensions,
        null,
        true,
        dispatch,
        "Projekt dimenziók letöltése...",
        null,
        true
      );
      commit("saveProjectDimensions", data);
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  fetchActiveProjectDimensionsWithValues: async function ({
    commit,
    dispatch,
    state,
  }) {
    try {
      let data = await this.remoteRequest(
        "get",
        urls.projectDimensionsSelection,
        null,
        true,
        dispatch,
        "Projekt dimenziók letöltése...",
        null,
        true
      );
      commit("saveProjectDimensionsWithValues", data);
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  fetchProjectDimensionValues: async function (
    { commit, dispatch, state },
    { params, dimensionId, force } = {}
  ) {
    try {
      const url = dimensionId
        ? urls.projectDimensionValuesByDimensionId(dimensionId)
        : urls.projectDimensionsAggregated;
      let data = await this.remoteRequest(
        "get",
        url,
        params,
        true,
        dispatch,
        "Projekt dimenzió értékek letöltése...",
        null,
        force
      );
      commit("saveProjectDimensionValues", data);
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),

  createColor: async function ({ dispatch, state }, request) {
    try {
      await this.remoteRequest(
        "post",
        urls.projectColors,
        request,
        false,
        dispatch,
        "projectDimensions.createColor.flair|Projekt szín létrehozása",
        undefined,
        undefined,
        true,
        {
          progress:
            "projectDimensions.createColor.progress|Projekt szín létrehozása folyamatban...",
          fail: "projectDimensions.createColor.fail|Projekt szín létrehozása sikertelen!",
          success:
            "projectDimensions.createColor.success|Projekt szín létrehozása sikeres!",
        }
      );
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
      await Promise.reject(err);
    }
  }.bind(storeCore),
  updateColor: async function ({ dispatch }, request) {
    try {
      await this.remoteRequest(
        "put",
        urls.projectColorsWithId(request.id),
        request,
        false,
        dispatch,
        "projectDimensions.updateColor.flair|Projekt szín mentése",
        undefined,
        undefined,
        true,
        {
          progress:
            "projectDimensions.updateColor.progress|Projekt szín mentése folyamatban...",
          fail: "projectDimensions.updateColor.fail|Projekt szín mentése sikertelen!",
          success:
            "projectDimensions.updateColor.success|Projekt szín mentése sikeres!",
        }
      );
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  deleteColor: async function ({ dispatch }, id) {
    try {
      await this.remoteRequest(
        "delete",
        urls.projectColorsWithId(id),
        null,
        false,
        dispatch,
        "projectDimensions.deleteColor.flair|Projekt szín törlése",
        undefined,
        undefined,
        true,
        {
          progress:
            "projectDimensions.deleteColor.progress|Projekt szín törlése folyamatban...",
          fail: "projectDimensions.deleteColor.fail|Projekt szín törlése sikertelen!",
          success:
            "projectDimensions.deleteColor.success|Projekt szín törlése sikeres!",
        }
      );
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),

  createDimension: async function ({ dispatch, state }, request) {
    try {
      await this.remoteRequest(
        "post",
        urls.projectDimensions,
        request,
        false,
        dispatch,
        "projectDimensions.createDimension.flair|Projekt dimenzió létrehozása",
        undefined,
        undefined,
        true,
        {
          progress:
            "projectDimensions.createDimension.progress|Projekt dimenzió létrehozása folyamatban...",
          fail: "projectDimensions.createDimension.fail|Projekt dimenzió létrehozása sikertelen!",
          success:
            "projectDimensions.createDimension.success|Projekt dimenzió létrehozása sikeres!",
        }
      );
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
      await Promise.reject(err);
    }
  }.bind(storeCore),
  updateDimension: async function ({ dispatch }, request) {
    try {
      await this.remoteRequest(
        "put",
        urls.projectDimensionsWithId(request.id),
        request,
        false,
        dispatch,
        "projectDimensions.updateDimension.flair|Projekt dimenzió mentése",
        undefined,
        undefined,
        true,
        {
          progress:
            "projectDimensions.updateDimension.progress|Projekt dimenzió mentése folyamatban...",
          fail: "projectDimensions.updateDimension.fail|Projekt dimenzió mentése sikertelen!",
          success:
            "projectDimensions.updateDimension.success|Projekt dimenzió mentése sikeres!",
        }
      );
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  deleteDimension: async function ({ dispatch }, id) {
    try {
      await this.remoteRequest(
        "delete",
        urls.projectDimensionsWithId(id),
        null,
        false,
        dispatch,
        "projectDimensions.deleteDimension.flair|Projekt dimenzió törlése",
        undefined,
        undefined,
        true,
        {
          progress:
            "projectDimensions.deleteDimension.progress|Projekt dimenzió törlése folyamatban...",
          fail: "projectDimensions.deleteDimension.fail|Projekt dimenzió törlése sikertelen!",
          success:
            "projectDimensions.deleteDimension.success|Projekt dimenzió törlése sikeres!",
        }
      );
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),

  createDimensionValue: async function ({ dispatch, state }, request) {
    try {
      await this.remoteRequest(
        "post",
        urls.projectDimensionValuesByDimensionId(request.dimensionId),
        request,
        false,
        dispatch,
        "projectDimensions.createDimensionValue.flair|Projekt dimenzióhoz tartozó érték létrehozása",
        undefined,
        undefined,
        true,
        {
          progress:
            "projectDimensions.createDimensionValue.progress|Projekt dimenzióhoz tartozó érték létrehozása folyamatban...",
          fail: "projectDimensions.createDimensionValue.fail|Projekt dimenzióhoz tartozó érték létrehozása sikertelen!",
          success:
            "projectDimensions.createDimensionValue.success|Projekt dimenzióhoz tartozó érték létrehozása sikeres!",
        }
      );
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
      await Promise.reject(err);
    }
  }.bind(storeCore),
  updateDimensionValue: async function ({ dispatch }, request) {
    try {
      await this.remoteRequest(
        "put",
        urls.projectDimensionValuesByDimensionValueId(request.id),
        request,
        false,
        dispatch,
        "projectDimensions.saveDimensionValue.flair|Projekt dimenzióhoz tartozó érték mentése",
        undefined,
        undefined,
        true,
        {
          progress:
            "projectDimensions.saveDimensionValue.progress|Projekt dimenzióhoz tartozó érték mentése folyamatban...",
          fail: "projectDimensions.saveDimensionValue.fail|Projekt dimenzióhoz tartozó érték mentése sikertelen!",
          success:
            "projectDimensions.saveDimensionValue.success|Projekt dimenzióhoz tartozó érték mentése sikeres!",
        }
      );
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  deleteDimensionValue: async function ({ dispatch }, id) {
    try {
      await this.remoteRequest(
        "delete",
        urls.projectDimensionValuesByDimensionValueId(id),
        null,
        false,
        dispatch,
        "projectDimensions.deleteDimensionValue.flair|Projekt dimenzióhoz tartozó érték törlése",
        undefined,
        undefined,
        true,
        {
          progress:
            "projectDimensions.deleteDimensionValue.progress|Projekt dimenzióhoz tartozó érték törlése folyamatban...",
          fail: "projectDimensions.deleteDimensionValue.fail|Projekt dimenzióhoz tartozó érték törlése sikertelen!",
          success:
            "projectDimensions.deleteDimensionValue.success|Projekt dimenzióhoz tartozó érték törlése sikeres!",
        }
      );
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  updateDimensionValuesForProject: async function (
    { dispatch },
    { projectTag, dimensionId, values }
  ) {
    try {
      await this.remoteRequest(
        "put",
        urls.dimensionValuesForProject(dimensionId, projectTag),
        values,
        false,
        dispatch,
        "projectDimensions.updateDimensionValuesForProject.flair|Projekthez tartozó dimenzió érték módosítása",
        undefined,
        undefined,
        true,
        {
          progress:
            "projectDimensions.updateDimensionValuesForProject.progress|Dimenzió értékek mentése folyamatban...",
          fail: "projectDimensions.updateDimensionValuesForProject.fail|Dimenzió értékek mentése sikertelen!",
          success:
            "projectDimensions.updateDimensionValuesForProject.success|Dimenzió értékek mentése sikeres!",
        }
      );
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  updateColorForProject: async function ({ dispatch }, { projectTag, values }) {
    try {
      await this.remoteRequest(
        "put",
        urls.colorForProject(projectTag),
        values,
        false,
        dispatch,
        "projectDimensions.updateDimensionValuesForProject.flair|Projekthez tartozó szín módosítása",
        undefined,
        undefined,
        true,
        {
          progress:
            "projectDimensions.updateDimensionValuesForProject.progress|Szín mentése folyamatban...",
          fail: "projectDimensions.updateDimensionValuesForProject.fail|Szín mentése sikertelen!",
          success:
            "projectDimensions.updateDimensionValuesForProject.success|Szín mentése sikeres!",
        }
      );
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  clearCache: async function ({}) {
    this.clearCoreCache();
  }.bind(storeCore),
};

const mutations = {
  saveProjectColors: (state, projectColors) => {
    state.projectColors = projectColors; //all not just actives (for admin)
  },
  saveProjectDimensions: (state, projectDimensions) => {
    state.projectDimensions = projectDimensions; //all not just actives (for admin)
  },
  saveProjectDimensionsWithValues: (state, projectDimensionsWithValues) => {
    state.projectDimensionsWithValues = projectDimensionsWithValues; //actives with values
  },
  saveProjectDimensionValues: (state, projectDimensionValues) => {
    projectDimensionValues.forEach(function (dimensionValue) {
      dimensionValue.projects.forEach(function (project) {
        project.isChecked = "true";
      });
    });
    state.projectDimensionValues = projectDimensionValues;
  },
};

const getters = {
  getProjectColors: (state) => {
    return state.projectColors;
  },
  getProjectDimensions: (state) => {
    return state.projectDimensions; //all not just actives (for admin)
  },
  getProjectDimensionsWithValues: (state) => {
    return state.projectDimensionsWithValues; //actives with values
  },
  getProjectDimensionValues: (state) => {
    return state.projectDimensionValues;
  },
};

export default {
  namespaced: true,
  state: initState(),
  mutations: mutations,
  actions: actions,
  getters: getters,
};
