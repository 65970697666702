<template>
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 140 121"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22.5 108.5H117.5V90.6925C114.23 90.035 110.37 88.5 107.5 88.5C103.855 88.5 98.5925 91 95 91C91.405 91 86.145 88.5 82.5 88.5C78.855 88.5 73.5925 91 70 91C66.405 91 61.145 88.5 57.5 88.5C53.855 88.5 48.5925 91 45 91C41.405 91 36.145 88.5 32.5 88.5C29.63 88.5 25.77 90.035 22.5 90.6925V108.5Z"
      fill="var(--lighterStrongerPrimary)"
    />
    <path
      d="M23.75 91.695C24.6575 91.4775 25.595 91.2175 26.5325 90.9525C28.6475 90.3625 30.8375 89.75 32.5 89.75C34.1575 89.75 36.3425 90.365 38.455 90.96C40.7075 91.5925 43.0375 92.25 45 92.25C46.9625 92.25 49.2925 91.5925 51.545 90.96C53.6575 90.365 55.8425 89.75 57.5 89.75C59.1575 89.75 61.3425 90.365 63.455 90.96C65.7075 91.5925 68.0375 92.25 70 92.25C71.9625 92.25 74.2925 91.5925 76.545 90.96C78.6575 90.365 80.8425 89.75 82.5 89.75C84.1575 89.75 86.3425 90.365 88.455 90.96C90.7075 91.5925 93.0375 92.25 95 92.25C96.9625 92.25 99.2925 91.5925 101.545 90.96C103.658 90.365 105.843 89.75 107.5 89.75C109.163 89.75 111.353 90.3625 113.468 90.9525C114.405 91.215 115.342 91.4775 116.25 91.695"
      stroke="var(--primary)"
      stroke-width="2.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M27.457 89.75C38.062 83.44 64.237 64.08 68.6245 24.75H73.612C68.797 69.4325 49.732 87.42 47.0445 89.75H27.457Z"
      fill="var(--lighterStrongerPrimary)"
    />
    <path
      d="M72.215 26C67.3925 67.6025 50.0375 85.335 46.5675 88.5H31.7475C43.8375 80.3525 65.4125 61.1575 69.7375 26H72.215ZM75 23.5H67.5C62.5 73.5 22.5 91 22.5 91H47.5C47.5 91 70 73.5 75 23.5Z"
      fill="var(--primary)"
    />
    <path
      d="M42.3442 60.4319C38.0792 51.3994 37.6567 42.9294 41.2467 36.8919C45.5242 29.6969 55.4967 25.5744 70.2342 24.8594C63.5692 29.2869 50.5867 40.0169 42.3442 60.4319Z"
      fill="var(--lighterStrongerPrimary)"
    />
    <path
      d="M65.9204 26.435C59.1004 31.7225 49.3329 41.4225 42.3304 57.255C39.3379 49.625 39.2954 42.6225 42.3204 37.5325C45.9479 31.4325 54.0279 27.6575 65.9204 26.435ZM75.0004 23.5C34.2854 23.5 32.8129 45.6875 42.5004 63.5C53.4379 33.8125 75.0004 23.5 75.0004 23.5Z"
      fill="var(--primary)"
    />
    <path
      d="M23.9277 40.3625C25.1877 29.075 33.2477 14.75 52.5002 14.75C61.7352 14.75 69.6627 20.45 71.0377 24.7475L66.4402 24.74C49.5452 24.74 39.6352 25.465 23.9277 40.3625Z"
      fill="var(--lighterStrongerPrimary)"
    />
    <path
      d="M52.5 16C60.33 16 66.53 20.21 68.9275 23.495L66.44 23.49C50.6575 23.49 40.3575 24.175 25.78 36.9825C28.1325 26.9475 36.04 16 52.5 16ZM52.5 13.5C30 13.5 22.5 32.1725 22.5 43.5C39.185 26.815 48.9075 25.99 66.44 25.99C68.36 25.99 70.3725 26 72.5 26C72.5 21 63.3675 13.5 52.5 13.5Z"
      fill="var(--primary)"
    />
    <path
      d="M91.8406 64.4975C90.9756 55.675 87.6506 34.5625 75.3281 24.875C86.5331 25.5475 93.7906 28.875 96.9281 34.7875C101.951 44.255 95.3831 58.2 91.8406 64.4975Z"
      fill="var(--lighterStrongerPrimary)"
    />
    <path
      d="M78.9198 26.4575C87.5723 27.5375 93.2423 30.5225 95.8198 35.37C99.7973 42.8575 95.9748 53.52 92.6048 60.375C91.2973 51.0175 87.8598 35.7075 78.9198 26.4575ZM70.8848 23.5C89.5448 33.0425 90.8848 68.5 90.8848 68.5C90.8848 68.5 121.352 23.5 70.8848 23.5Z"
      fill="var(--primary)"
    />
    <path
      d="M108.7 45.415C106.79 43.475 105.095 41.7125 103.555 40.11C92.2071 28.315 88.9446 24.9225 71.4121 24.755C72.5371 20.3375 79.3021 14.75 88.3446 14.75C104.095 14.75 108.222 30.7575 108.7 45.415Z"
      fill="var(--lighterStrongerPrimary)"
    />
    <path
      d="M88.345 16C101.962 16 106.327 29.0775 107.28 42.17C106.282 41.14 105.345 40.1675 104.458 39.245C93.23 27.5725 89.725 23.93 73.2775 23.5375C75.22 20.1175 80.6825 16 88.345 16ZM88.345 13.5C77.4775 13.5 70 20.9125 70 26C91.55 26 91.4875 29.9875 110 48.5C110 33.5 106.472 13.5 88.345 13.5Z"
      fill="var(--primary)"
    />
    <path
      d="M55.395 88.5C32.3675 88.5 22.5 88.5 22.5 88.5V91V108.5H81.875H85C85 108.5 71.8425 88.5 55.395 88.5Z"
      fill="var(--lightestPrimary)"
    />
    <path
      d="M23.75 89.75H55.395C68.0025 89.75 78.81 102.33 82.5525 107.25"
      stroke="var(--primary)"
      stroke-width="2.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
  </svg>
</template>

<script>
export default {
  name: "HolidayBeach",
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/colors.scss";
svg {
  --lightestPrimary: color-mix(in srgb, var(--primary), #{$white} 85%);
  --lighterStrongerPrimary: color-mix(in srgb, var(--primary), #{$white} 65%);
}
</style>
