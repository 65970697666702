import { newLogger } from "@/utils/util";
import network from "../../../utils/network";
import StoreCore from "@/store/StoreCore";

let logger = newLogger("TenantStore");

const urls = {
  create: "/tenant/tenant",
  tenants: "/tenant/tenants",
  tenant: (id) => `/tenant/tenant/${id}`,
};

/**
 * Tenant store
 */

const initState = () => {
  return {
    tenants: [],
    tenant: {},
  };
};

const storeCore = new StoreCore();
const actions = {
  fetchAll: async function ({ commit, dispatch }, force) {
    try {
      let data = await this.remoteRequest(
        "get",
        urls.tenants,
        null,
        false,
        dispatch,
        "Tenantok betöltése...",
        null,
        force
      );
      commit("saveTenants", data);
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  fetch: async function ({ commit, dispatch }, tenantId) {
    try {
      let data = await this.remoteRequest(
        "get",
        urls.tenant(tenantId),
        null,
        false,
        dispatch,
        "Tenant betöltése..."
      );
      commit("saveTenant", data);
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  createTenant: async function ({ dispatch }, params) {
    try {
      let data = await this.remoteRequest(
        "post",
        urls.create,
        params,
        false,
        dispatch,
        "Tenant létrehozása..."
      );
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
      await Promise.reject(err);
    }
  }.bind(storeCore),
  editTenant: async function ({ dispatch }, params) {
    try {
      let data = await this.remoteRequest(
        "put",
        urls.tenant(params.id),
        params,
        false,
        dispatch,
        "Tenant szerkesztése..."
      );
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
      await Promise.reject(err);
    }
  }.bind(storeCore),
  deleteTenant: async function ({ dispatch }, tenantId) {
    try {
      let data = await this.remoteRequest(
        "delete",
        urls.tenant(tenantId),
        null,
        false,
        dispatch,
        "Tenant törlése..."
      );
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
      await Promise.reject(err);
    }
  }.bind(storeCore),
  clearCache: async function ({}) {
    this.clearCoreCache();
  }.bind(storeCore),
};

const mutations = {
  saveTenants: (state, tenants) => {
    state.tenants = tenants;
  },
  saveTenant: (state, tenant) => {
    state.tenant = tenant;
  },
};

const getters = {
  tenants: (state) => {
    return state.tenants;
  },
  tenant: (state) => {
    return state.tenant;
  },
};

export default {
  namespaced: true,
  state: initState(),
  mutations: mutations,
  actions: actions,
  getters: getters,
};
