<script>
import _ from "lodash";

// curly brace syntax
_.templateSettings.interpolate = /{{([\s\S]+?)}}/g;

// this would most likely be in a language file
const TEMPLATES_MAP = {
  alpha: "Nem megfelelő formátum.",
  minLength: "Minimum hossz: {{min}} karakter.",
  maxLength: "Maximum hossz: {{max}} karakter.",
  betweenHours: "A számnak 0 és 24 közt kell lennie.",
  minValue: "Legkisebb érték: {{min}}.",
  maxValue: "Legnagyobb érték: {{max}}.",
  length: "Nem megfelelő hossz, vagy formátum.",
  required: "A mező kitöltése kötelező.",
  requiredIf: "A mező kitöltése kötelező.",
  email: "Nem megfelelő email formátum.",
  numeric: "A mező csak számokat tartalmazhat.",
  decimal: "A mező csak számokat és tizedespontot tartalmazhat.",
  leThanAmount: "Az érték nem lehet nagyobb mint fennmaradó összeg.",
  sameAsPassword: "A megadott jelszavak nem egyeznek.",
};

export default {
  props: {
    validations: {
      required: true,
      type: Object,
    },
  },
  emits: ["submit"],
  computed: {
    errors() {
      if (!this.invalid) {
        return [];
      }

      return Object.keys(this.validations).reduce((errors, validator) => {
        if (!validator.startsWith("$")) {
          if (this.validations[validator].$invalid) {
            const compiled = _.template(TEMPLATES_MAP[validator]);

            errors.push(compiled(this.validations[validator].$params));
          }
        }

        return errors;
      }, []);
    },

    invalid() {
      return this.validations.$dirty && this.validations.$invalid;
    },
  },

  render() {
    return this.$slots.default({
      errors: this.errors,
      invalid: this.invalid,
    });
  },
};
</script>
