<template>
  <div class="mt-4">
    <b-modal
      has-modal-card
      trap-focus
      aria-role="dialog"
      aria-modal
      v-model="isFeeModalOpen"
      v-on:cancel="handleCancel"
    >
      <div class="card p-4">
        <activity-fee-form
          :activity="activity"
          :activity-type="activityType"
          :fee-data="embeddableEdit"
          :edit="editingFee"
          v-on:fee-submitted="handleSubmit"
        />
      </div>
    </b-modal>
    <b-modal
      has-modal-card
      trap-focus
      aria-role="dialog"
      aria-modal
      v-model="isExpenseModalOpen"
      v-on:cancel="handleCancel"
    >
      <div class="card p-4">
        <activity-expense-form
          :activity="activity"
          :activity-type="activityType"
          :expense-data="embeddableEdit"
          v-on:expense-submitted="handleSubmit"
        />
      </div>
    </b-modal>

    <div class="is-flex is-justify-content-space-between">
      <div>
        <h4 class="title">
          {{ $tf("supportActivityDetails.title.fees|Havidíjak") }}
        </h4>
      </div>
      <div>
        <b-button
          type="is-info"
          size="is-small"
          rounded
          @click="isFeeModalOpen = true"
        >
          {{ $tf("projectpage.supportActivity.fee.add|Havidíj hozzáadása") }}
        </b-button>
      </div>
    </div>
    <b-table
      :data="sortedFees"
      ref="expenseTable"
      :per-page="10"
      detailed
      detail-key="identifier"
      :show-detail-icon="false"
      aria-next-label="Következő lap"
      aria-previous-label="Előző lap"
      aria-page-label="Lap"
      aria-current-label="Aktuális lap"
    >
      <template #empty>
        <div class="has-text-centered">
          {{
            $tf("projectpage.supportActivity.table.empty|Nincsenek havidíjak")
          }}
        </div>
      </template>
      <b-table-column
        field="validFrom"
        label="Dátumtól"
        sortable
        centered
        v-slot="props"
      >
        {{ props.row.validFrom }}
      </b-table-column>
      <b-table-column
        field="monthlyFee"
        label="Havidíj"
        sortable
        centered
        v-slot="props"
      >
        {{ moneyify(props.row.monthlyFee) }}
      </b-table-column>
      <b-table-column
        v-if="activityType === 'FIX'"
        field="budget"
        label="Büdzsé"
        sortable
        centered
        v-slot="props"
      >
        {{ moneyify(props.row.budget) }}
      </b-table-column>
      <b-table-column
        v-if="activityType === 'LOCKUP'"
        field="budget"
        label="Büdzsé keret"
        sortable
        centered
        v-slot="props"
      >
        {{ moneyify(props.row.budget) }}
      </b-table-column>
      <b-table-column
        v-if="activityType === 'FIX'"
        field="profit"
        label="Profit%"
        sortable
        centered
        v-slot="props"
      >
        {{ percentify(props.row.profit) }}
      </b-table-column>
      <b-table-column
        v-if="activityType === 'LOCKUP'"
        field="profit"
        label="Becsült Profit%"
        sortable
        centered
        v-slot="props"
      >
        {{ percentify(props.row.profit) }}
      </b-table-column>
      <b-table-column
        v-if="activityType === 'FIX'"
        field="monthlyHours"
        label="Becsült időkeret"
        sortable
        centered
        v-slot="props"
      >
        {{ roundToTwoDecimals(props.row.monthlyHours) }} óra
      </b-table-column>
      <b-table-column
        v-if="activityType === 'LOCKUP'"
        field="monthlyHours"
        label="Lekötött órák"
        sortable
        centered
        v-slot="props"
      >
        {{ roundToTwoDecimals(props.row.monthlyHours) }} óra
      </b-table-column>
      <b-table-column
        field="actions"
        :label="$tf('projectpage.override.table.actions|Műveletek')"
        centered
        v-slot="props"
        width="80"
      >
        <b-tooltip
          :label="$tf('projectpage.override.table.actions.edit|Szerkesztés')"
          type="is-light"
        >
          <clickable-icon
            icon="edit"
            type="is-info"
            @click="editFee(props.row)"
          />
        </b-tooltip>
        <b-tooltip
          :label="$tf('projectpage.tigList.table.actions.delete|Törlés')"
          type="is-light"
        >
          <clickable-icon
            icon="trash"
            type="is-danger"
            @click="deleteEmbeddable(props.row.id, 'FEE')"
          />
        </b-tooltip>
      </b-table-column>
    </b-table>
    <div class="is-flex is-justify-content-space-between mt-4">
      <div>
        <h4 class="title">
          {{ $tf("supportActivityDetails.title.expenses|Kiadások") }}
        </h4>
      </div>
      <div>
        <b-button
          type="is-info"
          size="is-small"
          rounded
          @click="isExpenseModalOpen = true"
        >
          {{ $tf("projectpage.supportActivity.expense.add|Kiadás hozzáadása") }}
        </b-button>
      </div>
    </div>
    <b-table
      :data="sortedExpenses"
      ref="expenseTable"
      :per-page="10"
      detailed
      detail-key="identifier"
      :show-detail-icon="false"
      aria-next-label="Következő lap"
      aria-previous-label="Előző lap"
      aria-page-label="Lap"
      aria-current-label="Aktuális lap"
    >
      <template #empty>
        <div class="has-text-centered">
          {{
            $tf("projectpage.supportActivity.table.empty|Nincsenek kiadások")
          }}
        </div>
      </template>
      <b-table-column
        field="date"
        label="Dátum"
        sortable
        centered
        v-slot="props"
      >
        {{ props.row.date }}
      </b-table-column>
      <b-table-column
        field="amount"
        label="Összeg"
        sortable
        centered
        v-slot="props"
      >
        {{ moneyify(props.row.amount) }}
      </b-table-column>
      <b-table-column
        field="description"
        label="Leírás"
        sortable
        centered
        v-slot="props"
      >
        {{ props.row.description }}
      </b-table-column>
      <b-table-column
        field="actions"
        :label="$tf('projectpage.override.table.actions|Műveletek')"
        centered
        v-slot="props"
        width="80"
      >
        <b-tooltip
          :label="$tf('projectpage.override.table.actions.edit|Szerkesztés')"
          type="is-light"
        >
          <clickable-icon
            icon="edit"
            type="is-info"
            @click="editExpense(props.row)"
          />
        </b-tooltip>
        <b-tooltip
          :label="$tf('projectpage.tigList.table.actions.delete|Törlés')"
          type="is-light"
        >
          <clickable-icon
            icon="trash"
            type="is-danger"
            @click="deleteEmbeddable(props.row.id, 'EXPENSE')"
          />
        </b-tooltip>
      </b-table-column>
    </b-table>
  </div>
</template>

<script>
import ActivityFeeForm from "@/components/enterprise/project/activity/ActivityFeeForm.vue";
import {
  deepCopy,
  moneyify,
  percentify,
  roundToTwoDecimals,
} from "@/utils/util";
import ActivityExpenseForm from "@/components/enterprise/project/activity/ActivityExpenseForm.vue";
import ClickableIcon from "@/components/module/icon/ClickableIcon.vue";

export default {
  name: "SupportFeeActivityDetails",
  components: { ClickableIcon, ActivityExpenseForm, ActivityFeeForm },
  emits: ["refresh"],
  props: {
    projectIdentifier: {
      type: String,
      required: true,
    },
    activity: {
      type: Object,
      required: true,
    },
    activityType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isFeeModalOpen: false,
      editingFee: false,
      isExpenseModalOpen: false,
      embeddableEdit: null,
      selectedYear: new Date().getFullYear(),
    };
  },
  computed: {
    sortedFees() {
      let fees = deepCopy(this.activity.activityFees);
      fees.sort((a, b) => new Date(a.validFrom) - new Date(b.validFrom));
      return fees;
    },
    sortedExpenses() {
      let fees = deepCopy(this.activity.activityExpenses);
      fees.sort((a, b) => new Date(a.date) - new Date(b.date));
      return fees;
    },
  },
  methods: {
    roundToTwoDecimals,
    percentify,
    moneyify,
    async handleSubmit() {
      this.isFeeModalOpen = false;
      this.editingFee = false;
      this.isExpenseModalOpen = false;
      this.$emit("refresh");
    },
    handleCancel() {
      this.editingFee = false;
      this.embeddableEdit = null;
    },
    editFee(fee) {
      this.embeddableEdit = fee;
      this.editingFee = true;
      this.isFeeModalOpen = true;
    },
    editExpense(expense) {
      this.embeddableEdit = expense;
      this.isExpenseModalOpen = true;
    },
    async deleteEmbeddable(id, embeddableType) {
      await this.$store.dispatch("support_projects/deleteEmbeddable", {
        id: id,
        activityId: this.activity.id,
        activityType: this.activityType,
        embeddableType: embeddableType,
      });
      this.$emit("refresh");
    },
  },
};
</script>

<style scoped lang="scss"></style>
