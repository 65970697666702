import network from "../../../../utils/network";
import { newLogger } from "@/utils/util";
import StoreCore from "../../../StoreCore";
import app from "@/main.js";

let logger = newLogger("WorkScheduleStore");
let generateRequestId = function () {
  return Math.floor(Math.random() * 100000);
};

const urls = {
  workSchedule: "/census/work-schedule",
  workScheduleForUser: (userId) => `/census/work-schedule/user/${userId}`,
  workScheduleForMe: "/census/work-schedule/mine",
  updateOwnWorkScheduleSites: "/census/work-schedule/sites/mine",
};

const initState = () => {
  return {
    requestInProgress: false,
    ownWorkSchedules: null,
    adminWorkSchedules: null,
    workScheduleSites: null,
  };
};

const storeCore = new StoreCore();
const actions = {
  createWorkSchedule: async ({ dispatch, state }, request) => {
    if (state.requestInProgress) return;

    state.requestInProgress = true;

    let requestId = generateRequestId();
    dispatch(
      "loading/startLoading",
      {
        id: requestId,
        type: "POST",
        url: urls.workSchedule,
        flair: "workschedule.create.flair|Munkarend mentése",
        event: true,
        messages: {
          progress:
            "workschedule.create.flair|Munkarend mentése folyamatban...",
          fail: "workschedule.create.fail|Munkarend mentése sikertelen!",
          success: "workschedule.create.success|Munkarend mentése sikeres!",
        },
      },
      { root: true }
    );

    try {
      const axios = await network.connection();
      logger.debug("Create workschedule...");
      await axios.post(urls.workSchedule + "/create/" + request.user, request);

      dispatch(
        "loading/finishLoading",
        { id: requestId, result: true, event: true },
        { root: true }
      );
      state.requestInProgress = false;
    } catch (err) {
      logger.error(err);
      dispatch(
        "loading/finishLoading",
        { id: requestId, result: false, event: true },
        { root: true }
      );
      state.requestInProgress = false;
      await Promise.reject(err);
    }
  },
  getWorkSchedulesForUser: async function (
    { dispatch, state, commit },
    userId
  ) {
    try {
      let data = await this.remoteRequest(
        "get",
        urls.workScheduleForUser(userId),
        null,
        true,
        dispatch,
        "Munkarendek betöltése..."
      );
      commit("setOwnWorkSchedules", data);
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  getWorkSchedulesForMe: async function ({ dispatch, commit }, force = false) {
    try {
      let data = await this.remoteRequest(
        "get",
        urls.workScheduleForMe,
        null,
        true,
        dispatch,
        "Munkarendek betöltése...",
        null,
        force
      );
      commit("setOwnWorkSchedules", data);
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  createWorkScheduleSite: async function ({ dispatch, state }, request) {
    try {
      await this.remoteRequest(
        "post",
        urls.workSchedule + "/site",
        request,
        false,
        dispatch,
        "workschedule.createSite.flair|Helyszín elmentése",
        undefined,
        undefined,
        false,
        true,
        {
          progress:
            "workschedule.createSite.progress|Helyszín mentése folyamatban...",
          fail: "workschedule.createSite.fail|Helyszín mentése sikertelen!",
          success: "workschedule.createSite.success|Helyszín mentése sikeres!",
        }
      );
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
      await Promise.reject(err);
    }
  }.bind(storeCore),
  getWorkScheduleSites: async function ({ dispatch, state, commit }, params) {
    try {
      let data = await this.remoteRequest(
        "get",
        urls.workSchedule + "/site/selectable",
        params,
        true,
        dispatch,
        "Munkahelyszínek betöltése..."
      );
      commit("setWorkScheduleSites", data);
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  getWorkScheduleAdminSites: async function (
    { dispatch, state, commit },
    force
  ) {
    try {
      let data = await this.remoteRequest(
        "get",
        urls.workSchedule + "/site",
        null,
        true,
        dispatch,
        "Munkahelyszínek betöltése...",
        null,
        force
      );
      commit("setWorkScheduleSites", data);
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  updateWorkScheduleSites: async function ({ dispatch, state }, site) {
    try {
      await this.remoteRequest(
        "put",
        urls.workSchedule + "/site/" + site.id,
        site,
        false,
        dispatch,
        "workschedule.updateSite.flair|Helyszín frissítése",
        undefined,
        undefined,
        false,
        true,
        {
          progress:
            "workschedule.updateSite.progress|Helyszín frissítése folyamatban...",
          fail: "workschedule.updateSite.fail|Helyszín frissítése sikertelen!",
          success:
            "workschedule.updateSite.success|Helyszín frissítése sikeres!",
        }
      );
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
      await Promise.reject(err);
    }
  }.bind(storeCore),
  deleteWorkScheduleSite: async function ({ dispatch, state }, id) {
    try {
      await this.remoteRequest(
        "delete",
        urls.workSchedule + "/site/" + id,
        null,
        "false",
        dispatch,
        "workschedule.deleteSite.flair|Helyszín törlése",
        undefined,
        undefined,
        false,
        true,
        {
          progress:
            "workschedule.deleteSite.progress|Helyszín törlése folyamatban...",
          fail: "workschedule.deleteSite.fail|Helyszín törlése sikertelen!",
          success: "workschedule.deleteSite.success|Helyszín törlése sikeres!",
        }
      );
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
        app.config.globalProperties.$buefy.toast.open({
          duration: 5000,
          message: err?.response?.data,
          position: "is-bottom",
          type: "is-danger",
        });
      }
    }
  }.bind(storeCore),
  updateWorkScheduleSitesByUser: async function ({ dispatch, state }, request) {
    try {
      await this.remoteRequest(
        "put",
        urls.updateOwnWorkScheduleSites,
        request,
        false,
        dispatch,
        "workschedule.updateOwnSites.flair|Helyszíneim frissítése",
        undefined,
        undefined,
        true,
        {
          progress:
            "workschedule.updateOwnSites.progress|Helyszínek frissítése folyamatban...",
          fail: "workschedule.updateOwnSites.fail|Helyszínek frissítése sikertelen!",
          success:
            "workschedule.updateOwnSites.success|Helyszínek frissítése sikeres!",
        }
      );
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
      await Promise.reject(err);
    }
  }.bind(storeCore),
  clearCache: async function ({}) {
    this.clearCoreCache();
  }.bind(storeCore),
};

const mutations = {
  setOwnWorkSchedules(state, workSchedules) {
    state.ownWorkSchedules = workSchedules;
  },
  setAdminWorkSchedules(state, workSchedules) {
    state.adminWorkSchedules = workSchedules;
  },
  setWorkScheduleSites(state, workScheduleSites) {
    state.workScheduleSites = workScheduleSites;
  },
};

const getters = {
  ownWorkSchedules: (state) => {
    return state.ownWorkSchedules;
  },
  requestInProgress: (state) => {
    return state.requestInProgress;
  },
  adminWorkSchedules: (state) => {
    return state.adminWorkSchedules;
  },
  workScheduleSites: (state) => {
    return state.workScheduleSites;
  },
};

export default {
  namespaced: true,
  state: initState(),
  mutations: mutations,
  actions: actions,
  getters: getters,
};
