<template>
  <div>
    <enterprise-outsource-project-details></enterprise-outsource-project-details>
  </div>
</template>

<script>
import EnterpriseOutsourceProjectDetails from "../../../components/enterprise/ProjectDetails/OutsourceProjectDetails";

export default {
  name: "Enterprise-OutsourceDetails",
  components: {
    EnterpriseOutsourceProjectDetails,
  },
};
</script>

<style lang="scss"></style>
