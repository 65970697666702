<template>
  <b-field :label="$tf('forecastYearSelector.label|Év kiválasztása')">
    <b-dropdown v-model="computedValue" append-to-body aria-role="list">
      <template #trigger="{ active }">
        <a class="navbar-item" role="button">
          <span>{{ computedValue }}</span>
          <b-icon :icon="active ? 'chevron-up' : 'chevron-down'"></b-icon>
        </a>
      </template>
      <b-dropdown-item
        aria-role="listitem"
        v-for="year in years"
        :key="year.id"
        :value="year.year"
        @click="$emit('select', year)"
      >
        <div class="media">
          <b-icon class="media-left" :icon="icon(year)" />
          <div class="media-content">
            {{ year.year }}
          </div>
        </div>
      </b-dropdown-item>
    </b-dropdown>
  </b-field>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ForecastYearSelector",
  props: {
    modelValue: {
      type: Number,
      required: true,
    },
    onlyClosed: {
      type: Boolean,
      default: false,
    },
  },
  async mounted() {
    if (this.onlyClosed) {
      await this.$store.dispatch("forecast_year/fetchClosedYears");
      const year =
        this.years.find((it) => it.year === this.modelValue) ??
        this.years[this.years.length - 1];
      this.computedValue = year?.year;
      this.$emit("select", year);
    } else {
      await this.$store.dispatch("forecast_year/fetchYears");
      const year = this.years.find((it) => it.year === this.modelValue);
      this.computedValue = year?.year;
      this.$emit("select", year);
    }
  },
  computed: {
    ...mapGetters({
      allYears: "forecast_year/years",
      closedYears: "forecast_year/closedYears",
    }),
    years() {
      return this.onlyClosed ? this.closedYears : this.allYears;
    },
    computedValue: {
      get() {
        return this.modelValue;
      },
      set(modelValue) {
        this.$emit("update:modelValue", modelValue);
      },
    },
  },
  watch: {
    modelValue(val) {
      this.$emit(
        "select",
        this.years.find((year) => year.year === val)
      );
    },
  },
  methods: {
    icon(year) {
      const thisYear = new Date().getFullYear();
      if (year.year === thisYear) return "check";
      if (year.year > thisYear && year.hasPlans) return "calendar-day";
      if (year.planningClosed) return "lock";
      return "plus";
    },
  },
};
</script>
