<template>
  <div>
    <loading-skeleton v-if="loading" />
    <div v-else>
      <b-modal
        has-modal-card
        trap-focus
        aria-role="dialog"
        aria-modal
        style="height: 100%"
        v-model="isModalOpen"
        v-on:cancel="editedActivity = null"
      >
        <div class="card p-4">
          <support-activity-form
            :project-identifier="projectDetails.identifier"
            :prefix="prefix"
            :activity-type="activityType"
            :edited-activity="editedActivity"
            v-on:activity-submitted="handleCreate"
          />
        </div>
      </b-modal>
      <div class="is-flex">
        <div>
          <div class="is-flex has-gap-2" v-if="filteredActivities.length > 0">
            <b-dropdown aria-role="list" v-model="selectedActivity">
              <template #trigger="{ active }">
                <b-button
                  :label="`${selectedActivity.name} (${selectedActivity.identifier})`"
                  type="is-info"
                  size="is-small"
                  :icon-right="active ? 'chevron-up' : 'chevron-down'"
                  rounded
                />
              </template>

              <b-dropdown-item
                v-for="activity in filteredActivities"
                :key="activity.id"
                :value="activity"
                aria-role="listitem"
                >{{
                  activity.name + " (" + activity.identifier + ")"
                }}</b-dropdown-item
              >
            </b-dropdown>
            <div class="is-flex is-align-items-center">
              <b-tooltip
                :label="
                  $tf('projectpage.override.table.actions.edit|Szerkesztés')
                "
                type="is-light"
              >
                <clickable-icon
                  icon="edit"
                  type="is-info"
                  @click="handleEdit"
                />
              </b-tooltip>
              <b-tooltip
                :label="$tf('projectpage.tigList.table.actions.delete|Törlés')"
                type="is-light"
              >
                <clickable-icon
                  icon="trash"
                  type="is-danger"
                  @click="handleDelete"
                />
              </b-tooltip>
            </div>
            <div
              v-if="issueTrackingSystem !== ISSUE_TRACKING_SYSTEM.SELF.enum"
              class="is-flex is-align-items-center"
            >
              <external-issue-targeting-link
                :modelValue="selectedActivity.externalIssueTargeting"
              />
            </div>
          </div>
          <span v-else>
            {{
              $tf(
                "projectPage.activities.empty|Ehhez a projekthez még nincs létrehozva ilyen aktivitás."
              )
            }}
          </span>
        </div>
        <div class="ml-auto">
          <b-button
            rounded
            size="is-small"
            type="is-info"
            @click="isModalOpen = true"
          >
            {{ $tf("projectpage.activities.add|Új aktivitás hozzáadása") }}
          </b-button>
        </div>
      </div>
      <div v-if="selectedActivity">
        <support-fee-activity-details
          :project-identifier="projectDetails.identifier"
          :activity="selectedActivity"
          :activity-type="activityType"
          v-on:refresh="refreshData"
        />
      </div>
    </div>
  </div>
</template>

<script>
import SupportActivityForm from "@/components/enterprise/project/form/SupportActivityForm.vue";
import LoadingSkeleton from "@/components/module/loading/LoadingSkeleton.vue";
import SupportFeeActivityDetails from "@/components/enterprise/project/activity/SupportFeeActivityDetails.vue";
import ExternalIssueTargetingLink from "@/components/enterprise/targeting/ExternalIssueTargetingLink.vue";
import ClickableIcon from "@/components/module/icon/ClickableIcon.vue";
import { formatDate, openDeleteConfirm } from "@/utils/util";
import { ISSUE_TRACKING_SYSTEM } from "@/utils/const";
import { mapGetters } from "vuex";

export default {
  name: "SupportActivityTabItem",
  components: {
    ClickableIcon,
    ExternalIssueTargetingLink,
    SupportFeeActivityDetails,
    LoadingSkeleton,
    SupportActivityForm,
  },
  data() {
    return {
      selectedActivity: this.projectDetails[this.activityField][0],
      editedActivity: null,
      isModalOpen: false,
      loading: false,
      refreshKey: 0,
    };
  },
  props: {
    activityType: {
      type: String,
      required: true,
    },
    activityField: {
      type: String,
      required: true,
    },
    projectDetails: {
      type: Object,
      required: true,
    },
    prefix: {
      type: String,
      required: true,
    },
    employees: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ISSUE_TRACKING_SYSTEM() {
      return ISSUE_TRACKING_SYSTEM;
    },
    filteredActivities() {
      return this.projectDetails[this.activityField];
    },
    ...mapGetters({
      issueTrackingSystem: "uiConfigStore/getIssueTrackingSystem",
    }),
  },
  methods: {
    formatDate,
    async handleCreate() {
      this.isModalOpen = false;
      await this.refreshData();
    },
    handleEdit() {
      this.editedActivity = this.selectedActivity;
      this.isModalOpen = true;
    },
    async handleDelete() {
      openDeleteConfirm(this.$buefy, async () => {
        await this.$store.dispatch("support_projects/deleteActivity", {
          projectId: this.projectDetails.id,
          activityType: this.activityType,
          id: this.selectedActivity.id,
        });
        await this.refreshData();
      });
    },
    async refreshData() {
      this.loading = true;
      let params = new URLSearchParams();
      params.append("year", new Date().getFullYear());
      params.append("includeFixes", "true");
      params.append("includeLockups", "true");
      params.append("includeBillables", "true");
      await this.$store.dispatch("support_projects/fetchProjectDetails", {
        projectIdentifier: this.projectDetails.identifier,
        requestParams: { params },
        force: true,
      });
      const field = this.projectDetails[this.activityField];
      if (!this.selectedActivity) {
        this.selectedActivity = field ? field[0] : undefined;
      } else {
        this.selectedActivity = field?.find(
          (a) => a.id === this.selectedActivity.id
        );
      }
      this.refreshKey++;
      this.loading = false;
    },
  },
};
</script>

<style scoped lang="scss"></style>
