import { newLogger } from "@/utils/util";
import StoreCore from "../../../../StoreCore";

let logger = newLogger("Enterprise-ForecastScenarioStore");

const urls = {
  scenario: "/enterprise/forecast/scenario",
  scenarioWithId: (id) => `/enterprise/forecast/scenario/${id}`,
};

const initState = () => {
  return {
    scenarios: [],
    scenario: {},
  };
};

const storeCore = new StoreCore();
const actions = {
  fetchScenarios: async function ({ commit, dispatch }, force) {
    try {
      let scenario = await this.remoteRequest(
        "get",
        urls.scenario,
        undefined,
        true,
        dispatch,
        "Scenariok betöltése",
        null,
        force
      );
      commit("saveScenarios", scenario);
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  fetchScenario: async function ({ commit, dispatch }, { id, force } = {}) {
    try {
      let scenario = await this.remoteRequest(
        "get",
        urls.scenarioWithId(id),
        null,
        true,
        dispatch,
        "Scenario betöltése",
        null,
        force
      );
      commit("saveScenario", scenario);
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  createScenario: async function ({ dispatch }, scenario) {
    try {
      await this.remoteRequestObject({
        method: "post",
        url: urls.scenario,
        data: scenario,
        cacheable: false,
        dispatch,
        flair: "Scenario létrehozása...",
        toast: true,
      });
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
    dispatch("clearCache");
  }.bind(storeCore),
  updateScenario: async function ({ dispatch }, scenario) {
    try {
      await this.remoteRequestObject({
        method: "put",
        url: urls.scenarioWithId(scenario.id),
        data: scenario,
        cacheable: false,
        dispatch,
        flair: "Scenario frissítése...",
        toast: true,
      });
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
    dispatch("clearCache");
  }.bind(storeCore),
  deleteScenario: async function ({ dispatch }, id) {
    try {
      await this.remoteRequestObject({
        method: "delete",
        url: urls.scenarioWithId(id),
        cacheable: false,
        dispatch,
        flair: "Scenario törlése...",
        toast: true,
      });
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
        return Promise.reject(err);
      }
    }
    dispatch("clearCache");
  }.bind(storeCore),
  clearCache: async function ({}) {
    this.clearCoreCache();
  }.bind(storeCore),
};

const mutations = {
  saveScenarios: (state, scenario) => {
    state.scenarios = scenario;
  },
  saveScenario: (state, scenario) => {
    state.scenario = scenario;
  },
};

const getters = {
  scenarios: (state) => {
    return state.scenarios;
  },
  scenario: (state) => {
    return state.scenario;
  },
};

export default {
  namespaced: true,
  state: initState(),
  mutations: mutations,
  actions: actions,
  getters: getters,
};
