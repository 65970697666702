<template>
  <div class="modal-card" style="width: auto">
    <header
      class="modal-card-head is-flex-direction-column is-align-items-start pb-0"
    >
      <div class="is-flex is-align-items-center has-gap-4">
        <div
          style="
            width: 32px;
            height: 32px;
            border-radius: 64px;
            background-color: #ebe9fe;
            display: flex;
            align-items: center;
            justify-content: center;
          "
          class="has-text-dark-blue"
        >
          <b-icon icon="user" size="is-small" style="opacity: 0.6" />
        </div>
        <h3
          style="font-size: 24px"
          class="my-3 has-text-black has-font-weight-500"
        >
          {{ index === null ? "Ügyfél hozzáadása" : "Ügyfél szerkesztése " }}
        </h3>
      </div>
      <p class="font-size:12px;color:darkgrey;">
        Az ügyfelek adatait módosíthatod a későbbiekben is.
      </p>
    </header>
    <section class="modal-card-body">
      <b-field
        label="Rövid név"
        label-position="on-border"
        :type="{ 'is-danger': triedToSubmit && !identifierEdit }"
      >
        <b-input v-model="identifierEdit" placeholder="pl.: Loginet" />
      </b-field>

      <b-field
        label="Teljes név"
        label-position="on-border"
        :type="{ 'is-danger': triedToSubmit && !nameEdit }"
      >
        <b-input v-model="nameEdit" placeholder="pl.: Loginet Systems Kft." />
      </b-field>

      <b-field label="Leírás" label-position="on-border">
        <b-input v-model="descriptionEdit" type="textarea" />
      </b-field>
    </section>
    <footer class="modal-card-foot user-edit-modal-footer">
      <b-button
        class="onboarding-button onboarding-button-transparent onboarding-button-small"
        @click="instance.emit('close')"
        label="Mégse"
      />
      <b-button
        class="onboarding-button onboarding-button-small"
        label="Mentés"
        type="is-primary"
        @click="saveAndClose()"
      />
    </footer>
  </div>
</template>

<script>
import { cloneDeep } from "lodash";
import { getCurrentInstance } from "vue";

export default {
  name: "OnboardingSetupClientModal",
  setup: () => {
    const instance = getCurrentInstance();
    return {
      instance: instance,
    };
  },
  props: {
    index: { type: Number, required: false, default: null },
  },
  data() {
    return {
      clients: [],
      identifierEdit: null,
      nameEdit: null,
      descriptionEdit: null,
      triedToSubmit: false,
    };
  },
  created() {
    this.clients = cloneDeep(this.$store.getters["onboarding/getClients"]);
    if (this.index !== null) {
      this.identifierEdit = this.clients[this.index].identifier;
      this.nameEdit = this.clients[this.index].name;
      this.descriptionEdit = this.clients[this.index].description;
    }
  },
  methods: {
    async saveAndClose() {
      this.triedToSubmit = true;
      if (!this.identifierEdit || !this.nameEdit) {
        return;
      }

      if (this.index == null) {
        this.clients.push({
          identifier: this.identifierEdit,
          name: this.nameEdit,
          description: this.descriptionEdit,
        });
      } else {
        this.clients[this.index].identifier = this.identifierEdit;
        this.clients[this.index].name = this.nameEdit;
        this.clients[this.index].description = this.descriptionEdit;
      }

      await this.$store.dispatch("onboarding/updateClients", this.clients);

      this.instance.emit("close");
    },
  },
};
</script>

<style scoped></style>
