<template>
  <section class="section">
    <div class="container">
      <h1 class="title">Tenantok</h1>
      <h2 class="subtitle">Tenantok létrehozása, kezelése.</h2>

      <b-button
        type="is-info"
        class="is-pulled-top-right"
        @click="toTenantEdit()"
        ><portal-icon icon="plus-circle" class="mr-2"></portal-icon>Új tenant
      </b-button>
    </div>

    <div class="container">
      <b-table :data="filteredTenants" :loading="loading" focusable paginated>
        <b-table-column
          field="identifier"
          label="Azonosító"
          sortable
          searchable
          v-slot="props"
        >
          {{ props.row.identifier }}
        </b-table-column>
        <b-table-column
          field="name"
          label="Név"
          sortable
          searchable
          v-slot="props"
        >
          {{ props.row.name }}
        </b-table-column>
        <b-table-column
          field="companyName"
          label="Cég név"
          sortable
          searchable
          v-slot="props"
        >
          {{ props.row.companyName }}
        </b-table-column>
        <b-table-column
          field="contact"
          label="Kontakt"
          searchable
          :custom-search="onContactFilter"
          v-slot="props"
        >
          {{ props.row.contactEmail }}
          {{ props.row.contactPhoneNumber }}
        </b-table-column>
        <b-table-column
          field="numberOfUsers"
          label="Dolgozók száma"
          v-slot="props"
          sortable
          numeric
        >
          {{ props.row.numberOfUsers }}
        </b-table-column>
        <b-table-column
          field="numbeOfSuperUsers"
          label="Szuperuserek száma"
          v-slot="props"
          sortable
          numeric
        >
          {{ props.row.numberOfSuperUsers }}
        </b-table-column>
        <b-table-column
          field="powerUserRoleIdentifier"
          label="Szuperuser szerep azonosítója"
          v-slot="props"
          sortable
          searchable
        >
          {{ props.row.powerUserRoleIdentifier }}
        </b-table-column>
        <b-table-column
          field="deleted"
          label="Aktív?"
          sortable
          searchable
          centered
        >
          <template #searchable>
            <div class="pl-3 pt-2">
              <tri-state-check-box v-model="active"></tri-state-check-box>
            </div>
          </template>
          <template v-slot="props">
            <b-icon
              v-if="props.row.deleted"
              icon="x"
              type="is-danger"
              size="is-small"
            />
            <b-icon v-else icon="check" type="is-success" />
          </template>
        </b-table-column>

        <b-table-column v-slot="props" cell-class="do-not-wrap" width="48">
          <div v-if="!props.row.deleted">
            <clickable-icon
              icon="user-plus"
              type="is-success"
              class="mr-2"
              @click="toAddUser(props.row.id)"
            ></clickable-icon>
            <clickable-icon
              portal-icon
              icon="pencil"
              type="is-info"
              class="mr-2"
              @click="toTenantEdit(props.row.id)"
            ></clickable-icon>
          </div>
        </b-table-column>
      </b-table>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import { openDeleteConfirm } from "@/utils/util";
import ClickableIcon from "../../components/module/icon/ClickableIcon";
import eventBus from "../../utils/eventBus";
import TenantEdit from "./TenantEdit";
import TriStateCheckBox from "@/components/module/input/TriStateCheckBox";
import UserModal from "../../components/census/user/UserModal";
import LoadingMixin from "@/mixins/LoadingMixin";
import { defineAsyncComponent } from "vue";

export default {
  name: "TenantList",
  components: {
    TriStateCheckBox,
    ClickableIcon,
    PortalIcon: defineAsyncComponent(() =>
      import("@/components/module/icon/PortalIcon")
    ),
  },
  mixins: [LoadingMixin],
  async mounted() {
    await this.doStartLoading();
    await this.fetchData();
    await this.doFinishLoading();
  },
  data() {
    return {
      active: null,
      loading: false,
    };
  },
  computed: {
    filteredTenants() {
      return !!this.tenants
        ? this.tenants.filter((tenant) => {
            if (this.active === null) return true;
            return tenant.deleted === !this.active;
          })
        : [];
    },
    ...mapGetters({
      tenants: "tenant/tenants",
    }),
  },
  methods: {
    onContactFilter(props, input) {
      return (
        props.contactEmail.toLowerCase() +
        " " +
        props.contactPhoneNumber.toLowerCase()
      ).includes(input.toLowerCase());
    },

    async fetchData(force) {
      this.loading = true;
      await this.$store.dispatch("tenant/fetchAll", force);
      this.loading = false;
    },
    async toTenantEdit(id) {
      if (!!id) await this.$store.dispatch("tenant/fetch", id);
      this.$buefy.modal.open({
        parent: this,
        component: TenantEdit,
        props: {
          tenant: !!id ? this.$store.getters["tenant/tenant"] : undefined,
        },
        hasModalCard: true,
        trapFocus: true,
      });
    },
    async toAddUser(id) {
      const self = this;
      this.$buefy.modal.open({
        parent: this,
        component: UserModal,
        props: {
          newUser: true,
          tenantId: id,
          superadminCreate: true,
        },
        hasModalCard: true,
        trapFocus: true,
        events: {
          async fetch() {
            await self.fetchData(true);
          },
        },
      });
    },
  },
};
</script>

<style></style>
