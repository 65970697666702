<template>
  <b-dropdown
    v-bind="$attrs"
    :append-to-body="appendToBody"
    :scrollable="scrollable"
    :max-height="maxHeight"
    :class="{ 'fill-area': fillAreaOnDropdown, 'max-width-100': true }"
    @active-change="visibleList = $event"
    expanded
  >
    <template #trigger="{ active }">
      <b-input
        v-model="search"
        :placeholder="$tf('multiselectDropdown.searchPlaceholder|Keresés...')"
        :icon="icon"
        :icon-right="active ? 'chevron-up' : 'chevron-down'"
        icon-right-clickable
        class="p-0"
        v-bind="$attrs"
        ref="searchInput"
        v-if="visibleList && searchable"
        style="width: 100%"
      />
      <b-button
        v-else
        :icon-left="icon"
        :icon-right="active ? 'chevron-up' : 'chevron-down'"
        class="filter-dropdown-field max-width-100"
        v-bind="$attrs"
      >
        <template v-if="hideSelected">
          <p class="filter-placeholder">
            {{
              $tf(
                "multiselectDropdown.placeholder|Válassz a listából vagy gépelj a kereséshez..."
              )
            }}
          </p>
        </template>
        <template v-else>
          <div class="is-flex is-align-items-center has-gap-1 max-width-100">
            <div
              class="is-flex-grow-1 overflow-hidden text-overflow-ellipsis max-width-100"
            >
              {{ label }}
            </div>
            <div class="is-flex is-align-items-center has-gap-2">
              <div
                v-if="selectedItems.length > 0"
                class="filter-dropdown-badge"
              >
                {{ selectedItems.length }}
              </div>
            </div>
          </div>
        </template>
      </b-button>
    </template>
    <div class="filter-dropdown-content">
      <slot name="select-all">
        <template
          v-if="
            hasSelectAllOption &&
            filteredItems.length !== 0 &&
            filteredItems.length === items.length
          "
        >
          <div class="is-flex is-align-items-center">
            <b-checkbox
              :indeterminate="isGroupIndeterminate"
              :checked="isGroupComplete"
              :modelValue="isGroupComplete"
              @update:modelValue="handleGroupSelection"
            >
              {{ $tf("multiselectDropdown.all|Mind") }}
            </b-checkbox>
          </div>
        </template>
      </slot>
      <slot name="empty">
        <template v-if="filteredItems.length === 0">
          <div
            class="is-flex has-gap-2 has-text-centered is-justify-content-center"
          >
            <b-icon icon="frown" />
            <p class="is-size-5">
              {{ $tf("multiselectDropdown.emptyList|Nincs találat") }}
            </p>
          </div>
        </template>
      </slot>
      <slot>
        <div v-for="(item, id) in filteredItems" :key="id">
          <b-checkbox
            v-model="selectedItems"
            :native-value="item[identifierField]"
            @update:modelValue="input"
          >
            <p v-if="useTf">{{ $tf(item[nameField]) }}</p>
            <p v-else>{{ item[nameField] }}</p>
          </b-checkbox>
        </div>
      </slot>
    </div>
  </b-dropdown>
</template>

<script>
import { nextTick } from "vue";

export default {
  name: "MultiselectDropdown",
  props: {
    noneSelectedText: {
      type: String,
      default: "-",
    },
    icon: {
      type: String,
    },
    items: {
      type: Array,
      default: () => [],
    },
    identifierField: {
      type: String,
      default: "identifier",
    },
    nameField: {
      type: String,
      default: "name",
    },
    modelValue: {
      type: Array,
      required: false,
      default: () => [],
    },
    labelLength: {
      type: Number,
      default: 0,
    },
    searchable: {
      type: Boolean,
      default: false,
    },
    hasSelectAllOption: {
      type: Boolean,
      default: false,
    },
    fillAreaOnDropdown: {
      type: Boolean,
      default: false,
    },
    appendToBody: {
      type: Boolean,
      default: false,
    },
    scrollable: {
      type: Boolean,
      default: false,
    },
    maxHeight: {
      type: [String, Number],
      default: "200px",
    },
    hideSelected: {
      type: Boolean,
      default: false,
    },
    useTf: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedItems: [],
      search: "",
      visibleList: false,
    };
  },
  watch: {
    modelValue: {
      handler() {
        this.selectedItems = this.modelValue ?? [];
      },
      immediate: true,
      deep: true,
    },
    visibleList() {
      if (this.visibleList) {
        nextTick(() => {
          this.$refs.searchInput?.focus();
        });
      } else {
        this.search = "";
      }
    },
  },
  computed: {
    filteredItems() {
      return this.items.filter((item) => {
        return item[this.nameField]
          .toLowerCase()
          .includes(this.search.toLowerCase());
      });
    },
    label() {
      if (this.selectedItems.length === 0) {
        return this.noneSelectedText;
      } else if (this.selectedItems.length === this.items.length) {
        return this.$tf("multiselectDropdown.all|Mind");
      } else {
        let joinedItems = this.selectedItems
          .map((id) => {
            const item = this.items.find(
              (t) => t[this.identifierField]?.toString() === id?.toString()
            );
            return item
              ? this.useTf
                ? this.$tf(item[this.nameField])
                : item[this.nameField]
              : undefined;
          })
          .filter((item) => item !== undefined)
          .map((item) =>
            this.labelLength > 0 && item.length > this.labelLength
              ? item.slice(0, this.labelLength) + "..."
              : item
          )
          .join(", ");

        return joinedItems;
      }
    },

    isGroupIndeterminate() {
      return this.selectedItems.length > 0 && !this.isGroupComplete;
    },
    isGroupComplete() {
      return this.selectedItems.length === this.items.length;
    },
  },
  methods: {
    input() {
      this.$emit("update:modelValue", this.selectedItems);
    },
    handleGroupSelection(value) {
      if (value) {
        this.selectedItems = this.items.map(
          (item) => item[this.identifierField]
        );
      } else {
        this.selectedItems = [];
      }
      this.input();
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/assets/scss/colors.scss";

.filter-dropdown-content {
  padding: 0 12px;

  div {
    display: flex;
    align-items: center;
    padding: 6px 0;

    &:not(:first-child) {
      border-top: 1px solid $grey-lighter;
    }
  }
}

.filter-dropdown-field {
  width: 200px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid $grey-lighter;
  border-radius: 8px;
  padding: calc(0.5em - 1px) calc(0.75em - 1px);
  cursor: pointer;
}

.filter-dropdown-badge {
  padding-left: 6px;
  padding-right: 6px;
  color: $white;
  background-color: $primary;
  border-radius: 16px;
  font-size: 14px;
  font-weight: 700;
}

.filter-placeholder {
  color: #808082;
  font-weight: 400;
}
</style>

<style lang="scss">
.fill-area {
  &.dropdown {
    flex-direction: column;

    .dropdown-menu {
      position: relative !important;
    }
  }
}

.filter-dropdown-field {
  span:not(.icon) {
    max-width: 100%;
  }
}
</style>
