import { deepCopy, newLogger } from "@/utils/util";
import StoreCore from "@/store/StoreCore";

let logger = newLogger("Enterprise-OutsourceProjects");

const urls = {
  project: (identifier) => `/enterprise/projects/outsource/${identifier}`,
  projects: "/enterprise/projects/outsource",
  contract: (id) => `/enterprise/projects/outsource/contract/${id}`,
  targeting: (id) => `/enterprise/projects/outsource/${id}/targeting`,
};

const initState = () => {
  return {
    project: {},
    projects: [],
  };
};

const storeCore = new StoreCore();
const actions = {
  fetchProjectDetails: async function (
    { commit, dispatch },
    { projectIdentifier, requestParams, force } = {}
  ) {
    try {
      let data = await this.remoteRequest(
        "get",
        urls.project(projectIdentifier),
        requestParams,
        true,
        dispatch,
        "Projekt adatainak betöltése...",
        0,
        force
      );
      commit("saveProjectDetails", {
        identifier: projectIdentifier,
        data: data,
      });
      return data;
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
        throw err;
      }
    }
  }.bind(storeCore),
  fetchProjectsForYear: async function (
    { commit, dispatch },
    { requestParams, force } = {}
  ) {
    try {
      let data = deepCopy(
        await this.remoteRequest(
          "get",
          urls.projects,
          requestParams,
          true,
          dispatch,
          "Outsource projektek betöltése...",
          force
        )
      );
      commit("saveProjects", data);
      return data;
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  saveTargeting: async function (
    { commit, dispatch },
    { projectId, targeting } = {}
  ) {
    try {
      await this.remoteRequest(
        "put",
        urls.targeting(projectId),
        targeting,
        false,
        dispatch,
        "Outsource Célzás mentése..."
      );
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  createContract: async function (
    { commit, dispatch },
    { identifier, contract } = {}
  ) {
    try {
      await this.remoteRequest(
        "post",
        `${urls.project(identifier)}/contract`,
        contract,
        false,
        dispatch,
        "Outsource szerződés létrehozása..."
      );
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  updateContract: async function ({ commit, dispatch }, contract) {
    try {
      await this.remoteRequest(
        "put",
        `${urls.contract(contract.id)}`,
        contract,
        false,
        dispatch,
        "Outsource szerződés szerkesztése..."
      );
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  deleteContract: async function ({ commit, dispatch }, id) {
    try {
      await this.remoteRequest(
        "delete",
        `${urls.contract(id)}`,
        null,
        false,
        dispatch,
        "Outsource szerződés törlése..."
      );
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  clearCache: async function ({}) {
    this.clearCoreCache();
  }.bind(storeCore),
};

const mutations = {
  saveProjectDetails: (state, { identifier, data }) => {
    if (data.technologies && !Array.isArray(data.technologies))
      data.technologies = data.technologies.split(",");
    if (data.laborTypes && !Array.isArray(data.laborTypes))
      data.laborTypes = data.laborTypes.split(",");
    if (data.costs && data.costs.length) {
      for (let c in data.costs) {
        data.costs[c].day = new Date(data.costs[c].date);
      }
    }
    if (data.invoices && data.invoices.length) {
      for (let c in data.invoices) {
        data.invoices[c].day = new Date(data.invoices[c].date);
      }
    }
    data.overrides = data.overrides || [];
    state.project[identifier] = data;
  },
  saveProjects: (state, data) => {
    state.projects = data;
  },
};

const getters = {
  projectDetails: (state) => (identifier) => {
    return state.project[identifier];
  },
  projectData: (state) => {
    return state.projects;
  },
};

export default {
  namespaced: true,
  state: initState(),
  mutations: mutations,
  actions: actions,
  getters: getters,
};
