<template>
  <div>
    <slot></slot>
    <b-table
      :data="projects?.items || []"
      ref="projectsTable"
      hoverable
      :show-detail-icon="true"
      paginated
      backend-pagination
      :total="pagination.value.totalCount"
      :per-page="pagination.value.size"
      @page-change="onPageChange"
      backend-sorting
      @sort="onSort"
      @click="goToProject"
      default-sort-direction="asc"
      default-sort="name"
      backend-filtering
      aria-next-label="Következő lap"
      aria-previous-label="Előző lap"
      aria-page-label="Lap"
      aria-current-label="Aktuális lap"
      scrollable
      sticky-header
      height="70vh"
      :row-class="() => 'cursor-pointer'"
    >
      <b-table-column v-slot="props" :cell-class="$style['thinner-column']">
        <div class="is-flex is-flex-direction-column">
          <star-icon v-if="props.row.starred" filled></star-icon>
          <heart-icon
            v-if="likedProjects.includes(props.row.id)"
            filled
          ></heart-icon>
        </div>
      </b-table-column>
      <b-table-column
        centered
        field="status"
        :label="$tf('allProjectsList.table.status|Státusz')"
        v-slot="props"
      >
        <b-tag
          :type="
            getProjectStatusObject(props.row.status, props.row.archived).type
          "
        >
          {{
            getProjectStatusObject(props.row.status, props.row.archived).text
          }}
        </b-tag>
      </b-table-column>
      <b-table-column
        field="name"
        :label="$tf('allProjectsList.table.nameAndID|Név / Azonosító')"
        sortable
        :cell-class="$style['wider-column']"
      >
        <template v-slot="props">
          <div class="is-flex is-flex-direction-column has-gap-2">
            <div class="has-font-weight-600 has-text-grey-dark">
              {{ props.row.name }}
            </div>
            <div
              class="has-font-weight-500 is-flex has-gap-2 is-align-items-center"
            >
              {{ props.row.identifier }}
              <b-tooltip
                v-if="props.row.internal"
                :label="
                  $tf('allProjectsList.table.internal.tooltip|Belső projekt')
                "
                type="is-info"
              >
                <b-icon
                  style="display: block"
                  class="my-auto"
                  icon="house-user"
                />
              </b-tooltip>
            </div>
          </div>
        </template>
      </b-table-column>
      <b-table-column
        field="client"
        :label="$tf('allProjectsList.table.clientAndLead|Ügyfél / Felelős')"
        sortable
        :cell-class="$style['medium-column']"
      >
        <template v-slot="props">
          <div class="is-flex is-flex-direction-column has-gap-2">
            <div class="has-font-weight-600 has-text-grey-dark">
              <client-info
                :client="getClientDetailsByName(props.row.clientName)"
                displayMode="minimal"
                tooltipPosition="right"
              />
            </div>
            <div class="has-font-weight-500 has-text-grey">
              <user-info
                :user="getEmployeeDetailsByName(props.row.leadName)"
                displayMode="minimal"
                tooltipPosition="right"
              />
            </div>
          </div>
        </template>
      </b-table-column>
      <b-table-column
        field="dueDate"
        :label="$tf('allProjectsList.table.dueDate|Határidő')"
        sortable
        centered
        v-slot="props"
        cell-class="do-not-wrap"
      >
        {{ formatDate(new Date(props.row.dueDate)) }}
      </b-table-column>
      <b-table-column
        field="expectedIncome"
        :label="$tf('allProjectsList.table.income|Terv/Tény Bevétel')"
        sortable
        numeric
        v-slot="props"
        cell-class="do-not-wrap"
      >
        {{ moneyify(props.row.expectedIncome) }}
        <div class="has-text-success">
          {{ moneyify(props.row.invoicedIncome) }}
        </div>
      </b-table-column>
      <b-table-column
        field="budget"
        :label="$tf('allProjectsList.table.budgetAndSpent|Büdzsé / Költés')"
        sortable
        numeric
        :cell-class="$style['medium-column']"
        v-slot="props"
      >
        <div>{{ moneyify(props.row.budget) }}</div>
        <div class="has-text-grey">{{ moneyify(props.row.spent) }}</div>
      </b-table-column>
      <b-table-column
        field="readiness"
        :label="
          $tf(
            'allProjectsList.table.readinessAndSpentness|Készültség / Költöttség'
          )
        "
        numeric
        v-slot="props"
        :cell-class="$style['wider-column']"
      >
        <div class="is-flex is-align-items-center has-gap-1 has-text-grey">
          <div
            class="is-flex is-flex-direction-column is-align-items-center has-gap-2 p-bar-div"
          >
            <div
              class="is-flex is-align-self-stretch is-align-items-center has-gap-2"
            >
              <div
                class="has-font-weight-500 has-text-left"
                style="width: 5rem"
              >
                {{
                  calcProgressValue(
                    1,
                    props.row.status === "CLOSED" ? 1 : props.row.readiness
                  )
                }}%
              </div>
              <b-progress
                :max="getMaxPercentage(props.row)"
                size="is-small"
                class="p-bar"
              >
                <template #bar>
                  <b-progress-bar
                    :value="
                      calcProgressValue(
                        1,
                        props.row.status === 'CLOSED' ? 1 : props.row.readiness
                      )
                    "
                    type="is-info"
                  ></b-progress-bar>
                </template>
              </b-progress>
            </div>
            <div
              class="is-flex is-align-self-stretch is-align-items-center has-gap-2"
            >
              <div
                class="has-font-weight-500 has-text-left"
                style="width: 5rem"
              >
                {{ calcProgressValue(1, props.row.spentPercentage) }}%
              </div>
              <b-progress
                :max="getMaxPercentage(props.row)"
                size="is-small"
                class="p-bar"
              >
                <template #bar>
                  <b-progress-bar
                    :value="
                      Math.min(
                        calcProgressValue(1, props.row.spentPercentage),
                        100
                      )
                    "
                    type="is-warning"
                  ></b-progress-bar>
                  <b-progress-bar
                    v-if="calcProgressValue(1, props.row.spentPercentage) > 100"
                    :value="getMaxPercentage(props.row) / 50"
                    type="is-light"
                  >
                  </b-progress-bar>
                  <b-progress-bar
                    v-if="calcProgressValue(1, props.row.spentPercentage) > 100"
                    :value="
                      calcProgressValue(1, props.row.spentPercentage) - 100
                    "
                    type="is-warning"
                  >
                  </b-progress-bar>
                </template>
              </b-progress>
            </div>
          </div>
        </div>
      </b-table-column>
      <b-table-column
        field="budgetRiskKPI"
        :label="$tf('allProjectsList.table.budgetRiskKPI|Büdzsé risk KPI')"
        numeric
        v-slot="props"
        :visible="true"
        cell-class="do-not-wrap"
      >
        <div class="is-flex is-justify-content-center has-font-weight-700">
          <risk-kpi-tag :value="props.row.budgetRiskKPI" />
        </div>
      </b-table-column>

      <b-table-column
        field="forecastTrendingBudget"
        :label="
          $tf(
            'allProjectsList.table.forecastTrendingBudget|Várható költés (trendt. FC)'
          )
        "
        sortable
        numeric
        v-slot="props"
        cell-class="do-not-wrap"
      >
        <div
          v-if="props.row.forecastTrendingBudget"
          class="is-flex is-flex-direction-column"
        >
          <div>{{ moneyify(props.row.forecastTrendingBudget) }}</div>
          <div
            class="is-italic"
            :class="{
              'has-text-danger':
                props.row.forecastTrendingBudget - props.row.budget > 0,
              'has-text-success':
                props.row.forecastTrendingBudget - props.row.budget <= 0,
            }"
          >
            {{ moneyify(props.row.forecastTrendingBudget - props.row.budget) }}
          </div>
        </div>
        <div v-else>-</div>
      </b-table-column>

      <b-table-column
        field="expectedProfit"
        :label="$tf('allProjectsList.table.expectedProfit|Várható profit')"
        numeric
        v-slot="props"
        :visible="true"
        cell-class="do-not-wrap"
      >
        <div
          :class="{
            'has-text-weight-bold has-text-danger':
              props.row.expectedProfit < 0,
          }"
        >
          {{ moneyify(props.row.expectedProfit) }}
        </div>
      </b-table-column>

      <template #empty>
        <section class="section">
          <div class="content has-text-grey has-text-centered">
            <p><b-icon icon="frown" size="is-large"></b-icon></p>
            <p>
              {{
                $tf(
                  "allProjectsList.table.empty|Nincs találat a megadott szűrési feltételekre."
                )
              }}
            </p>
            <b-button @click="resetFilters">{{
              $tf(
                "allProjectsList.table.resetFilters|Szűrők alapértelmezettre állítása"
              )
            }}</b-button>
          </div>
        </section>
      </template>
    </b-table>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ProjectListMixin from "@/mixins/ProjectListMixin";
import debounce from "lodash.debounce";
import {
  getProjectStatusObject,
  percentify,
  roundToTwoDecimals,
} from "@/utils/util";
import eventBus, { EVENTS } from "../../../../utils/eventBus";
import { PROJECT_PROGRESS_TYPE } from "@/utils/const";
import StarIcon from "@/components/module/icon/StarIcon.vue";
import HeartIcon from "@/components/module/icon/HeartIcon.vue";
import LocalStoreMixin from "@/mixins/LocalStoreMixin";
import SaveableObject from "@/objects/SaveableObject";
import RiskKpiTag from "@/components/enterprise/project/module/RiskKpiTag.vue";
import UserInfo from "@/components/module/info/UserInfo.vue";
import ClientInfo from "@/components/module/info/ClientInfo.vue";

const LOCAL_KEY_PREFIX = "project_list_all_";

export default {
  name: "AllProjectsList",
  components: { RiskKpiTag, HeartIcon, StarIcon, UserInfo, ClientInfo },
  mixins: [ProjectListMixin, LocalStoreMixin],
  watch: {
    filters: {
      handler: debounce(function () {
        this.pagination.resetToDefault();
        this.getAllProjects();
      }, 500),
      deep: true,
    },
    $route() {
      this.getAllProjects();
    },
  },
  computed: {
    ...mapGetters({
      projects: "enterprise_core/allProjects",
      riskKpiLimits: "uiConfigStore/getRiskKpiLimits",
      likedProjects: "project_like/likedProjects",
      employees: "employee/employees",
      clients: "enterprise_clients/clients",
    }),
  },
  data() {
    return {
      pagination: new SaveableObject(LOCAL_KEY_PREFIX + "pagination", {
        totalCount: 0,
        page: 0,
        size: 20,
      }),
      sort: new SaveableObject(LOCAL_KEY_PREFIX + "sort", {
        field: "name",
        order: "asc",
      }),
      PROJECT_PROGRESS_TYPE,
      percentify,
      getProjectStatusObject,
    };
  },
  methods: {
    goToProject(row) {
      this.$router.push(`/projects/project/${row.identifier}`);
    },
    resetFilters() {
      this.pagination.resetToDefault();
      this.$emit("reset-filters");
    },
    async getAllProjects() {
      let requestParams = this.composeRequestParams();
      let projects = await this.$store.dispatch(
        "enterprise_core/fetchAllProjects",
        requestParams
      );
      this.pagination.value = projects?.pagination
        ? projects.pagination
        : {
            totalCount: 0,
            page: 0,
            size: 20,
          };
    },
    onPageChange: function (page) {
      this.pagination.value.page = page - 1;
      this.pagination.save();
      this.getAllProjects();
    },
    onSort: function (field, order) {
      this.sort.value.field = field;
      this.sort.value.order = order;
      this.sort.save();
      this.getAllProjects();
    },
    getMaxPercentage(project) {
      return Math.max(
        this.calcProgressValue(
          1,
          project.status === "CLOSED" ? 1 : project.readiness
        ),
        this.calcProgressValue(1, project.spentPercentage),
        100
      );
    },
    getEmployeeDetailsByName(name) {
      const employee = this.employees.find((emp) => emp.name === name);
      if (employee) {
        return {
          userId: employee.id,
          name: employee.name,
          avatar: employee.avatar,
          email: employee.email,
          phone: employee.phone,
        };
      } else {
        return null;
      }
    },
    getClientDetailsByName(name) {
      const client = this.clients.find((client) => client.name === name);
      if (client) {
        return {
          identifier: client.identifier,
          name: client.name,
          avatar: client.avatar,
        };
      } else {
        return null;
      }
    },
  },
  async mounted() {
    await this.$store.dispatch("project_like/fetchLikedProjects");
    await this.getAllProjects();
    await this.$store.dispatch(
      "project_dimensions/fetchActiveProjectDimensionsWithValues"
    );
    await this.$store.dispatch("enterprise_clients/fetchClients");
    await this.$store.dispatch("employee/fetchColleagues");
    eventBus.$on(EVENTS.PROJECT_FILTER_CHANGE, this.getAllProjects());
  },
  unmounted() {
    eventBus.$off(EVENTS.PROJECT_FILTER_CHANGE);
  },
};
</script>

<style module lang="scss">
.wider-column {
  min-width: 10rem;
}
.medium-column {
  max-width: 10rem;
}
.thinner-column {
  max-width: 2.5rem;
}
</style>

<style scoped lang="scss">
.scrollable {
  overflow: auto;
  white-space: nowrap;
}
.scrollable-element {
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 10px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
.p-bar-div {
  width: 100%;
  align-items: center;
}
.p-bar {
  height: 24px;
  margin-bottom: 0 !important;
  padding-top: 6px;
  padding-bottom: 6px;
  align-items: center;
  text-align: left;
}
</style>
