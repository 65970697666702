<template>
  <div class="onboarding-step-card-content">
    <h2>
      {{ $tf("onboarding.welcome.title|Üdv a Forecastify-ban, kezdjük el!") }}
    </h2>
    <b-button
      class="onboarding-button"
      type="is-info"
      @click="$emit('nextPage', ONBOARDING_PAGES.EXTERNAL_ISSUE_TRACKER_SELECT)"
    >
      {{ $tf("onboarding.welcome.continue|Tovább") }}
    </b-button>
  </div>
</template>

<script>
import { ONBOARDING_PAGES } from "@/utils/const";

export default {
  name: "OnboardingWelcome",
  data: function () {
    return {
      ONBOARDING_PAGES,
    };
  },
};
</script>

<style></style>
