<template>
  <section class="section">
    <div class="container">
      <h1 class="title">{{ $tf("invoiceList.title|Számlák") }}</h1>
      <b-modal v-model="sSummary" @close="closeSummaryModal()">
        <div class="card">
          <div class="card-content m-4">
            <p>{{ vSummary }}</p>
          </div>
        </div>
      </b-modal>
      <div class="columns">
        <div class="column">
          <b-field :label="$tf('invoiceList.search|Keresés')">
            <b-input v-model="search"></b-input>
          </b-field>
        </div>
        <div class="column">
          <b-field :label="$tf('invoiceList.status|Státusz')">
            <b-select v-model="statusFilter" expanded>
              <option value="ANY">
                {{ $tf("invoiceList.status.all|Összes") }}
              </option>
              <option value="DUE">
                {{ $tf("invoiceList.status.due|Fizetendő") }}
              </option>
              <option value="PAID">
                {{ $tf("invoiceList.status.paid|Fizetett") }}
              </option>
              <option value="CANCELLED">
                {{ $tf("invoiceList.status.cancelled|Visszavont") }}
              </option>
            </b-select>
          </b-field>
        </div>
        <div class="column"></div>
        <div class="column"></div>
      </div>

      <b-table
        :data="filteredInvoices"
        :loading="loading"
        ref="invoiceTable"
        paginated
        :per-page="10"
        detailed
        detail-key="identifier"
        :show-detail-icon="false"
        aria-next-label="Következő lap"
        aria-previous-label="Előző lap"
        aria-page-label="Lap"
        aria-current-label="Aktuális lap"
      >
        <template #empty>
          <div class="has-text-centered">
            {{ $tf("invoiceList.table.empty|Nincs találat") }}
          </div>
        </template>
        <b-table-column
          field="project"
          :label="$tf('invoiceList.table.projekt|Projekt')"
          centered
          v-slot="props"
        >
          {{ props.row.projectName }}
        </b-table-column>
        <b-table-column
          field="number"
          :label="
            $tf(
              'invoiceList.table.invoiceNumberAndComment|Számlaszám / Megjegyzés'
            )
          "
          centered
          v-slot="props"
        >
          <b-tooltip
            :label="
              $tf(
                'invoiceList.table.showInvoiceComment|Megjegyzés megjelenítése'
              )
            "
            type="is-light"
          >
            <a
              v-if="props.row.number"
              @click="showSummaryModal(props.row.summary)"
            >
              {{ props.row.number }}
            </a>
            <clickable-icon
              v-else
              @click="showSummaryModal(props.row.summary)"
              icon="eye"
              type="is-info"
            />
          </b-tooltip>
        </b-table-column>
        <b-table-column
          field="date"
          :label="$tf('invoiceList.table.date|Kelt')"
          centered
          v-slot="props"
        >
          {{ props.row.date }}
        </b-table-column>
        <b-table-column
          field="status"
          :label="$tf('invoiceList.table.status|Státusz')"
          centered
          v-slot="props"
        >
          <span
            class="tag"
            v-bind:class="{
              'is-success': props.row.status === 'PAID',
              'is-warning': props.row.status === 'CANCELLED',
              'is-info': props.row.status === 'DUE',
            }"
          >
            {{ resolveStatus(props.row.status) }}
            <span v-if="props.row.status === 'PAID'"
              >: {{ props.row.paid }}</span
            >
          </span>
        </b-table-column>
        <b-table-column
          field="cost"
          :label="$tf('invoiceList.table.amount|Összeg')"
          centered
          v-slot="props"
        >
          {{ moneyify(props.row.cost) }}
        </b-table-column>
        <b-table-column
          field="actions"
          :label="$tf('invoiceList.table.actions|Műveletek')"
          centered
          v-slot="props"
        >
          <b-tooltip
            v-if="canEvaluateInvoice && props.row.status === 'DUE'"
            :label="
              $tf('invoiceList.table.actions.markPaid|Fizetettnek jelölés')
            "
            type="is-light"
          >
            <clickable-icon
              icon="circle-check"
              type="is-success"
              @click="payInvoice(props.row.id)"
            />
          </b-tooltip>
          <b-tooltip
            v-if="canEvaluateInvoice && props.row.status === 'DUE'"
            :label="$tf('invoiceList.table.actions.cancel|Visszavonás')"
            type="is-light"
          >
            <clickable-icon
              icon="circle-xmark"
              type="is-danger"
              @click="cancelInvoice(props.row.id)"
            />
          </b-tooltip>
        </b-table-column>
      </b-table>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import ClickableIcon from "@/components/module/icon/ClickableIcon.vue";
import { formatDate, moneyify } from "@/utils/util";
import { PRIVILEGES } from "@/utils/const";

export default {
  name: "InvoiceList",
  components: { ClickableIcon },
  data() {
    return {
      loading: false,
      search: "",
      statusFilter: "ANY",
      vSummary: null,
      sSummary: false,
    };
  },
  computed: {
    canEvaluateInvoice() {
      return this.$store.getters["session/hasAnyAuthority"](
        PRIVILEGES.ENTERPRISE.PROJECT_EXTRAS.EVALUATE_INVOICE,
        PRIVILEGES.ENTERPRISE.PROJECT_EXTRAS.ADMIN
      );
    },
    filteredInvoices() {
      let invoiceList =
        this.statusFilter === "ANY"
          ? this.invoices
          : this.invoices.filter((i) => i.status === this.statusFilter);
      return invoiceList.filter((i) => {
        let pn = i.projectName ?? "";
        let n = i.number ?? "";
        let s = i.summary ?? "";
        return (
          pn
            .toString()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .toLowerCase()
            .indexOf(
              this.search
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .toLowerCase()
            ) >= 0 ||
          n
            .toString()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .toLowerCase()
            .indexOf(
              this.search
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .toLowerCase()
            ) >= 0 ||
          s
            .toString()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .toLowerCase()
            .indexOf(
              this.search
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .toLowerCase()
            ) >= 0
        );
      });
    },
    ...mapGetters({
      invoices: "enterprise_projects/invoices",
    }),
  },
  methods: {
    moneyify,
    resolveStatus(enu) {
      switch (enu) {
        case "DUE":
          return this.$tf("invoiceList.status.due|Fizetendő");
        case "PAID":
          return this.$tf("invoiceList.status.paid|Fizetett");
        case "CANCELLED":
          return this.$tf("invoiceList.status.cancelled|Visszavont");
        default:
          return this.$tf("invoiceList.status.unknown|Ismeretlen");
      }
    },
    closeSummaryModal() {
      this.sSummary = false;
      this.vSummary = "";
    },
    showSummaryModal(summary) {
      this.vSummary = summary;
      this.sSummary = true;
    },
    async fetchData() {
      this.loading = true;
      await this.$store.dispatch("enterprise_projects/fetchInvoices");
      this.loading = false;
    },
    async payInvoice(id) {
      this.$buefy.dialog.prompt({
        title: this.$tf(
          "invoiceList.payDialog.title|Számla fizetettnek jelölése"
        ),
        message: this.$tf("invoiceList.payDialog.payDate|Kifizetés dátuma"),
        confirmText: this.$tf("invoiceList.payDialog.markPaid|Jelölés"),
        cancelText: this.$tf("invoiceList.payDialog.cancel|Mégsem"),
        inputAttrs: {
          type: "date",
          value: formatDate(new Date()),
        },
        trapFocus: true,
        onConfirm: async (value) => {
          await this.$store.dispatch("enterprise_projects/evaluateInvoice", {
            id: id,
            request: {
              status: "PAID",
              date: formatDate(value),
            },
          });
          await this.fetchData();
        },
      });
    },
    async cancelInvoice(id) {
      this.$buefy.dialog.confirm({
        title: this.$tf("invoiceList.cancelDialog.title|Számla visszavonása"),
        message: this.$tf(
          "invoiceList.cancelDialog.text|Biztos visszavonásra kerüljön a számla?"
        ),
        confirmText: this.$tf("invoiceList.cancelDialog.confirm|Visszavonás"),
        cancelText: this.$tf("invoiceList.cancelDialog.cancel|Mégsem"),
        trapFocus: true,
        onConfirm: async (value) => {
          await this.$store.dispatch("enterprise_projects/evaluateInvoice", {
            id: id,
            request: {
              status: "CANCELLED",
              date: formatDate(value),
            },
          });
          await this.fetchData();
        },
      });
    },
  },
  async mounted() {
    await this.fetchData();
  },
};
</script>
