<template>
  <div class="container">
    <h3 class="heading">
      <loading-component>
        {{ $tf("execDashboard.delay.title|Csúszás várható") }}
      </loading-component>
    </h3>
    <loading-component>
      <template #loader><div></div></template>
      <div v-if="this.delay.projects.length < 1">
        <section class="section">
          <div class="content has-text-grey has-text-centered">
            <p><b-icon icon="smile" size="is-large"></b-icon></p>
            <p>
              {{
                $tf(
                  "execDashboard.delay.empty|Nincs csúszás a szűrési feltételeknek megfelelő projekteken"
                )
              }}
            </p>
          </div>
        </section>
      </div>
    </loading-component>
    <div class="columns">
      <div class="column is-one-third">
        <loading-component
          loading-height="250px"
          loading-width="250px"
          type="circle"
          :hide-component="this.delay?.projects.length < 1"
        >
          <apex-chart
            type="donut"
            :options="getLateRadialChartOptions"
            :series="[
              this.delay?.number['DELAY']
                ? this.delay?.number['DELAY'].value
                : 0,
              this.delay?.number['IN_TIME']
                ? this.delay?.number['IN_TIME'].value
                : 0,
            ]"
            height="250px"
          />
        </loading-component>
      </div>
      <div class="column is-one-third">
        <loading-component :hide-component="this.delay?.projects.length < 1">
          <apex-chart
            type="bar"
            :options="getLateBarChartOptions"
            :series="[
              {
                data: [
                  this.delay?.delay['LESS_THAN_1_WEEK']
                    ? this.delay?.delay['LESS_THAN_1_WEEK'].value
                    : 0,
                  this.delay?.delay['BETWEEN_1_AND_4_WEEK']
                    ? this.delay?.delay['BETWEEN_1_AND_4_WEEK'].value
                    : 0,
                  this.delay?.delay['MORE_THAN_30_DAYS']
                    ? this.delay?.delay['MORE_THAN_30_DAYS'].value
                    : 0,
                ],
                name: $tf(
                  'execDashboard.delay.projectChat.title|Projektek száma'
                ),
              },
            ]"
            height="200px"
          />
        </loading-component>
      </div>
    </div>
    <loading-component :hide-component="this.delay?.projects.length < 1">
      <template #loader><loading-table></loading-table></template>
      <b-table
        v-if="delay?.projects.length > 0"
        :data="delay.projects"
        hoverable
        default-sort="delay"
        default-sort-direction="desc"
        scrollable
      >
        <b-table-column
          field="name"
          :label="$tf('execDashboard.delay.table.projectName|Projekt név')"
          v-slot="props"
        >
          <router-link :to="`/projects/project/${props.row.identifier}`">
            {{ props.row.name }}
          </router-link>
        </b-table-column>

        <b-table-column
          field="income"
          :label="$tf('execDashboard.delay.table.income|Bevétel')"
          sortable
          v-slot="props"
          >{{ moneyify(props.row.income) }}</b-table-column
        >

        <b-table-column
          field="dueDate"
          :label="$tf('execDashboard.delay.table.dueDate|Határidő')"
          sortable
          v-slot="props"
          >{{ formatDate(props.row.dueDate) }}</b-table-column
        >

        <b-table-column
          field="expectedEndDate"
          :label="
            $tf('execDashboard.delay.table.expectedEndDate|Várható befejezés')
          "
          sortable
          v-slot="props"
          >{{ formatDate(props.row.expectedEndDate) }}</b-table-column
        >

        <b-table-column field="delay" label="Csúszás" sortable v-slot="props">{{
          props.row.delay
        }}</b-table-column>
        <b-table-column
          field="readiness"
          :label="$tf('execDashboard.delay.table.readiness|Készültség')"
          sortable
          v-slot="props"
          >{{ percentify(props.row.readiness) }}</b-table-column
        >

        <b-table-column
          field="budgetRiskKPI"
          :label="$tf('execDashboard.delay.table.budgetKPI|Büdzsé KPI')"
          numeric
          sortable
          v-slot="props"
          cell-class="do-not-wrap"
        >
          <risk-kpi-tag :value="props.row.budgetRiskKPI" />
        </b-table-column>

        <b-table-column
          field="forecastedOverspending"
          :label="
            $tf('execDashboard.delay.table.forecastedOverspending|FC túlköltés')
          "
          sortable
          v-slot="props"
          >{{ moneyify(props.row.forecastedOverspending) }}</b-table-column
        >
        <b-table-column
          field="forecastedProfit"
          :label="$tf('execDashboard.delay.table.forecastedProfit|FC profit')"
          sortable
          v-slot="props"
          >{{ moneyify(props.row.forecastedProfit) }}</b-table-column
        >
      </b-table>
    </loading-component>
  </div>
</template>

<script>
import {
  formatDate,
  getBudgetRiskValue,
  moneyify,
  percentify,
} from "@/utils/util";
import LoadingTable from "@/components/loading/LoadingTable.vue";
import RiskKpiTag from "@/components/enterprise/project/module/RiskKpiTag.vue";
import { defineAsyncComponent } from "vue";

export default {
  name: "ExecutiveDashboardDelay",
  props: {
    delay: {
      type: Object,
      required: false,
    },
  },
  components: {
    RiskKpiTag,
    LoadingTable,
    LoadingComponent: defineAsyncComponent(() =>
      import("@/components/loading/LoadingComponent.vue")
    ),
  },
  computed: {
    getLateRadialChartOptions() {
      const self = this;
      return {
        chart: {
          type: "donut",
          toolbar: {
            show: false,
          },
        },
        stroke: {
          width: 0,
          show: false,
        },
        legend: {
          position: "bottom",
          labels: {
            colors: "var(--text)",
          },
        },
        labels: [
          this.delay?.number["DELAY"]?.text ?? "",
          this.delay?.number["IN_TIME"]?.text ?? "",
        ],
        title: {
          text: self.$tf.call(
            self,
            "execDashboard.delay.projectRadialChart.title|Projektek száma"
          ),
          align: "center",
          style: {
            fontFamily: "Roboto, sans-serif",
            color: "var(--text)",
          },
        },
        colors: ["var(--red)", "var(--green)"],
        plotOptions: {
          pie: {
            donut: {
              size: "40%",
            },
          },
        },
      };
    },
    getLateBarChartOptions() {
      const self = this;
      return {
        chart: {
          type: "bar",
          toolbar: {
            show: false,
          },
        },
        legend: {
          show: false,
        },
        labels: [
          this.delay?.delay["LESS_THAN_1_WEEK"]?.text ?? "",
          this.delay?.delay["BETWEEN_1_AND_4_WEEK"]?.text ?? "",
          this.delay?.delay["MORE_THAN_30_DAYS"]?.text ?? "",
        ],
        tooltip: {
          y: {
            formatter: function (value) {
              return (
                value.toFixed(0) +
                self.$tf.call(self, "execDashboard.delay.piece|db")
              );
            },
          },
        },
        title: {
          text: self.$tf.call(
            self,
            "execDashboard.delay.delayChart|Projektek száma , várható csúszás napokban"
          ),
          align: "center",
          style: {
            fontFamily: "Roboto, sans-serif",
            color: "var(--text)",
          },
        },
        colors: ["var(--blue)", "var(--purple)", "var(--red)"],
        plotOptions: {
          bar: {
            distributed: true,
            borderRadius: 4,
            horizontal: true,
          },
        },
        xaxis: {
          labels: {
            style: {
              fontSize: "10px",
              colors: "var(--text)",
            },
          },
        },
        yaxis: {
          labels: {
            style: {
              colors: "var(--text)",
            },
          },
        },
      };
    },
  },

  // TO BE REMOVED
  data() {
    return {
      LoadingTable, // DO NOT REMOVE
      data: {
        lateProjectCount: [8, 11],
        lateDetailedProjectCount: [1, 4, 1],
      },
    };
  },
  methods: {
    getBudgetRiskValue,
    percentify,
    formatDate,
    moneyify,
  },
};
</script>

<style scoped></style>
