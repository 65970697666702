import { newLogger } from "@/utils/util";
import StoreCore from "../../../../StoreCore";
import { FORECAST_DASHBOARD_TYPE } from "@/utils/const";

let logger = newLogger("Enterprise-ForecastSegmentStore");

const urls = {
  segment: "/enterprise/forecast/segment",
  segmentWithId: (id) => `/enterprise/forecast/segment/${id}`,
};

const initState = () => {
  return {
    segments: [],
    allSegments: [],
    dashboard: {},
    dashboardQuarterly: {},
    dashboardAll: {},
    dashboardAllQuarterly: {},
    total: {},
    totalQuarterly: {},
    totalAll: {},
    totalAllQuarterly: {},
    segment: {},
  };
};

const storeCore = new StoreCore();
const actions = {
  fetchSegments: async function ({ commit, dispatch }, { force, params } = {}) {
    try {
      let segment = await this.remoteRequest(
        "get",
        urls.segment,
        params,
        true,
        dispatch,
        "Szegmensek betöltése",
        null,
        force
      );
      commit("saveSegments", segment);
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  fetchAllSegments: async function ({ commit, dispatch }, force) {
    try {
      let segment = await this.remoteRequest(
        "get",
        urls.segment + "/all",
        undefined,
        true,
        dispatch,
        "Szegmensek betöltése",
        null,
        force
      );
      commit("saveAllSegments", segment);
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  fetchForDashboard: async function (
    { commit, dispatch },
    { force, params, type } = {}
  ) {
    try {
      let segment = await this.remoteRequest(
        "get",
        urls.segment +
          `/dashboard/${FORECAST_DASHBOARD_TYPE[type].toLowerCase()}`,
        params,
        true,
        dispatch,
        "Szegmensek betöltése",
        null,
        force
      );
      commit("saveForDashboard", {
        data: segment,
        type,
        quarter: params?.params?.get("quarter"),
      });
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  fetchAllForDashboard: async function (
    { commit, dispatch },
    { force, params, type } = {}
  ) {
    try {
      let segment = await this.remoteRequest(
        "get",
        urls.segment +
          `/dashboard/${FORECAST_DASHBOARD_TYPE[type].toLowerCase()}/all`,
        params,
        true,
        dispatch,
        "Szegmensek betöltése",
        null,
        force
      );
      commit("saveAllForDashboard", {
        data: segment,
        type,
        quarter: params?.params?.get("quarter"),
      });
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  fetchTotalsForDashboard: async function (
    { commit, dispatch },
    { force, params, type } = {}
  ) {
    try {
      let segment = await this.remoteRequest(
        "get",
        urls.segment +
          `/dashboard/${FORECAST_DASHBOARD_TYPE[type].toLowerCase()}/total`,
        params,
        true,
        dispatch,
        "Összesítések betöltése",
        null,
        force
      );
      commit("saveTotalForDashboard", {
        data: segment,
        type,
        quarter: params?.params?.get("quarter"),
      });
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  fetchTotalsAllForDashboard: async function (
    { commit, dispatch },
    { force, params, type } = {}
  ) {
    try {
      let segment = await this.remoteRequest(
        "get",
        urls.segment +
          `/dashboard/${FORECAST_DASHBOARD_TYPE[type].toLowerCase()}/all/total`,
        params,
        true,
        dispatch,
        "Összesítések betöltése",
        null,
        force
      );
      commit("saveTotalAllForDashboard", {
        data: segment,
        type,
        quarter: params?.params?.get("quarter"),
      });
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  fetchSegment: async function ({ commit, dispatch }, { id, force } = {}) {
    try {
      let segment = await this.remoteRequest(
        "get",
        urls.segmentWithId(id),
        null,
        true,
        dispatch,
        "Szegmens betöltése",
        null,
        force
      );
      commit("saveSegment", segment);
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  createSegment: async function ({ dispatch }, segment) {
    try {
      await this.remoteRequestObject({
        method: "post",
        url: urls.segment,
        data: segment,
        dispatch,
        flair: "Szegmens létrehozása...",
        toast: true,
      });
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
    dispatch("clearCache");
  }.bind(storeCore),
  updateSegment: async function ({ dispatch }, segment) {
    try {
      await this.remoteRequestObject({
        method: "put",
        url: urls.segmentWithId(segment.id),
        data: segment,
        dispatch,
        flair: "Szegmens frissítése...",
        toast: true,
      });
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
    dispatch("clearCache");
  }.bind(storeCore),
  deleteSegment: async function ({ dispatch }, id) {
    try {
      await this.remoteRequestObject({
        method: "delete",
        url: urls.segmentWithId(id),
        dispatch,
        flair: "Szegmens törlése...",
        toast: true,
      });
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
    dispatch("clearCache");
  }.bind(storeCore),
  clearCache: async function ({}) {
    this.clearCoreCache();
  }.bind(storeCore),
};

const mutations = {
  saveSegments: (state, segment) => {
    state.segments = segment;
  },
  saveAllSegments: (state, segment) => {
    state.allSegments = segment;
  },
  saveSegment: (state, segment) => {
    state.segment = segment;
  },
  saveForDashboard: (state, { data, type, quarter }) => {
    if (quarter) {
      if (!state.dashboardQuarterly[quarter])
        state.dashboardQuarterly[quarter] = {};
      state.dashboardQuarterly[quarter][type] = data;
      return;
    }
    state.dashboard[type] = data;
  },
  saveAllForDashboard: (state, { data, type, quarter }) => {
    if (quarter) {
      if (!state.dashboardAllQuarterly[quarter])
        state.dashboardAllQuarterly[quarter] = {};
      state.dashboardAllQuarterly[quarter][type] = data;
      return;
    }
    state.dashboardAll[type] = data;
  },
  saveTotalForDashboard: (state, { data, type, quarter }) => {
    if (quarter) {
      if (!state.totalQuarterly[quarter]) state.totalQuarterly[quarter] = {};
      state.totalQuarterly[quarter][type] = data;
      return;
    }
    state.total[type] = data;
  },
  saveTotalAllForDashboard: (state, { data, type, quarter }) => {
    if (quarter) {
      if (!state.totalAllQuarterly[quarter])
        state.totalAllQuarterly[quarter] = {};
      state.totalAllQuarterly[quarter][type] = data;
      return;
    }
    state.totalAll[type] = data;
  },
};

const getters = {
  segments: (state) => {
    return state.segments;
  },
  allSegments: (state) => {
    return state.allSegments;
  },
  segment: (state) => {
    return state.segment;
  },
  dashboard: (state) => (quarter) => {
    if (quarter) return state.dashboardQuarterly[quarter];
    return state.dashboard;
  },
  dashboardAll: (state) => (quarter) => {
    if (quarter) return state.dashboardAllQuarterly[quarter];
    return state.dashboardAll;
  },
  total: (state) => (quarter) => {
    if (quarter) return state.totalQuarterly[quarter];
    return state.total;
  },
  totalAll: (state) => (quarter) => {
    if (quarter) return state.totalAllQuarterly[quarter];
    return state.totalAll;
  },
};

export default {
  namespaced: true,
  state: initState(),
  mutations: mutations,
  actions: actions,
  getters: getters,
};
