<template>
  <div v-if="!loading">
    <div class="container mx-0 px-5">
      <h1 class="title">Logolási statisztikák</h1>
      <template v-if="loggingRequired">
        <h3 class="title">Saját statisztikák</h3>
        <div
          class="is-flex has-gap-5"
          style="width: 100%"
          v-if="data.ownLogStatistic.logCount !== 0"
        >
          <div class="is-flex is-flex-direction-column has-gap-4">
            <div class="mini-stat-card card-shadowed">
              <div>Logok száma</div>
              <div>{{ data.ownLogStatistic.logCount }} bejegyzés</div>
            </div>
            <div class="mini-stat-card card-shadowed">
              <div>
                <b-icon class="icon-top-border" icon="clock" size="is-small" />
                Átlagos logolt idő
              </div>
              <div>
                {{
                  secondToShortWorkDayFormat(data.ownLogStatistic.avgLogTime)
                }}
              </div>
            </div>
            <div class="mini-stat-card card-shadowed">
              <div>
                <b-icon
                  class="icon-top-border"
                  icon="comment-dots"
                  size="is-small"
                />
                Megjegyzések átlagos hossza
              </div>
              <div>
                {{ data.ownLogStatistic.avgCommentLength.toFixed(2) }} karakter
              </div>
            </div>
            <div class="mini-stat-card card-shadowed">
              <div>
                <b-icon icon="comment-slash" size="is-small" />
                Megjegyzés nélküli logok száma
              </div>
              <div>{{ data.ownLogStatistic.noCommentLogCount }} bejegyzés</div>
            </div>
          </div>
          <div class="is-flex-grow-1 mini-stat-card card-shadowed">
            <h5 class="has-text-dark-blue has-font-weight-700 my-4">
              Projektek szerinti megoszlás
            </h5>
            <div
              class="project-stat-row"
              v-for="projectStatistic in data.ownProjectStatistic"
              :key="projectStatistic.key"
            >
              <div class="has-font-weight-500">
                {{ projectStatistic.projectKey }}
              </div>
              <div class="has-font-weight-500 has-text-dark-blue">
                {{ projectStatistic.name }}
              </div>
              <div class="has-font-weight-700">
                {{ projectStatistic.logCount }} bejegyzés
              </div>
              <div class="has-font-weight-700">
                {{ secondToShortWorkDayFormat(projectStatistic.sumLogTime) }}
              </div>
            </div>
          </div>
        </div>
        <div v-else>Ebben az évben nincs bejegyzésed</div>
      </template>
      <h3 class="title">Legtöbb bejegyzéssel rendelkező munkatársak</h3>
      <div class="is-flex is-justify-content-space-between mb-5">
        <div
          v-for="(mostLog, index) in data.mostLogPerEmployees"
          :key="index"
          class="is-flex is-flex-direction-column is-align-items-center is-justify-content-flex-end has-gap-1"
          style="width: 9%"
        >
          <div class="has-text-centered">
            <img
              style="width: 80%; max-width: 64px; border-radius: 1000px"
              :src="mostLog.avatar"
              alt="avatar"
            />
          </div>
          <div
            style="font-size: 0.9rem"
            class="has-font-weight-500 has-text-dark-blue has-text-centered"
          >
            {{ mostLog.name }}
          </div>
          <div
            style="font-size: 0.75rem"
            class="has-text-grey-dark has-text-centered"
          >
            {{ mostLog.logCount }} bejegyzés
          </div>
          <div
            class="is-flex is-align-items-center is-flex-direction-column has-gap-2 is-justify-content-center has-font-weight-700 card-shadowed"
            style="width: 100%; border-radius: 5px; font-family: monospace"
            :style="{ height: (10 - index) * 15 + 30 + 'px' }"
          >
            <div>{{ convertToRoman(index + 1) }}</div>
            <div class="has-text-centered" v-if="index < 3">
              <b-icon
                icon="medal"
                :style="{
                  color:
                    index === 0
                      ? '#FFD700'
                      : index === 1
                      ? '#C0C0C0'
                      : '#8A4638',
                }"
              />
            </div>
          </div>
          <div
            style="font-size: 0.7rem"
            class="has-text-grey-dark is-flex is-flex-direction-column has-gap-1 mt-3"
          >
            <b-tooltip label="Logbejegyzések átlagos ideje">
              <div class="is-flex has-gap-2 is-align-items-center">
                <b-icon class="icon-top-border" icon="clock" size="is-small" />
                <div>{{ secondToShortWorkDayFormat(mostLog.avgLogTime) }}</div>
              </div>
            </b-tooltip>
            <b-tooltip label="Megjegyzések átlagos hossza">
              <div class="is-flex has-gap-2 is-align-items-center">
                <b-icon
                  class="icon-top-border"
                  icon="comment-dots"
                  size="is-small"
                />
                <div>{{ mostLog.avgCommentLength.toFixed(2) }} karakter</div>
              </div>
            </b-tooltip>
            <b-tooltip label="Megjegyzés nélküli logbejegyzések száma">
              <div class="is-flex has-gap-2 is-align-items-center">
                <b-icon icon="comment-slash" size="is-small" />
                <div>{{ mostLog.noCommentLogCount }} db</div>
              </div>
            </b-tooltip>
          </div>
        </div>
      </div>
      <div v-if="data.logCount !== 0" class="columns">
        <div class="column is-two-fifths">
          <apexchart
            type="donut"
            :options="getLoggingChartOptions"
            :series="
              formatChartData(data.loggingStatistics).map((s) => s.count)
            "
          />
        </div>
        <div class="column is-three-fifths">
          <dashboard-card
            :image="'calendar-icon'"
            :project="{
              name: 'Bejegyzések átlagos hossza',
              clientName: `${formatSeconds(data.averageLogLength, true)}`,
              clientAvatar: require('@/assets/images/dashboard/team-log.svg'),
              clickEmitter: true,
            }"
          />
          <!--
          <dashboard-card
            :image="'calendar-icon'"
            :project="{
              name: `Legtöbb bejegyzés egy munkatárs által`,
              clientName: `${data.mostLogs} bejegyzés`,
              clientAvatar: require('@/assets/images/dashboard/all-logs.svg'),
              clickEmitter: true,
            }"
          />

          <dashboard-card
            :image="'calendar-icon'"
            :project="{
              name: 'Legtöbb bejegyzéssel rendelkező munkatárs',
              clientName: `${data.userWithMostLogs}`,
              clientAvatar: require('@/assets/images/dashboard/logs.svg'),
              clickEmitter: true,
            }"
          />
          -->

          <b-tooltip
            label="Megjegyzés nélküli bejegyzések figyelmbe vétele nélkül"
            class="card-tooltip"
          >
            <dashboard-card
              :image="'calendar-icon'"
              :project="{
                name: 'Megjegyzések átlagos hossza',
                clientName: `${
                  Math.round(data.averageCommentLength * 100) / 100
                } karakter`,
                clientAvatar: require('@/assets/images/dashboard/team-backlog.svg'),
                clickEmitter: true,
              }"
            />
          </b-tooltip>
          <dashboard-card
            :image="'calendar-icon'"
            :project="{
              name: 'Bejegyzések megjegyzés nélkül',
              clientName: `${data.logsWithoutComments} bejegyzés`,
              clientAvatar: require('@/assets/images/dashboard/scorecard-icon.svg'),
              clickEmitter: true,
            }"
          />
        </div>
      </div>
      <div v-else>
        <p class="subtitle">Ebben az évben még nem volt logolás.</p>
      </div>
    </div>
    <div class="container mx-0 px-5">
      <h1 class="title">Jegy statisztikák</h1>
      <h3 class="title">Legtöbb logolással rendelkező jegyek</h3>
      <div class="is-flex is-justify-content-space-between mb-5">
        <div
          v-for="(mostLog, index) in data.mostLogPerIssues"
          :key="index"
          class="is-flex is-flex-direction-column is-align-items-center is-justify-content-flex-end has-gap-1"
          style="width: 9%"
        >
          <div
            style="font-size: 0.9rem"
            class="has-font-weight-500 has-text-dark-blue has-text-centered"
          >
            <a :href="createTicketUrl(mostLog.issueKey)" target="_blank">{{
              mostLog.issueKey
            }}</a>
          </div>
          <div
            style="font-size: 0.75rem"
            class="has-text-grey-dark has-text-centered"
          >
            {{ mostLog.name }}
          </div>
          <div
            class="is-flex is-align-items-center is-flex-direction-column has-gap-2 is-justify-content-center has-font-weight-700 card-shadowed"
            style="width: 100%; border-radius: 5px; font-family: monospace"
            :style="{ height: (10 - index) * 15 + 30 + 'px' }"
          >
            <div>{{ convertToRoman(index + 1) }}</div>
            <div class="has-text-centered" v-if="index < 3">
              <b-icon
                icon="medal"
                :style="{
                  color:
                    index === 0
                      ? '#FFD700'
                      : index === 1
                      ? '#C0C0C0'
                      : '#8A4638',
                }"
              />
            </div>
          </div>
          <div
            style="font-size: 0.7rem"
            class="has-text-grey-dark is-flex is-flex-direction-column has-gap-1 mt-3"
          >
            <b-tooltip label="Logbejegyzések idejének összege">
              <div class="is-flex has-gap-2 is-align-items-center">
                <b-icon icon="clock" size="is-small" />
                <div>{{ secondToShortWorkDayFormat(mostLog.sumLogTime) }}</div>
              </div>
            </b-tooltip>
            <b-tooltip label="Logbejegyzések száma">
              <div class="is-flex has-gap-2 is-align-items-center">
                <b-icon icon="bars-staggered" size="is-small" />
                <div>{{ mostLog.logCount }} log</div>
              </div>
            </b-tooltip>
            <b-tooltip label="Megjegyzéssel ellátott logbejegyzések száma">
              <div class="is-flex has-gap-2 is-align-items-center">
                <b-icon icon="comment-dots" size="is-small" />
                <div>{{ mostLog.commentedLogCount }} log</div>
              </div>
            </b-tooltip>
          </div>
        </div>
      </div>
      <div
        v-if="data.issueTypeStatistics.length !== 0"
        class="columns is-multiline"
      >
        <div
          v-if="data.issueEstimateStatistics.length !== 0"
          class="column is-one-third"
        >
          <apexchart
            type="donut"
            :options="getIssueEstimateChartOptions"
            :series="
              formatChartData(data.issueEstimateStatistics).map((s) => s.count)
            "
          />
        </div>
        <div
          v-if="data.issueTimeSpentStatistics.length !== 0"
          class="column is-one-third"
        >
          <apexchart
            type="donut"
            :options="getIssueTimeSpentChartOptions"
            :series="
              formatChartData(data.issueTimeSpentStatistics).map((s) => s.count)
            "
          />
        </div>
        <div class="column is-one-third">
          <apexchart
            type="donut"
            :options="getIssueTypeChartOptions"
            :series="
              formatChartData(data.issueTypeStatistics).map((s) => s.count)
            "
          />
        </div>
        <div class="column is-half">
          <dashboard-card
            v-if="data.averageEstimate > 0"
            :image="'calendar-icon'"
            :project="{
              name: 'Jegyek átlagos becslése',
              clientName: `${formatSeconds(data.averageEstimate, true)}`,
              clientAvatar: require('@/assets/images/dashboard/estimated_time-icon.svg'),
              clickEmitter: true,
            }"
          />
          <dashboard-card
            v-if="data.averageTimeSpent > 0"
            :image="'calendar-icon'"
            :project="{
              name: 'Jegyek átlagos logolási ideje',
              clientName: `${formatSeconds(data.averageTimeSpent, true)}`,
              clientAvatar: require('@/assets/images/dashboard/team-log.svg'),
              clickEmitter: true,
            }"
          />
          <dashboard-card
            :image="'calendar-icon'"
            :project="{
              name: `Jegyek becslés nélkül`,
              clientName: `${data.zeroEstimates} jegy`,
              clientAvatar: require('@/assets/images/dashboard/team-backlog.svg'),
              clickEmitter: true,
            }"
          />
          <dashboard-card
            :image="'calendar-icon'"
            :project="{
              name: `Jegyek logolás nélkül`,
              clientName: `${data.zeroTimeSpents} jegy`,
              clientAvatar: require('@/assets/images/dashboard/team-backlog.svg'),
              clickEmitter: true,
            }"
          />
          <dashboard-card
            :image="'calendar-icon'"
            :project="{
              name: `Jegyek becslés és logolás nélkül`,
              clientName: `${data.uselessIssues} jegy`,
              clientAvatar: require('@/assets/images/dashboard/scorecard-icon.svg'),
              clickEmitter: true,
            }"
          />
        </div>
        <div class="column">
          <dashboard-card
            :image="'calendar-icon'"
            :project="{
              name: `Jegyek becsléssel és logolással`,
              clientName: `${data.usefulIssues} jegy`,
              clientAvatar: require('@/assets/images/dashboard/all-logs.svg'),
              clickEmitter: true,
            }"
          />
          <b-tooltip
            :label="`A jegyeken lévő becslés átlagosan ${
              Math.round(data.averageEstimationRatio * 1000) / 10
            }%-a az eltöltött időnek`"
            class="card-tooltip"
            multilined
          >
            <dashboard-card
              v-if="data.usefulIssues > 0"
              :image="'calendar-icon'"
              :project="{
                name: `Becslés és logolási idő arányának átlaga`,
                clientName: `${
                  Math.round(data.averageEstimationRatio * 1000) / 10
                }%`,
                clientAvatar: require('@/assets/images/dashboard/calendar.svg'),
                clickEmitter: true,
              }"
            />
          </b-tooltip>
          <dashboard-card
            v-if="data.averageResolvationTime > 0"
            :image="'calendar-icon'"
            :project="{
              name: 'Átlagos időtartam egy jegy létrehozása és befejezése között',
              clientName: `${formatSeconds(data.averageResolvationTime, true)}`,
              clientAvatar: require('@/assets/images/dashboard/calendar.svg'),
              clickEmitter: true,
            }"
          />
          <dashboard-card
            :image="'calendar-icon'"
            :project="{
              name: `Jegyek létrejöttük időpontja előtti logolással`,
              clientName: `${data.issuePastLoggedCount} jegy`,
              clientAvatar: require('@/assets/images/dashboard/hurry-icon.svg'),
              clickEmitter: true,
            }"
          />
        </div>
      </div>
      <div v-else>
        <p class="subtitle">Ebben az évben még nem lett jegy létrehozva.</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import VueApexCharts from "vue3-apexcharts";
import DashboardCard from "@/components/enterprise/project/module/DashboardCard";
import { createTicketUrl, secondToShortWorkDayFormat } from "@/utils/util";

export default {
  name: "YearStatistics",
  components: {
    apexchart: VueApexCharts,
    DashboardCard,
  },
  data() {
    return {
      data: null,
      loading: true,
      romanMatrix: [
        [1000, "M"],
        [900, "CM"],
        [500, "D"],
        [400, "CD"],
        [100, "C"],
        [90, "XC"],
        [50, "L"],
        [40, "XL"],
        [10, "X"],
        [9, "IX"],
        [5, "V"],
        [4, "IV"],
        [1, "I"],
      ],
    };
  },
  props: {
    year: {
      type: Number,
      required: false,
      default: new Date().getFullYear(),
    },
  },
  computed: {
    getLoggingChartOptions() {
      return {
        chart: {
          type: "donut",
        },
        stroke: {
          width: 2,
          show: false,
        },
        legend: {
          position: "bottom",
          labels: {
            colors: "var(--text)",
          },
        },
        labels: this.data.loggingStatistics.map((s) =>
          s.value === "Minden más"
            ? "Minden más"
            : this.formatSeconds(s.value, false)
        ),
        title: {
          text: "Bejegyzések hosszának eloszlása",
          align: "center",
          style: {
            fontFamily: "Roboto, serif",
            color: "var(--text)",
          },
        },
        colors: this.randomColors(10),
      };
    },
    getIssueTypeChartOptions() {
      return {
        chart: {
          type: "donut",
        },
        stroke: {
          width: 0,
          show: false,
        },
        legend: {
          position: "bottom",
          labels: {
            colors: "var(--text)",
          },
        },
        labels: this.data.issueTypeStatistics.map((s) => s.value),
        title: {
          text: "Jegyek típusainak eloszlása",
          align: "center",
          style: {
            fontFamily: "Roboto, serif",
            color: "var(--text)",
          },
        },
        colors: this.randomColors(10),
        plotOptions: {
          pie: {
            donut: {
              size: "50%",
            },
          },
        },
      };
    },
    getIssueEstimateChartOptions() {
      return {
        chart: {
          type: "donut",
        },
        stroke: {
          width: 0,
          show: false,
        },
        legend: {
          position: "bottom",
          labels: {
            colors: "var(--text)",
          },
        },
        labels: this.data.issueEstimateStatistics.map((s) =>
          s.value === "Minden más"
            ? "Minden más"
            : this.formatSeconds(s.value, false)
        ),
        title: {
          text: "Jegyek becsült idejének eloszlása",
          align: "center",
          margin: 10,
          style: {
            fontFamily: "Roboto, serif",
            color: "var(--text)",
          },
        },
        colors: this.randomColors(10),
        plotOptions: {
          pie: {
            donut: {
              size: "50%",
            },
          },
        },
      };
    },
    getIssueTimeSpentChartOptions() {
      return {
        chart: {
          type: "donut",
        },
        stroke: {
          width: 0,
          show: false,
        },
        legend: {
          position: "bottom",
          labels: {
            colors: "var(--text)",
          },
        },
        labels: this.data.issueTimeSpentStatistics.map((s) =>
          s.value === "Minden más"
            ? "Minden más"
            : this.formatSeconds(s.value, false)
        ),
        title: {
          text: "Jegyek logolt idejének eloszlása",
          align: "center",
          margin: 10,
          style: {
            fontFamily: "Roboto, serif",
            color: "var(--text)",
          },
        },
        colors: this.randomColors(10),
        plotOptions: {
          pie: {
            donut: {
              size: "50%",
            },
          },
        },
      };
    },
    ...mapGetters({
      statistics: "enterprise_statistics/statistics",
      loggingRequired: "session/loggingRequired",
    }),
  },
  async created() {
    await this.$store.dispatch(
      "enterprise_statistics/fetchStatistics",
      this.year
    );
    this.data = this.statistics(this.year);
    this.loading = false;
  },
  methods: {
    createTicketUrl,
    secondToShortWorkDayFormat,
    formatSeconds(n, isDouble) {
      const duration = isDouble ? Math.round(parseFloat(n)) : n;
      const days = Math.floor(duration / 86400);
      const hours = Math.floor((duration - days * 86400) / 3600);
      const minutes = Math.floor((duration - days * 86400 - hours * 3600) / 60);
      const seconds =
        (duration - days * 86400 - hours * 3600 - minutes * 60) % 60;
      let formattedString = days === 0 ? "" : hours + " nap ";
      formattedString =
        hours === 0 ? formattedString : formattedString.concat(hours + " óra ");
      formattedString =
        minutes === 0
          ? formattedString
          : formattedString.concat(minutes + " perc ");
      formattedString =
        seconds === 0
          ? formattedString
          : formattedString.concat(seconds + " mp ");
      return formattedString;
    },
    formatChartData(data) {
      if (data.length > 10) {
        data.sort((a, b) => {
          if (a.count < b.count) return 1;
          if (a.count > b.count) return -1;
          return 0;
        });
        let restSum = data[9].count;
        for (var i = 10; i < data.length; i++) {
          restSum += data[i].count;
        }
        data.splice(9, Infinity, { value: "Minden más", count: restSum });
      }
      return data;
    },

    randomColors(count) {
      let colors = [];
      for (let i = 2; i < count + 2; i++) {
        const hue = i * 137.508; // use golden angle approximation
        colors.push(`hsl(${hue}, 70%, ${Math.random() * 20 + 50}%)`);
      }
      return colors;
    },
    convertToRoman(num) {
      if (num === 0) {
        return "";
      }
      for (let i = 0; i < this.romanMatrix.length; i++) {
        if (num >= this.romanMatrix[i][0]) {
          return (
            this.romanMatrix[i][1] +
            this.convertToRoman(num - this.romanMatrix[i][0])
          );
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/colors.scss";

.card-tooltip {
  width: 100%;
}

.mini-stat-card {
  border-radius: 8px;
  padding: 8px 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.mini-stat-card > div:first-child {
  font-weight: 500;
  color: $custom-dark-blue;
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 0.9rem;
}

.mini-stat-card > div:nth-child(2) {
  font-weight: 700;
}

.project-stat-row {
  padding: 4px;
  display: grid;
  grid-template-columns: 1fr 3fr 1fr 1fr;
}

.project-stat-row:not(:last-child) {
  border-bottom: 1px solid $grey-lighter;
}

.icon-top-border {
  border-top: 1px solid $grey-dark;
}
</style>
