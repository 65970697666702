<template>
  <div
    class="workers mx-0 container"
    v-if="employeeData && employeeData.length"
  >
    <ProjectCollapse id="worker-list">
      <template #header>
        <h3 class="title">
          {{
            $tf(
              "projectPage.workersList.title|Dolgozói, szerepköri és szenioritási lebontás"
            )
          }}
          <b-tooltip
            type="is-info"
            :triggers="['click']"
            :auto-close="['outside', 'escape']"
          >
            <template v-slot:content>
              <div class="content tooltip">
                <p>
                  {{
                    $tf(
                      "projectPage.workersList.info|A projektre logolt idők és költségek megoszlása a résztvevők alapján. A megjelenített ráta átlag, ha a dolgozó besorolása esetleg a projekt közben változott. A projektre vonatkozó esetleges költségfelülírás az itteni számításra is érvényes. A költség kiszámítása itt is percre pontos kerekítéssel van kiszámolva."
                    )
                  }}
                </p>
              </div>
            </template>
            <portal-icon icon="info-circle" />
          </b-tooltip>
        </h3>
      </template>
      <div class="content">
        <b-tabs v-model="workerTab" type="is-toggle" class="toggle-only">
          <b-tab-item
            key="projectWorker"
            :label="
              $tf('projectPage.workersList.tabs.wholeProject|Teljes projekt')
            "
            id="projectWorker"
            :visible="true"
            icon="folder"
          >
          </b-tab-item>
          <b-tab-item
            v-for="tigDetails in sortedTigs"
            :key="tigDetails.identifier"
            :label="tigDetails.name"
            :id="'worker' + tigDetails.identifier"
            :visible="
              tigDetails.status === 'ONGOING' ||
              tigDetails.status === 'PENDING' ||
              tigDetails.status === 'COMPLETED'
            "
            icon="file"
          >
          </b-tab-item>
        </b-tabs>
        <b-tabs v-model="detailsTab">
          <b-tab-item
            :label="
              $tf(
                'projectPage.workersList.detailTabs.byEmployees|Dolgozók szerint'
              )
            "
          >
            <div class="employee-graph">
              <template v-if="noDataForEmployees">
                <section class="section">
                  <div class="content has-text-grey has-text-centered">
                    <p>
                      <b-icon
                        icon="exclamation-triangle"
                        size="is-large"
                      ></b-icon>
                    </p>
                    <p>
                      {{
                        $tf(
                          "projectPage.workersList.byWorkers.atLeastOneWorkerNeeded|Legalább egy dolgozót ki kell jelölni!"
                        )
                      }}
                    </p>
                  </div>
                </section>
              </template>
              <template v-else>
                <div class="columns">
                  <div class="column is-half">
                    <h3 class="heading">
                      {{
                        $tf(
                          "projectPage.workersList.byWorkers.byTimelogs|Logolt idő szerint"
                        )
                      }}
                    </h3>
                    <apex-chart
                      type="bar"
                      height="300"
                      :options="monthlyBarChartOptionsByEmployeeTime"
                      :series="monthlyBarChartDataByEmployees"
                    ></apex-chart>
                  </div>
                  <div class="column is-half">
                    <h3 class="heading">
                      {{
                        $tf(
                          "projectPage.workersList.byWorkers.byCost|Költség szerint"
                        )
                      }}
                    </h3>
                    <apex-chart
                      type="bar"
                      height="300"
                      :options="monthlyBarChartOptionsByEmployeeMoney"
                      :series="monthlyBarChartDataByEmployeesMoney"
                    ></apex-chart>
                  </div>
                </div>
              </template>
            </div>

            <h3 class="heading">
              {{
                $tf(
                  "projectPage.workersList.byWorkers.selectData.title|Adatok kiválasztása"
                )
              }}
            </h3>
            <h2 class="subtitle">
              {{
                $tf(
                  "projectPage.workersList.byWorkers.selectData.subitle|A kijelölt dolgozók adatai jelennek meg a grafikonokon, illetve számítódnak bele a táblázat összegző sorába."
                )
              }}
            </h2>

            <div>
              <div class="is-flex mb-1 mt-4">
                <b-button
                  type="is-info"
                  size="is-small"
                  class="mr-1"
                  rounded
                  @click="doSelectAllEmployees"
                  >{{
                    $tf(
                      "projectPage.workersList.byWorkers.selectData.all|Összes kijelölése"
                    )
                  }}
                </b-button>
                <b-button
                  type="is-info"
                  size="is-small"
                  rounded
                  @click="unSelectAllEmployees"
                  :disabled="noEmployeesSelected"
                  >{{
                    $tf(
                      "projectPage.workersList.byWorkers.selectData.none|Kijelöltek törlése"
                    )
                  }}
                </b-button>
              </div>
              <div class="overflow-x-auto">
                <table class="worker-table">
                  <tr>
                    <th colspan="4"></th>
                    <th
                      class="bg-lightgray"
                      style="
                        border-top-left-radius: 8px;
                        border-bottom-left-radius: 8px;
                      "
                    >
                      {{
                        $tf(
                          "projectPage.workersList.byWorkers.table.sum|Összesen"
                        )
                      }}
                    </th>
                    <th
                      class="bg-lightgray"
                      v-for="(month, index) in months"
                      :key="month"
                      :style="{
                        borderRadius:
                          index === months.length - 1 ? '0 8px 8px 0' : '0',
                      }"
                    >
                      {{ month }}
                    </th>
                  </tr>
                  <tr class="blank-row-small">
                    <td></td>
                  </tr>
                  <template v-for="emp in employees" :key="emp.externalId">
                    <tr
                      :class="{
                        'inactive-row': !selectedEmployees[
                          selectionIndex
                        ].includes(emp.externalId),
                      }"
                    >
                      <td rowspan="2" style="width: 45px" class="checkbox-cell">
                        <div
                          class="is-flex is-justify-content-space-between is-align-items-center"
                          style="width: 45px"
                        >
                          <div>
                            <b-checkbox
                              v-model="selectedEmployees[selectionIndex]"
                              :native-value="emp.externalId"
                            />
                          </div>
                          <div
                            style="
                              width: 5px;
                              height: 64px;
                              border-radius: 50px;
                            "
                            :style="{ backgroundColor: emp.color }"
                          />
                        </div>
                      </td>
                      <td rowspan="2" style="width: 64px">
                        <p class="image is-64x64">
                          <img
                            class="is-rounded"
                            :src="
                              emp.avatar
                                ? emp.avatar
                                : 'https://cdn2.iconfinder.com/data/icons/social-flat-buttons-3/512/anonymous-512.png'
                            "
                            alt="Avatar"
                          />
                        </p>
                      </td>
                      <td class="has-dotted-bottom-border">
                        <span class="has-font-weight-500">{{ emp.name }}</span>
                      </td>
                      <td class="has-dotted-bottom-border">
                        {{
                          $tf(
                            "projectPage.workersList.byWorkers.table.timeSpent|Logolt idő"
                          )
                        }}
                      </td>
                      <td class="has-dotted-bottom-border">
                        <span class="has-font-weight-500">{{
                          secondToShortWorkDayFormat(emp.timespent)
                        }}</span>
                      </td>
                      <td
                        v-for="(monthlyLog, index) in emp.monthlyLogged"
                        :key="index"
                        class="has-dotted-bottom-border"
                      >
                        {{ secondToShortWorkDayFormat(monthlyLog) }}
                      </td>
                    </tr>
                    <tr
                      :class="{
                        'inactive-row': !selectedEmployees[
                          selectionIndex
                        ].includes(emp.externalId),
                      }"
                    >
                      <td>{{ moneyify(emp.avgRate) }}/h</td>
                      <td>
                        {{
                          $tf(
                            "projectPage.workersList.byWorkers.table.moneySpent|Költés"
                          )
                        }}
                      </td>
                      <td>
                        <span class="has-font-weight-500">{{
                          moneyify(emp.moneyspent)
                        }}</span>
                      </td>
                      <td
                        v-for="(monthlySpent, index) in emp.monthlySpent"
                        :key="index"
                      >
                        {{ moneyify(monthlySpent) }}
                      </td>
                    </tr>
                    <tr class="blank-row-small">
                      <td></td>
                    </tr>
                  </template>

                  <tr>
                    <td colspan="2" rowspan="2"></td>
                    <td
                      rowspan="2"
                      class="bg-black"
                      style="border-radius: 8px 0 0 8px"
                    >
                      <div
                        class="is-flex is-align-items-center has-font-weight-500"
                        style="height: 64px"
                      >
                        {{
                          $tf(
                            "projectPage.workersList.byWorkers.table.sum|Összesen"
                          )
                        }}
                      </div>
                    </td>
                    <td class="bg-black has-dotted-bottom-border">
                      {{
                        $tf(
                          "projectPage.workersList.byWorkers.table.timeSpent|Logolt idő"
                        )
                      }}
                    </td>
                    <td
                      class="bg-black has-font-weight-500 has-dotted-bottom-border"
                    >
                      {{
                        secondToShortWorkDayFormat(
                          monthlySelectedEmployeeData.reduce(
                            (partialSum, a) => partialSum + a.timespent,
                            0
                          )
                        )
                      }}
                    </td>
                    <td
                      v-for="(m, index) in months"
                      :key="index"
                      class="bg-black has-dotted-bottom-border"
                      :style="{
                        borderTopRightRadius:
                          index === months.length - 1 ? '8px' : '0',
                      }"
                    >
                      {{
                        secondToShortWorkDayFormat(
                          monthlySelectedEmployeeData
                            .map((med) => {
                              return med.monthlyLogged[index];
                            })
                            .reduce((partialSum, a) => partialSum + a, 0)
                        )
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td class="bg-black">
                      {{
                        $tf(
                          "projectPage.workersList.byWorkers.table.moneySpent|Költés"
                        )
                      }}
                    </td>
                    <td class="bg-black has-font-weight-500">
                      {{
                        moneyify(
                          monthlySelectedEmployeeData.reduce(
                            (partialSum, a) => partialSum + a.moneyspent,
                            0
                          )
                        )
                      }}
                    </td>
                    <td
                      v-for="(m, index) in months"
                      :key="index"
                      class="bg-black"
                      :style="{
                        borderBottomRightRadius:
                          index === months.length - 1 ? '8px' : '0',
                      }"
                    >
                      {{
                        moneyify(
                          monthlySelectedEmployeeData
                            .map((med) => {
                              return med.monthlySpent[index];
                            })
                            .reduce((partialSum, a) => partialSum + a, 0)
                        )
                      }}
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </b-tab-item>
          <b-tab-item
            :label="
              $tf(
                'projectPage.workersList.detailTabs.byRoles|Szerepkör szerint'
              )
            "
          >
            <div class="employee-graph">
              <template v-if="noDataForRoles">
                <section class="section">
                  <div class="content has-text-grey has-text-centered">
                    <p>
                      <b-icon
                        icon="exclamation-triangle"
                        size="is-large"
                      ></b-icon>
                    </p>
                    <p>
                      {{
                        $tf(
                          "projectPage.workersList.byRoles.atLeastOneRoleNeeded|Legalább egy szerepkört ki kell jelölni!"
                        )
                      }}
                    </p>
                  </div>
                </section>
              </template>
              <template v-else>
                <div class="columns">
                  <div class="column is-half">
                    <h3 class="heading">
                      {{
                        $tf(
                          "projectPage.workersList.byRoles.byTimelogs|Logolt idő szerint"
                        )
                      }}
                    </h3>
                    <apex-chart
                      type="bar"
                      height="300"
                      :options="monthlyBarChartOptionsByRoleTime"
                      :series="monthlyBarChartDataByRoles"
                    ></apex-chart>
                  </div>
                  <div class="column is-half">
                    <h3 class="heading">
                      {{
                        $tf(
                          "projectPage.workersList.byRoles.byCost|Költség szerint"
                        )
                      }}
                    </h3>
                    <apex-chart
                      type="bar"
                      height="300"
                      :options="monthlyBarChartOptionsByRoleMoney"
                      :series="monthlyBarChartDataByRolesMoney"
                    ></apex-chart>
                  </div>
                </div>
              </template>
            </div>

            <h3 class="heading">
              {{
                $tf(
                  "projectPage.workersList.byRoles.selectData.title|Adatok kiválasztása"
                )
              }}
            </h3>
            <h2 class="subtitle">
              {{
                $tf(
                  "projectPage.workersList.byRoles.subtitle|A kijelölt szerepkörök adatai jelennek meg a grafikonokon, illetve számítódnak bele a táblázat összegző sorába."
                )
              }}
            </h2>

            <div>
              <div class="is-flex mb-1">
                <b-button
                  type="is-info"
                  size="is-small"
                  class="mr-1"
                  rounded
                  @click="doSelectAllRoles"
                  >{{
                    $tf(
                      "projectPage.workersList.byRoles.selectData.all|Összes kijelölése"
                    )
                  }}
                </b-button>
                <b-button
                  type="is-info"
                  size="is-small"
                  rounded
                  @click="unSelectAllRoles"
                  :disabled="noRolesSelected"
                  >{{
                    $tf(
                      "projectPage.workersList.byRoles.selectData.none|Kijelöltek törlése"
                    )
                  }}
                </b-button>
              </div>

              <div class="overflow-x-auto">
                <table class="worker-table">
                  <tr>
                    <th colspan="3"></th>
                    <th
                      class="bg-lightgray"
                      style="
                        border-top-left-radius: 8px;
                        border-bottom-left-radius: 8px;
                      "
                    >
                      {{
                        $tf(
                          "projectPage.workersList.byRoles.table.sum|Összesen"
                        )
                      }}
                    </th>
                    <th
                      class="bg-lightgray"
                      v-for="(month, index) in months"
                      :key="month"
                      :style="{
                        borderRadius:
                          index === months.length - 1 ? '0 8px 8px 0' : '0',
                      }"
                    >
                      {{ month }}
                    </th>
                  </tr>
                  <tr class="blank-row-small">
                    <td></td>
                  </tr>
                  <template v-for="role in filteredRoles" :key="role.id">
                    <tr
                      :class="{
                        'inactive-row': !selectedRoles[selectionIndex].includes(
                          role.id
                        ),
                      }"
                    >
                      <td rowspan="2" style="width: 45px" class="checkbox-cell">
                        <div
                          class="is-flex is-justify-content-space-between is-align-items-center"
                          style="width: 45px"
                        >
                          <div>
                            <b-checkbox
                              v-model="selectedRoles[selectionIndex]"
                              :native-value="role.id"
                            />
                          </div>
                          <div
                            style="
                              width: 5px;
                              height: 64px;
                              border-radius: 50px;
                            "
                            :style="{ backgroundColor: role.color }"
                          />
                        </div>
                      </td>
                      <td rowspan="2" style="vertical-align: middle">
                        <div
                          class="has-font-weight-500 is-flex is-align-items-center has-gap-2"
                        >
                          <span>{{ role.name }}</span>
                        </div>
                      </td>
                      <td class="has-dotted-bottom-border">
                        {{
                          $tf(
                            "projectPage.workersList.byRoles.table.timeSpent|Logolt idő"
                          )
                        }}
                      </td>
                      <td class="has-dotted-bottom-border">
                        <span class="has-font-weight-500">{{
                          secondToShortWorkDayFormat(role.filteredTimespent)
                        }}</span>
                      </td>
                      <td
                        v-for="(monthlyLog, index) in role.monthlyLogged"
                        :key="index"
                        class="has-dotted-bottom-border"
                      >
                        {{ secondToShortWorkDayFormat(monthlyLog) }}
                      </td>
                    </tr>
                    <tr
                      :class="{
                        'inactive-row': !selectedRoles[selectionIndex].includes(
                          role.id
                        ),
                      }"
                    >
                      <td>
                        {{
                          $tf(
                            "projectPage.workersList.byRoles.table.moneySpent|Költés"
                          )
                        }}
                      </td>
                      <td>
                        <span class="has-font-weight-500">{{
                          moneyify(role.filteredMoneyspent)
                        }}</span>
                      </td>
                      <td
                        v-for="(monthlySpent, index) in role.monthlySpent"
                        :key="index"
                      >
                        {{ moneyify(monthlySpent) }}
                      </td>
                    </tr>
                    <tr class="blank-row-small">
                      <td></td>
                    </tr>
                  </template>

                  <tr>
                    <td rowspan="2"></td>
                    <td
                      rowspan="2"
                      class="bg-black"
                      style="border-radius: 8px 0 0 8px"
                    >
                      <div
                        class="is-flex is-align-items-center has-font-weight-500"
                        style="height: 64px"
                      >
                        {{
                          $tf(
                            "projectPage.workersList.byRoles.table.sum|Összesen"
                          )
                        }}
                      </div>
                    </td>
                    <td class="bg-black has-dotted-bottom-border">
                      {{
                        $tf(
                          "projectPage.workersList.byRoles.table.timeSpent|Logolt idő"
                        )
                      }}
                    </td>
                    <td
                      class="bg-black has-font-weight-500 has-dotted-bottom-border"
                    >
                      {{
                        secondToShortWorkDayFormat(
                          filteredRoles
                            .filter((fc) =>
                              selectedRoles[selectionIndex].includes(fc.id)
                            )
                            .reduce(
                              (partialSum, a) =>
                                partialSum + a.filteredTimespent,
                              0
                            )
                        )
                      }}
                    </td>
                    <td
                      v-for="(m, index) in months"
                      :key="index"
                      class="bg-black has-dotted-bottom-border"
                      :style="{
                        borderTopRightRadius:
                          index === months.length - 1 ? '8px' : '0',
                      }"
                    >
                      {{
                        secondToShortWorkDayFormat(
                          filteredRoles
                            .filter((fc) =>
                              selectedRoles[selectionIndex].includes(fc.id)
                            )
                            .map((med) => {
                              return med.monthlyLogged[index];
                            })
                            .reduce((partialSum, a) => partialSum + a, 0)
                        )
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td class="bg-black">
                      {{
                        $tf(
                          "projectPage.workersList.byRoles.table.moneySpent|Költés"
                        )
                      }}
                    </td>
                    <td class="bg-black has-font-weight-500">
                      {{
                        moneyify(
                          filteredRoles
                            .filter((fc) =>
                              selectedRoles[selectionIndex].includes(fc.id)
                            )
                            .reduce(
                              (partialSum, a) =>
                                partialSum + a.filteredMoneyspent,
                              0
                            )
                        )
                      }}
                    </td>
                    <td
                      v-for="(m, index) in months"
                      :key="index"
                      class="bg-black"
                      :style="{
                        borderBottomRightRadius:
                          index === months.length - 1 ? '8px' : '0',
                      }"
                    >
                      {{
                        moneyify(
                          filteredRoles
                            .filter((fc) =>
                              selectedRoles[selectionIndex].includes(fc.id)
                            )
                            .map((med) => {
                              return med.monthlySpent[index];
                            })
                            .reduce((partialSum, a) => partialSum + a, 0)
                        )
                      }}
                    </td>
                  </tr>
                </table>
              </div>
            </div>

            <div class="mt-5 is-flex is-justify-content-space-between">
              <div>
                <h3 class="heading">
                  {{
                    $tf(
                      "projectPage.workersList.byRoles.distribution.title|Szerepkör megoszlás"
                    )
                  }}
                </h3>
                <h2 class="subtitle">
                  {{
                    $tf(
                      "projectPage.workersList.byRoles.distribution.subtitle|Szerepkörök megoszlása összesítve, a teljes projekt, vagy a kiválasztott mérföldkő ideje alatt."
                    )
                  }}
                </h2>
                <div
                  class="is-flex is-flex-direction-column has-gap-2 has-text-dark-blue"
                >
                  <div
                    v-for="role in filteredRoles"
                    :key="role.id"
                    class="is-flex has-gap-2 is-align-items-center"
                  >
                    <div
                      style="width: 16px; height: 16px; border-radius: 4px"
                      :style="{ background: role.color }"
                    ></div>
                    <div>{{ role.name }}</div>
                  </div>
                </div>
              </div>
              <div
                class="is-flex is-flex-direction-column is-align-items-center"
              >
                <h3 class="heading">
                  {{
                    $tf(
                      "projectPage.workersList.byRoles.distribution.byTimeSpent|Logolt idő szerint"
                    )
                  }}
                </h3>
                <apex-chart
                  :options="rolesRadialOptions"
                  :series="rolesRadialTimeData"
                  :height="200"
                />
              </div>
              <div
                class="is-flex is-flex-direction-column is-align-items-center"
              >
                <h3 class="heading">
                  {{
                    $tf(
                      "projectPage.workersList.byRoles.distribution.byMoneySpent|Költés szerint"
                    )
                  }}
                </h3>
                <apex-chart
                  :options="rolesRadialOptions"
                  :series="rolesRadialMoneyData"
                  :height="200"
                />
              </div>
            </div>
          </b-tab-item>
          <b-tab-item
            :label="
              $tf(
                'projectPage.workersList.detailTabs.bySeniorities|Szenioritás szerint'
              )
            "
          >
            <div class="employee-graph">
              <template v-if="noDataForSeniorities">
                <section class="section">
                  <div class="content has-text-grey has-text-centered">
                    <p>
                      <b-icon
                        icon="exclamation-triangle"
                        size="is-large"
                      ></b-icon>
                    </p>
                    <p>
                      {{
                        $tf(
                          "projectPage.workersList.bySeniorities.atLeastOneRoleNeeded|Legalább egy szenioritást ki kell jelölni!"
                        )
                      }}
                    </p>
                  </div>
                </section>
              </template>
              <template v-else>
                <div class="columns">
                  <div class="column is-half">
                    <h3 class="heading">
                      {{
                        $tf(
                          "projectPage.workersList.bySeniorities.byTimelogs|Logolt idő szerint"
                        )
                      }}
                    </h3>
                    <apex-chart
                      type="bar"
                      height="300"
                      :options="monthlyBarChartOptionsBySeniorityTime"
                      :series="monthlyBarChartDataBySeniorities"
                    ></apex-chart>
                  </div>
                  <div class="column is-half">
                    <h3 class="heading">
                      {{
                        $tf(
                          "projectPage.workersList.bySeniorities.byCost|Költség szerint"
                        )
                      }}
                    </h3>
                    <apex-chart
                      type="bar"
                      height="300"
                      :options="monthlyBarChartOptionsBySeniorityMoney"
                      :series="monthlyBarChartDataBySenioritiesMoney"
                    ></apex-chart>
                  </div>
                </div>
              </template>
            </div>

            <h3 class="heading">
              {{
                $tf(
                  "projectPage.workersList.bySeniorities.selectData.title|Adatok kiválasztása"
                )
              }}
            </h3>
            <h2 class="subtitle">
              {{
                $tf(
                  "projectPage.workersList.bySeniorities.subtitle|A kijelölt szenioritások adatai jelennek meg a grafikonokon, illetve számítódnak bele a táblázat összegző sorába."
                )
              }}
            </h2>

            <div>
              <div class="is-flex mb-1">
                <b-button
                  type="is-info"
                  size="is-small"
                  class="mr-1"
                  rounded
                  @click="doSelectAllSeniorities"
                  >{{
                    $tf(
                      "projectPage.workersList.bySeniorities.selectData.all|Összes kijelölése"
                    )
                  }}
                </b-button>
                <b-button
                  type="is-info"
                  size="is-small"
                  rounded
                  @click="unSelectAllSeniorities"
                  :disabled="noSenioritiesSelected"
                  >{{
                    $tf(
                      "projectPage.workersList.bySeniorities.selectData.none|Kijelöltek törlése"
                    )
                  }}
                </b-button>
              </div>

              <div class="overflow-x-auto">
                <table class="worker-table">
                  <tr>
                    <th colspan="3"></th>
                    <th
                      class="bg-lightgray"
                      style="
                        border-top-left-radius: 8px;
                        border-bottom-left-radius: 8px;
                      "
                    >
                      {{
                        $tf(
                          "projectPage.workersList.bySeniorities.table.sum|Összesen"
                        )
                      }}
                    </th>
                    <th
                      class="bg-lightgray"
                      v-for="(month, index) in months"
                      :key="month"
                      :style="{
                        borderRadius:
                          index === months.length - 1 ? '0 8px 8px 0' : '0',
                      }"
                    >
                      {{ month }}
                    </th>
                  </tr>
                  <tr class="blank-row-small">
                    <td></td>
                  </tr>
                  <template
                    v-for="seniority in filteredSeniorities"
                    :key="seniority.id"
                  >
                    <tr
                      :class="{
                        'inactive-row': !selectedSeniorities[
                          selectionIndex
                        ].includes(seniority.id),
                      }"
                    >
                      <td rowspan="2" style="width: 45px" class="checkbox-cell">
                        <div
                          class="is-flex is-justify-content-space-between is-align-items-center"
                          style="width: 45px"
                        >
                          <div>
                            <b-checkbox
                              v-model="selectedSeniorities[selectionIndex]"
                              :native-value="seniority.id"
                            />
                          </div>
                          <div
                            style="
                              width: 5px;
                              height: 64px;
                              border-radius: 50px;
                            "
                            :style="{ backgroundColor: seniority.color }"
                          />
                        </div>
                      </td>
                      <td rowspan="2" style="vertical-align: middle">
                        <div
                          class="has-font-weight-500 is-flex is-align-items-center has-gap-2"
                        >
                          <span>{{ seniority.name }}</span>
                        </div>
                      </td>
                      <td class="has-dotted-bottom-border">
                        {{
                          $tf(
                            "projectPage.workersList.bySeniorities.table.timeSpent|Logolt idő"
                          )
                        }}
                      </td>
                      <td class="has-dotted-bottom-border">
                        <span class="has-font-weight-500">{{
                          secondToShortWorkDayFormat(
                            seniority.filteredTimespent
                          )
                        }}</span>
                      </td>
                      <td
                        v-for="(monthlyLog, index) in seniority.monthlyLogged"
                        :key="index"
                        class="has-dotted-bottom-border"
                      >
                        {{ secondToShortWorkDayFormat(monthlyLog) }}
                      </td>
                    </tr>
                    <tr
                      :class="{
                        'inactive-row': !selectedSeniorities[
                          selectionIndex
                        ].includes(seniority.id),
                      }"
                    >
                      <td>
                        {{
                          $tf(
                            "projectPage.workersList.bySeniorities.table.moneySpent|Költés"
                          )
                        }}
                      </td>
                      <td>
                        <span class="has-font-weight-500">{{
                          moneyify(seniority.filteredMoneyspent)
                        }}</span>
                      </td>
                      <td
                        v-for="(monthlySpent, index) in seniority.monthlySpent"
                        :key="index"
                      >
                        {{ moneyify(monthlySpent) }}
                      </td>
                    </tr>
                    <tr class="blank-row-small">
                      <td></td>
                    </tr>
                  </template>

                  <tr>
                    <td rowspan="2"></td>
                    <td
                      rowspan="2"
                      class="bg-black"
                      style="border-radius: 8px 0 0 8px"
                    >
                      <div
                        class="is-flex is-align-items-center has-font-weight-500"
                        style="height: 64px"
                      >
                        {{
                          $tf(
                            "projectPage.workersList.bySeniorities.table.sum|Összesen"
                          )
                        }}
                      </div>
                    </td>
                    <td class="bg-black has-dotted-bottom-border">
                      {{
                        $tf(
                          "projectPage.workersList.bySeniorities.table.timeSpent|Logolt idő"
                        )
                      }}
                    </td>
                    <td
                      class="bg-black has-font-weight-500 has-dotted-bottom-border"
                    >
                      {{
                        secondToShortWorkDayFormat(
                          filteredSeniorities
                            .filter((fs) =>
                              selectedSeniorities[selectionIndex].includes(
                                fs.id
                              )
                            )
                            .reduce(
                              (partialSum, a) =>
                                partialSum + a.filteredTimespent,
                              0
                            )
                        )
                      }}
                    </td>
                    <td
                      v-for="(m, index) in months"
                      :key="index"
                      class="bg-black has-dotted-bottom-border"
                      :style="{
                        borderTopRightRadius:
                          index === months.length - 1 ? '8px' : '0',
                      }"
                    >
                      {{
                        secondToShortWorkDayFormat(
                          filteredSeniorities
                            .filter((fs) =>
                              selectedSeniorities[selectionIndex].includes(
                                fs.id
                              )
                            )
                            .map((med) => {
                              return med.monthlyLogged[index];
                            })
                            .reduce((partialSum, a) => partialSum + a, 0)
                        )
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td class="bg-black">
                      {{
                        $tf(
                          "projectPage.workersList.bySeniorities.table.moneySpent|Költés"
                        )
                      }}
                    </td>
                    <td class="bg-black has-font-weight-500">
                      {{
                        moneyify(
                          filteredSeniorities
                            .filter((fs) =>
                              selectedSeniorities[selectionIndex].includes(
                                fs.id
                              )
                            )
                            .reduce(
                              (partialSum, a) =>
                                partialSum + a.filteredMoneyspent,
                              0
                            )
                        )
                      }}
                    </td>
                    <td
                      v-for="(m, index) in months"
                      :key="index"
                      class="bg-black"
                      :style="{
                        borderBottomRightRadius:
                          index === months.length - 1 ? '8px' : '0',
                      }"
                    >
                      {{
                        moneyify(
                          filteredSeniorities
                            .filter((fs) =>
                              selectedSeniorities[selectionIndex].includes(
                                fs.id
                              )
                            )
                            .map((med) => {
                              return med.monthlySpent[index];
                            })
                            .reduce((partialSum, a) => partialSum + a, 0)
                        )
                      }}
                    </td>
                  </tr>
                </table>
              </div>
            </div>

            <div class="mt-5 is-flex is-justify-content-space-between">
              <div>
                <h3 class="heading">
                  {{
                    $tf(
                      "projectPage.workersList.bySeniorities.distribution.title|Szenioritás megoszlás"
                    )
                  }}
                </h3>
                <h2 class="subtitle">
                  {{
                    $tf(
                      "projectPage.workersList.bySeniorities.distribution.subtitle|Szenioritások megoszlása összesítve, a teljes projekt, vagy a kiválasztott mérföldkő ideje alatt."
                    )
                  }}
                </h2>
                <div
                  class="is-flex is-flex-direction-column has-gap-2 has-text-dark-blue"
                >
                  <div
                    v-for="seniority in filteredSeniorities"
                    :key="seniority.id"
                    class="is-flex has-gap-2 is-align-items-center"
                  >
                    <div
                      style="width: 16px; height: 16px; border-radius: 4px"
                      :style="{ background: seniority.color }"
                    ></div>
                    <div>{{ seniority.name }}</div>
                  </div>
                </div>
              </div>
              <div
                class="is-flex is-flex-direction-column is-align-items-center"
              >
                <h3 class="heading">
                  {{
                    $tf(
                      "projectPage.workersList.bySeniorities.distribution.byTimeSpent|Logolt idő szerint"
                    )
                  }}
                </h3>
                <apex-chart
                  :options="senioritiesRadialOptions"
                  :series="senioritiesRadialTimeData"
                  :height="200"
                />
              </div>
              <div
                class="is-flex is-flex-direction-column is-align-items-center"
              >
                <h3 class="heading">
                  {{
                    $tf(
                      "projectPage.workersList.bySeniorities.distribution.byMoneySpent|Költés szerint"
                    )
                  }}
                </h3>
                <apex-chart
                  :options="senioritiesRadialOptions"
                  :series="senioritiesRadialMoneyData"
                  :height="200"
                />
              </div>
            </div>
          </b-tab-item>
        </b-tabs>
      </div>
    </ProjectCollapse>
  </div>
</template>

<script>
import {
  secondToWorkDayFormat,
  secondToShortWorkDayFormat,
  moneyify,
  deepCopy,
  workdayify,
} from "@/utils/util";
import PortalIcon from "@/components/module/icon/PortalIcon";
import ProjectCollapse from "@/components/enterprise/project/module/ProjectCollapse";
import { mapGetters, mapState } from "vuex";
import dayjs from "dayjs";
import { CURRENCY_TIERS } from "@/utils/const";

export default {
  name: "WorkersList",
  props: {
    employeeData: {
      type: Array,
      required: true,
    },
    projectData: {
      type: Object,
      required: true,
    },
  },
  components: {
    ProjectCollapse,
    PortalIcon,
  },
  data() {
    return {
      workerTab: 0,
      detailsTab: 0,
      moneyify,
      workdayify,
      secondToWorkDayFormat,
      secondToShortWorkDayFormat,
      selectedEmployees: [],
      selectedRoles: [],
      selectedSeniorities: [],
      graphType: 0,
      showSaturday: false,
      showSunday: false,
    };
  },
  created() {
    this.$store.dispatch("user_indicator/fetch");
    this.selectedEmployees[0] = this.employeeData
      ? this.employeeData.map((employee) => employee.externalId)
      : [];

    this.selectedRoles[0] = this.roles.map((role) => role.id);
    this.selectedSeniorities[0] = this.seniorities.map((sen) => sen.id);
    for (let key in this.tigEmployeeDetails) {
      let index = parseInt(/[^-]*$/.exec(key)[0]);
      this.selectedEmployees[index] = this.tigEmployeeDetails[key]
        ? this.tigEmployeeDetails[key].map((employee) => employee.externalId)
        : [];
      this.selectedRoles[index] = this.roles.map((role) => role.id);
      this.selectedSeniorities[index] = this.seniorities.map((sen) => sen.id);
    }
  },
  computed: {
    sourceData() {
      let pd = deepCopy(this.projectData);
      pd.employeeData = this.employeeData;
      return this.workerTab === 0 ? pd : this.selectedTig;
    },
    endDate() {
      let end = new Date(
        this.sourceData?.endDate
          ? this.sourceData.endDate
          : this.sourceData?.dueDate
          ? this.sourceData.dueDate
          : null
      );
      if (end > new Date()) end = new Date();
      return end;
    },
    monthNumber() {
      return (
        Math.ceil(
          dayjs(this.endDate).diff(
            dayjs(this.projectData.startDate),
            "month",
            true
          )
        ) + 1
      );
    },
    months() {
      let months = [];

      let actYear = dayjs(this.projectData.startDate).year();
      let actMonth = dayjs(this.projectData.startDate).month();

      for (let i = 0; i < this.monthNumber; i++) {
        months.push(dayjs(new Date(actYear, actMonth, 1)).format("YYYY. MM."));
        actMonth++;
        if (actMonth > 12) {
          actMonth = 1;
          actYear++;
        }
      }

      return months;
    },
    monthlySelectedEmployeeData() {
      return this.monthlyEmployeeData.filter((med) =>
        this.selectedEmployeeObjects
          .map((seo) => seo.externalId)
          .includes(med.externalId)
      );
    },
    monthlyEmployeeData() {
      let distro = [];

      for (let employee of this.employees) {
        let issues = this.worklogs?.filter(
          (worklog) => worklog?.authorId === employee.externalId
        );

        let monthlyLogged = [];
        let monthlySpent = [];

        let actYear = dayjs(this.projectData.startDate).year();
        let actMonth = dayjs(this.projectData.startDate).month();

        for (let i = 0; i < this.monthNumber; i++) {
          let actualMonthIssues = issues.filter(
            (iss) =>
              dayjs(iss.day).year() === actYear &&
              dayjs(iss.day).month() === actMonth
          );

          monthlyLogged.push(
            actualMonthIssues.reduce(
              (partialSum, a) => partialSum + a.seconds,
              0
            )
          );
          monthlySpent.push(
            actualMonthIssues.reduce(
              (partialSum, a) =>
                partialSum + (a.seconds / 3600) * employee.avgRate,
              0
            )
          );

          actMonth++;
          if (actMonth > 11) {
            actMonth = 0;
            actYear++;
          }
        }

        distro.push(
          Object.assign(employee, {
            monthlyLogged: monthlyLogged,
            monthlySpent: monthlySpent,
          })
        );
      }
      return distro;
    },
    monthlyBarChartOptionsByEmployeeTime() {
      return {
        chart: {
          type: "bar",
          height: 350,
          stacked: true,
        },
        dataLabels: {
          enabled: false,
        },
        plotOptions: {
          bar: {
            borderRadius: 8,
            borderRadiusApplication: "end",
            borderRadiusWhenStacked: "last",
            dataLabels: {
              dataLabels: {
                total: {
                  enabled: true,
                  offsetX: 0,
                  style: {
                    fontSize: "13px",
                    fontWeight: 900,
                  },
                },
              },
            },
          },
        },
        stroke: {
          width: 0,
          show: false,
        },
        xaxis: {
          categories: this.months,
          labels: {
            style: {
              colors: "var(--text)",
            },
          },
        },
        yaxis: {
          title: {
            text: undefined,
          },
          labels: {
            formatter: function (val) {
              return workdayify(val / 28800);
            },
            style: {
              colors: "var(--text)",
            },
          },
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return secondToShortWorkDayFormat(val);
            },
          },
        },
        fill: {
          opacity: 1,
        },
        legend: {
          show: false,
        },
        colors: this.selectedEmployeeColors,
      };
    },
    monthlyBarChartOptionsByRoleTime() {
      return {
        chart: {
          type: "bar",
          height: 350,
          stacked: true,
        },
        dataLabels: {
          enabled: false,
        },
        plotOptions: {
          bar: {
            borderRadius: 8,
            borderRadiusApplication: "end",
            borderRadiusWhenStacked: "last",
            dataLabels: {
              dataLabels: {
                total: {
                  enabled: true,
                  offsetX: 0,
                  style: {
                    fontSize: "13px",
                    fontWeight: 900,
                  },
                },
              },
            },
          },
        },
        stroke: {
          width: 0,
          show: false,
        },
        xaxis: {
          categories: this.months,
          labels: {
            style: {
              colors: "var(--text)",
            },
          },
        },
        yaxis: {
          title: {
            text: undefined,
          },
          labels: {
            formatter: function (val) {
              return workdayify(val / 28800);
            },
            style: {
              colors: ["var(--text)"],
            },
          },
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return secondToShortWorkDayFormat(val);
            },
          },
        },
        fill: {
          opacity: 1,
        },
        legend: {
          show: false,
        },
        colors: this.selectedRoleColors,
      };
    },
    monthlyBarChartOptionsBySeniorityTime() {
      return {
        chart: {
          type: "bar",
          height: 350,
          stacked: true,
        },
        dataLabels: {
          enabled: false,
        },
        plotOptions: {
          bar: {
            borderRadius: 8,
            borderRadiusApplication: "end",
            borderRadiusWhenStacked: "last",
            dataLabels: {
              dataLabels: {
                total: {
                  enabled: true,
                  offsetX: 0,
                  style: {
                    fontSize: "13px",
                    fontWeight: 900,
                  },
                },
              },
            },
          },
        },
        stroke: {
          width: 0,
          show: false,
        },
        xaxis: {
          categories: this.months,
          labels: {
            style: {
              colors: "var(--text)",
            },
          },
        },
        yaxis: {
          title: {
            text: undefined,
          },
          labels: {
            formatter: function (val) {
              return workdayify(val / 28800);
            },
            style: {
              colors: ["var(--text)"],
            },
          },
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return secondToShortWorkDayFormat(val);
            },
          },
        },
        fill: {
          opacity: 1,
        },
        legend: {
          show: false,
        },
        colors: this.selectedSeniorityColors,
      };
    },
    monthlyBarChartDataByEmployees() {
      return this.monthlySelectedEmployeeData.map((md) => {
        return { name: md.name, data: md.monthlyLogged };
      });
    },
    monthlyBarChartOptionsByEmployeeMoney() {
      const self = this;
      return {
        chart: {
          type: "bar",
          height: 350,
          stacked: true,
        },
        dataLabels: {
          enabled: false,
        },
        plotOptions: {
          bar: {
            borderRadius: 8,
            borderRadiusApplication: "end",
            borderRadiusWhenStacked: "last",
            dataLabels: {
              dataLabels: {
                total: {
                  enabled: true,
                  offsetX: 0,
                  style: {
                    fontSize: "13px",
                    fontWeight: 900,
                  },
                },
              },
            },
          },
        },
        stroke: {
          width: 0,
          show: false,
        },
        xaxis: {
          categories: this.months,
          labels: {
            style: {
              colors: "var(--text)",
            },
          },
        },
        yaxis: {
          title: {
            text: undefined,
          },
          labels: {
            formatter: function (val) {
              return moneyify.call(self, CURRENCY_TIERS.LEVEL_ONE);
            },
            style: {
              colors: "var(--text)",
            },
          },
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return moneyify.call(self, Math.round(val));
            },
          },
        },
        fill: {
          opacity: 1,
        },
        legend: {
          show: false,
        },
        colors: this.selectedEmployeeColors,
      };
    },
    monthlyBarChartOptionsByRoleMoney() {
      const self = this;
      return {
        chart: {
          type: "bar",
          height: 350,
          stacked: true,
        },
        dataLabels: {
          enabled: false,
        },
        plotOptions: {
          bar: {
            borderRadius: 8,
            borderRadiusApplication: "end",
            borderRadiusWhenStacked: "last",
            dataLabels: {
              dataLabels: {
                total: {
                  enabled: true,
                  offsetX: 0,
                  style: {
                    fontSize: "13px",
                    fontWeight: 900,
                  },
                },
              },
            },
          },
        },
        stroke: {
          width: 0,
          show: false,
        },
        xaxis: {
          categories: this.months,
          labels: {
            style: {
              colors: "var(--text)",
            },
          },
        },
        yaxis: {
          title: {
            text: undefined,
          },
          labels: {
            formatter: function (val) {
              return moneyify.call(self, val, CURRENCY_TIERS.LEVEL_ONE);
            },
            style: {
              colors: "var(--text)",
            },
          },
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return moneyify.call(self, Math.round(val));
            },
          },
        },
        fill: {
          opacity: 1,
        },
        legend: {
          show: false,
        },
        colors: this.selectedRoleColors,
      };
    },
    monthlyBarChartOptionsBySeniorityMoney() {
      const self = this;
      return {
        chart: {
          type: "bar",
          height: 350,
          stacked: true,
        },
        dataLabels: {
          enabled: false,
        },
        plotOptions: {
          bar: {
            borderRadius: 8,
            borderRadiusApplication: "end",
            borderRadiusWhenStacked: "last",
            dataLabels: {
              dataLabels: {
                total: {
                  enabled: true,
                  offsetX: 0,
                  style: {
                    fontSize: "13px",
                    fontWeight: 900,
                  },
                },
              },
            },
          },
        },
        stroke: {
          width: 0,
          show: false,
        },
        xaxis: {
          categories: this.months,
          labels: {
            style: {
              colors: "var(--text)",
            },
          },
        },
        yaxis: {
          title: {
            text: undefined,
          },
          labels: {
            formatter: function (val) {
              return moneyify.call(self, val, CURRENCY_TIERS.LEVEL_ONE);
            },
            style: {
              colors: "var(--text)",
            },
          },
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return moneyify.call(self, Math.round(val));
            },
          },
        },
        fill: {
          opacity: 1,
        },
        legend: {
          show: false,
        },
        colors: this.selectedSeniorityColors,
      };
    },
    monthlyBarChartDataByEmployeesMoney() {
      return this.monthlySelectedEmployeeData.map((md) => {
        return { name: md.name, data: md.monthlySpent };
      });
    },
    monthlyBarChartDataByRoles() {
      return this.filteredRoles
        .filter((fr) => this.selectedRoles[this.selectionIndex].includes(fr.id))
        .map((fr) => {
          return { name: fr.name, data: fr.monthlyLogged };
        });
    },
    monthlyBarChartDataByRolesMoney() {
      return this.filteredRoles
        .filter((fr) => this.selectedRoles[this.selectionIndex].includes(fr.id))
        .map((fr) => {
          return { name: fr.name, data: fr.monthlySpent };
        });
    },
    monthlyBarChartDataBySeniorities() {
      return this.filteredSeniorities
        .filter((fs) =>
          this.selectedSeniorities[this.selectionIndex].includes(fs.id)
        )
        .map((fs) => {
          return { name: fs.name, data: fs.monthlyLogged };
        });
    },
    monthlyBarChartDataBySenioritiesMoney() {
      return this.filteredSeniorities
        .filter((fs) =>
          this.selectedSeniorities[this.selectionIndex].includes(fs.id)
        )
        .map((fs) => {
          return { name: fs.name, data: fs.monthlySpent };
        });
    },
    tigEmployeeDetails() {
      let tigs = {};
      for (let i in this.employeeData) {
        this.employeeData[i].segments.forEach((segment) => {
          if (!tigs[segment.identifier]) {
            tigs[segment.identifier] = [];
          }
          tigs[segment.identifier].push({
            externalId: this.employeeData[i].externalId,
            avgRate: this.employeeData[i].avgRate,
            timespent: segment.timespent,
            moneyspent: segment.moneyspent,
          });
        });
      }
      return tigs;
    },
    sortedTigs() {
      let sorted = deepCopy(this.projectData.tigDatas);
      if (!sorted) return [];
      sorted.forEach((tig) => {
        tig.employeeData = this.tigEmployeeDetails[tig.identifier];
      });
      return sorted.sort((a, b) =>
        a.key > b.key ? 1 : b.key > a.key ? -1 : 0
      );
    },
    selectedTig() {
      return this.sortedTigs[this.workerTab - 1];
    },
    selectedEmployeeColors() {
      return this.selectedEmployeeObjects.map((emp) => emp.color);
    },
    selectedRoleColors() {
      return this.filteredRoles
        .filter((r) =>
          this.selectedRoles[this.selectionIndex].some((rc) => r.id === rc)
        )
        .map((role) => role.color);
    },
    selectedSeniorityColors() {
      return this.filteredSeniorities
        .filter((s) =>
          this.selectedSeniorities[this.selectionIndex].some(
            (sc) => s.id === sc
          )
        )
        .map((sen) => sen.color);
    },
    selectedEmployeeObjects() {
      let array = this.employees;
      return array?.filter((employee) =>
        this.selectedEmployees[this.selectionIndex]?.includes(
          employee.externalId
        )
      );
    },
    employees() {
      let toReturn =
        !this.sourceData.employeeData || !this.sourceData.employeeData.length
          ? []
          : this.sourceData.employeeData;
      let count = 2;
      toReturn.forEach((emp) => {
        const hue = count++ * 137.508; // use golden angle approximation
        emp.color = `hsl(${hue}, 75%, ${Math.random() * 40 + 31}%)`;
      });
      return toReturn;
    },
    selectionIndex() {
      return this.workerTab === 0
        ? 0
        : parseInt(/[^-]*$/.exec(this.sourceData.identifier)[0]);
    },
    noEmployeesSelected() {
      return (
        !this.selectedEmployees[this.selectionIndex] ||
        this.selectedEmployees[this.selectionIndex].length === 0
      );
    },
    noRolesSelected() {
      return (
        !this.selectedRoles[this.selectionIndex] ||
        this.selectedRoles[this.selectionIndex].length === 0
      );
    },
    noSenioritiesSelected() {
      return (
        !this.selectedSeniorities[this.selectionIndex] ||
        this.selectedSeniorities[this.selectionIndex].length === 0
      );
    },
    noDataForEmployees() {
      return this.noEmployeesSelected;
    },
    noDataForRoles() {
      return this.noRolesSelected;
    },
    noDataForSeniorities() {
      return this.noRolesSelected;
    },
    roles() {
      let roles = [];
      let count = 44;
      for (let employee of this.employees) {
        let worker = this.workers.find(
          (w) => w.externalId === employee.externalId
        );
        if (worker.roleId) {
          let role = this.indicators.find((i) => i.id === worker.roleId);
          let prev = roles.find((r) => r.id === role.id);
          if (!!prev) {
            prev.timespent += employee.timespent;
            prev.moneyspent += employee.moneyspent;
            prev.employees.push(employee.externalId);
          } else {
            roles.push({
              id: role.id,
              name: role.name,
              timespent: employee.timespent,
              moneyspent: employee.moneyspent,
              color: `hsl(${count++ * 137.508}, 75%, ${
                Math.random() * 40 + 31
              }%)`,
              employees: [employee.externalId],
            });
          }
        }
      }
      return roles.sort((a, b) => (a.id > b.id ? 1 : b.id > a.id ? -1 : 0));
    },
    filteredRoles() {
      let filteredRoles = [];

      for (let i = 0; i < this.monthlyEmployeeData.length; i++) {
        let emp = this.monthlyEmployeeData[i];
        let worker = this.workers.find((w) => w.externalId === emp.externalId);
        let employeeRole = this.roles.find((r) => r.id === worker.roleId);

        if (employeeRole) {
          let previousFilteredRole = filteredRoles.find(
            (fr) => fr.id === employeeRole.id
          );

          if (previousFilteredRole) {
            previousFilteredRole.filteredEmployees.push(emp.externalId);
            previousFilteredRole.filteredTimespent += emp.timespent;
            previousFilteredRole.filteredMoneyspent += emp.moneyspent;
            for (let i = 0; i < this.monthNumber; i++) {
              previousFilteredRole.monthlyLogged[i] += emp.monthlyLogged[i];
              previousFilteredRole.monthlySpent[i] += emp.monthlySpent[i];
            }
          } else {
            filteredRoles.push(
              Object.assign(employeeRole, {
                filteredTimespent: emp.timespent,
                filteredMoneyspent: emp.moneyspent,
                filteredEmployees: [emp.externalId],
                monthlyLogged: [...emp.monthlyLogged],
                monthlySpent: [...emp.monthlySpent],
              })
            );
          }
        }
      }

      return filteredRoles.sort((a, b) =>
        a.id > b.id ? 1 : b.id > a.id ? -1 : 0
      );
    },
    seniorities() {
      let seniorities = [];
      let count = 44;
      for (let employee of this.employees) {
        let worker = this.workers.find(
          (w) => w.externalId === employee.externalId
        );
        if (worker.seniorityId) {
          let seniority = this.indicators.find(
            (i) => i.id === worker.seniorityId
          );
          let prev = seniorities.find((s) => s.id === seniority.id);
          if (!!prev) {
            prev.timespent += employee.timespent;
            prev.moneyspent += employee.moneyspent;
            prev.employees.push(employee.externalId);
          } else {
            seniorities.push({
              id: seniority.id,
              name: seniority.name,
              timespent: employee.timespent,
              moneyspent: employee.moneyspent,
              color: `hsl(${count++ * 137.508}, 75%, ${
                Math.random() * 40 + 31
              }%)`,
              employees: [employee.externalId],
            });
          }
        }
      }
      return seniorities.sort((a, b) =>
        a.id > b.id ? 1 : b.id > a.id ? -1 : 0
      );
    },
    filteredSeniorities() {
      let filteredSeniorities = [];

      for (let i = 0; i < this.monthlyEmployeeData.length; i++) {
        let emp = this.monthlyEmployeeData[i];
        let worker = this.workers.find((w) => w.externalId === emp.externalId);
        let employeeSeniority = this.seniorities.find(
          (r) => r.id === worker.seniorityId
        );

        if (employeeSeniority) {
          let previousFilteredSeniority = filteredSeniorities.find(
            (fr) => fr.id === employeeSeniority.id
          );

          if (previousFilteredSeniority) {
            previousFilteredSeniority.filteredEmployees.push(emp.externalId);
            previousFilteredSeniority.filteredTimespent += emp.timespent;
            previousFilteredSeniority.filteredMoneyspent += emp.moneyspent;
            for (let i = 0; i < this.monthNumber; i++) {
              previousFilteredSeniority.monthlyLogged[i] +=
                emp.monthlyLogged[i];
              previousFilteredSeniority.monthlySpent[i] += emp.monthlySpent[i];
            }
          } else {
            filteredSeniorities.push(
              Object.assign(employeeSeniority, {
                filteredTimespent: emp.timespent,
                filteredMoneyspent: emp.moneyspent,
                filteredEmployees: [emp.externalId],
                monthlyLogged: [...emp.monthlyLogged],
                monthlySpent: [...emp.monthlySpent],
              })
            );
          }
        }
      }

      return filteredSeniorities.sort((a, b) =>
        a.id > b.id ? 1 : b.id > a.id ? -1 : 0
      );
    },
    rolesRadialOptions() {
      return {
        chart: {
          height: 300,
          type: "donut",
        },
        stroke: {
          show: false,
          width: 0,
        },
        plotOptions: {
          pie: {
            donut: {
              size: "50%",
            },
            expandOnClick: false,
          },
        },
        legend: {
          show: false,
        },
        colors: this.filteredRoles.map((fc) => fc.color),
        labels: this.filteredRoles.map((fc) => fc.name),
        tooltip: {
          y: {
            formatter: function (val) {
              return secondToShortWorkDayFormat(val);
            },
          },
        },
      };
    },
    rolesRadialTimeData() {
      return this.filteredRoles.map((fc) => fc.filteredTimespent);
    },
    rolesRadialMoneyData() {
      return this.filteredRoles.map((fc) => fc.filteredMoneyspent);
    },
    senioritiesRadialOptions() {
      return {
        chart: {
          height: 300,
          type: "donut",
        },
        stroke: {
          show: false,
          width: 0,
        },
        plotOptions: {
          pie: {
            donut: {
              size: "50%",
            },
            expandOnClick: false,
          },
        },
        legend: {
          show: false,
        },
        colors: this.filteredSeniorities.map((fs) => fs.color),
        labels: this.filteredSeniorities.map((fs) => fs.name),
        tooltip: {
          y: {
            formatter: function (val) {
              return secondToShortWorkDayFormat(val);
            },
          },
        },
      };
    },
    senioritiesRadialTimeData() {
      return this.filteredSeniorities.map((fs) => fs.filteredTimespent);
    },
    senioritiesRadialMoneyData() {
      return this.filteredSeniorities.map((fs) => fs.filteredMoneyspent);
    },
    worklogs() {
      // fixme: tig based is broken due to identifier
      const base = this.issues(this.projectData.id)?.filter(
        (issue) => issue.worklogs?.length > 0
      );
      base?.forEach((issue) =>
        issue.worklogs?.forEach((worklog) => (worklog.key = issue.key))
      );
      return [].concat.apply(
        [],
        base?.map((issue) => issue.worklogs)
      );
    },
    ...mapGetters({
      workers: "employee/employees",
      indicators: "user_indicator/indicators",
    }),
    ...mapState({
      issues(_state, getters) {
        return getters["enterprise_core/issues"];
      },
    }),
  },
  methods: {
    selectEmployee(externalId) {
      let index =
        this.selectedEmployees[this.selectionIndex].indexOf(externalId);

      const oldArray = this.selectedEmployees[this.selectionIndex];
      if (index !== -1) {
        oldArray.splice(index, 1);
      } else {
        oldArray.push(externalId);
      }
      this.selectedEmployees[this.selectionIndex] = oldArray;
    },
    doSelectAllEmployees() {
      this.selectedEmployees[this.selectionIndex] = this.employees.map(
        (employee) => employee.externalId
      );
    },
    unSelectAllEmployees() {
      this.selectedEmployees[this.selectionIndex] = [];
    },
    doSelectAllRoles() {
      this.selectedRoles[this.selectionIndex] = this.roles.map(
        (role) => role.id
      );
    },
    unSelectAllRoles() {
      this.selectedRoles[this.selectionIndex] = [];
    },
    doSelectAllSeniorities() {
      this.selectedSeniorities[this.selectionIndex] = this.seniorities.map(
        (sen) => sen.id
      );
    },
    unSelectAllSeniorities() {
      this.selectedSeniorities[this.selectionIndex] = [];
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/assets/scss/colors.scss";

.not-selected {
  opacity: 0.6;
}

.not-selectable {
  opacity: 0.6;
  cursor: not-allowed;

  * {
    cursor: not-allowed;
    pointer-events: none;
  }
}

.circle {
  width: 50px;
  height: 80px;
  margin: -4px;
  border-radius: 0 8px 8px 0;
  box-shadow: 0 5px 30px rgb(48 52 62 / 10%);
}

.checkboxes {
  .is-flex {
    label {
      margin-right: 0;
    }
  }
}

.bg-lightgray {
  background: $grey-lighter;
}

.worker-table th,
.worker-table td:not(.has-dotted-bottom-border) {
  border: none;
  color: $custom-dark-blue;
}

.blank-row-small {
  height: 6px !important;
  line-height: 0;
}

.has-dotted-bottom-border {
  border-bottom: 1px dashed $grey-lighter;
}

.inactive-row > td:not(.checkbox-cell) {
  color: $custom-grayer-blue !important;
  opacity: 0.5;
}

.bg-black {
  background: $black;
  color: $white !important;
}
</style>
