<template>
  <div
    class="is-flex is-align-items-end"
    :style="{ height: `${maxHeight > 250 ? maxHeight + 100 : 310}px` }"
  >
    <div class="is-flex" id="graph">
      <timesheet-bar-graph
        v-for="(log, index) in logs"
        :key="index"
        :day="getDayName(log.day)"
        :date="getDayDate(log.day)"
        :logged="
          log.dailyTotalSeconds + log.dailyTotalOvertimeSeconds
            ? log.dailyTotalSeconds + log.dailyTotalOvertimeSeconds
            : 0
        "
        :expected="log.expected"
        :max-logged="maxLogged"
        :max-height="maxHeight"
        :class="{ 'ml-4': currentWeekIsDifferentThanPrevious(index) }"
      ></timesheet-bar-graph>
    </div>
    <div
      class="is-flex is-flex-direction-column-reverse is-justify-content-flex-end mb-auto graph-value-container"
    >
      <div
        v-for="(value, index) in graphValues"
        :key="index"
        :style="{ 'border-bottom': border(index), height: height(index) }"
        class="graph-value-container-container is-flex is-justify-content-flex-end"
      >
        <p class="graph-value-container-container-value is-align-self-flex-end">
          {{ value }} ó
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { DAY_NUMBER_SHORT_LABEL } from "@/utils/const";
import TimesheetBarGraph from "@/components/timesheet/tab/graph/TimesheetBarGraph";
import { getWeekOfMonth } from "@/utils/util";
import dayjs from "dayjs";

export default {
  name: "TimesheetBarGraphContainer",
  components: { TimesheetBarGraph },
  props: {
    logs: {
      type: Array,
      required: true,
    },
  },
  mounted() {
    this.graph = document.getElementById("graph");
  },
  data() {
    return {
      currentWeek: 0,
      graph: {},
    };
  },
  computed: {
    maxLogged() {
      return Math.max(
        ...this.logs.map((log) => {
          return (
            (log.dailyTotalSeconds ? log.dailyTotalSeconds : 0) +
            (log.dailyTotalOvertimeSeconds ? log.dailyTotalOvertimeSeconds : 0)
          );
        })
      );
    },
    graphValues() {
      const initial = [0, 2, 4, 6, 8];
      for (let i = 10; i <= this.maxLogged + 1; i += 2) {
        initial.push(i);
      }
      return initial;
    },
    maxHeight() {
      if (!this.maxLogged > 8) return 200;
      return this.maxLogged * 25;
    },
  },
  methods: {
    getDayName(date) {
      return this.$tf(DAY_NUMBER_SHORT_LABEL[new Date(date).getDay()]);
    },
    getDayDate(date) {
      return dayjs(date).format("MM. DD.");
    },
    currentWeekIsDifferentThanPrevious(index) {
      if (index === 0) return false;
      const thisWeekNumber = getWeekOfMonth(new Date(this.logs.at(index)?.day));
      const prevWeekNumber = getWeekOfMonth(
        new Date(this.logs.at(index - 1)?.day)
      );
      return thisWeekNumber !== prevWeekNumber;
    },
    border(index) {
      if (index === -1) return "none";
      if (this.graphValues.at(index) === 8)
        return "2px solid rgba(60, 176, 121, 0.4)";
      return "1px solid rgba(171, 157, 146, 0.2)";
    },
    height(index) {
      return this.graphValues.at(index) === 6 ? "49px" : "50px";
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/assets/scss/colors.scss";
.graph-value-container {
  padding-bottom: 44px;
  width: 100%;
  position: absolute;
  text-align: end;
  &-container {
    z-index: 1;
    width: 100%;
    &-value {
      color: $custom-grayer-blue;
    }
  }
}

#graph {
  gap: 9px;
  z-index: 2;
  overflow: auto;
}
</style>
