<template>
  <section
    class="has-text-centered is-flex is-justify-content-center is-align-items-center"
    :class="$style['w-100']"
  >
    <div>
      <h1 class="title">Hiba az oldal betöltése közben</h1>
      <h2 class="subtitle">
        Kérjük próbáld újra később, vagy vedd fel a kapcsolatot a
        rendszergazdával!
      </h2>
      <span class="icon" :class="$style['is-xxxlarge']">
        <i class="fas fa-6x fa-triangle-exclamation"></i>
      </span>
    </div>
  </section>
</template>

<script>
export default {
  name: "PortalError",
};
</script>
<style scoped module lang="scss">
.w-100 {
  width: 100%;
}
.is-xxxlarge {
  font-size: 3rem;
  height: 6rem;
  width: 6rem;
}
</style>
