<template>
  <div>
    <div>
      <b-tabs
        v-model="activeTab"
        :animated="false"
        type="is-toggle"
        position="is-centered"
      >
        <b-tab-item
          :label="$tf('reports.tab.monthlyWorksheet|Havi jelenléti ív')"
        >
          <div
            class="is-flex is-flex-direction-column has-gap-3 is-align-items-center"
          >
            <div>
              {{
                $tf("reports.monthlyWorksheet.selectMonth|Hónap kiválasztása")
              }}
            </div>
            <b-datepicker
              v-model="selectedDateMonthly"
              type="month"
              position="is-top-right"
              :placeholder="
                $tf(
                  'reports.mmonthlyWorksheet.selectMonth.placeholder|Kérlek, válassz hónapot...'
                )
              "
              icon="calendar-week"
              trap-focus
              :max-date="maxDate"
              :month-names="getMonthNames"
            >
            </b-datepicker>

            <b-field>
              <excel-exporter
                :url="
                  '/census/work-schedule/export/' +
                  selectedYearMonthly +
                  '/' +
                  selectedMonthMonthly
                "
                :filename="
                  'timesheet_report_' +
                  selectedYearMonthly +
                  '_' +
                  selectedMonthMonthly
                "
                :button-type="'is-dark'"
                :is-disabled="!selectedMonthMonthly"
                :icon-name="'download'"
              >
                {{
                  selectedDateMonthly
                    ? $tf(
                        "reports.monthlyWorksheet.button|{year} {month} havi jelenléti ív",
                        {
                          year: selectedYearMonthly,
                          month: getMonthName(selectedDateMonthly),
                        }
                      )
                    : $tf(
                        "reports.monthlyWorksheet.button.empty|Havi jelenléti ív"
                      )
                }}
              </excel-exporter>
            </b-field>
          </div>
        </b-tab-item>
        <b-tab-item :label="$tf('reports.tab.yearlyAbsences|Éves távollétek')">
          <div
            class="is-flex is-flex-direction-column has-gap-3 is-align-items-center"
          >
            <div>
              {{ $tf("reports.yearlyAbsences.selectYear|Év kiválasztása") }}
            </div>
            <b-select v-model="selectedYearYearly">
              <option
                v-for="(n, i) in maxDate.getFullYear() -
                minDate.getFullYear() +
                1"
                :key="i"
              >
                {{ i + minDate.getFullYear() }}
              </option>
            </b-select>
            <b-field>
              <excel-exporter
                :url="'/absence/absence-request/export/' + selectedYearYearly"
                :filename="'yearly_absence_report_' + selectedYearYearly"
                :button-type="'is-dark'"
                :is-disabled="!selectedYearYearly"
                :icon-name="'download'"
              >
                {{
                  $tf(
                    "reports.tab.yearlyAbsences.button|{year} évi távollétek",
                    { year: selectedYearYearly }
                  )
                }}
              </excel-exporter>
            </b-field>
          </div>
        </b-tab-item>
      </b-tabs>
    </div>
  </div>
</template>

<script>
import ExcelExporter from "@/components/module/export/ExcelExporter";
import { getMonthName } from "@/utils/util";

export default {
  components: { ExcelExporter },
  name: "ExcelReports",
  watch: {
    selectedDateMonthly() {
      this.selectedYearMonthly = this.selectedDateMonthly.getFullYear();
      this.selectedMonthMonthly = this.selectedDateMonthly.getMonth() + 1;
    },
  },
  data() {
    const today = new Date();
    const previousYear = 5;
    return {
      getMonthName,
      selectedDateMonthly: null,
      selectedYearMonthly: null,
      selectedMonthMonthly: null,
      selectedYearYearly: today.getFullYear(),
      minDate: new Date(
        today.getFullYear() - previousYear,
        today.getMonth(),
        today.getDate()
      ),
      maxDate: new Date(today.getFullYear(), today.getMonth(), today.getDate()),
      activeTab: 0,
    };
  },
  computed: {},
  methods: {
    getMonthNames() {
      return [
        this.$tf("month.january|Január"),
        this.$tf("month.february|Február"),
        this.$tf("month.march|Március"),
        this.$tf("month.april|Április"),
        this.$tf("month.may|Május"),
        this.$tf("month.june|Június"),
        this.$tf("month.july|Július"),
        this.$tf("month.august|Augusztus"),
        this.$tf("month.september|Szeptember"),
        this.$tf("month.october|Október"),
        this.$tf("month.november|November"),
        this.$tf("month.december|December"),
      ];
    },
  },
};
</script>
