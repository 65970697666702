import { mapGetters } from "vuex";

export default {
  async beforeMount() {
    await this.fetchIsSeatingFeatureEnabled();
  },
  data() {
    return {
      isSeatingFeatureEnabled: true,
    };
  },
  computed: {
    ...mapGetters({
      isLoggedIn: "session/isLoggedIn",
    }),
  },
  methods: {
    async fetchIsSeatingFeatureEnabled() {
      // FIXME:: disabling ui-configs endpoint
      // if (!this.$store.getters["uiConfigStore/isLoaded"] && this.isLoggedIn) {
      //   await this.$store.dispatch("uiConfigStore/fetchAll");
      // }

      this.isSeatingFeatureEnabled = true;
    },
  },
};
