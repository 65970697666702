<template>
  <div class="is-flex is-flex-direction-column has-gap-2">
    <div class="is-flex is-justify-content-space-between">
      <div class="is-flex has-gap-4">
        <div-with-loading
          loading-width="56px"
          loading-height="48px"
        ></div-with-loading>
        <div-with-loading
          loading-width="56px"
          loading-height="48px"
        ></div-with-loading>
      </div>
      <div-with-loading
        loading-width="40%"
        loading-height="48px"
      ></div-with-loading>
      <div>
        <div-with-loading
          loading-width="64px"
          loading-height="48px"
        ></div-with-loading>
      </div>
    </div>
    <div class="is-flex is-justify-content-space-between has-gap-2">
      <DivWithLoading
        v-for="n in 7"
        :key="n"
        loading-width="20%"
        loading-height="100px"
      ></DivWithLoading>
    </div>
    <div>
      <DivWithLoading
        v-for="n in 7"
        :key="n"
        loading-width="100%"
        loading-height="56px"
        class="mb-1"
      ></DivWithLoading>
    </div>
  </div>
</template>

<script>
import LoadingMixin from "@/mixins/LoadingMixin";
import { defineAsyncComponent } from "vue";

export default {
  name: "LoadingCalendar",
  components: {
    DivWithLoading: defineAsyncComponent(() =>
      import("@/components/loading/DivWithLoading")
    ),
  },
  mixins: [LoadingMixin],
};
</script>
