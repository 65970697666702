<template>
  <b-tab-item
    :label="$tf('employees.tabs.teams|Csapatok')"
    icon="users"
    type="is-primary"
  >
    <b-table
      :data="teams"
      ref="table"
      paginated
      per-page="30"
      detailed
      detail-key="identifier"
      :show-detail-icon="true"
      aria-next-label="Következő lap"
      aria-previous-label="Előző lap"
      aria-page-label="Lap"
      aria-current-label="Aktuális lap"
    >
      <!--
      <b-table-column
        field="identifier"
        :label="$tf('employees.table.identifier|Azonosító')"
        v-slot="props"
        sortable
        searchable
      >
        {{ props.row.identifier }} <br />
      </b-table-column>
      -->
      <b-table-column
        field="name"
        :label="$tf('employees.table.teamName|Csapat név')"
        v-slot="props"
        sortable
        searchable
      >
        {{ props.row.name }}
      </b-table-column>

      <b-table-column
        field="teamSize"
        :label="$tf('employees.table.teamSize|Létszám')"
        v-slot="props"
        searchable
      >
        {{ getTeamSize(props.row) }}
      </b-table-column>

      <template #detail="props">
        <div class="container content">
          <div v-for="leadType in ['Financial', 'Resource']" :key="leadType">
            <p class="subtitle">{{ leadType }}-lead:</p>
            <div
              class="is-flex is-flex-wrap-wrap is-justify-content-space-between mb-5"
            >
              <template v-if="!hasLead(props.row, leadType.toUpperCase())">
                <div class="is-flex-1 content has-text-grey has-text-centered">
                  <p><b-icon icon="frown" size="is-large"></b-icon></p>
                  <p>
                    {{
                      $tf(
                        "employees.teams.noLeadYet|Még nincsen {leadType}-lead a csapathoz rendelve",
                        { leadType: leadType.toLowerCase() }
                      )
                    }}
                  </p>
                </div>
              </template>
              <template v-else>
                <template v-for="lead in props.row.leads">
                  <div
                    class="is-flex-1"
                    v-if="lead.role === leadType.toUpperCase()"
                    :key="lead.employeeId"
                  >
                    <div class="media pb-2 pt-2">
                      <figure class="media-left mb-auto">
                        <p class="image is-64x64">
                          <img
                            class="is-rounded"
                            :src="
                              getEmp(lead.employeeId).avatar
                                ? getEmp(lead.employeeId).avatar
                                : 'https://cdn2.iconfinder.com/data/icons/social-flat-buttons-3/512/anonymous-512.png'
                            "
                            alt="User avatar"
                          />
                        </p>
                      </figure>
                      <div
                        class="media-content is-flex is-flex-direction-column is-justify-content-center"
                      >
                        <strong>{{ getEmp(lead.employeeId).name }}</strong>
                        <small>
                          {{ getEmp(lead.employeeId).email }}
                        </small>
                        <small>
                          {{
                            $filterPhone(
                              !!getEmp(lead.employeeId).contact
                                ? getEmp(lead.employeeId).contact
                                : getEmp(lead.employeeId).phone
                            )
                          }}
                        </small>
                      </div>
                    </div>
                  </div>
                </template>
              </template>
            </div>
          </div>

          <p class="subtitle">
            {{ $tf("employees.teams.teamMembers|Csapattagok:") }}
          </p>
          <div
            class="is-flex is-flex-wrap-wrap is-justify-content-space-between"
          >
            <template v-if="props.row.employees.length === 0">
              <div class="is-flex-1 content has-text-grey has-text-centered">
                <p><b-icon icon="frown" size="is-large"></b-icon></p>
                <p>
                  {{
                    $tf(
                      "employees.teams.noMembersYet|Még nincsenek csapattagok a csapathoz rendelve"
                    )
                  }}
                </p>
              </div>
            </template>
            <template v-else>
              <div
                v-for="emp in props.row.employees"
                :key="emp.employeeId"
                class="is-flex-1"
              >
                <user-info
                  :user="getEmp(emp.employeeId)"
                  displayMode="full"
                  tooltipPosition="bottom"
                />
              </div>
            </template>
          </div>
        </div>
      </template>
    </b-table>
  </b-tab-item>
</template>

<script>
import UserInfo from "@/components/module/info/UserInfo.vue";

export default {
  name: "EmployeeListTeamTab",
  components: { UserInfo },
  props: {
    teams: {
      type: Array,
      required: true,
    },
    employees: {
      type: Array,
      required: true,
    },
  },
  methods: {
    getEmp(empId) {
      let emp = this.employees.find((emp) => emp.id === empId);
      if (!emp) {
        console.warn("Can't find: " + empId);
        return {};
      } else {
        return emp;
      }
    },
    getTeamSize(row) {
      let employeeIds = [];
      row.leads.forEach((leads) => {
        let employeeId = leads.employeeId;
        if (!employeeIds.includes(employeeId)) {
          employeeIds.push(employeeId);
        }
      });
      row.employees.forEach((employee) => {
        let employeeId = employee.employeeId;
        if (!employeeIds.includes(employeeId)) {
          employeeIds.push(employeeId);
        }
      });
      return employeeIds.length;
    },
    hasLead(team, type) {
      return team.leads.some((lead) => lead.role === type);
    },
  },
};
</script>
