<template>
  <div>
    <b-table :data="tierCosts" :per-page="10" paginated v-if="loaded">
      <b-table-column
        field="tier.tier"
        :label="$tf('tiers.costs.table.tier|Szint')"
        sortable
        v-slot="props"
      >
        <label class="has-font-weight-500">{{ props.row.tier.tier }}</label>
      </b-table-column>

      <b-table-column
        field="cost"
        :label="$tf('tiers.costs.table.rate|Ár (bruttó órabér)')"
        sortable
        v-slot="props"
      >
        {{ props.row.cost }}
      </b-table-column>

      <b-table-column
        field="since"
        sortable
        :label="$tf('tiers.costs.table.validFrom|Érvényesség kezdete')"
        v-slot="props"
      >
        {{ $filterDateFormat(props.row.since) }}
      </b-table-column>

      <b-table-column v-slot="props" cell-class="do-not-wrap" width="32">
        <clickable-icon
          portal-icon
          icon="pencil"
          type="is-info"
          class="mr-2"
          @click="openEdit(props.row)"
        ></clickable-icon>
        <clickable-icon
          portal-icon
          icon="trash"
          type="is-danger"
          @click="deleteRow(props.row.id)"
        ></clickable-icon>
      </b-table-column>

      <template #empty>
        <section class="section">
          <div class="content has-text-grey has-text-centered">
            <p><b-icon icon="frown" size="is-large"></b-icon></p>
            <p>{{ $tf("tiers.costs.table.empty|Még nincsenek árszintek") }}</p>
          </div>
        </section>
      </template>
    </b-table>
    <LoadingSkeleton v-else />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { openDeleteConfirm } from "@/utils/util";
import ClickableIcon from "../../../../components/module/icon/ClickableIcon";
import TiersCostsEditModal from "../edit/TiersCostsEditModal";
import LoadingSkeleton from "../../../../components/module/loading/LoadingSkeleton";

export default {
  name: "TierCostsTab",
  components: { ClickableIcon, LoadingSkeleton },
  computed: {
    ...mapGetters({
      tierCosts: "enterprise_tiers/tierCosts",
    }),
  },
  data() {
    return {
      loaded: false,
    };
  },
  methods: {
    openEdit(row) {
      this.$buefy.modal.open({
        parent: this,
        component: TiersCostsEditModal,
        props: { tierCost: row },
        hasModalCard: true,
        trapFocus: true,
        events: {
          save: () => this.reloadPage(true),
        },
      });
    },
    async deleteRow(id) {
      openDeleteConfirm(this.$buefy, async () => {
        await this.$store.dispatch("enterprise_tiers/deleteTierCost", id);
        await this.reloadPage(true);
      });
    },
    async load(force) {
      await this.$store.dispatch("enterprise_tiers/fetchTierCosts", force);
    },
    async reloadPage(force) {
      await this.load(force);
    },
  },
  async mounted() {
    await this.load();
    this.loaded = true;
  },
};
</script>
