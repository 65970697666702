<!--suppress CssUnusedSymbol -->
<template>
  <div>
    <section v-if="commentedApproval && commentedApproval.length > 0">
      <template v-for="(comment, index) in commentedApproval">
        <article
          class="media"
          v-if="comment.absenceRequestId === absenceRequestId"
          :key="index"
        >
          <figure class="media-left">
            <!-- fixme: todo: ilyen egy avatár..? -->
            <template v-if="getAvatar(comment.createdBy) != null">
              <p class="image is-64x64">
                <img :src="getAvatar(comment.createdBy)" alt="User Avatar" />
              </p>
            </template>
            <template v-else>
              <p><b-icon icon="smile-beam" size="is-large"></b-icon></p>
            </template>
          </figure>
          <div class="media-content">
            <div class="content">
              <p>
                <strong> @{{ comment.user }} </strong>
                <small> [{{ formatDate(comment.created) }}] </small>
                <strong :class="getApprovalClass(comment.approval)">
                  {{ getApprovalDisplay(comment.approval) }}
                </strong>
                <br />
                {{ comment.comment }}
              </p>
            </div>
          </div>
        </article>
      </template>
    </section>
    <section v-else>
      {{
        $tf(
          "commentedApproval.nothingHappenedYet|Még nem történt semmi a szabadságkérelemmel."
        )
      }}
    </section>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { formatDate } from "@/utils/util";

export default {
  name: "CommentedApproval",
  mounted() {
    this.$store.dispatch("employee/fetchColleagues");
  },

  props: {
    absenceRequestId: null,
    reload: null,
  },
  data() {
    return {
      isLoading: true,
      avatars: {},
      formatDate,
    };
  },
  computed: {
    ...mapGetters({
      commentedApproval: "absence_request/commentedApproval",
      employees: "employee/employees",
    }),
  },

  watch: {
    async reload() {
      await this.$store.dispatch("absence_request/getCommentedApproval", {
        absenceRequestId: this.absenceRequestId,
        force: true,
      });
    },
  },
  created: async function () {
    await this.$store.dispatch("absence_request/getCommentedApproval", {
      absenceRequestId: this.absenceRequestId,
    });
  },

  methods: {
    getAvatar(user) {
      let employee = this.employees.filter((emp) => {
        return emp.id === user;
      });
      if (employee[0]) return employee[0].avatar;
      else return null;
    },
    getApprovalDisplay(approval) {
      switch (approval) {
        case "APPROVE":
          return "ELFOGADVA";
        case "DENY":
          return "ELUTASÍTVA";
        case "CLARIFY":
          return "EGYEZTETÉS SZÜKSÉGES";
      }
    },
    getApprovalClass(approvalDecision) {
      if (approvalDecision === "APPROVE") {
        return "green";
      } else if (approvalDecision === "DENY") {
        return "red";
      } else if (approvalDecision === "CLARIFY") {
        return "yellow";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/colors.scss";

.green {
  color: $green;
}
.red {
  color: $red;
}
.yellow {
  color: $yellow;
}
</style>
