<template>
  <div class="newForecastCard">
    <h3 class="title">
      {{ $tf("projectPage.forecastSinceStart.title|Eddig eltelt időszak") }}
    </h3>
    <div>
      <ForecastSinceStartTable
        class="is-flex-1"
        :project-details="projectDetails"
      ></ForecastSinceStartTable>
    </div>
  </div>
</template>

<script>
import StatusMixin from "../../../../../mixins/StatusMixin";
import ForecastSinceStartTable from "@/components/enterprise/project/module/forecast/ForecastSinceStartTable.vue";

export default {
  name: "ForecastSinceStartStatusOverview",
  components: {
    ForecastSinceStartTable,
  },
  mixins: [StatusMixin],
  props: {
    projectDetails: {
      type: Object,
      required: true,
    },
    projectData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
};
</script>
