<template>
  <b-modal
    has-modal-card
    trap-focus
    aria-role="dialog"
    :aria-label="label"
    :close-button-aria-label="$tf('moneyModal.close|Bezárás')"
    aria-modal
    @close="close"
    v-model="modalOpen"
  >
    <div class="card">
      <div class="card-content">
        <h3 class="title" v-if="editData">
          {{
            $tf("moneyModal.editTitle|{type} szerkesztése", { type: dataType })
          }}
        </h3>
        <h3 class="title" v-else>
          {{
            $tf("moneyModal.newTitle|{type} létrehozása", { type: dataType })
          }}
        </h3>
        <form>
          <div class="is-flex is-flex-direction-column">
            <template v-if="type === 'overrides'">
              <b-field
                :label="$tf('moneyModal.projectId|Projekt azonosító')"
                label-position="on-border"
              >
                <b-input disabled type="text" :value="projectId"></b-input>
              </b-field>
              <b-field
                :label="$tf('moneyModal.employee|Dolgozó')"
                label-position="on-border"
              >
                <b-autocomplete
                  rounded
                  v-model="name"
                  :data="filteredEmployees"
                  :placeholder="
                    $tf('moneyModal.employee.placeholder|pl. Elektrom Ágnes')
                  "
                  icon="search"
                  clearable
                  field="name"
                  @select="(option) => (modifiedData.userId = option.id)"
                >
                  <template #empty>{{
                    $tf("moneyModal.employee.empty|Nincs találat")
                  }}</template>
                </b-autocomplete>
              </b-field>
              <money-input
                :modelValue="modifiedData.cost ? modifiedData.cost : ''"
                :label="$tf('moneyModal.cost|Pontos óradíj')"
                label-position="on-border"
                @update:modelValue="(val) => (modifiedData.cost = val)"
              />
            </template>
            <template v-else>
              <b-field
                v-for="column in columns"
                :key="column.field"
                :label="column.label"
                label-position="on-border"
              >
                <template>
                  <template v-if="column.field === 'date'">
                    <b-datepicker
                      v-model="modifiedData.date"
                      :placeholder="$tf('moneyModal.choose|Válassz')"
                      icon="calendar-day"
                      trap-focus
                    >
                    </b-datepicker>
                  </template>
                  <template v-else-if="column.field === 'tigIdentifiers'">
                    <b-select
                      multiple
                      v-model="modifiedData[column.field]"
                      :native-size="tigs.length > 4 ? 4 : tigs.length"
                      expanded
                    >
                      <option
                        v-for="tig in tigs"
                        :key="tig.id"
                        :value="tig.identifier"
                      >
                        {{ tig.identifier }}
                      </option>
                    </b-select>
                  </template>
                  <template v-else-if="modifiedData[column.field] || !editData">
                    <money-input
                      :modelValue="
                        modifiedData[column.field]
                          ? modifiedData[column.field]
                          : ''
                      "
                      label-position="on-border"
                      @update:modelValue="
                        (val) => (modifiedData[column.field] = val)
                      "
                      v-if="column.mask"
                    />
                    <b-input
                      v-model="modifiedData[column.field]"
                      v-else
                      :type="column.field === 'summary' ? 'textarea' : null"
                    />
                  </template>
                </template>
              </b-field>
            </template>
          </div>
          <div class="is-flex is-justify-content-flex-end mt-4">
            <b-button
              native-type="button"
              type="is-info is-light"
              @click="close()"
            >
              {{ $tf("moneyModal.cancel|Mégse") }}
            </b-button>
            <b-button
              native-type="button"
              type="is-info"
              @click="submit(modifiedData)"
            >
              {{ $tf("moneyModal.save|Mentés") }}
            </b-button>
          </div>
        </form>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { deepCopy } from "@/utils/util";
import MoneyInput from "@/components/module/input/MoneyInput";

export default {
  name: "MoneyModal",
  components: {
    MoneyInput,
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    show: {
      type: Boolean,
      required: true,
    },
    editData: {
      type: Object,
      required: false,
    },
    type: {
      type: String,
      required: true,
    },
    employees: {
      type: Array,
      required: false,
    },
    tigs: {
      type: Array,
      required: false,
    },
    modify: {
      type: Function,
      required: false,
    },
    create: {
      type: Function,
      required: false,
    },
    dataType: {
      type: String,
      required: true,
    },
    columns: {
      type: Array,
      required: true,
    },
    projectId: {
      type: Number,
      required: false,
    },
  },
  mounted() {
    if (this.type === "invoices") {
      this.modifiedData.tigIdentifiers = this.modifiedData.tigIdentifiers ?? [];
    }
  },
  watch: {
    editData() {
      this.modifiedData = deepCopy(this.editData);
      if (this.modifiedData.date) {
        this.modifiedData.date = new Date(this.modifiedData.date);
      }

      if (this.type === "invoices") {
        this.modifiedData.tigIdentifiers =
          this.modifiedData.tigIdentifiers ?? [];
      }
    },
    show(newVal) {
      this.modalOpen = newVal;
    },
  },
  data() {
    return {
      modifiedData: {},
      name: "",
      modalOpen: false,
    };
  },
  computed: {
    filteredEmployees() {
      let employees = this.employees || [];
      return employees.filter((element) => {
        if (!this.name || !element.name || !element.username) return false;
        return (
          element.name
            .toString()
            .toLowerCase()
            .indexOf(
              this.name
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .toLowerCase()
            ) >= 0 ||
          element.username
            .toString()
            .toLowerCase()
            .indexOf(
              this.name
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .toLowerCase()
            ) >= 0
        );
      });
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
    submit(data) {
      if (this.modify) this.modify(data);
      else if (this.create) this.create(data);
      this.close();
    },
  },
};
</script>

<style scoped lang="scss">
.is-flex {
  gap: 15px;
}
</style>
