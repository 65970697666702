import { newLogger } from "@/utils/util";
import StoreCore from "../../../StoreCore";

let logger = newLogger("QuotaStore");

const initState = () => {
  return {
    waitingRequests: null,
  };
};

const storeCore = new StoreCore();
const actions = {
  getWaitingRequests: async function ({ commit, dispatch }, force) {
    try {
      let data = await this.remoteRequest(
        "get",
        "absence/absence-request/waiting-numbers",
        null,
        true,
        dispatch,
        "Fetching waiting request numbers...",
        null,
        force
      );
      commit("setWaitingRequests", data);
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  clearCache: async function ({}) {
    this.clearCoreCache();
  }.bind(storeCore),
};

const mutations = {
  setWaitingRequests: (state, waitingRequests) => {
    state.waitingRequests = waitingRequests;
  },
};

const getters = {
  waitingRequests: (state) => {
    return state.waitingRequests;
  },
};

export default {
  namespaced: true,
  state: initState(),
  mutations: mutations,
  actions: actions,
  getters: getters,
};
