import { newLogger } from "@/utils/util";
import StoreCore from "../../../StoreCore";

let logger = newLogger("Census-UserIndicator");

const urls = {
  indicatorWithId: (id) => `/census/users/indicator/${id}`,
  indicator: "/census/users/indicator",
  indicators: "/census/users/indicators",
  tenantIndicators: (id) => `/tenant/cross/census/users/indicators/${id}`,
};

const initState = () => {
  return {
    indicators: [],
  };
};

const storeCore = new StoreCore();
const actions = {
  fetch: async function ({ commit, dispatch }, force) {
    try {
      let data = await this.remoteRequest(
        "get",
        urls.indicators,
        null,
        true,
        dispatch,
        "Felhasználó attribútumok letöltése...",
        null,
        force
      );
      commit("saveIndicators", data);
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  fetchForTenant: async function ({ commit, dispatch }, tenantId) {
    try {
      let data = await this.remoteRequest(
        "get",
        urls.tenantIndicators(tenantId),
        null,
        true,
        dispatch,
        "Felhasználó attribútumok letöltése...",
        null,
        true
      );
      commit("saveIndicators", data);
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  update: async function ({ dispatch }, indicator) {
    try {
      await this.remoteRequest(
        "put",
        urls.indicatorWithId(indicator.id),
        indicator,
        false,
        dispatch,
        "Felhasználó attribútum frissítése..."
      );
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  create: async function ({ dispatch }, request) {
    try {
      await this.remoteRequest(
        "post",
        urls.indicator,
        request,
        false,
        dispatch,
        "userIndicator.create.flair|Felhasználó attribútum létrehozása",
        undefined,
        undefined,
        false,
        true,
        {
          progress:
            "userIndicator.create.progress|Felhasználó attribútum létrehozása folyamatban...",
          fail: "userIndicator.create.fail|Felhasználó attribútum létrehozása sikertelen!",
          success:
            "userIndicator.create.success|Felhasználó attribútum létrehozása sikeres!",
        }
      );
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  delete: async function ({ dispatch }, id) {
    try {
      await this.remoteRequest(
        "delete",
        urls.indicatorWithId(id),
        null,
        false,
        dispatch,
        "userIndicator.delete.flair|Felhasználó attribútum törlése",
        undefined,
        undefined,
        false,
        true,
        {
          progress:
            "userIndicator.delete.progress|Felhasználó attribútum törlése folyamatban...",
          fail: "userIndicator.delete.fail|Felhasználó attribútum törlése sikertelen!",
          success:
            "userIndicator.delete.success|Felhasználó attribútum törlése sikeres!",
        }
      );
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  clearCache: async function ({}) {
    this.clearCoreCache();
  }.bind(storeCore),
};

const mutations = {
  saveIndicators: (state, indicators) => {
    state.indicators = indicators;
  },
};

const getters = {
  indicators: (state) => {
    return state.indicators;
  },
};

export default {
  namespaced: true,
  state: initState(),
  mutations: mutations,
  actions: actions,
  getters: getters,
};
