<template>
  <div class="modal-card" style="width: auto">
    <header
      class="modal-card-head is-flex-direction-column is-align-items-start pb-0"
    >
      <div class="is-flex is-align-items-center has-gap-4">
        <OnboardingMassSettingIcon />
        <h3
          style="font-size: 24px"
          class="my-3 has-text-black has-font-weight-500"
        >
          Egységes beállítás
        </h3>
      </div>
      <p class="font-size:12px;color:darkgrey;">
        A kiválasztott adatok az összes kijelölt alkalmazottra beállításra
        kerülnek. Az üres mezők nem kerülnek felülírásra.
      </p>
      <table class="mass-setting-table">
        <tr>
          <th>Szerepkör</th>
          <th>Munkakör</th>
          <th>Szenioritás</th>
          <th>Kompetencia</th>
          <th>Terület</th>
          <th>Árszint</th>
        </tr>
        <tr>
          <td>
            <b-select v-model="massSettings.roleId" size="is-small" expanded>
              <option :value="null">nincs érték</option>
              <option v-for="role in roles" :value="role.id" :key="role.id">
                {{ role.name }}
              </option>
            </b-select>
          </td>
          <td>
            <b-select v-model="massSettings.title" size="is-small" expanded>
              <option :value="null">nincs érték</option>
              <option
                v-for="title in competencies.titles"
                :value="title"
                :key="title"
              >
                {{ title }}
              </option>
            </b-select>
          </td>
          <td>
            <b-select v-model="massSettings.seniority" size="is-small" expanded>
              <option :value="null">nincs érték</option>
              <option
                v-for="seniority in competencies.seniorities"
                :value="seniority"
                :key="seniority"
              >
                {{ seniority }}
              </option>
            </b-select>
          </td>
          <td>
            <b-select
              v-model="massSettings.competency"
              size="is-small"
              expanded
            >
              <option :value="null">nincs érték</option>
              <option
                v-for="competency in competencies.competencies"
                :value="competency"
                :key="competency"
              >
                {{ competency }}
              </option>
            </b-select>
          </td>
          <td>
            <b-select
              v-model="massSettings.department"
              size="is-small"
              expanded
            >
              <option :value="null">nincs érték</option>
              <option
                v-for="department in competencies.department"
                :value="department"
                :key="department"
              >
                {{ department }}
              </option>
            </b-select>
          </td>
          <td>
            <b-select v-model="massSettings.tierId" size="is-small" expanded>
              <option :value="null">nincs érték</option>
              <option
                v-for="priceLevel in priceLevels"
                :value="priceLevel.id"
                :key="priceLevel.id"
              >
                {{ priceLevel.name }}
              </option>
            </b-select>
          </td>
        </tr>
      </table>
      <h3 class="has-font-weight-500 has-text-black">
        Válaszd ki, mely felhasználókra szeretnéd alkalmazni a beállításaid
      </h3>
    </header>
    <section class="modal-card-body pt-0">
      <div class="onboarding-modal-body pt-0">
        <div class="my-3" style="max-width: 300px">
          <b-field label="Keresés a névben" label-position="on-border">
            <b-input size="is-small" v-model="searchCriterion" />
          </b-field>
        </div>
        <div class="mass-setting-user-list">
          <div>
            <b-checkbox
              :indeterminate="
                selectedUserIds.length > 0 &&
                users.length > selectedUserIds.length
              "
              :modelValue="users.length === selectedUserIds"
              @update:modelValue="(value) => handleGroupSelection(value)"
              class="onboarding-checkbox-thin"
            >
              Összes kijelölése
            </b-checkbox>
          </div>
          <div v-for="user in filteredUsers" :key="user.id">
            <b-checkbox
              v-model="selectedUserIds"
              :native-value="user.id"
              class="onboarding-checkbox-thin"
            >
              {{ user.name }}
            </b-checkbox>
          </div>
        </div>
      </div>
    </section>
    <footer class="modal-card-foot mass-setting-modal-footer">
      <b-button
        class="onboarding-button onboarding-button-transparent onboarding-button-small"
        @click="instance.emit('close')"
        label="Mégse"
      />
      <b-button
        class="onboarding-button onboarding-button-small"
        label="Alkalmaz"
        type="is-primary"
        @click="applyMassSettings(false)"
      />
      <b-button
        class="onboarding-button onboarding-button-small"
        label="Alkalmaz és bezár"
        type="is-primary"
        @click="applyMassSettings(true)"
      />
    </footer>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { Vue, getCurrentInstance } from "vue";
import OnboardingMassSettingIcon from "@/components/onboarding/OnboardingMassSettingIcon.vue";
import { cloneDeep } from "lodash";

export default {
  name: "OnboardingSetupUserMassSettings",
  setup: () => {
    const instance = getCurrentInstance();
    return {
      instance: instance,
    };
  },
  components: { OnboardingMassSettingIcon },
  props: {
    users: {
      type: Array,
    },
  },
  computed: {
    filteredUsers() {
      return this.users.filter(({ name }) =>
        name
          .toLowerCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .includes(
            this.searchCriterion
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
              .toLowerCase()
          )
      );
    },
    ...mapGetters({
      roles: "onboarding/getUsedRoles",
      competencies: "onboarding/getCompetencies",
      priceLevels: "onboarding/getTiers",
    }),
  },
  data: function () {
    return {
      selectedUserIds: [],
      searchCriterion: "",
      massSettings: {
        roleId: null,
        title: null,
        competency: null,
        seniority: null,
        department: null,
        tierId: null,
      },
    };
  },
  methods: {
    async applyMassSettings(toClose) {
      this.users
        .filter((u) => this.selectedUserIds.includes(u.id))
        .forEach((u) => {
          Object.keys(this.massSettings).forEach((massSettingKey) => {
            if (this.massSettings[massSettingKey]) {
              // Vue.set(
              //   this.users[this.users.indexOf(u)],
              //   massSettingKey,
              //   this.massSettings[massSettingKey]
              // );
              // eslint-disable-next-line vue/no-mutating-props
              this.users[this.users.indexOf(u)][massSettingKey] =
                this.massSettings[massSettingKey];
            }
          });
        });

      const userCopy = cloneDeep(this.users);

      userCopy.forEach((user) => {
        if ((user.role && user.roleId !== user.role.id) || !user.role) {
          user.role = this.roles.find((r) => r.id === user.roleId);
        }
        if (
          (user.priceTier && user.tierId !== user.priceTier.id) ||
          !user.priceTier
        ) {
          user.priceTier = this.priceLevels.find((pl) => pl.id === user.tierId);
        }
        delete user.roleId;
        delete user.tierId;
      });

      await this.$store.dispatch("onboarding/updateUsers", userCopy);

      if (toClose) {
        this.instance.emit("close");
      }
    },
    handleGroupSelection(value) {
      if (value) {
        this.selectedUserIds = this.users.map((u) => u.id);
      } else {
        this.selectedUserIds = [];
      }
    },
  },
};
</script>

<style scoped>
.mass-setting-modal-footer {
  border-top: 1px solid #cad3de;
  justify-content: flex-end;
}

.mass-setting-table > tr > th {
  border-bottom: 1px solid #cad3de;
  color: #a19e97;
  font-weight: normal;
  font-size: 14px;
}

.mass-setting-table > tr > td,
.mass-setting-table > tr > th {
  font-size: 14px;
  font-weight: 500;
  padding: 8px 20px;
  min-width: 200px;
}

.onboarding-modal-body {
  padding: 16px 32px;
}

.mass-setting-user-list {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-row-gap: 0.5em;
  grid-column-gap: 1em;
}
</style>
