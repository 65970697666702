<template>
  <b-collapse
    class="card container"
    animation="slide"
    aria-id="config-messages-collapse"
  >
    <template #trigger="props">
      <div class="card-header" role="button" :aria-expanded="props.open">
        <p class="card-header-title m-0">
          Konfigurációs hibák / Karbantartási hiányosságok vannak a projektben!
        </p>
        <a class="card-header-icon">
          <b-icon :icon="props.open ? 'chevron-up' : 'chevron-down'" />
        </a>
      </div>
    </template>
    <div class="card-content">
      <article class="panel is-danger" v-if="numberOfCritical">
        <p class="panel-heading">
          Kritikus Konfigurációs hibák ({{ numberOfCritical }}db)
        </p>
        <b-message v-if="coreDataMissing" type="is-danger" has-icon>
          A rendszer alapvető működéséhez szükséges adatok nem kitöltöttek!
          Kérjük töltsd ki a Határidő, Büdzsé, Vállalás, Bevétel mezőket!
        </b-message>
        <b-message v-if="noTigs" type="is-danger" has-icon>
          A Projekten nem találhatóak még mérföldkövek. Jegyek kizárólag
          Mérföldkövekhez köthetőek, kérjük vegyél fel legalább egyet!
        </b-message>
        <b-message
          v-if="tigTargetingMissing && !isSelfTracking"
          type="is-danger"
          has-icon
        >
          A Projektben található olyan Mérföldkő, melyhez nincs jegyekre
          vonatkozó célzás beállítva. Az ilyen Mérföldkövekkel a Rendszer nem
          tud érdemi számításokat végezni!
        </b-message>
        <b-message v-if="problematicIssues" type="is-danger" has-icon>
          A projekt egyes jegyein található logolás összeg nem egyezik a rájuk
          vonatkozó tényleges időlogok összegével! <br />
          Az érintett jegyek:
          <strong v-for="i in projectDetails.problematic" v-bind:key="i">
            {{ i }}
          </strong>
        </b-message>
        <template v-if="!loading">
          <b-message v-if="potentialUnknownsPresent" type="is-danger" has-icon>
            Léteznek olyan ismeretlen logolások a rendszerben, melyek prefixe
            megegyezik ennek a projektnek a prefixével! Ellenőrizd hogy az
            alábbi jegyeket a Mérföldkövek célzásának (vagy a jegyek
            attribútumainak) megfelelő módosításával ehhez a projekthez
            kellene-e kötni!
            <br />
            <strong
              v-for="u in unknown(projectData.prefix)"
              v-bind:key="u.issueKey"
              >{{ u.issueKey }},
            </strong>
          </b-message>
          <b-message v-if="sharedIssuesPresent" type="is-danger" has-icon>
            Léteznek olyan jegyek, melyeket több mérföldkő / projekt is
            figyelembe vesz! Ellenőrizd, hogy az alábbi jegyeket célzó
            Mérföldkövek beállítása helyes-e!
            <br />
            <strong
              v-for="u in shared(projectData.prefix)"
              v-bind:key="u.issueKey"
            >
              {{ u.issueKey }}
              <b-tooltip :label="u.tigName"
                ><b-icon
                  icon="exclamation-triangle"
                  size="is-small"
                  class="untracked-triangle"
              /></b-tooltip>
            </strong>
          </b-message>
        </template>
      </article>
      <article class="panel is-warning" v-if="numberOfAmber">
        <p class="panel-heading">Figyelmeztetések ({{ numberOfAmber }}db)</p>
        <b-message v-if="tigTicketsMissing" type="is-warning" has-icon>
          A Projektben található olyan Mérföldkő, melyhez a célzása mentén nem
          tartozik egyetlen jegy sem. Biztos hogy helyesen állítottad be a
          célzást?
        </b-message>
        <b-message v-if="readinessEstimation" type="is-warning" has-icon>
          A Projekt Készültségét kalkuláló automata módszerek már 100% felettire
          számolnák a Készültséget! Ez jelentős túlköltésre vagy rossz
          beállításokra utal!
        </b-message>
        <b-message v-if="billsMissing" type="is-warning" has-icon>
          Léteznek lezárt Mérföldkövek a Projekten, de nincs Bevétel (Számla)
          rögzítve a projektre! Kérjük pótold az elmaradást a bevételek
          megfelelő számításához.
        </b-message>
        <b-message v-if="unestimatedCount" type="is-warning" has-icon>
          A Projekt jegyeinek {{ unestimatedCount }}%-án nincsen becslés! A
          becslés nélküli jegyek rontják a Forecast működését és növelik a
          Projekt kockázatát!
        </b-message>
        <b-message v-if="estimatedCommitment" type="is-warning" has-icon>
          A Projekt jegyein lévő becslések összessége
          {{ estimatedCommitment }}%-al eltér a Vállalástól! A Forecast és a
          Készültséget számoló funkciók ekkora eltérésnél nem tudnak
          megbízhatóan működni!
        </b-message>
      </article>
      <article class="panel is-info" v-if="numberOfTodo">
        <p class="panel-heading">
          Karbantartási teendők ({{ numberOfTodo }}db)
        </p>
        <b-message v-if="badBudget" type="is-info" has-icon>
          A Projektre beállított Büdzsé ({{
            moneyify(this.projectDetails.budget)
          }}) ~ {{ badBudget }}%-al eltér a Vállalásból az Általános Óradíjjal
          számított értéktől, ami
          {{ moneyify(this.projectDetails.commitmentBudget) }}) lenne!
        </b-message>
        <b-message v-if="budgetTigSumMismatch" type="is-info" has-icon>
          A Projekt Mérföldköveiben beállított Büdzsék összege
          {{ moneyify(budgetTigSumDiff) }}, ami több mint 10%-al eltér a
          Projektre beállított Büdzsétől!
        </b-message>
        <b-message v-if="commitmentTigSumMismatch" type="is-info" has-icon>
          A Projekt Mérföldköveiben beállított Vállalások összege
          {{ workdayify(commitmentTigSumDiff) }}, ami több mint 10%-al eltér a
          Projektre beállított Vállalástól!
        </b-message>
        <b-message v-if="incomeTigSumMismatch" type="is-info" has-icon>
          A Projekt Mérföldköveiben beállított Számlázható Összegek összege
          {{ moneyify(incomeTigSumDiff) }}, ami több mint 10%-al eltér a
          Projektre beállított előirányzott Bevételtől!
        </b-message>
        <b-message v-if="pastDue" type="is-info" has-icon>
          A Projekt határideje már ~ {{ pastDue }} napja lejárt, de még nincs
          lezárva!
        </b-message>
        <b-message v-if="pastDueTig" type="is-info" has-icon>
          A Projektben létezik lezáratlan, több mint két hete lejárt határidejű
          Mérföldkő!
        </b-message>
      </article>
    </div>
  </b-collapse>
</template>

<script>
import { moneyify, workdayify } from "@/utils/util";
import { mapGetters } from "vuex";
import { ISSUE_TRACKING_SYSTEM } from "@/utils/const";

export default {
  name: "ConfigurationWarnings",
  components: {},
  mixins: [],
  props: {
    projectDetails: {
      type: Object,
      required: true,
    },
    projectData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      moneyify,
      workdayify,
      loading: true,
    };
  },
  mounted() {
    this.fetchData();
  },
  computed: {
    isSelfTracking() {
      return this.issueTrackingSystem === ISSUE_TRACKING_SYSTEM.SELF.enum;
    },
    ...mapGetters({
      shared: "risk_management/getSharedIssuesFiltered",
      unknown: "risk_management/getUnknownIssuesFiltered",
      issueTrackingSystem: "uiConfigStore/getIssueTrackingSystem",
    }),
    numberOfCritical() {
      let number = 0;
      if (this.coreDataMissing) ++number;
      if (this.noTigs) ++number;
      if (this.tigTargetingMissing && !this.isSelfTracking) ++number;
      if (this.potentialUnknownsPresent) ++number;
      if (this.sharedIssuesPresent) ++number;
      if (this.problematicIssues) ++number;
      return number;
    },
    numberOfAmber() {
      let number = 0;
      if (this.tigTicketsMissing) ++number;
      if (this.readinessEstimation) ++number;
      if (this.unestimatedCount) ++number;
      if (this.estimatedCommitment) ++number;
      return number;
    },
    numberOfTodo() {
      let number = 0;
      if (this.badBudget) ++number;
      if (this.budgetTigSumMismatch) ++number;
      if (this.commitmentTigSumMismatch) ++number;
      if (this.incomeTigSumMismatch) ++number;
      if (this.pastDue) ++number;
      if (this.pastDueTig) ++number;
      return number;
    },
    coreDataMissing() {
      return (
        !this.projectData.dueDate ||
        !this.projectDetails.budget ||
        !this.projectDetails.commitment ||
        !this.projectDetails.expectedIncome
      );
    },
    noTigs() {
      return (
        !this.projectDetails.tigDetails ||
        !this.projectDetails.tigDetails.length
      );
    },
    potentialUnknownsPresent() {
      return !!this.unknown(this.projectData.prefix).length;
    },
    sharedIssuesPresent() {
      return !!this.shared(this.projectData.prefix).length;
    },
    problematicIssues() {
      return (
        this.projectDetails &&
        this.projectDetails.problematic &&
        !!this.projectDetails.problematic.length
      );
    },
    badBudget() {
      if (
        Math.abs(
          this.projectDetails.budget - this.projectDetails.commitmentBudget
        ) >
        this.projectDetails.budget * 0.25
      ) {
        return (
          Math.round(
            (Math.abs(
              this.projectDetails.budget - this.projectDetails.commitmentBudget
            ) /
              this.projectDetails.budget) *
              10000
          ) / 100
        );
      } else return false;
    },
    budgetTigSumMismatch() {
      if (this.noTigs || this.coreDataMissing) return false;
      let tigSum = this.projectDetails.tigDetails.reduce(
        (acc, cur) => acc + cur.budget,
        0
      );
      return (
        Math.abs(this.projectDetails.budget - tigSum) >
        this.projectDetails.budget * 0.1
      );
    },
    budgetTigSumDiff() {
      let tigSum = this.projectDetails.tigDetails.reduce(
        (acc, cur) => acc + cur.budget,
        0
      );
      return Math.abs(this.projectDetails.budget - tigSum);
    },
    commitmentTigSumMismatch() {
      if (this.noTigs || this.coreDataMissing) return false;
      let tigSum = this.projectDetails.tigDetails.reduce(
        (acc, cur) => acc + cur.commitment,
        0
      );
      return (
        Math.abs(this.projectDetails.commitment - tigSum) >
        this.projectDetails.commitment * 0.1
      );
    },
    commitmentTigSumDiff() {
      let tigSum = this.projectDetails.tigDetails.reduce(
        (acc, cur) => acc + cur.commitment,
        0
      );
      return Math.abs(this.projectDetails.commitment - tigSum);
    },
    incomeTigSumMismatch() {
      if (this.noTigs || this.coreDataMissing) return false;
      let tigSum = this.projectDetails.tigDetails.reduce(
        (acc, cur) => acc + cur.billableAmount,
        0
      );
      return (
        Math.abs(this.projectDetails.expectedIncome - tigSum) >
        this.projectDetails.expectedIncome * 0.1
      );
    },
    incomeTigSumDiff() {
      let tigSum = this.projectDetails.tigDetails.reduce(
        (acc, cur) => acc + cur.billableAmount,
        0
      );
      return Math.abs(this.projectDetails.expectedIncome - tigSum);
    },
    tigTargetingMissing() {
      if (this.noTigs || this.coreDataMissing) return false;
      return this.projectData.tigDatas.some(
        (tig) =>
          tig.type !== "UNTRACKED" &&
          (!tig.externalIssueTargeting || !tig.externalIssueTargeting.link)
      );
    },
    tigTicketsMissing() {
      if (this.noTigs || this.coreDataMissing) return false;
      return this.projectDetails.tigDetails.some(
        (tig) =>
          tig.status !== "PLANNED" &&
          tig.externalIssueTargeting?.link &&
          !tig.issueCount
      );
    },
    billsMissing() {
      if (this.noTigs || this.coreDataMissing) return false;
      let billed = this.projectDetails.tigDetails.some(
        (tig) => tig.status === "BILLED" || tig.status === "COMPLETED"
      );
      return billed && !this.projectDetails.invoicedIncome;
    },
    unestimatedCount() {
      if (this.noTigs || this.coreDataMissing) return false;
      let unestimatedRate =
        Math.round(
          (Math.abs(
            this.projectDetails.unestimatedIssueCount -
              this.projectDetails.issueCount
          ) /
            this.projectDetails.issueCount) *
            10000
        ) / 100;
      return unestimatedRate > 20 ? unestimatedRate : false;
    },
    readinessEstimation() {
      if (this.noTigs || this.coreDataMissing) return false;
      return (
        this.projectDetails.projectReadinessMap.BASIC > 0.989 ||
        this.projectDetails.projectReadinessMap.ESTIMATED > 0.989 ||
        this.projectDetails.projectReadinessMap.EDUCATED > 0.989
      );
    },
    estimatedCommitment() {
      if (this.noTigs || this.coreDataMissing) return false;
      let diffRate =
        Math.round(
          (Math.abs(
            this.projectDetails.sumEstimateMd - this.projectDetails.commitment
          ) /
            this.projectDetails.commitment) *
            10000
        ) / 100;
      return diffRate > 20 ? diffRate : false;
    },
    pastDue() {
      if (
        this.coreDataMissing ||
        this.projectData.status === "CANCELLED" ||
        this.projectData.status === "DONE" ||
        this.projectData.status === "CLOSED"
      )
        return false;
      let diff = new Date() - new Date(this.projectData.dueDate);
      if (diff > 1000 * 60 * 60 * 24 * 14)
        return Math.round(diff / 1000 / 60 / 60 / 24);
      else return false;
    },
    pastDueTig() {
      if (this.noTigs || this.coreDataMissing) return false;
      return this.projectData.tigDatas.some(
        (tig) =>
          (tig.status === "PENDING" || tig.status === "PLANNED") &&
          new Date() - new Date(tig.dueDate) > 1000 * 60 * 60 * 24 * 14
      );
    },
  },
  methods: {
    async fetchData() {
      this.loading = true;
      await this.$store.dispatch("risk_management/clearCache");
      await this.$store.dispatch(
        "risk_management/fetchSharedIssues",
        this.projectData.id
      );
      await this.$store.dispatch(
        "risk_management/fetchUnknownIssues",
        this.projectData.prefix
      );
      this.loading = false;
    },
  },
};
</script>

<style scoped lang="scss">
.full-page-message {
  margin-bottom: 1rem;
  margin-left: 2rem;
  margin-right: 2rem;
}
</style>
