<template>
  <div class="onboarding-step-page-main">
    <div class="onboarding-step-page-content pt-6">
      <div v-if="users.length > 0" class="mass-info-card">
        <div class="is-flex is-align-items-center has-gap-4">
          <OnboardingMassSettingIcon />
          <h3 class="my-3">
            {{
              $tf("onboarding.setupUsers.massSetting.title|Csoportos beállítás")
            }}
          </h3>
        </div>
        <p>
          {{
            $tf(
              "onboarding.setupUsers.massSetting.subtitle|Ennek használatakor a kiválasztott adatok az összes kijelölt alkalmazottra beállításra kerülnek. Az üres mezők nem kerülnek felülírásra."
            )
          }}
        </p>
        <b-button
          class="onboarding-button onboarding-button-transparent onboarding-button-small"
          icon-left="user-group"
          @click="openMassSettingModal"
        >
          {{
            $tf(
              "onboarding.setupUsers.massSetting.open|Csoportosan, egyszerre állítom be"
            )
          }}
        </b-button>
      </div>

      <h2>
        {{
          $tf(
            "onboarding.setupUsers.title|Adatok hozzárendelése a felhasználóidhoz"
          )
        }}
      </h2>
      <p>
        {{
          $tf(
            "onboarding.setupUsers.subtitle|Alapértelmezetten mindenkihez beállítjuk az alap adatokat, ezeket módosíthatod egyesével is, valamint a csoportos módosítással is."
          )
        }}
      </p>
      <b-button
        v-if="users.length > 0"
        class="onboarding-button onboarding-button-transparent onboarding-button-small mb-3"
        icon-left="pencil"
        @click="openUserEditModal"
      >
        {{ $tf("onboarding.setupUsers.edit|Adatok szerkesztése") }}
      </b-button>
      <div v-if="users.length < 1">
        <p>
          {{
            $tf(
              "onboarding.setupUsers.empty|Nem találtunk egy felhasználót sem."
            )
          }}
        </p>
      </div>
      <div v-else class="user-setup-container">
        <table class="user-setup-table main-table">
          <tr>
            <th>{{ $tf("onboarding.setupUsers.table.name|Alkalmazott") }}</th>
            <th></th>
            <th>
              {{ $tf("onboarding.setupUsers.table.username|Felhasználónév") }}
            </th>
            <th>{{ $tf("onboarding.setupUsers.table.email|E-mail cím") }}</th>
            <th>{{ $tf("onboarding.setupUsers.table.role|Szerepkör") }}</th>
            <th>{{ $tf("onboarding.setupUsers.table.title|Munkakör") }}</th>
            <th>
              {{ $tf("onboarding.setupUsers.table.seniority|Szenioritás") }}
            </th>
            <th>
              {{ $tf("onboarding.setupUsers.table.competency|Kompetencia") }}
            </th>
            <th>{{ $tf("onboarding.setupUsers.table.department|Terület") }}</th>
            <th>{{ $tf("onboarding.setupUsers.table.tier|Árszint") }}</th>
            <th></th>
          </tr>
          <tr v-for="(user, index) in users" :key="user.id">
            <td>
              <span class="has-font-weight-700">{{ user.name }}</span>
            </td>
            <td>
              <b-icon
                icon="trash"
                class="has-text-blue cursor-pointer"
                size="is-small"
                @click="openRemovalDialog(index)"
              />
            </td>
            <td>
              {{ user.username }}
            </td>
            <td>
              {{ user.email }}
            </td>
            <td>
              {{ roles.find((role) => role.id === user.roleId).name }}
            </td>
            <td>
              {{ user.title ? user.title : "-" }}
            </td>
            <td>
              {{ user.seniority ? user.seniority : "-" }}
            </td>
            <td>
              {{ user.competency ? user.competency : "-" }}
            </td>
            <td>
              {{ user.department ? user.department : "-" }}
            </td>
            <td>
              {{ priceLevels.find((pl) => pl.id === user.tierId).name }}
            </td>
          </tr>
        </table>
      </div>
      <div v-if="users.length > 0" class="mt-3">
        <b-button
          class="onboarding-button onboarding-button-transparent onboarding-button-small"
          icon-left="arrow-up"
          @click="scrollToTop"
        >
          {{ $tf("onboarding.setupUsers.jumpToTop|Ugrás a tetejére") }}
        </b-button>
      </div>
    </div>
    <div class="onboarding-step-page-footer is-justify-content-end">
      <div class="is-flex is-justify-content-end is-flex-direction-column">
        <div class="ml-auto">
          <p v-if="!isEssentialRoleUsed" class="is-danger m-0 mb-1 p-0">
            {{
              $tf(
                "onboarding.setupUsers.essentialRoleNotUsed.warning|Legalább egy felhasználónak rendelkeznie kell a(z) {essentialRole} szerepkörrel!",
                { essentialRole: essentialRole.name }
              )
            }}
          </p>
        </div>
        <div class="is-flex ml-auto has-gap-2">
          <b-button
            class="onboarding-button onboarding-button-transparent onboarding-button-small"
            @click="stepBack"
          >
            {{ $tf("onboarding.setupUsers.back|Vissza") }}
          </b-button>
          <b-button
            class="onboarding-button onboarding-button-small"
            type="is-info"
            @click="stepNext"
            :disabled="!isEssentialRoleUsed"
          >
            {{ $tf("onboarding.setupUsers.continue|Tovább") }}
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ONBOARDING_PAGES } from "@/utils/const";
import { mapGetters } from "vuex";
import { cloneDeep } from "lodash";
import OnboardingMassSettingIcon from "@/components/onboarding/OnboardingMassSettingIcon.vue";
import OnboardingSetupUserMassSettings from "@/components/onboarding/OnboardingSetupUserMassSettings.vue";
import OnboardingSetupUsersEditModal from "@/components/onboarding/OnboardingSetupUsersEditModal.vue";

export default {
  name: "OnboardingSetupUsers",
  components: { OnboardingMassSettingIcon },
  data: function () {
    return {
      ONBOARDING_PAGES,
      users: [],
    };
  },
  computed: {
    ...mapGetters({
      roles: "onboarding/getUsedRoles",
      competencies: "onboarding/getCompetencies",
      priceLevels: "onboarding/getTiers",
      defaultTier: "onboarding/getDefaultTier",
      defaultRole: "onboarding/getDefaultRole",
    }),
    essentialRole() {
      return this.roles.find((role) => role.essential);
    },
    isEssentialRoleUsed() {
      const essentialRole = this.essentialRole;
      if (!essentialRole) return true;
      return this.users.map((user) => user.roleId).includes(essentialRole.id);
    },
  },
  mounted() {
    this.getAndInitializeUsers();
  },
  methods: {
    removeUser(index) {
      this.users.splice(index, 1);
    },
    async save() {
      this.users.forEach((user) => {
        if ((user.role && user.roleId !== user.role.id) || !user.role) {
          user.role = this.roles.find((r) => r.id === user.roleId);
        }
        if (
          (user.priceTier && user.tierId !== user.priceTier.id) ||
          !user.priceTier
        ) {
          user.priceTier = this.priceLevels.find((pl) => pl.id === user.tierId);
        }
        delete user.roleId;
        delete user.tierId;
      });
      await this.$store.dispatch("onboarding/updateUsers", this.users);
    },
    async stepBack() {
      await this.save();
      this.$emit("nextPage", ONBOARDING_PAGES.SETUP_PRICE_LEVEL);
    },
    async stepNext() {
      await this.save();
      this.$emit("nextPage", ONBOARDING_PAGES.SETUP_CLIENTS);
    },
    openMassSettingModal() {
      this.$buefy.modal.open({
        parent: this,
        component: OnboardingSetupUserMassSettings,
        props: {
          users: this.users,
        },
        hasModalCard: false,
        fullScreen: true,
        canCancel: true,
        trapFocus: true,
        onCancel: () => this.getAndInitializeUsers(),
      });
    },
    getAndInitializeUsers() {
      this.users = cloneDeep(this.$store.getters["onboarding/getUsers"]);

      this.users.forEach((user, index) => {
        this.users[index].roleId =
          user.role && this.roles.map((r) => r.id).includes(user.role.id)
            ? user.role.id
            : this.defaultRole.id;
        this.users[index].tierId =
          user.priceTier &&
          this.priceLevels.map((pr) => pr.id).includes(user.priceTier.id)
            ? user.priceTier.id
            : this.defaultTier.id;
      });
    },
    openUserEditModal() {
      this.$buefy.modal.open({
        parent: this,
        component: OnboardingSetupUsersEditModal,
        props: {
          users: this.users,
        },
        hasModalCard: false,
        fullScreen: true,
        canCancel: true,
        trapFocus: true,
        onCancel: () => this.getAndInitializeUsers(),
      });
    },
    openRemovalDialog(index) {
      this.$buefy.dialog.confirm({
        title: this.$tf(
          "onboarding.setupUsers.removalDialog.title|Felhasználó törlése"
        ),
        message: this.$tf(
          "onboarding.setupUsers.removalDialog.message|A felhasználó törlése nem vonható vissza!"
        ),
        confirmText: this.$tf(
          "onboarding.setupUsers.removalDialog.confirm|Törlés"
        ),
        cancelText: this.$tf(
          "onboarding.setupUsers.removalDialog.cancel|Mégse"
        ),
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => this.removeUser(index),
      });
    },
    scrollToTop() {
      document.querySelector(".onboarding-step-page-content").scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/assets/scss/colors.scss";
.user-setup-container {
  max-width: calc(100vw - 580px);
  min-width: 720px;
  padding: 18px 18px;
  font-size: 14px;
  line-height: 150%;
  overflow-x: scroll;
}

.user-setup-table > tr > th.required::after {
  content: " *";
  font-weight: 700;
  color: $red;
}

.user-setup-table > tr > td,
.user-setup-table > tr > th {
  border: none;
  vertical-align: middle;
}

.user-setup-table {
  overflow-x: auto;
}

.user-setup-table > tr > th:not(:nth-child(2)) {
  min-width: 200px;
}

.user-setup-table > tr > th {
  border-bottom: 1px solid $grey-light;
  color: $grey-light;
  font-weight: normal;
  font-size: 14px;
}

.user-setup-table > tr > td {
  font-weight: 500;
  color: $custom-dark-blue;
  font-size: 16px;
}

.mass-info-card {
  max-width: 768px;
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1),
    0 0px 0 1px rgba(10, 10, 10, 0.02);
}
</style>
