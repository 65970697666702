import network from "../../../../utils/network";
import { newLogger } from "@/utils/util";
import StoreCore from "../../../StoreCore";

let logger = newLogger("Absence-Admin");

const urls = {
  admins: "/absence/admins",
  admin: "/absence/admin",
};

const initState = () => {
  return {
    admins: [],
  };
};

const storeCore = new StoreCore();
const actions = {
  fetchAdmins: async function ({ commit, dispatch }, force) {
    try {
      let data = await this.remoteRequest(
        "get",
        urls.admins,
        null,
        true,
        dispatch,
        "Elbírálók letöltése...",
        null,
        force
      );
      commit("saveAdmins", data);
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  saveAdmin: async function ({ dispatch }, admin) {
    try {
      await this.remoteRequest(
        "put",
        urls.admin,
        admin,
        false,
        dispatch,
        "Elbíráló mentése",
        undefined,
        undefined,
        false,
        true,
        {
          progress: "Elbíráló mentése folyamatban...",
          fail: "Elbíráló mentése sikertelen!",
          success: "Elbíráló mentése sikeres!",
        }
      );
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  deleteAdmin: async function ({ dispatch }, id) {
    try {
      await this.remoteRequest(
        "delete",
        `${urls.admin}/${id}`,
        null,
        false,
        dispatch,
        "Elbíráló törlése",
        undefined,
        undefined,
        false,
        true,
        {
          progress: "Elbíráló törlése folyamatban...",
          fail: "Elbíráló törlése sikertelen!",
          success: "Elbíráló törlése sikeres!",
        }
      );
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  clearCache: async function ({}) {
    this.clearCoreCache();
  }.bind(storeCore),
};

const mutations = {
  saveAdmins: (state, admins) => {
    state.admins = admins;
  },
};

const getters = {
  admins: (state) => {
    return state.admins;
  },
};

export default {
  namespaced: true,
  state: initState(),
  mutations: mutations,
  actions: actions,
  getters: getters,
};
