import { newLogger } from "@/utils/util";
import StoreCore from "../../../../StoreCore";

let logger = newLogger("Enterprise-ForecastChanceCategoryStore");

const urls = {
  chanceCategory: "/enterprise/forecast/chance-category",
  chanceCategoryWithId: (id) => `/enterprise/forecast/chance-category/${id}`,
};

const initState = () => {
  return {
    chanceCategories: [],
    chanceCategory: {},
  };
};

const storeCore = new StoreCore();
const actions = {
  fetchChanceCategories: async function ({ commit, dispatch }, force) {
    try {
      let chanceCategory = await this.remoteRequest(
        "get",
        urls.chanceCategory,
        undefined,
        true,
        dispatch,
        "Esélykategóriák betöltése",
        null,
        force
      );
      commit("saveChanceCategories", chanceCategory);
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  fetchChanceCategory: async function (
    { commit, dispatch },
    { id, force } = {}
  ) {
    try {
      let chanceCategory = await this.remoteRequest(
        "get",
        urls.chanceCategoryWithId(id),
        null,
        true,
        dispatch,
        "Esélykategória betöltése",
        null,
        force
      );
      commit("saveChanceCategory", chanceCategory);
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  createChanceCategory: async function ({ dispatch }, chanceCategory) {
    try {
      await this.remoteRequestObject({
        method: "post",
        url: urls.chanceCategory,
        data: chanceCategory,
        cacheable: false,
        dispatch,
        flair: "Esélykategória létrehozása...",
        toast: true,
      });
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
    dispatch("clearCache");
  }.bind(storeCore),
  updateChanceCategory: async function ({ dispatch }, chanceCategory) {
    try {
      await this.remoteRequestObject({
        method: "put",
        url: urls.chanceCategoryWithId(chanceCategory.id),
        data: chanceCategory,
        cacheable: false,
        dispatch,
        flair: "Esélykategória frissítése...",
        toast: true,
      });
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
    dispatch("clearCache");
  }.bind(storeCore),
  deleteChanceCategory: async function ({ dispatch }, id) {
    try {
      await this.remoteRequestObject({
        method: "delete",
        url: urls.chanceCategoryWithId(id),
        cacheable: false,
        dispatch,
        flair: "Esélykategória törlése...",
        toast: true,
      });
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
        return Promise.reject(err);
      }
      dispatch("clearCache");
    }
  }.bind(storeCore),
  clearCache: async function ({}) {
    this.clearCoreCache();
  }.bind(storeCore),
};

const mutations = {
  saveChanceCategories: (state, chanceCategory) => {
    state.chanceCategories = chanceCategory;
  },
  saveChanceCategory: (state, chanceCategory) => {
    state.chanceCategory = chanceCategory;
  },
};

const getters = {
  chanceCategories: (state) => {
    return state.chanceCategories;
  },
  chanceCategory: (state) => {
    return state.chanceCategory;
  },
};

export default {
  namespaced: true,
  state: initState(),
  mutations: mutations,
  actions: actions,
  getters: getters,
};
