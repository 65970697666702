import { newLogger } from "@/utils/util";
import StoreCore from "../../../../StoreCore";

let logger = newLogger("Enterprise-ForecastPlanStore");

const urls = {
  plan: "/enterprise/forecast/plan",
  planWithId: (id) => `/enterprise/forecast/plan/${id}`,
  duplicate: (id) => `/enterprise/forecast/plan/duplicate/${id}`,
};

const initState = () => {
  return {
    plans: [],
    plan: {},
  };
};

const storeCore = new StoreCore();
const actions = {
  fetchPlans: async function ({ commit, dispatch }, { params, force } = {}) {
    try {
      let plan = await this.remoteRequestObject({
        method: "get",
        url: urls.plan,
        data: params,
        cacheable: true,
        dispatch,
        flair: "Tervadatok betöltése",
        force,
        toast: true,
      });
      commit("savePlans", plan);
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
    dispatch("clearCache");
  }.bind(storeCore),
  fetchPlan: async function ({ commit, dispatch }, { id, force } = {}) {
    try {
      let plan = await this.remoteRequest(
        "get",
        urls.planWithId(id),
        null,
        true,
        dispatch,
        "Tervadat betöltése",
        null,
        force
      );
      commit("savePlan", plan);
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  createPlan: async function ({ dispatch }, plan) {
    try {
      await this.remoteRequestObject({
        method: "post",
        url: urls.plan,
        data: plan,
        cacheable: false,
        dispatch,
        flair: "Tervadat létrehozása...",
        toast: true,
      });
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
    dispatch("clearCache");
  }.bind(storeCore),
  updatePlan: async function ({ dispatch }, plan) {
    try {
      await this.remoteRequestObject({
        method: "put",
        url: urls.planWithId(plan.id),
        data: plan,
        cacheable: false,
        dispatch,
        flair: "Tervadat frissítése...",
        toast: true,
      });
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  duplicatePlan: async function ({ dispatch }, id) {
    try {
      await this.remoteRequestObject({
        method: "post",
        url: urls.duplicate(id),
        cacheable: false,
        dispatch,
        flair: "Tervadat duplikálása...",
        toast: true,
      });
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
    dispatch("clearCache");
  }.bind(storeCore),
  deletePlan: async function ({ dispatch }, id) {
    try {
      await this.remoteRequestObject({
        method: "delete",
        url: urls.planWithId(id),
        cacheable: false,
        dispatch,
        flair: "Tervadat törlése...",
        toast: true,
      });
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
    dispatch("clearCache");
  }.bind(storeCore),
  clearCache: async function ({}) {
    this.clearCoreCache();
  }.bind(storeCore),
};

const mutations = {
  savePlans: (state, plan) => {
    state.plans = plan;
  },
  savePlan: (state, plan) => {
    state.plan = plan;
  },
};

const getters = {
  plans: (state) => {
    return state.plans;
  },
  plan: (state) => {
    return state.plan;
  },
};

export default {
  namespaced: true,
  state: initState(),
  mutations: mutations,
  actions: actions,
  getters: getters,
};
