<template>
  <div>
    <enterprise-support-project-details></enterprise-support-project-details>
  </div>
</template>

<script>
import EnterpriseSupportProjectDetails from "../../../components/enterprise/ProjectDetails/SupportProjectDetails";

export default {
  name: "Enterprise-SupportDetails",
  components: {
    EnterpriseSupportProjectDetails,
  },
};
</script>

<style lang="scss"></style>
