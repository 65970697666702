<template>
  <div :class="type">
    <money-modal
      :label="getHeader()"
      @close="closeEditModal()"
      :project-id="projectId"
      :employees="employees"
      :show="editModal"
      :modify="modify"
      :edit-data="editData"
      :type="type"
      :data-type="getDataType"
      :columns="columns"
    />
    <money-modal
      :label="getHeader()"
      @close="closeCreateModal()"
      :project-id="projectId"
      :employees="employees"
      :show="createModal"
      :create="create"
      :type="type"
      :data-type="getDataType"
      :columns="columns"
    />
    <b-modal v-model="sSummary" @close="closeSummaryModal()">
      <div class="card">
        <div class="card-content m-4">
          <p>{{ vSummary }}</p>
        </div>
      </div>
    </b-modal>
    <div class="is-flex is-justify-content-space-between">
      <div>
        <h4 class="title">
          {{ getHeader() }}
          <b-tooltip
            type="is-info"
            :triggers="['click']"
            :auto-close="['outside', 'escape']"
            v-if="type === 'overrides'"
            position="is-right"
          >
            <template v-slot:content>
              <div class="content tooltip">
                <p>
                  {{
                    $tf(
                      "projectpage.override.tooltip|A megadott érték kizárólag ebben a projektben érvényesül, itt viszont általánosan, a dolgozó minden munka bejegyzésére."
                    )
                  }}
                </p>
              </div>
            </template>
            <portal-icon icon="info-circle" />
          </b-tooltip>
        </h4>
      </div>
      <div>
        <b-button
          type="is-info"
          size="is-small"
          rounded
          @click="showCreateModal"
        >
          {{
            $tf("projectpage.overrideAndExpense.add|{type} hozzáadása", {
              type: getDataType,
            })
          }}
        </b-button>
      </div>
    </div>
    <b-table
      :data="data"
      ref="expenseTable"
      :per-page="10"
      detailed
      detail-key="identifier"
      :show-detail-icon="false"
      aria-next-label="Következő lap"
      aria-previous-label="Előző lap"
      aria-page-label="Lap"
      aria-current-label="Aktuális lap"
    >
      <template #empty>
        <div class="has-text-centered">
          {{
            $tf("projectpage.overrideAndExpense.table.empty|Nincsen {type}", {
              type: getDataType.toLowerCase(),
            })
          }}
        </div>
      </template>
      <b-table-column
        v-for="column in columns"
        :key="column.field"
        :field="column.field"
        :label="column.label"
        sortable
        centered
        v-slot="props"
      >
        <template v-if="column.field === 'summary'">
          <b-tooltip
            :label="
              $tf(
                'projectpage.override.table.showSummary|Megjegyzés megjelenítése'
              )
            "
            type="is-light"
          >
            <clickable-icon
              @click="showSummaryModal(props.row[column.field])"
              icon="eye"
              type="is-info"
            />
          </b-tooltip>
        </template>
        <template v-else>
          {{ props.row[column.field] }}
        </template>
      </b-table-column>
      <b-table-column
        field="actions"
        :label="$tf('projectpage.override.table.actions|Műveletek')"
        centered
        v-slot="props"
        width="80"
      >
        <b-tooltip
          :label="$tf('projectpage.override.table.actions.edit|Szerkesztés')"
          type="is-light"
        >
          <clickable-icon
            icon="edit"
            type="is-info"
            @click="showEditModal(props.row)"
          />
        </b-tooltip>
        <b-tooltip
          :label="$tf('projectpage.override.table.actions.delete|Törlés')"
          type="is-light"
        >
          <clickable-icon
            icon="trash"
            type="is-danger"
            @click="delete props.row.identifier"
          />
        </b-tooltip>
      </b-table-column>
    </b-table>
  </div>
</template>

<script>
import { deepCopy } from "@/utils/util";
import PortalIcon from "@/components/module/icon/PortalIcon";
import ClickableIcon from "@/components/module/icon/ClickableIcon";
import MoneyModal from "@/components/enterprise/ProjectDetails/TabItems/MoneyModal";

export default {
  name: "MoneyTable",
  components: {
    MoneyModal,
    PortalIcon,
    ClickableIcon,
  },
  props: {
    type: {
      type: String,
      required: true,
    },
    columns: {
      type: Array,
      required: true,
    },
    delete: {
      type: Function,
      required: true,
    },
    modify: {
      type: Function,
      required: true,
    },
    create: {
      type: Function,
      required: true,
    },
    data: {
      type: Array,
      required: true,
    },
    employees: {
      type: Array,
      required: false,
    },
    projectId: {
      type: Number,
      required: false,
    },
  },
  data() {
    return {
      newData: {},
      modifiedData: {},
      name: "",
      editModal: false,
      createModal: false,
      sSummary: false,
      vSummary: "",
      editData: {},
    };
  },
  computed: {
    getDataType() {
      switch (this.type) {
        case "expenses":
          return this.$tf("projectpage.expense|Kiadás");
        case "overrides":
          return this.$tf("projectpage.override|Felülírás");
        default:
          return this.$tf("projectpage.expenseAndOverrideMisc|Egyéb");
      }
    },
    filteredEmployees() {
      let employees = this.employees || [];
      return employees.filter((element) => {
        if (!this.name || !element.name || !element.username) return false;
        return (
          element.name
            .toString()
            .toLowerCase()
            .indexOf(
              this.name
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .toLowerCase()
            ) >= 0 ||
          element.username
            .toString()
            .toLowerCase()
            .indexOf(
              this.name
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .toLowerCase()
            ) >= 0
        );
      });
    },
  },
  methods: {
    getHeader() {
      switch (this.type) {
        case "expenses":
          return this.$tf("projectpage.expenses|Kiadások");
        case "overrides":
          return this.$tf("projectpage.overrides|Felülírások");
        default:
          return this.$tf("projectpage.expensesAndOverridesMisc|Egyéb");
      }
    },
    toggleDetails(props) {
      this.modifiedData = deepCopy(props.row);
      if (props.row.date) {
        this.modifiedData.date = new Date(props.row.date);
      }
      if (props.row.day) {
        this.modifiedData.day = new Date(props.row.day);
      }
      props.toggleDetails(props.row);
    },
    toggleOverrideDetail(props) {
      this.toggleDetails(props);
      this.name = props.row.displayName;
    },
    closeEditModal() {
      this.editData = {};
      this.editModal = false;
    },
    showEditModal(data) {
      this.editData = deepCopy(data);
      this.editModal = true;
    },
    closeCreateModal() {
      this.createModal = false;
    },
    showCreateModal() {
      this.createModal = true;
    },
    closeSummaryModal() {
      this.sSummary = false;
      this.vSummary = "";
    },
    showSummaryModal(summary) {
      this.vSummary = summary;
      this.sSummary = true;
    },
  },
};
</script>

<style scoped></style>
