<template>
  <section class="section">
    <div class="container">
      <h1 class="title">Statisztikák</h1>
      <b-tabs v-model="activeTab" destroy-on-hide>
        <b-tab-item v-for="year in years" :key="year" :label="String(year)">
          <year-statistics :year="year"></year-statistics>
        </b-tab-item>
      </b-tabs>
    </div>
  </section>
</template>

<script>
import YearStatistics from "@/components/enterprise/statistics/YearStatistics";

export default {
  name: "YearlyStatistics",
  components: {
    YearStatistics,
  },
  data() {
    return {
      activeTab: new Date().getFullYear() - 2020,
    };
  },
  computed: {
    years() {
      let years = [];
      let minYear = 2020;
      while (minYear <= new Date().getFullYear()) {
        years.push(minYear);
        minYear++;
      }
      return years;
    },
  },
};
</script>
