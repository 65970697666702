<template>
  <div
    class="newForecastCard is-flex is-flex-direction-column"
    style="gap: 24px"
  >
    <h3 class="title">
      {{ $tf("projectPage.forecastFutureStatus.title|Hátralévő időszak") }}
    </h3>
    <div
      class="is-flex is-justify-content-space-evenly is-flex-direction-column"
      style="gap: 24px"
    >
      <div>
        <ForecastFutureTable
          class="is-flex-1"
          :project-details="projectDetails"
        ></ForecastFutureTable>
      </div>
      <!--      <div class="is-flex is-justify-content-space-evenly has-gap-4">-->
      <!--        <div class="newForecastCard is-flex-grow-1 is-flex-basis-0">-->
      <!--          <forecast-horizontal-overheaded-of-first-bar-->
      <!--            :title="budgetData.title"-->
      <!--            :tooltip="budgetData.tooltip"-->
      <!--            :data="[-->
      <!--              {-->
      <!--                label: this.$tf(-->
      <!--                  'projectPage.forecastFutureStatus.budgetChart.budget.label|Hátralévő keret'-->
      <!--                ),-->
      <!--                value: Math.round(-->
      <!--                  projectDetails.budget - projectDetails.sumSpentBudget-->
      <!--                ),-->
      <!--                kind: 'ft',-->
      <!--                color: currentColors['forecast-primary'],-->
      <!--              },-->
      <!--              {-->
      <!--                label: this.$tf(-->
      <!--                  'projectPage.forecastFutureStatus.budgetChart.planningForecast.label|Tervtartó FC'-->
      <!--                ),-->
      <!--                value: Math.round(-->
      <!--                  Math.max(-->
      <!--                    projectDetails.forecastPlanningBudget - -->
      <!--                      projectDetails.sumSpentBudget,-->
      <!--                    0-->
      <!--                  )-->
      <!--                ),-->
      <!--                kind: 'ft',-->
      <!--                color: currentColors['forecast-tertiary'],-->
      <!--              },-->
      <!--              {-->
      <!--                label: this.$tf(-->
      <!--                  'projectPage.forecastFutureStatus.budgetChart.trendingForecast.label|Trendtartó FC'-->
      <!--                ),-->
      <!--                value: Math.round(-->
      <!--                  Math.max(-->
      <!--                    projectDetails.forecastTrendingBudget - -->
      <!--                      projectDetails.sumSpentBudget,-->
      <!--                    0-->
      <!--                  )-->
      <!--                ),-->
      <!--                kind: 'ft',-->
      <!--                color: currentColors['forecast-secondary'],-->
      <!--              },-->
      <!--            ]"-->
      <!--          />-->
      <!--        </div>-->
      <!--        <div class="newForecastCard is-flex-grow-1 is-flex-basis-0">-->
      <!--          <forecast-horizontal-overheaded-of-first-bar-->
      <!--            :title="mwdData.title"-->
      <!--            :tooltip="mwdData.tooltip"-->
      <!--            :data="[-->
      <!--              {-->
      <!--                label: this.$tf(-->
      <!--                  'projectPage.forecastFutureStatus.mwdChart.mwd.label|Hátralévő keret'-->
      <!--                ),-->
      <!--                value: projectDetails.commitment - projectDetails.sumSpentMd,-->
      <!--                kind: 'mwd',-->
      <!--                color: currentColors['forecast-primary'],-->
      <!--              },-->
      <!--              {-->
      <!--                label: this.$tf(-->
      <!--                  'projectPage.forecastFutureStatus.mwdChart.planningForecast|Tervtartó FC'-->
      <!--                ),-->
      <!--                value: Math.max(-->
      <!--                  projectDetails.forecastPlanningMd - projectDetails.sumSpentMd,-->
      <!--                  0-->
      <!--                ),-->
      <!--                kind: 'mwd',-->
      <!--                color: currentColors['forecast-tertiary'],-->
      <!--              },-->
      <!--              {-->
      <!--                label: this.$tf(-->
      <!--                  'projectPage.forecastFutureStatus.mwdChart.trendingForecast|Trendtartó FC'-->
      <!--                ),-->
      <!--                value: Math.max(-->
      <!--                  projectDetails.forecastTrendingMd - projectDetails.sumSpentMd,-->
      <!--                  0-->
      <!--                ),-->
      <!--                kind: 'mwd',-->
      <!--                color: currentColors['forecast-secondary'],-->
      <!--              },-->
      <!--            ]"-->
      <!--          />-->
      <!--        </div>-->
      <!--      </div>-->
    </div>
  </div>
</template>

<script>
import StatusMixin from "../../../../../mixins/StatusMixin";
import ForecastFutureTable from "@/components/enterprise/project/module/forecast/ForecastFutureTable.vue";
// import ForecastHorizontalOverheadedOfFirstBar from "@/components/enterprise/project/module/forecast/ForecastHorizontalOverheadedOfFirstBar.vue";
import { mapGetters } from "vuex";
import { getCurrentColorSet } from "@/utils/util";

export default {
  name: "ForecastFutureStatusOverview",
  components: {
    // ForecastHorizontalOverheadedOfFirstBar,
    ForecastFutureTable,
  },
  mixins: [StatusMixin],
  props: {
    projectDetails: {
      type: Object,
      required: true,
    },
    projectData: {
      type: Object,
      required: true,
    },
  },
  computed: {
    currentColors() {
      return getCurrentColorSet();
    },
    ...mapGetters({
      currencySymbol: "uiConfigStore/getCurrencySymbol",
    }),
    budgetData() {
      return {
        title: this.$tf(
          "projectPage.forecastFutureStatus.budgetChart.title|Hátralévő büdzsé / költés - ezer {currency}",
          { currency: this.currencySymbol }
        ),
        tooltip: this.$tf(
          "projectPage.forecastFutureStatus.budgetChart.tooltip|Az oszlopdiagram megmutatja, hogy a projekt hátralévő részében a várható költés hogyan fog alakulni a tervvel szemben. Optimális esetben a két előrejelzési adat a terv alatt van, vagy megegyezik vele. Amennyiben a költés előrejelzés meghaladja a tervezett büdzsé értékét, a diagram jelzi az egyes oszlopokon a különbséget."
        ),
      };
    },
    mwdData() {
      return {
        title: this.$tf(
          "projectPage.forecastFutureStatus.mwdChart.title|Munkaidő - MWD"
        ),
        tooltip: this.$tf(
          "projectPage.forecastFutureStatus.mwdChart.tooltip|Az oszlopdiagram megmutatja, hogy a projekt hátralévő részében a várható erőforrás ráfordítás hogyan fog alakulni a tervvel szemben. Optimális esetben a két előrejelzési adat a terv alatt van, vagy megegyezik vele. Amennyiben a ráfordítás előrejelzés meghaladja a tervezett ráfordítás értékét, a diagram jelzi az egyes oszlopokon a különbséget."
        ),
      };
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/assets/scss/colors.scss";

.is-flex-basis-0 {
  flex-basis: 0;
}
</style>
