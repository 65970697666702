<template>
  <div>
    <ul>
      <li>
        {{
          $tf(
            "riskManagement.unknownIssues.totalTime|Teljes ismeretlen helyre logolt idő:"
          )
        }}
        &nbsp;
        <b>{{
          loading
            ? $tf("riskManagement.unknownIssues.loading|Töltés...")
            : secondToShortWorkDayFormat(issues.sumLoggedSeconds)
        }}</b>
      </li>
      <li>
        {{
          $tf(
            "riskManagement.unknownIssues.totalCost|Teljes (esztimált) rejtett költség:"
          )
        }}
        <b>{{
          loading
            ? $tf("riskManagement.unknownIssues.loading|Töltés...")
            : moneyify(issues.sumEstimatedCost)
        }}</b>
      </li>
      <li>
        {{
          $tf(
            "riskManagement.unknownIssues.issueCount|Ismeretlen jegyek száma:"
          )
        }}
        <b>{{
          loading
            ? $tf("riskManagement.unknownIssues.loading|Töltés...")
            : issues.totalIssueCount + " db"
        }}</b>
      </li>
    </ul>
    <b-table
      :data="issues?.projects || []"
      paginated
      :per-page="50"
      hoverable
      :loading="loading"
      detailed
      detail-key="prefix"
      :show-detail-icon="true"
    >
      <b-table-column
        field="prefix"
        :label="$tf('riskManagement.unknownIssues.table.prefix|Prefix')"
        searchable
        v-slot="props"
      >
        {{ props.row.prefix }}
      </b-table-column>

      <b-table-column
        field="sumLoggedSeconds"
        :label="$tf('riskManagement.unknownIssues.table.seconds|Elköltött idő')"
        sortable
        v-slot="props"
      >
        {{ secondToShortWorkDayFormat(props.row.sumLoggedSeconds) }}
      </b-table-column>

      <b-table-column
        field="sumCost"
        :label="$tf('riskManagement.unknownIssues.table.spent|Elköltött pénz')"
        sortable
        v-slot="props"
      >
        {{ moneyify(props.row.sumCost) }}
      </b-table-column>

      <template #detail="props">
        <span>{{
          $tf(
            "riskManagement.unknownIssues.detailsTable.title|Az egyes ismeretlen Prefixen lévő jegyek adatai"
          )
        }}</span
        ><br />
        <b-table
          :data="props.row.issues"
          ref="inner"
          detailed
          detail-key="issueKey"
        >
          <b-table-column
            field="issueKey"
            :label="
              $tf(
                'riskManagement.unknownIssues.detailsTable.issueKey|Jegy kulcs'
              )
            "
            v-slot="issueProps"
          >
            <a
              :href="`https://loginet.atlassian.net/browse/${issueProps.row.issueKey}`"
              target="_blank"
            >
              {{ issueProps.row.issueKey }}
            </a>
          </b-table-column>
          <b-table-column
            field="sumSeconds"
            :label="
              $tf(
                'riskManagement.unknownIssues.detailsTable.loggedTime|Költség'
              )
            "
            sortable
            v-slot="issueProps"
          >
            <div>
              {{ secondToShortWorkDayFormat(issueProps.row.sumSeconds) }}
            </div>
            <div>{{ moneyify(issueProps.row.sumCost) }}</div>
          </b-table-column>
          <b-table-column
            field="firstStarted"
            :label="
              $tf(
                'riskManagement.unknownIssues.detailsTable.dates|Munka dátumok'
              )
            "
            v-slot="issueProps"
          >
            <div>{{ issueProps.row.firstStarted }}</div>
            <div>{{ issueProps.row.lastStarted }}</div>
          </b-table-column>
          <b-table-column
            field="firstLogged"
            :label="
              $tf(
                'riskManagement.unknownIssues.detailsTable.dates|Logolási dátumok'
              )
            "
            v-slot="issueProps"
          >
            <div>{{ issueProps.row.firstLogged }}</div>
            <div>{{ issueProps.row.lastLogged }}</div>
          </b-table-column>
          <template #detail="issueProps">
            <span>{{
              $tf(
                "riskManagement.unknownIssues.innerTable.title|Az egyes ismeretlen Prefixen lévő jegyek adatai"
              )
            }}</span>
            <b-table :data="issueProps.row.logs" ref="secondInner">
              <b-table-column
                field="name"
                :label="
                  $tf(
                    'riskManagement.unknownIssues.innerTable.user|Munkavállaló'
                  )
                "
                searchable
                v-slot="innerProps"
              >
                <user-info
                  :user="{
                    userId: innerProps.row.userId,
                    name: innerProps.row.name,
                    avatar: innerProps.row.avatar,
                    email: innerProps.row.email,
                    phone: innerProps.row.phone,
                  }"
                  displayMode="long"
                  tooltipPosition="bottom"
                />
              </b-table-column>
              <b-table-column
                field="comment"
                :label="
                  $tf('riskManagement.unknownIssues.innerTable.day|Komment')
                "
                v-slot="innerProps"
              >
                {{ innerProps.row.comment }}
              </b-table-column>
              <b-table-column
                field="day"
                :label="
                  $tf(
                    'riskManagement.unknownIssues.innerTable.day|Munkabejegyzés napja'
                  )
                "
                v-slot="innerProps"
              >
                {{ innerProps.row.day }}
              </b-table-column>
              <b-table-column
                field="seconds"
                :label="
                  $tf('riskManagement.unknownIssues.innerTable.seconds|Költség')
                "
                sortable
                v-slot="innerProps"
              >
                <div>
                  {{ secondToShortWorkDayFormat(innerProps.row.seconds) }}
                </div>
                <div>{{ moneyify(innerProps.row.cost) }}</div>
              </b-table-column>
            </b-table>
          </template>
        </b-table>
      </template>
    </b-table>
  </div>
</template>

<script>
import { secondToShortWorkDayFormat, formatDate, moneyify } from "@/utils/util";
import { mapGetters } from "vuex";
import UserInfo from "@/components/module/info/UserInfo.vue";
export default {
  name: "RiskManagementUnknownTable",
  components: { UserInfo },
  computed: {
    ...mapGetters({
      issues: "risk_management/getUnknownIssues",
    }),
  },
  methods: {
    async fetchData() {
      this.loading = true;
      await this.$store.dispatch("risk_management/clearCache");
      await this.$store.dispatch("risk_management/fetchUnknownIssues");
      this.loading = false;
    },
  },
  watch: {
    onlyMyProjects() {
      this.fetchData();
    },
    showUnknown() {
      this.fetchData();
    },
  },
  data() {
    return {
      moneyify: moneyify,
      secondToShortWorkDayFormat: secondToShortWorkDayFormat,
      formatDate: formatDate,
      onlyMyProjects: true,
      showUnknown: false,
      loading: true,
    };
  },
  async mounted() {
    await this.fetchData();
  },
};
</script>

<style></style>
