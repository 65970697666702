<template>
  <div v-if="isPageLoading">
    <!-- <vue-skeleton-loader
      animation="wave-new"
      width="100%"
      height="30px"
      class="mb-1"
    ></vue-skeleton-loader>
    <vue-skeleton-loader
      animation="wave-new"
      width="100%"
      height="20px"
      class="mb-1"
    ></vue-skeleton-loader>
    <vue-skeleton-loader
      animation="wave-new"
      width="100%"
      height="20px"
      class="mb-1"
    ></vue-skeleton-loader>
    <vue-skeleton-loader
      animation="wave-new"
      width="100%"
      height="20px"
      class="mb-1"
    ></vue-skeleton-loader>
    <vue-skeleton-loader
      animation="wave-new"
      width="100%"
      height="20px"
      class="mb-1"
    ></vue-skeleton-loader>
    <vue-skeleton-loader
      animation="wave-new"
      width="100%"
      height="20px"
      class="mb-1"
    ></vue-skeleton-loader> -->
  </div>
</template>

<script>
import LoadingMixin from "@/mixins/LoadingMixin";

export default {
  name: "LoadingTable",
  mixins: [LoadingMixin],
};
</script>
