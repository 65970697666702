import { newLogger } from "@/utils/util";
import StoreCore from "../../../StoreCore";

let logger = newLogger("Enterprise-OvertimeRequestStore");

const urls = {
  toApprove: "/enterprise/overtime-requests/to-approve",
  overtimeRequest: "/enterprise/overtime-request",
  overtimeRequestWithId: (id) => `/enterprise/overtime-request/${id}`,
  revoke: (overtimeRequestId) =>
    `/enterprise/overtime-request/${overtimeRequestId}/revoke`,
  approve: (overtimeRequestId) =>
    `/enterprise/overtime-request/${overtimeRequestId}/approve`,
  deny: (overtimeRequestId) =>
    `/enterprise/overtime-request/${overtimeRequestId}/deny`,
  past: (user) => `/enterprise/overtime-request/past/${user}`,
  waiting: (user) => `/enterprise/overtime-request/waiting/${user}`,
};

const initState = () => {
  return {
    toApproveRequests: {
      items: [],
    },
    overtimeRequest: {},
    pastRequests: {},
    waitingRequests: {},
  };
};

const storeCore = new StoreCore();
const actions = {
  getOvertimeRequestToApprove: async function (
    { commit, dispatch, state },
    { params, force } = {}
  ) {
    try {
      let data = await this.remoteRequest(
        "get",
        urls.toApprove,
        params,
        true,
        dispatch,
        "Túlóra kérelmek betöltése...",
        null,
        force
      );
      commit("setToApproveRequests", data);
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  createOvertimeRequest: async function ({ dispatch, state }, payload) {
    try {
      await this.remoteRequest(
        "post",
        urls.overtimeRequest,
        payload,
        false,
        dispatch,
        "Túlóra kérelem feladása..."
      );
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  revoke: async function ({ dispatch, state }, overtimeRequestId) {
    try {
      await this.remoteRequest(
        "patch",
        urls.revoke(overtimeRequestId),
        null,
        false,
        dispatch,
        "Túlóra kérelem visszavonása..."
      );
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  approve: async function ({ dispatch, state }, payload) {
    try {
      await this.remoteRequest(
        "patch",
        urls.approve(payload.id),
        payload.request,
        false,
        dispatch,
        "Túlóra kérelem elfogadása..."
      );
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  deny: async function ({ dispatch, state }, id) {
    try {
      await this.remoteRequest(
        "patch",
        urls.deny(id),
        null,
        false,
        dispatch,
        "Túlóra kérelem elutasítása..."
      );
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  getOvertimeRequest: async function ({ commit, dispatch, state }, id) {
    try {
      let data = await this.remoteRequest(
        "get",
        urls.overtimeRequestWithId(id),
        null,
        true,
        dispatch,
        "Túlóra kérelem betöltése..."
      );
      commit("setOvertimeRequest", data);
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  fetchPastOvertimeRequests: async function (
    { commit, dispatch, state },
    payload
  ) {
    try {
      let data = await this.remoteRequest(
        "get",
        urls.past(payload.user),
        payload.pageable,
        true,
        dispatch,
        "Korábbi túlóra kérelmek betöltése...",
        null,
        payload.force
      );
      commit("setPastRequests", data);
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  fetchWaitingOvertimeRequests: async function (
    { commit, dispatch, state },
    payload
  ) {
    try {
      let data = await this.remoteRequest(
        "get",
        urls.waiting(payload.user),
        payload.pageable,
        true,
        dispatch,
        "Várakozó túlóra kérelmek betöltése...",
        null,
        payload.force
      );
      commit("setWaitingRequests", data);
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  clearCache: async function ({}) {
    this.clearCoreCache();
  }.bind(storeCore),
};

const mutations = {
  setToApproveRequests: (state, data) => {
    state.toApproveRequests = data;
  },
  setOvertimeRequest: (state, data) => {
    state.overtimeRequest = data;
  },
  setPastRequests: (state, data) => {
    state.pastRequests = data;
  },
  setWaitingRequests: (state, data) => {
    state.waitingRequests = data;
  },
};

const getters = {
  toApproveRequests: (state) => {
    return state.toApproveRequests;
  },
  overtimeRequest: (state) => {
    return state.overtimeRequest;
  },
  pastOvertimeRequest: (state) => {
    return state.pastRequests;
  },
  waitingOvertimeRequest: (state) => {
    return state.waitingRequests;
  },
};

export default {
  namespaced: true,
  state: initState(),
  mutations: mutations,
  actions: actions,
  getters: getters,
};
