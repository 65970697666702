<template>
  <div>
    <h3 class="title">
      {{ $tf("activityExpenseForm.base.title|Új kiadás felvétele") }}
    </h3>
    <div class="columns mt-5">
      <div class="column">
        <b-field
          :label="$tf('activityExpenseForm.base.date|Kiadás dátuma')"
          label-position="on-border"
        >
          <b-datepicker
            v-model="expenseEdit.date"
            :placeholder="$tf('moneyModal.choose|Válassz')"
            icon="calendar-day"
            trap-focus
          >
          </b-datepicker>
        </b-field>
      </div>
      <div class="column">
        <money-input
          v-model="expenseEdit.amount"
          label-position="on-border"
          :label="$tf('activityExpenseForm.base.amount|Kiadás összege')"
        />
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <b-field
          :label="$tf('activityExpenseForm.base.description|Leírás')"
          label-position="on-border"
        >
          <b-input v-model="expenseEdit.description" type="textarea"></b-input>
        </b-field>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <b-button type="is-info" :expanded="true" @click="createExpense"
          >Mentés</b-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import MoneyInput from "@/components/module/input/MoneyInput.vue";
import { correctWithTimezoneOffset, deepCopy, percentify } from "@/utils/util";

export default {
  name: "ActivityExpenseForm",
  components: { MoneyInput },
  emits: ["expense-submitted"],
  props: {
    activity: {
      type: Object,
      required: true,
    },
    activityType: {
      type: String,
      required: true,
    },
    expenseData: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      expenseEdit: {},
    };
  },
  mounted() {
    if (this.expenseData) {
      this.expenseEdit = deepCopy(this.expenseData);
    }
  },
  methods: {
    percentify,
    async createExpense() {
      const request = deepCopy(this.expenseEdit);
      request.activityId = this.activity.id;
      request.activityType = this.activityType;
      request.date = correctWithTimezoneOffset(new Date(request.date));
      if (this.expenseData) {
        await this.$store.dispatch("support_projects/updateExpense", request);
      } else {
        await this.$store.dispatch("support_projects/createExpense", request);
      }
      this.$emit("expense-submitted");
    },
  },
};
</script>

<style scoped lang="scss"></style>
