<template>
  <div class="onboarding-step-page-main">
    <div class="onboarding-step-page-content">
      <h2>
        {{
          $tf(
            "onboarding.setupCurrency.title|Milyen valutában vezeted a céged belső elszámolását?"
          )
        }}
      </h2>
      <p>
        {{
          $tf(
            "onboarding.setupCurrency.subtitle|A felületeken minden összeg ebben a valutában jelenik majd meg."
          )
        }}
      </p>
      <div>
        <div v-if="currency">
          <b-dropdown
            v-model="currencyId"
            scrollable
            :max-height="200"
            aria-role="list"
          >
            <template #trigger>
              <div class="currency-dropdown-input">
                <img :src="currency.icon" :alt="currency.code" />
                <div class="is-flex-grow-1">
                  {{ currency.code }}
                  &nbsp;-&nbsp;{{ currency.name }}
                </div>
                <b-icon icon="chevron-down" size="is-small"></b-icon>
              </div>
            </template>

            <b-dropdown-item
              v-for="curr in currencies"
              :key="curr.id"
              :value="curr.id"
              aria-role="listitem"
            >
              <div class="media">
                <img
                  class="media-left is-align-self-center"
                  :alt="curr.code"
                  :src="curr.icon"
                />
                <div
                  class="media-content has-text-dark-blue has-font-weight-500"
                >
                  {{ curr.code }}
                  - {{ curr.name }}
                </div>
              </div>
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </div>
      <h2>
        {{
          $tf("onboarding.setupPriceLevels.title|Belső árszintek meghatározása")
        }}
      </h2>
      <p>
        {{
          $tf(
            "onboarding.setupPriceLevels.subtitle|Ez a projektek költségének kiszámításához szükséges. Bármennyi árszintet felvehetsz, viszont alapértelmezett csak egy lehet."
          )
        }}
        <br />
        {{
          $tf(
            "onboarding.setupPriceLevels.howToSetDefaults|Az alapértelmezést a rádiógomb segítségével állíthatod be."
          )
        }}
      </p>
      <div class="pricesetter-container">
        <b-table :data="priceLevels" class="onboarding-price-tiers-table">
          <b-table-column
            :label="
              $tf('onboarding.setupPriceLevels.table.default|Alapértelmezett')
            "
            v-slot="props"
            centered
            cell-class="is-flex is-justify-content-center"
          >
            <div
              class="select-circle has-font-weight-700 is-flex is-justify-content-center is-align-items-center is-flex-shrink-0"
              :class="{
                'select-circle-active': props.index === defaultPriceLevelIndex,
              }"
              @click="selectDefaultPriceLevelIndex(props.index)"
            >
              <b-icon
                class="has-text-white"
                icon="check"
                size="is-small"
                v-if="props.index === defaultPriceLevelIndex"
              />
            </div>
          </b-table-column>
          <b-table-column
            :label="$tf('onboarding.setupPriceLevels.table.name|Név')"
            v-slot="props"
            centered
          >
            <div class="has-font-weight-700">
              {{
                $tf(
                  "onboarding.setupPriceLevels.priceLevel.name|{number}. árszint",
                  { number: props.index + 1 }
                )
              }}
            </div>
          </b-table-column>
          <b-table-column
            :label="
              $tf('onboarding.setupPriceLevels.table.hourlyWage|Bruttó órabér')
            "
            v-slot="props"
            centered
          >
            <div class="is-flex has-gap-2 is-justify-content-center">
              <div class="is-flex is-align-items-center has-gap-1">
                <input
                  v-model="props.row.price"
                  class="moneyInput"
                  @blur="formatMoneys"
                />
              </div>
            </div>
          </b-table-column>
          <b-table-column v-slot="props">
            <div style="width: 32px">
              <b-icon
                class="has-text-blue cursor-pointer"
                size="is-small"
                v-if="props.index !== defaultPriceLevelIndex"
                icon="trash"
                @click="remove(props.index)"
              />
            </div>
          </b-table-column>
        </b-table>
        <b-button
          class="onboarding-button onboarding-button-transparent onboarding-button-small is-align-self-flex-start"
          icon-left="plus"
          @click="addNew"
        >
          {{ $tf("onboarding.setupPriceLevels.add|Újabb hozzáadása") }}
        </b-button>
      </div>
    </div>
    <div class="onboarding-step-page-footer">
      <div class="is-flex ml-auto has-gap-2">
        <b-button
          class="onboarding-button onboarding-button-transparent onboarding-button-small"
          @click="stepBack"
        >
          {{ $tf("onboarding.setupPriceLevels.back|Vissza") }}
        </b-button>
        <b-button
          class="onboarding-button onboarding-button-small"
          type="is-info"
          @click="stepNext"
        >
          {{ $tf("onboarding.setupPriceLevels.continue|Tovább") }}
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { ONBOARDING_PAGES } from "@/utils/const";
import { cloneDeep } from "lodash";
import { mapGetters } from "vuex";

export default {
  name: "OnboardingSetupPriceLevel",
  data: function () {
    return {
      ONBOARDING_PAGES,
      priceLevels: [],
      currencyId: null,
    };
  },
  mounted() {
    this.priceLevels = cloneDeep(this.$store.getters["onboarding/getTiers"]);
    this.formatMoneys();
  },
  computed: {
    defaultPriceLevelIndex() {
      const index = this.priceLevels.findIndex((role) => role.standard);
      return index === -1 ? 0 : index;
    },
    ...mapGetters({
      currency: "onboarding/getCurrency",
      currencies: "onboarding/getCurrencies",
    }),
  },
  methods: {
    remove(index) {
      this.priceLevels.splice(index, 1);
    },
    addNew() {
      this.priceLevels.push({
        price:
          this.sanitizeMoney(
            this.priceLevels[this.priceLevels.length - 1].price
          ) + this.currency.increment,
        standard: false,
        name: this.$tf(
          "onboarding.setupPriceLevels.priceLevel.name|{number}. árszint",
          { number: this.priceLevels.length }
        ),
      });
      this.formatMoneys();
    },
    async save() {
      const pricesToSave = cloneDeep(this.priceLevels);
      pricesToSave.forEach((pl, index) => {
        pl.name = this.$tf(
          "onboarding.setupPriceLevels.priceLevel.name|{number}. árszint",
          { number: index + 1 }
        );
        pl.price = this.sanitizeMoney(pl.price);
      });
      await this.$store.dispatch("onboarding/updateTiers", pricesToSave);
      await this.$store.dispatch("onboarding/fetchInfo");
    },
    async stepBack() {
      await this.save();
      this.$emit("nextPage", ONBOARDING_PAGES.SETUP_USER_PARAMETERS);
    },
    async stepNext() {
      await this.save();
      this.$emit("nextPage", ONBOARDING_PAGES.SETUP_USERS);
    },
    formatMoneys() {
      this.priceLevels.forEach((pl) => {
        pl.price = this.formatMoney(pl.price);
      });
    },
    formatMoney(amount) {
      return this.sanitizeMoney(amount)
        .toFixed(this.currency.decimals ?? 2)
        .toString()
        .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1 ")
        .concat(" ")
        .concat(this.currency.symbol);
    },
    sanitizeMoney(amount) {
      return parseFloat(amount.toString().replace(/[^0-9.]/g, ""));
    },
    selectDefaultPriceLevelIndex(newIndex) {
      const oldIndex = this.defaultPriceLevelIndex;
      this.priceLevels[oldIndex].standard = false;
      this.priceLevels[newIndex].standard = true;
    },
  },
  watch: {
    async currencyId(newVal) {
      const currency = this.currencies.find((c) => c.id === newVal);

      if (this.currency.id != null) {
        const oldCurrency = this.currencies.find(
          (c) => c.id === this.currency.id
        );
        const divisor = oldCurrency.increment / currency.increment;

        this.priceLevels.forEach((pl) => {
          pl.price = pl.price.replace(/[^0-9.]/g, "") / divisor;
        });
      }

      await this.$store.dispatch("onboarding/updateCurrency", currency);
      this.formatMoneys();
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/assets/scss/colors.scss";
.pricesetter-container {
  width: 100%;
  max-width: 512px;
  margin-top: 12px;
  padding: 18px 0;
  font-size: 14px;
  line-height: 150%;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.moneyInput {
  text-align: center;
  height: 100%;
  padding: 8px 16px;
  width: 192px;
  font-weight: 500;
  font-size: 14px;
  color: $custom-dark-blue;
}

.select-circle {
  width: 24px;
  height: 24px;
  border-radius: 50px;
  border: 1px solid #cccccc;
  transition: all 0.3s;
}

.select-circle:hover {
  cursor: pointer;
}

.select-circle-active {
  border: 1px solid #aaaaaa;
  background-color: $blue;
}

.currency-dropdown-input {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
  gap: 8px;
  width: 250px;
  cursor: pointer;
  background: $white;
  border: 1px solid lightgray;
  border-radius: 5px;
  color: $custom-dark-blue;
  font-weight: 500;
  font-size: 14px;
}
</style>

<style lang="scss">
.onboarding-price-tiers-table {
  .table {
    td {
      border: none !important;
    }
  }
}
</style>
