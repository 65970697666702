import { newLogger } from "@/utils/util";
import StoreCore from "../../StoreCore";
import axios from "axios";
import router from "@/router";

let logger = newLogger("BulwarkPasswordPolicyStore");

const urls = {
  passwordPolicy: "/api/bulwark/auth/password-policy",
};

const initState = () => {
  return {
    passwordPolicy: null,
  };
};

const storeCore = new StoreCore();
const actions = {
  fetch: async function ({ commit, tenant }) {
    try {
      let { data } = await axios.get(urls.passwordPolicy, {
        headers: { "X-TENANT-ID": tenant },
      });
      commit("save", data);
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
};

const mutations = {
  save: (state, data) => {
    state.passwordPolicy = data;
  },
};

const getters = {
  get: (state) => {
    return state.passwordPolicy;
  },
};

export default {
  namespaced: true,
  state: initState(),
  mutations: mutations,
  actions: actions,
  getters: getters,
};
