import { newLogger } from "@/utils/util";
import StoreCore from "../../StoreCore";

let logger = newLogger("ProjectRiskStore");

const urls = {
  list: "/enterprise/project-risks",
};

/**
 * Project risk store module
 */

const initState = () => {
  return {
    risks: [],
  };
};

const storeCore = new StoreCore();
const actions = {
  list: async function ({ commit, dispatch }, force) {
    try {
      let data = await this.remoteRequest(
        "get",
        urls.list,
        null,
        true,
        dispatch,
        "Loading project risks...",
        null,
        force
      );
      commit("saveList", data);
    } catch (err) {
      if ("Cached!" !== err) {
        logger.error(err);
      }
    }
  }.bind(storeCore),
  clearCache: async function ({}) {
    this.clearCoreCache();
  }.bind(storeCore),
};

const mutations = {
  saveList: (state, risks) => {
    state.risks = risks;
  },
};

const getters = {
  list: (state) => {
    return state.risks;
  },
};

export default {
  namespaced: true,
  state: initState(),
  mutations: mutations,
  actions: actions,
  getters: getters,
};
