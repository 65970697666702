<template>
  <div class="workers mx-0 container">
    <ProjectCollapse id="worker-list">
      <template #header>
        <h3 class="title">
          {{ $tf("projectPage.tigStatus.title|Mérföldkő áttekintés") }}
          <b-tooltip
            type="is-info"
            :triggers="['click']"
            :auto-close="['outside', 'escape']"
          >
            <template v-slot:content>
              <div class="content tooltip">
                <p>
                  {{
                    $tf(
                      "projectPage.tigStatus.info|A mérföldköveinek tényadatainak összefoglalója"
                    )
                  }}
                </p>
              </div>
            </template>
            <portal-icon icon="info-circle" />
          </b-tooltip>
        </h3>
      </template>
      <div class="content">
        <div class="mt-5 mb-2 is-flex is-align-items-center has-gap-5">
          <div class="is-flex is-align-items-center has-gap-2">
            <div class="legend-stripe legend-stripe-warning" />
            <div>Büdzsé költés</div>
          </div>
          <div class="is-flex is-align-items-center has-gap-2">
            <div class="legend-stripe legend-stripe-default" />
            <div>MWD költés</div>
          </div>
        </div>
        <b-table :data="sortedTigs">
          <b-table-column
            field="nameIdentifier"
            v-slot="props"
            :label="
              $tf('projectpage.tigDetails.table.nameIdentifier|Név / Azonosító')
            "
          >
            <div>{{ props.row.name }}</div>
            <div>{{ props.row.identifier }}</div>
          </b-table-column>
          <b-table-column
            field="startDueDate"
            v-slot="props"
            :label="
              $tf('projectpage.tigDetails.table.startDueDate|Kezdés / Határidő')
            "
            centered
          >
            <div>{{ props.row.startDate }}</div>
            <div>{{ props.row.dueDate }}</div>
          </b-table-column>
          <b-table-column
            field="commitmentSpent"
            v-slot="props"
            :label="
              $tf(
                'projectpage.tigDetails.table.commitmentSpent|Vállalás / MWD Költés'
              )
            "
            centered
          >
            <template v-if="props.row.type === 'UNTRACKED'"> Nincs </template>
            <template v-else>
              <div>{{ workdayify(props.row.commitment) }}</div>
              <div>{{ workdayify(props.row.sumSpentMd) }}</div>
            </template>
          </b-table-column>
          <b-table-column
            field="budgetSpent"
            v-slot="props"
            :label="
              $tf('projectpage.tigDetails.table.budgetSpent|Büdzsé / Költés')
            "
            cell-class="is-fullwidth"
            centered
          >
            <template v-if="props.row.type === 'UNTRACKED'"> Nincs </template>
            <template v-else>
              <div>{{ moneyify(props.row.budget) }}</div>
              <div>{{ moneyify(props.row.sumSpentBudget) }}</div>
            </template>
          </b-table-column>
          <b-table-column
            field="readynessSpent"
            v-slot="props"
            :label="
              $tf('projectpage.tigDetails.table.readynessSpent|Költöttség')
            "
            cell-class="is-fullwidth"
            centered
          >
            <div class="mb-2" v-if="props.row.type !== 'UNTRACKED'">
              <b-progress
                :value="props.row.sumSpentMd / props.row.commitment"
                :max="1"
                type="is-info"
                size="is-small"
              />
            </div>
            <div v-if="props.row.type !== 'UNTRACKED'">
              <b-progress
                :value="props.row.sumSpentBudget / props.row.budget"
                :max="1"
                type="is-warning"
                size="is-small"
              />
            </div>
          </b-table-column>
          <b-table-column
            field="KPI"
            :label="$tf('projectpage.tigDetails.table.kpi|KPI')"
            v-slot="props"
            centered
          >
            <risk-kpi-tag :value="calculateKPI(props.row)" />
          </b-table-column>
          <b-table-column
            field="issueCount"
            v-slot="props"
            :label="$tf('projectpage.tigDetails.table.issueCount|Jegyek száma')"
            centered
            >{{ props.row.issueCount }}</b-table-column
          >
          <b-table-column
            field="status"
            v-slot="props"
            :label="$tf('projectpage.tigDetails.table.status|Státusz')"
            centered
          >
            <b-tag :type="getTigStatusObject(props.row.status).type">
              {{ $tf(getTigStatusObject(props.row.status).text) }}
            </b-tag>
          </b-table-column>
        </b-table>
      </div>
    </ProjectCollapse>
  </div>
</template>

<script>
import {
  secondToWorkDayFormat,
  secondToShortWorkDayFormat,
  moneyify,
  deepCopy,
  percentify,
  workdayify,
  getTigStatusObject,
} from "@/utils/util";
import PortalIcon from "@/components/module/icon/PortalIcon";
import ProjectCollapse from "@/components/enterprise/project/module/ProjectCollapse";
import RiskKpiTag from "@/components/enterprise/project/module/RiskKpiTag.vue";

export default {
  name: "NormalTigStatus",
  props: {
    projectDetails: {
      type: Object,
      required: true,
    },
  },
  components: {
    RiskKpiTag,
    ProjectCollapse,
    PortalIcon,
  },
  data() {
    return {
      workerTab: 0,
      moneyify,
      secondToWorkDayFormat,
      secondToShortWorkDayFormat,
      selectedEmployees: [],
      selectedCompetencies: [],
      graphType: 0,
      showSaturday: false,
      showSunday: false,
    };
  },
  created() {},
  computed: {
    sortedTigs() {
      if (this.projectDetails?.tigDetails) {
        const tigs = deepCopy(this.projectDetails.tigDetails);
        tigs.sort(function (a, b) {
          return new Date(a.startDate) - new Date(b.startDate);
        });
        return tigs;
      }
      return [];
    },
  },
  methods: {
    workdayify,
    getTigStatusObject,
    percentify,
    calculateKPI(tig) {
      let timeRatio = 0;
      if (tig.remainingDays < 1) {
        timeRatio = 1;
      } else if (new Date(tig.startDate) < new Date()) {
        timeRatio = 1 - tig.daysLeftPercentage / 100;
      }
      let costBudgetRatio =
        tig.budget === 0 ? 1 : tig.sumSpentBudget / tig.budget;
      return timeRatio === 0 ? costBudgetRatio : costBudgetRatio / timeRatio;
    },
  },
};
</script>

<style scoped lang="scss">
.legend-stripe {
  height: 0.5rem;
  width: 1.5rem;
  border-radius: 2rem;
}

.legend-stripe-warning {
  background: #ce9700;
}

.legend-stripe-default {
  background: #167df0;
}
</style>
